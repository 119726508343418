import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import MapChart from "../../components/Historic/MapChart";
import LoadingContent from "../../components/LoadingContent";
import DataTableSummary from "./nationalities/DataTableSummary";

const Nationalities = () => {
  const { t } = useTranslation();
  document.title = `LVF1 - ${t("Nationalities")}`;
  const [raceData, setRaceData] = useState<RacesChartData[]>([]);
  const [driverData, setDriverData] = useState<DriversChartData[]>([]);
  const [spanishData, setSpanishData] = useState<DriversChartData[]>([]);
  const [summary, setSummary] = useState<SummaryNationality[]>([]);
  const [circuits, setCircuits] = useState<CircuitsChartData[]>([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/historic/getNationalities`
      )
      .then(
        ({
          data,
        }: {
          data: {
            races: RacesChartData[];
            drivers: DriversChartData[];
            spanish: DriversChartData[];
            summary: SummaryNationality[];
            circuits: CircuitsChartData[];
          };
        }) => {
          setRaceData(data.races);
          setDriverData(data.drivers);
          setSpanishData(data.spanish);
          setSummary(data.summary);
          setCircuits(data.circuits);
        }
      )
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  if (
    raceData.length === 0 ||
    driverData.length === 0 ||
    spanishData.length === 0 ||
    circuits.length === 0 ||
    summary.length === 0
  )
    return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="nationalities-container">
        <div className="frame-turqoise">
          <h3 className="frame-title">
            <b>{t("GraphicRaces").toUpperCase()}</b>
          </h3>
          <MapChart id={"races-map"} race_data={raceData} circuits={circuits} />
        </div>
        <div className="frame-turqoise">
          <h3 className="frame-title">
            <b>{t("GraphicCountries").toUpperCase()}</b>
          </h3>
          <MapChart
            id={"drivers-map"}
            driver_data={driverData}
            spanish_data={spanishData}
          />
        </div>
        <div className="frame-turqoise">
          <h3 className="frame-title">
            <b>{t("SummaryNationality").toUpperCase()}</b>
          </h3>
          <DataTableSummary summary={summary} />
        </div>
      </div>
    </div>
  );
};

export default Nationalities;
