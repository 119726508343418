import React from "react";
const Button = ({ text, onClick }: { text: string; onClick?: () => void }) => {
  return (
    <div className="button-container ">
      <button className="button-lvf1-pushable" onClick={onClick}>
        <span className="button-lvf1-shadow"></span>
        <span className="button-lvf1-edge"></span>
        <span className="button-lvf1-front text">{text}</span>
      </button>
    </div>
  );
};
export default Button;
