import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import { useParams } from "react-router-dom";
import GrandPrixRace from "../../components/GrandPrix/GrandPrixRace";
import ErrorPage from "../ErrorPage";

const Race = () => {
  let { grandprix } = useParams();
  if (grandprix == null) return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={3} />
      <GrandPrixRace grand_prix_name={grandprix} />
    </div>
  );
};

export default Race;
