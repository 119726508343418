import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";
import SelectRaceLivetimingFile from "./parts/SelectRaceLivetimingFile";
import { getDescriptionRace } from "../../../utils/Admin";

const UploadGP = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Upload GP";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refPicture = useRef<any>(null);
  const refQualy = useRef<any>(null);
  const refsRace = useRef<any>([]);

  const [files, setFiles] = useState({
    gp_picture: "",
    qualy_file: "",
    race_files: [],
  });
  const [uploading, setUploading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    grand_prix: "",
    press_conference: "",
    pole_video: "",
    gp_video: "",
    milestones_en: "",
    milestones_es: "",
  });

  const [nextGrandsPrix, setNextGrandsPrix] = useState<
    {
      name: string;
      id: number;
      type_race: string;
      type_races: number;
      type_championship: number;
    }[]
  >();
  const [checkboxState, setCheckboxState] = useState<{
    [key: string]: boolean;
  }>({});

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    const gp = nextGrandsPrix?.find(
      (gp) => gp.id === parseInt(formData.grand_prix)
    );
    const n_races = gp?.type_race.split(" ").length;
    //Case NOT HILLCLIMB => ONLY 1 JSON ALLOWED
    if (gp?.type_races !== 1 && checked && n_races === 1) {
      for (let key in checkboxState) {
        if (checkboxState[key])
          setCheckboxState((prevState) => ({
            ...prevState,
            [key]: false,
          }));
      }
    }
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getNextGrandsPrix`
      )
      .then(({ data }) => {
        setNextGrandsPrix(data);
        setFormData({
          ...formData,
          grand_prix: data[0].id,
        });
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [update]);

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    setFormSubmitted(true);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    let checksInvalidityRace = false;
    nextGrandsPrix
      ?.find((gp) => gp.id === parseInt(formData.grand_prix))
      ?.type_race.split(" ")
      .forEach((race: string, index: number) => {
        if (files.race_files[index] == null) checksInvalidityRace = true;
      });
    if (
      !(form !== null && form.checkValidity() === false) &&
      !checkFileInvalidity(refPicture) &&
      !checkFileInvalidity(refQualy) &&
      !checksInvalidityRace
    ) {
      const formDataFiles = new FormData();
      formDataFiles.append("gp_picture", files.gp_picture);
      formDataFiles.append("qualy_file", files.qualy_file);
      nextGrandsPrix
        ?.find((gp) => gp.id === parseInt(formData.grand_prix))
        ?.type_race.split(" ")
        .forEach((race: string, index: number) => {
          formDataFiles.append("race_file" + index, files.race_files[index]);
        });

      formDataFiles.append("grand_prix", formData.grand_prix);
      formDataFiles.append("press_conference", formData.press_conference);
      formDataFiles.append("pole_video", formData.pole_video);
      formDataFiles.append("gp_video", formData.gp_video);
      formDataFiles.append("milestones_en", formData.milestones_en);
      formDataFiles.append("milestones_es", formData.milestones_es);
      formDataFiles.append("user", user);
      formDataFiles.append("token", JSON.parse(token).token);
      formDataFiles.append("checkboxState", JSON.stringify(checkboxState));

      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/uploadGP`,
          formDataFiles
        )
        .then(({ data }) => {
          toast.success("Grand Prix uploaded successfully");
          setUploading(false);
          setUpdate(!update);
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
  };

  const checkFileInvalidity = (ref: any) => {
    if (!formSubmitted) return false;

    if (ref != null && ref.files != null) {
      return ref.files.length == 0;
    }
    return false;
  };

  const handleChange = (
    ref: any,
    key: "gp_picture" | "qualy_file" | "race_files",
    index?: number
  ) => {
    const file = ref.files[0]; // accessing file
    if (key === "race_files" && index != null) {
      const arr: any = files.race_files;
      arr[index] = file;
      setFiles({ ...files, [key]: arr });
    } else if (index == null) setFiles({ ...files, [key]: file });
  };

  if (!access) return <ErrorPage />;
  if (nextGrandsPrix == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Upload GP Results" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Grand Prix (*)</Form.Label>
              <Form.Select
                name="grand_prix"
                required
                value={formData.grand_prix}
                onChange={(e) => inputChange(e)}
              >
                {nextGrandsPrix.map((gp) => {
                  return (
                    <option key={gp.id} value={gp.id}>
                      {gp.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>GP Picture (*)</Form.Label>
              <Form.Control
                name="picture"
                type="file"
                accept="image/*"
                ref={refPicture}
                onChange={() => handleChange(refPicture.current, "gp_picture")}
                isInvalid={checkFileInvalidity(refPicture.current)}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            {nextGrandsPrix?.find(
              (gp) => gp.id === parseInt(formData.grand_prix)
            )?.type_races !== 1 && (
              <Form.Group className="form-group" as={Col} md="6">
                <Form.Label>XML Qualy (*)</Form.Label>
                <Form.Control
                  name="qualy"
                  type="file"
                  accept="text/xml"
                  ref={refQualy}
                  onChange={() => handleChange(refQualy.current, "qualy_file")}
                  isInvalid={checkFileInvalidity(refQualy.current)}
                />
                <Form.Control.Feedback type="invalid">
                  Field required
                </Form.Control.Feedback>
              </Form.Group>
            )}
            {nextGrandsPrix
              .find((gp) => gp.id === parseInt(formData.grand_prix))
              ?.type_race.split(" ")
              .map((type_race_, index) => {
                const ref_ = (el: any) => (refsRace.current[index] = el);
                return (
                  <Form.Group
                    key={`R${type_race_}`}
                    className="form-group"
                    as={Col}
                    md={
                      nextGrandsPrix?.find(
                        (gp) => gp.id === parseInt(formData.grand_prix)
                      )?.type_races !== 1
                        ? "6"
                        : "12"
                    }
                  >
                    <Form.Label>
                      XML Race{" "}
                      {getDescriptionRace(
                        nextGrandsPrix?.find(
                          (gp) => gp.id === parseInt(formData.grand_prix)
                        ),
                        parseInt(type_race_)
                      )}{" "}
                      (*)
                    </Form.Label>
                    <Form.Control
                      name={`race${type_race_}`}
                      type="file"
                      accept="text/xml"
                      ref={ref_}
                      onChange={() =>
                        handleChange(
                          refsRace.current[index],
                          "race_files",
                          index
                        )
                      }
                      isInvalid={checkFileInvalidity(refsRace.current[index])}
                    />
                    <Form.Control.Feedback type="invalid">
                      Field required
                    </Form.Control.Feedback>
                  </Form.Group>
                );
              })}
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Press Conference</Form.Label>
            <Form.Control
              name="press_conference"
              type="text"
              onChange={(e) => inputChange(e)}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Pole Video</Form.Label>
            <Form.Control
              name="pole_video"
              type="text"
              onChange={(e) => inputChange(e)}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>GP Video</Form.Label>
            <Form.Control
              name="gp_video"
              type="text"
              onChange={(e) => inputChange(e)}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Milestones [EN]</Form.Label>
            <Form.Control
              as="textarea"
              name="milestones_en"
              onChange={(e) => inputChange(e)}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Milestones [ES]</Form.Label>
            <Form.Control
              as="textarea"
              name="milestones_es"
              onChange={(e) => inputChange(e)}
            />
          </Form.Group>
          <SelectRaceLivetimingFile
            gp={nextGrandsPrix.find(
              (gp) => gp.id === parseInt(formData.grand_prix)
            )}
            checkboxState={checkboxState}
            handleCheckboxChange={handleCheckboxChange}
          />
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Upload GP
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UploadGP;
