import React, { useEffect, useState } from "react";
import { i18n } from "../../App";

const MenuItem = ({
  onClick,
  icon,
  title,
  selected,
}: {
  onClick?: any;
  icon: string;
  title: string;
  selected: boolean;
}) => {
  const [titleLines, setTitleLines] = useState<string[]>([]);
  useEffect(() => {
    let lines: string[] = [];
    title.split(" ").forEach((t: string) => {
      if (lines.length === 2) lines[1] += " " + t;
      else lines.push(t);
    });
    setTitleLines(lines);
  }, [i18n.language]);
  return (
    <div
      onClick={onClick}
      className={`hover14 ${selected ? "selected" : "med-opacity"}`}
      title={title}
    >
      <figure>
        <img src={require(`../../assets/Images/Stats/${icon}.png`)} />
      </figure>
      {titleLines.map((line, index) => {
        return <span key={index}>{line}</span>;
      })}
    </div>
  );
};

export default MenuItem;
