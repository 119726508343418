import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import am5lang_de_DE from "@amcharts/amcharts5/locales/de_DE";
import am5lang_en from "@amcharts/amcharts5/locales/en";
import am5lang_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5lang_fr_FR from "@amcharts/amcharts5/locales/fr_FR";
import am5lang_hu_HU from "@amcharts/amcharts5/locales/hu_HU";
import am5lang_it_IT from "@amcharts/amcharts5/locales/it_IT";
import axios from "axios";
import { i18n } from "../../App";
import { useTranslation } from "react-i18next";
import "../../assets/style/historic.css";

const SeasonsChart = ({ data, categories }: { data: any; categories: any }) => {
  const { t } = useTranslation();

  const [chartRoot, setChartRoot] = useState<am5.Root | null>(null);

  useEffect(() => {
    let root: am5.Root | null = null;
    try {
      root = am5.Root.new("chartdiv");
      setChartRoot(root);
    } catch (error) {}
    return () => {
      if (root) {
        root.dispose();
        setChartRoot(null);
      }
    };
  }, []);

  useEffect(() => {
    chartRoot?.dispose();
    drawChart(data, categories);
  }, [data]);

  const getDateFormatString = () => {
    const formatObj = new Intl.DateTimeFormat(i18n.language).formatToParts(
      new Date()
    );
    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "dd";
          case "month":
            return "MM";
          case "year":
            return "yyyy";
          default:
            return obj.value;
        }
      })
      .join("");
  };

  const getLocalLanguage = () => {
    if (i18n.language === "de") return am5lang_de_DE;
    else if (i18n.language === "es") return am5lang_es_ES;
    else if (i18n.language === "fr") return am5lang_fr_FR;
    else if (i18n.language === "hu") return am5lang_hu_HU;
    else if (i18n.language === "it") return am5lang_it_IT;
    return am5lang_en;
  };

  const drawChart = async (data: [], categories: any[]) => {
    if (chartRoot == null) return;

    let root = am5.Root.new("chartdiv");

    root.locale = getLocalLanguage();
    root.dateFormatter.setAll({
      dateFormat: getDateFormatString(),
      dateFields: ["valueX", "openValueX"],
    });

    //Colours deppending on the drak/light mode (labels and grid)
    const defaultColour =
      window.localStorage.getItem("theme") === "dark" ? "#FFFFFF" : "#000000";
    const myTheme = am5.Theme.new(root);
    myTheme.rule("Label").setAll({
      fill: am5.color(defaultColour),
    });
    myTheme.rule("Grid").setAll({
      stroke: am5.color(defaultColour),
    });
    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Material.new(root),
      myTheme,
    ]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    let colors = chart.get("colors");
    if (colors == null) return;
    // Create axes
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererY.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
        //visible: false,
      })
    );
    yAxis.data.setAll(categories);

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "minute", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );
    // Add series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        openValueXField: "start",
        valueXField: "end",
        categoryYField: "category",
        sequencedInterpolation: true,
      })
    );

    series.columns.template.setAll({
      templateField: "columnSettings",
      strokeOpacity: 0,
      tooltipText: `{champ}:\n[bold]{openValueX}[/] - [bold]{valueX}[/]\n${t(
        "Winner"
      )}: {winner}`,
    });
    series.data.setAll(data);

    series.columns.template.adapters.add("tooltipText", (aa: any, bb: any) => {
      if (bb.dataItem.dataContext.category === t("Events")) {
        return `{champ}:\n[bold]{openValueX}[/]\n${t("Winner")}: {winner}`;
      }
      return aa;
    });

    // Add scrollbars
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, { orientation: "horizontal" })
    );

    //On category column click
    series.columns.template.events.on("click", function (ev) {
      const item: any = ev.target?.dataItem?.dataContext;
      const prev =
        item?.category === t("Events")
          ? t("routes:GrandPrix")
          : t("routes:Season");
      window.open(
        `${prev}/${encodeURIComponent(item?.champ.replace(/ /g, ""))}`,
        "_blank",
        "noopener,noreferrer"
      );
    });

    // Make stuff animate on load
    series.appear();
    chart.appear(1000, 100);
    setChartRoot(root);
    return () => root.dispose();
  };

  return (
    <div
      id={`chartdiv`}
      style={{ width: "100%", height: "calc(70vh - 280px)" }}
    ></div>
  );
};

export default SeasonsChart;
