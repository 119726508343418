import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import { CountriesOptions } from "../../../components/SelectRegions";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";
import TeamsAssignments from "../championship/add-championship/parts/TeamsAssignments";

interface GeneralValues {
  simulators: { name: string; id: number }[];
  style_sheets: string[];
  teams: { [key: string]: any }[];
  users: { [key: string]: any }[];
  circuits: { [key: string]: any }[];
  flags: { [key: string]: any }[];
  scorings: { [key: string]: any }[];

  championships?: { [key: string]: any }[];
}

const AddTeamChampionship = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Add Team Championship";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refsTeamsImg = useRef<any>([]);
  const refsTeamsLogosImg = useRef<any>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [generalValues, setGeneralValues] = useState<GeneralValues>();
  const [data, setData] = useState({
    teams: "0",
    championship: "",
  });

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/team/teamChamp`
      )
      .then(({ data }) => {
        setGeneralValues(data);
        setClasses(data.classes);
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });
  }, []);

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (!(form !== null && form.checkValidity() === false)) {
      //las iamenes habra q añadirlas con un name en espeicfico en funcion del team
      const formDataFiles = new FormData();
      formDataFiles.append("user", user);
      formDataFiles.append("token", JSON.parse(token).token);
      refsTeamsImg.current.forEach((curr: any) => {
        if (curr != null) formDataFiles.append("car_images", curr.files[0]);
      });
      let teams_logos: string[] = [];
      refsTeamsLogosImg.current.forEach((curr: any, index: number) => {
        if (curr != null) {
          teams_logos.push(teams[index].team_id);
          formDataFiles.append("logo_images", curr.files[0]);
        }
      });
      formDataFiles.append("championship", data.championship);
      formDataFiles.append("teams", JSON.stringify(teams));
      formDataFiles.append("teams_logos", JSON.stringify(teams_logos));

      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/addTeamsChampionship`,
          formDataFiles
        )
        .then(({ data }) => {
          toast.success("Teams added successfully");
          setUploading(false);
          setData({
            teams: "0",
            championship: "",
          });
        });
      /* .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });*/
    }
    setValidated(true);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container-md">
        <Title text="Create Teams Championship" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Form.Group className="form-group" as={Col} md="12">
            <Form.Label>Championship (*)</Form.Label>
            <Form.Select
              name="championship"
              value={data.championship}
              required
              onChange={(e: any) => {
                inputChange(e);
              }}
            >
              <option value="" disabled>
                None
              </option>
              {generalValues?.championships?.map((champ) => {
                return (
                  <option key={champ.id} value={champ.id}>
                    {champ.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <TeamsAssignments
            generalValues={generalValues}
            teams={teams}
            setTeams={setTeams}
            inputChange={inputChange}
            n_teams={data.teams}
            refsTeamsImg={refsTeamsImg}
            has_classes={true}
            classes={classes}
            data={data}
            setData={setData}
          />
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Add Teams Championship
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddTeamChampionship;
