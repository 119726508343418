import React from "react";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();
  document.title = "LVF1 - " + t("Error");

  return (
    <div id="error-container" className="default-color">
      <h3>{t("Oops")}</h3>
      <p>{t("ErrorMessage")}</p>
    </div>
  );
};

export default ErrorPage;
