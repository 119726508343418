import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Col, Row, Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

interface GP {
  name: string;
  id: number;
  press_conference: string;
  pole_video: string;
  gp_video: string;
  milestones_en: string;
  milestones_es: string;
}

interface Penalty {
  id: number;
  claim_id: string;
  assignment_id: number;
  type_penalty: string;
  value_penalty: number;
  session: number;
  description: string;
  driver: string;
}

interface Claim {
  id: number;
  username: string;
  time: number;
  description: string;
  session: number;
  claim_id: number;
}

const PostPenaltiesGP = () => {
  const { typeUser } = useIsAdmin();
  document.title = "LVF1 - Post Penalties";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const ref = useRef<any>(null);
  const [currentRace, setCurrentRace] = useState(-1);
  const [newId, setNewId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [grandPrixId, setGrandPrixId] = useState(-1);
  const [grandPrixIdTypeRaces, setGrandPrixTypeRaces] = useState(-1);
  const [validatedPenalty, setValidatedPenalty] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [lastGrandsPrix, setLastGrandsPrix] = useState<GP[]>();
  const [races, setRaces] = useState<
    { id: number; type_race: number; laps: number }[]
  >([]);
  const [show, setShow] = useState(false);
  const handleCloseLogoutModal = () => setShow(false);
  const handleShowLogoutModal = () => setShow(true);
  const [selectedGP, setSelectedGP] = useState({
    claims_gp: [],
    penalties_gp: [],
  });
  const [body, setBody] = useState({
    claim_id: null,
    assignment_id: 0,
    session: 0,
    type_penalty: 0,
    value_penalty: "",
    lap_penalty: "",
    grand_prix_event_id: 0,
    description: "",
    driver: "",
  });
  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    const final_value = name === "description" ? value : parseInt(value);
    setBody({
      ...body,
      [name]: final_value,
    });
    if (name === "assignment_id") {
      let name_driver = target.options[target.selectedIndex].text;
      setBody({
        ...body,
        [name]: final_value,
        driver: name_driver,
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getLastGrandsPrixPenalties`
      )
      .then(({ data }) => {
        setLastGrandsPrix(data.nextGrandsPrix);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, []);

  const changeGPData = () => {
    if (ref.current == null) return;
    setGrandPrixId(parseInt(ref.current.value));
    setLoading(true);
    let grand_prix_event_id = ref.current.value;
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getGPPenalties`,
        {
          params: {
            gp: grand_prix_event_id,
          },
        }
      )
      .then(({ data }) => {
        setSelectedGP(data.gp);
        setRaces(data.races);
        setGrandPrixTypeRaces(data.races[0].type_races);
        setAssignments(data.assignments);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  };
  const getSessionName = (session: number) => {
    if (session === -1) return "Q2";
    else if (session === 0) return "Q3";
    else return "R" + session;
  };
  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/updatePenalties`,
        {
          grand_prix_event_id: grandPrixId,
          penalties: selectedGP.penalties_gp,
          user,
          token: JSON.parse(token).token,
        }
      )
      .then(() => {
        toast.success("Penalties applied successfully");
        changeGPData();
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
        throw new Error(response);
      });
    setValidatedPenalty(true);
  };
  const deletePenalty = (penalty_to_delete: Penalty) => {
    const aux_selectedGP = JSON.parse(JSON.stringify(selectedGP));
    let penalties = aux_selectedGP.penalties_gp.filter((p: Penalty) => {
      return p.id !== penalty_to_delete.id;
    });
    aux_selectedGP.penalties_gp = penalties;
    setSelectedGP(aux_selectedGP);
  };
  const penaltyJSX = (penalty: Penalty) => {
    let penalty_text = "";
    if (parseInt(penalty.type_penalty) === 1)
      penalty_text = `+${penalty.value_penalty}s`;
    else if (parseInt(penalty.type_penalty) === 2)
      penalty_text = `+${penalty.value_penalty}pts`;
    else if (parseInt(penalty.type_penalty) === 3) penalty_text = `DQ`;
    else if (parseInt(penalty.type_penalty) === 4)
      penalty_text = `+${penalty.value_penalty} Qpos`;
    else if (parseInt(penalty.type_penalty) === 5) penalty_text = `PitStart`;
    else if (parseInt(penalty.type_penalty) === 6)
      penalty_text = `ExpulsionSeason`;
    else if (parseInt(penalty.type_penalty) === 7)
      penalty_text = `ExpulsionLVF1`;
    else if (parseInt(penalty.type_penalty) === 8)
      penalty_text = `DQChampionship`;
    return (
      <p className="penalty-row">
        <b>{penalty.driver}</b>: {penalty_text} (
        {getSessionName(penalty.session)}){" "}
        <FontAwesomeIcon
          icon={faTrash}
          className="red pointer"
          title="Delete"
          onClick={() => deletePenalty(penalty)}
        />
      </p>
    );
  };

  const submitFormAddPenalty = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      const body_ = {
        ...body,
        grand_prix_event_id: grandPrixId,
        id: "N" + newId,
      };
      setNewId(newId + 1);
      if (Number.isNaN(body_.claim_id)) body_.claim_id = null;
      const aux_selectedGP = JSON.parse(JSON.stringify(selectedGP));
      aux_selectedGP.penalties_gp.push(body_);
      setSelectedGP(aux_selectedGP);
      handleCloseLogoutModal();
    }
    setValidatedPenalty(true);
  };

  if (typeUser !== 2 && typeUser !== 3) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container-lg">
        <Title text="Post Penalties GP" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate onSubmit={submitForm}>
          <Form.Group className="form-group">
            <Form.Label>Grand Prix (*)</Form.Label>
            <Form.Select name="id" required onChange={changeGPData} ref={ref}>
              <option disabled={true} selected={true}>
                None
              </option>
              {lastGrandsPrix?.map((gp) => {
                return (
                  <option key={gp.id} value={gp.id}>
                    {gp.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <p>
            {assignments.length > 0 && (
              <Button
                variant="secondary"
                onClick={() => {
                  handleShowLogoutModal();
                  setValidatedPenalty(false);
                }}
              >
                Add Penalty
              </Button>
            )}
          </p>
          {(selectedGP.claims_gp.length > 0 ||
            selectedGP.penalties_gp.length > 0) && (
            <table id="penalty-table" className="form-group">
              <thead className="text-center">
                <tr>
                  <th>#</th>
                  <th>User</th>
                  <th>Session</th>
                  <th>Time</th>
                  <th>Description</th>
                  <th>Penalties</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  selectedGP.claims_gp.map(
                    (
                      gp: {
                        claim_id: string;
                        description: string;
                        username: string;
                        time: string;
                        session: string;
                      },
                      index: number
                    ) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{gp.username}</td>
                          <td className="text-center">
                            {getSessionName(parseInt(gp.session))}
                          </td>
                          <td className="text-center">{gp.time}</td>
                          <td>{gp.description}</td>
                          <td>
                            {selectedGP.penalties_gp.map(
                              (penalty: Penalty, index: number) => {
                                if (
                                  parseInt(penalty.claim_id) ===
                                  parseInt(gp.claim_id)
                                )
                                  return (
                                    <label key={index}>
                                      {penaltyJSX(penalty)}
                                    </label>
                                  );
                              }
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
                {selectedGP.penalties_gp.map(
                  (penalty: Penalty, index: number) => {
                    if (penalty.claim_id === null) {
                      return (
                        <tr key={index}>
                          <td className="text-center">E{index + 1}</td>
                          <td>Stewards</td>
                          <td className="text-center">
                            {getSessionName(penalty.session)}
                          </td>
                          <td></td>
                          <td>{penalty.description}</td>
                          <td>{penaltyJSX(penalty)}</td>
                        </tr>
                      );
                    }
                  }
                )}
              </tbody>
            </table>
          )}
          <div className="text-center">
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update GP
              </Button>
            )}
            {races[0] != null && races[0].id !== currentRace && (
              <p className="red">
                Careful! If you edit an old race, any driver involved in a
                points penalty who has been the victim of an exclusion will
                incur a loss of penalty information.
              </p>
            )}
          </div>
        </Form>
      </div>

      <Modal size="sm" show={show} onHide={handleCloseLogoutModal}>
        <Form
          noValidate
          validated={validatedPenalty}
          onSubmit={submitFormAddPenalty}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Penalty</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="form-group">
              <Form.Label>Claim (*)</Form.Label>
              <Form.Select
                name="claim_id"
                required
                onChange={inputChange}
                defaultValue=""
              >
                <option value="" disabled>
                  None
                </option>
                {selectedGP.claims_gp.map((claim: Claim) => {
                  return (
                    <option key={`A${claim.claim_id}`} value={claim.claim_id}>
                      {claim.username} - {getSessionName(claim.session)} (
                      {claim.time})
                    </option>
                  );
                })}
                <option value="Stewards">Stewards</option>
              </Form.Select>
            </Form.Group>
            <Row className="mb-3">
              <Form.Group className="form-group" as={Col} md="6">
                <Form.Label>Driver (*)</Form.Label>
                <Form.Select
                  name="assignment_id"
                  required
                  onChange={inputChange}
                  defaultValue=""
                >
                  <option value="" disabled>
                    None
                  </option>
                  {assignments.map(
                    (
                      assignment: { assignment_id: number; username: string },
                      index
                    ) => {
                      return (
                        <option
                          key={assignment.assignment_id}
                          value={assignment.assignment_id}
                        >
                          {assignment.username}
                        </option>
                      );
                    }
                  )}
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-group" as={Col} md="6">
                <Form.Label>Session (*)</Form.Label>
                <Form.Select
                  name="session"
                  required
                  onChange={inputChange}
                  defaultValue=""
                >
                  <option value="" disabled>
                    None
                  </option>
                  {races.map((race: { type_race: number }, index) => {
                    return (
                      <option key={index} value={race.type_race}>
                        R{race.type_race}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group className="form-group" as={Col} md="6">
                <Form.Label>Penalty (*)</Form.Label>
                <Form.Select
                  name="type_penalty"
                  required
                  onChange={inputChange}
                  defaultValue=""
                >
                  <option value="" disabled>
                    None
                  </option>
                  <option value="1">Time</option>
                  {grandPrixIdTypeRaces !== 1 && (
                    <>
                      <option value="2">Points (Driver License)</option>
                      <option value="3">DQ</option>
                      <option value="4">Positions Qualy</option>
                      <option value="5">Pit Lane Start</option>
                      <option value="6">Expulsion Championship</option>
                      <option value="7">Expulsion league</option>
                      <option value="8">DQ Championship</option>
                    </>
                  )}
                </Form.Select>
              </Form.Group>
              {[1, 2, 4].includes(body.type_penalty) && (
                <Form.Group
                  className="form-group"
                  as={Col}
                  md={grandPrixIdTypeRaces === 1 ? "3" : "6"}
                >
                  <Form.Label>Value (*)</Form.Label>
                  <Form.Control
                    name="value_penalty"
                    type="number"
                    required
                    onChange={inputChange}
                    isInvalid={
                      validatedPenalty &&
                      body.value_penalty === "" &&
                      body.type_penalty !== 3
                    }
                  />
                </Form.Group>
              )}
              {body.type_penalty === 1 && grandPrixIdTypeRaces === 1 && (
                <Form.Group className="form-group" as={Col} md="3">
                  <Form.Label>Lap (*)</Form.Label>
                  <Form.Control
                    name="lap_penalty"
                    type="number"
                    required
                    onChange={inputChange}
                    min={1}
                    max={races[0].laps}
                  />
                </Form.Group>
              )}
            </Row>
            <Form.Group className="form-group">
              <Form.Label>Description (*)</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                name="description"
                onChange={inputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Required field
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseLogoutModal}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default PostPenaltiesGP;
