import React, { useEffect, useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../../components/Admin/Title";
import GrandsPrix from "./parts/GrandsPrix";
import TeamsAssignments from "./parts/TeamsAssignments";
import Championship from "./parts/Championship";
import useIsAdmin from "../../../../hooks/useIsAdmin";
import ErrorPage from "../../../ErrorPage";
import { useUser } from "../../../../components/UserContext";
import Classes from "./parts/Classes";

interface GeneralValues {
  simulators: { name: string; id: number }[];
  style_sheets: string[];
  teams: { [key: string]: any }[];
  users: { [key: string]: any }[];
  circuits: { [key: string]: any }[];
  flags: { [key: string]: any }[];
  scorings: { [key: string]: any }[];
}

const AddChampionship = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Add Championship";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [generalValues, setGeneralValues] = useState<GeneralValues>();
  const [data, setData] = useState({
    teams: "0",
    gps: "0",
    game: "",
    styles_file: "",
    finish_percentage: "75",
    type_championship: "1",
    type_races: "0",
    has_teams: "1",
    has_classes: "0",
    hot_lap_points: "",
    hot_lap_points_min_pos: "",
    leaders_points: "",
    most_leader_points: "",
    top_scoreable_results: "",
    team_scoreable_drivers: "",
  });
  const [teams, setTeams] = useState<Team[]>([]);
  const [gps, setGPs] = useState<GPs[]>([]);
  const [classes, setClasses] = useState<Classes[]>([]);
  const refsTeamsImg = useRef<any>([]);
  const refsTeamsLogosImg = useRef<any>([]);

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (data.type_championship === "4") {
      setData({
        ...data,
        hot_lap_points: "0",
        hot_lap_points_min_pos: "0",
        leaders_points: "0",
        most_leader_points: "0",
        top_scoreable_results: "",
        team_scoreable_drivers: "",
        gps: "1",
      });
    }
  }, [data.type_championship]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/general/getNewChampionshipData`
      )
      .then(({ data }) => {
        setGeneralValues(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (!(form !== null && form.checkValidity() === false)) {
      //las iamenes habra q añadirlas con un name en espeicfico en funcion del team
      const formDataFiles = new FormData();
      formDataFiles.append("user", user);
      formDataFiles.append("token", JSON.parse(token).token);
      refsTeamsImg.current.forEach((curr: any) => {
        if (curr != null) formDataFiles.append("car_images", curr.files[0]);
      });
      let teams_logos: string[] = [];
      refsTeamsLogosImg.current.forEach((curr: any, index: number) => {
        if (curr != null) {
          teams_logos.push(teams[index].team_id);
          formDataFiles.append("logo_images", curr.files[0]);
        }
      });
      formDataFiles.append("teams", JSON.stringify(teams));
      formDataFiles.append("teams_logos", JSON.stringify(teams_logos));
      formDataFiles.append("gps", JSON.stringify(gps));
      formDataFiles.append("data", JSON.stringify(data));
      formDataFiles.append("classes", JSON.stringify(classes));

      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/addChampionship`,
          formDataFiles
        )
        .then(({ data }) => {
          toast.success("Championship added successfully");
          setUploading(false);
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container-md">
        <Title
          text={
            data.type_championship === "4" ? "Add Event" : "Add Championship"
          }
        />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Championship
            generalValues={generalValues}
            inputChange={inputChange}
            data={data}
          />
          {data.has_classes === "1" && (
            <Classes classes={classes} setClasses={setClasses} />
          )}
          {data.has_teams === "1" && (
            <TeamsAssignments
              generalValues={generalValues}
              teams={teams}
              setTeams={setTeams}
              inputChange={inputChange}
              n_teams={data.teams}
              refsTeamsImg={refsTeamsImg}
              has_classes={data.has_classes === "1"}
              classes={classes}
              data={data}
              setData={setData}
            />
          )}
          <GrandsPrix
            generalValues={generalValues}
            gps={gps}
            setGPs={setGPs}
            inputChange={inputChange}
            data={data}
          />
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Add Championship
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddChampionship;
