import $ from "jquery";

$(window).on("scroll", function (e) {
  const scrollTop = $(window).scrollTop();
  if (scrollTop !== undefined) {
    if (scrollTop > 111) $(".navbar-home").addClass("navbar-fixed");
    else if (scrollTop <= 111) $(".navbar-home").removeClass("navbar-fixed");
  }
});

function reveal() {
  var reveals = document.querySelectorAll(".reveal");
  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    if (elementTop < windowHeight) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);
