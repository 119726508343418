import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Title = ({ text }: { text: string }) => {
  return (
    <p className="main-title">
      <Link to="/Admin">
        <FontAwesomeIcon icon={faCircleArrowLeft} />
      </Link>
      {text}
    </p>
  );
};

export default Title;
