import React from "react";
import "../../assets/style/standings.css";

const StandingsResult = ({
  driver_result,
  poleman,
  fastest,
  most_leader,
  leaders,
  qualy,
  isDrivers,
  gp,
}: {
  driver_result: RaceResult[];
  poleman: string[];
  fastest: string[];
  most_leader: string[];
  leaders: string[];
  qualy: QualyResult[] | null;
  isDrivers: boolean;
  gp?: any;
}) => {
  let value = "";
  let className = "standings-Empty";
  let raise1 = <></>;
  let raise2 = <></>;
  if (driver_result.length > 0) {
    const qualy_pos = qualy != null && qualy[0] != null ? qualy[0].pos : null;
    const position = driver_result[0].position;
    const time = driver_result[0].time;
    const driver = driver_result[0].username;
    const points = driver_result[0].points;
    if (position === null && time !== null) {
      value = time;
      className = "standings-" + time;
    } else if (position === null) {
      value = "DNP";
      className = "standings-DNP";
    } else if (time === "DNF") {
      value = "Ret";
      className = "standings-Ret";
    } else if (time === "DSQ" || time === "DQ") {
      value = "DSQ";
      className = "standings-DSQ";
    } else if (time === "NC") {
      value = "NC";
      className = "standings-OUT_OF_POINTS";
    } else if (time.slice(-1) === "*") {
      value = position + "†";
    } else {
      value = position.toString();
    }

    if (className === "standings-Empty") {
      if (position === 1) className = "standings-WINNER";
      else if (position === 2) className = "standings-SECOND";
      else if (position === 3) className = "standings-THIRD";
      else if (points > 0) className = "standings-POINTS";
      else className = "standings-OUT_OF_POINTS";
    }
    if (poleman != null && poleman.includes(driver)) className += " bold";
    if (fastest != null && fastest.includes(driver)) className += " italic";

    if (isDrivers)
      className +=
        driver_result[0].hasOwnProperty("scoreable") &&
        driver_result[0].scoreable === false
          ? " med-opacity"
          : "";
    else
      className +=
        driver_result[0].hasOwnProperty("team_scoreable") &&
        driver_result[0].team_scoreable === false
          ? " med-opacity"
          : "";
    if (qualy_pos != null)
      raise1 = <sup className="qualy-raise">{qualy_pos} </sup>;
    if (most_leader != null && most_leader.includes(driver))
      raise2 = <sup> L*</sup>;
    else if (leaders != null && leaders.includes(driver))
      raise2 = <sup> L</sup>;
  } else {
    value = "";
  }

  if (gp != null)
    return (
      <div className={`standings-results driver-results-gp ${className}`}>
        {gp}
        <span>
          {raise1}
          {value}
          {raise2}
        </span>
      </div>
    );

  return (
    <td className={`standings-results ${className}`}>
      {raise1}
      {value}
      {raise2}
    </td>
  );
};

export default StandingsResult;
