import { useState, useRef } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const UpdateRegulation = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Update Regulation";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refEN = useRef<any>(null);
  const refES = useRef<any>(null);
  const refENHC = useRef<any>(null);
  const refESHC = useRef<any>(null);
  const refENEve = useRef<any>(null);
  const refESEve = useRef<any>(null);
  const [pictureEN, setPictureEN] = useState();
  const [pictureES, setPictureES] = useState();
  const [pictureENHC, setPictureENHC] = useState();
  const [pictureESHC, setPictureESHC] = useState();
  const [pictureENEve, setPictureENEve] = useState();
  const [pictureESEve, setPictureESEve] = useState();
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);

  const submitForm = (event: any) => {
    if (token == null) return;
    /* REALOADS BECAUSE THE FOLDER OF THE PDFS IS INSIDE /PUBLIC ! */
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);

    if (pictureEN != null) formDataFiles.append("pictureEN", pictureEN);
    if (pictureES != null) formDataFiles.append("pictureES", pictureES);
    if (pictureENHC != null) formDataFiles.append("pictureENHC", pictureENHC);
    if (pictureESHC != null) formDataFiles.append("pictureESHC", pictureESHC);
    if (pictureENEve != null)
      formDataFiles.append("pictureENEve", pictureENEve);
    if (pictureESEve != null)
      formDataFiles.append("pictureESEve", pictureESEve);

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/updateRegulation`,
          formDataFiles
        )
        .then(() => {
          toast.success("Regulation/s updated successfully");
          setUploading(false);
          setValidated(false);
        })
        .catch(({ response }) => {
          toast.error("Error updating regulation/s");
          setUploading(false);
          setValidated(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Update Regulation/s" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <h4>Main Championship Regulations</h4>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>English Regulation</Form.Label>
              <Form.Control
                type="file"
                ref={refEN}
                accept=".pdf"
                onChange={() => setPictureEN(refEN.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>Spanish Regulation</Form.Label>
              <Form.Control
                type="file"
                ref={refES}
                accept=".pdf"
                onChange={() => setPictureES(refES.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <h4>Secondary Championship Regulations</h4>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>English Regulation</Form.Label>
              <Form.Control
                type="file"
                ref={refENHC}
                accept=".pdf"
                onChange={() => setPictureENHC(refENHC.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>Spanish Regulation</Form.Label>
              <Form.Control
                type="file"
                ref={refESHC}
                accept=".pdf"
                onChange={() => setPictureESHC(refESHC.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <h4>Event Championship Regulations</h4>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>English Regulation</Form.Label>
              <Form.Control
                type="file"
                ref={refENEve}
                accept=".pdf"
                onChange={() => setPictureENEve(refENEve.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>Spanish Regulation</Form.Label>
              <Form.Control
                type="file"
                ref={refESEve}
                accept=".pdf"
                onChange={() => setPictureESEve(refESEve.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update Regulation/s
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateRegulation;
