interface GP {
  name: string;
  id: number;
  type_race: string;
  type_races: number;
  type_championship: number;
}

const getDescriptionRace = (gp: GP | undefined, type: number) => {
  const length = gp?.type_race.split(" ").length;
  if (length === 1) return "";
  if (length === 2 && type === 1) return "Main";
  if (length === 2 && type === 2) return "Sprint";
  return "Type " + type;
};

export { getDescriptionRace, GP };
