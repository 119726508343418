import useIsAdmin from "../../../hooks/useIsAdmin";
import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../../components/UserContext";
import { useTranslation } from "react-i18next";

const SettingsRF2 = ({
  serverTiming,
  tableData,
  typeRaces,
}: {
  serverTiming: ServerTiming;
  tableData: CircleDriver[];
  typeRaces: number;
}) => {
  const { t } = useTranslation();
  const { access } = useIsAdmin();
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [isOpen, setIsOpen] = useState(false);
  const [command, setCommand] = useState("");
  const [show, setShow] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseGrid = () => setShowGrid(false);
  const handleShowGrid = () => setShowGrid(true);
  const [body, setBody] = useState("");
  const [sortedGrid, setSortedGrid] = useState<CircleDriver[]>([]);
  const [q1, setQ1] = useState<
    { pos: number; name: string; fastestLap: string }[]
  >([]);
  const [q2, setQ2] = useState<
    { pos: number; name: string; fastestLap: string }[]
  >([]);

  useEffect(() => {
    if (q2.length > 0 && tableData.length != sortedGrid.length) {
      const grid = sortByQResults();
      setSortedGrid(grid);
    }
  }, [q2, tableData]);

  const sortByQResults = () => {
    const data: CircleDriver[] = [];
    q2.forEach((driver) => {
      const driver_td = tableData.find((d) => d.live_name === driver.name);
      if (driver_td != null) data.push(driver_td);
    });
    tableData.forEach((driver) => {
      const driver_td = data.find((d) => d.live_name === driver.live_name);
      if (driver_td == null) data.push(driver);
    });
    return data;
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const inputChange = ({ target }: { target: any }) => {
    const { value } = target;
    setBody(value);
  };

  const getCurrentGrid = () => {
    if (token == null) return;
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/general/getCurrentGrid`,
        {
          params: {
            trackdata_id: serverTiming.trackdata_id,
          },
        }
      )
      .then(({ data }) => {
        setQ1(data.q1_results);
        setQ2(data.q2_results);
      })
      .catch(({ response }) => {
        toast.error(response);
        throw new Error(response);
      });
  };

  const action = (action: string) => {
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/${body == "" ? "serverAction" : "serverBody"}`,
        {
          server: serverTiming,
          action,
          user,
          body,
          token: JSON.parse(token).token,
        }
      )
      .then(() => {
        toast.success("Config updated successfully");
      })
      .catch(({ response }) => {
        toast.error(response);
        throw new Error(response);
      });
  };

  const sortGrid = () => {
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/sortGrid`,
        {
          server: serverTiming,
          sortedGrid,
          user,
          token: JSON.parse(token).token,
        }
      )
      .then(() => {
        toast.success("Config updated successfully");
      })
      .catch(({ response }) => {
        toast.error(response);
        throw new Error(response);
      });
  };

  const onDragStart = (e: any, index: number) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const onDrop = (e: any, dropIndex: number) => {
    const dragIndex = e.dataTransfer.getData("index");
    const draggedItem = sortedGrid[dragIndex];

    const newSortedGrid = [...sortedGrid];
    newSortedGrid.splice(dragIndex, 1);
    newSortedGrid.splice(dropIndex, 0, draggedItem);

    setSortedGrid(newSortedGrid);
  };
  if (!access) return <></>;
  return (
    <>
      <div className={`dropdown-container-lt ${isOpen ? "open" : ""}`}>
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleMenu}
          className="menu-icon"
        />
        {
          <div className={`dropdown-menu-lt ${isOpen ? "open" : ""}`}>
            <a
              onClick={() => {
                handleShow();
                setCommand("/navigation/action/NAV_NEXT_SESSION");
                setBody("");
              }}
              className="dropdown-item"
            >
              Next Session
            </a>
            <a
              onClick={() => {
                handleShow();
                setCommand("/navigation/action/NAV_RESTART_WEEKEND");
                setBody("");
              }}
              className="dropdown-item"
            >
              Restart Weekend
            </a>
            <a
              onClick={() => {
                handleShow();
                setCommand("/rest/chat");
                setBody("");
              }}
              className="dropdown-item"
            >
              Chat message
            </a>

            {serverTiming.trackdata_session == "WarmUp" && typeRaces !== 1 && (
              <a
                onClick={() => {
                  handleShowGrid();
                  getCurrentGrid();
                }}
                className="dropdown-item"
              >
                Sort Grid
              </a>
            )}
          </div>
        }
      </div>{" "}
      <Modal size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send command to server</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to send the command <b>{command}</b> to the
          server{" "}
          <b>
            {serverTiming.trackdata_name}, ID({serverTiming.trackdata_id})
          </b>
          ?
          {command === "/rest/chat" && (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Body</Form.Label>
              <Form.Control
                name="body"
                type="text"
                value={body}
                onChange={inputChange}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              action(command);
              handleClose();
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={showGrid} onHide={handleCloseGrid}>
        <Modal.Header closeButton>
          <Modal.Title>Order starting grid</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-sort-grid">
          <div id="drag-zone">
            {sortedGrid.map((driver, index) => {
              return (
                <span
                  key={driver.live_name}
                  draggable
                  onDragStart={(e) => onDragStart(e, index)}
                  onDragOver={(e) => onDragOver(e)}
                  onDrop={(e) => onDrop(e, index)}
                  style={{
                    display: "block",
                    padding: "8px",
                    border: "1px solid black",
                    marginBottom: "4px",
                    cursor: "move",
                  }}
                >
                  {index + 1}
                  {". "}
                  {driver.live_name}
                </span>
              );
            })}
          </div>
          <div id="qualy-results">
            {q1.length > 0 && (
              <>
                <h4>Q2</h4>
                <div className="container-qres">
                  {q1.map((driver) => {
                    return (
                      <div className="flex" key={driver.pos}>
                        <div>
                          {driver.pos}
                          {". "}
                        </div>
                        <div>{driver.name}</div>
                        <div>{driver.fastestLap}</div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            <h4>Q3</h4>
            <div className="container-qres">
              {q2.map((driver) => {
                return (
                  <div className="flex" key={driver.pos}>
                    <div>
                      {driver.pos}
                      {". "}
                    </div>
                    <div>{driver.name}</div>
                    <div>{driver.fastestLap}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseGrid}>
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              sortGrid();
              handleCloseGrid();
            }}
          >
            Sort
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SettingsRF2;
