import React, { useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";

interface GeneralValues {
  simulators: { name: string }[];
  style_sheets: string[];
  teams: { [key: string]: any }[];
  users: { [key: string]: any }[];
  circuits: { [key: string]: any }[];
  flags: { [key: string]: any }[];
  scorings: { [key: string]: any }[];
}

const GrandsPrix = ({
  generalValues,
  gps,
  setGPs,
  inputChange,
  data,
}: {
  generalValues: GeneralValues | undefined;
  gps: GPs[];
  setGPs: (gps: any) => void;
  inputChange: ({ target }: { target: any }) => void;
  data: { [key: string]: string };
}) => {
  const raceInputChange = (
    { target }: { target: any },
    index: number,
    index_assignment: number
  ) => {
    const { name, value } = target;
    setGPs((prev_teams: GPs[]) => {
      let aux_teams = prev_teams.slice();
      aux_teams[index]["Races"][index_assignment][name] = value;
      return aux_teams;
    });
  };

  const gpInputChange = ({ target }: { target: any }, index: number) => {
    type options =
      | "name"
      | "date"
      | "initials"
      | "flag"
      | "circuit"
      | "scoring"
      | "n_races";
    const { name, value }: { name: options; value: string } = target;
    setGPs((prev_gps: GPs[]) => {
      let aux_gps = prev_gps.slice();
      aux_gps[index][name] = value;
      return aux_gps;
    });
  };

  useEffect(() => {
    if (data.type_championship === "4") {
      setGPs([
        {
          name: "",
          date: "",
          initials: "",
          flag: "",
          circuit: "",
          scoring: "",
          n_races: "1",
          Races: [
            {
              scoring: "",
              type: "1",
            },
          ],
        },
      ]);
    }
  }, [data.type_championship]);

  const updateGPsRows = (value: string) => {
    setGPs((prev_teams: GPs[]) => {
      const diff = parseInt(value) - prev_teams.length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          prev_teams.push({
            name: "",
            date: "",
            initials: "",
            flag: "",
            circuit: "",
            scoring: "",
            n_races: "1",
            Races: [
              {
                scoring: "",
                type: "1",
              },
            ],
          });
        }
      } else if (diff < 0) {
        for (let i = diff; i < 0; i++) {
          prev_teams.pop();
        }
      }
      return prev_teams;
    });
  };

  const setTotalRaces = ({ target }: { target: any }, index: number) => {
    const { name, value } = target;
    setGPs((prev_teams: GPs[]) => {
      let aux_teams = prev_teams.slice();

      const diff = parseInt(value) - prev_teams[index]["Races"].length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          aux_teams[index]["Races"].push({
            scoring: "",
            type: "1",
          });
        }
      } else if (diff < 0) {
        for (let i = diff; i < 0; i++) {
          aux_teams[index]["Races"].pop();
        }
      }
      return aux_teams;
    });
  };

  return (
    <>
      <h4 className="text-center backgrounded-title">Grands Prix</h4>
      <Form.Group className="form-group">
        <Form.Label>Number of GPs (*)</Form.Label>
        <Form.Control
          name="gps"
          type="number"
          disabled={data.type_championship === "4"}
          required
          value={data.gps}
          onChange={(e: any) => {
            inputChange(e);
            updateGPsRows(e.target.value);
          }}
        />
      </Form.Group>
      {gps.map((gp: GPs, index: number) => {
        return (
          <Row key={index} className="mb-3 AQ">
            <Form.Group as={Col} md="7">
              <Row key={index} className="mb-3">
                <Form.Group as={Col} md="6">
                  <Form.Label>Name (*)</Form.Label>
                  <Form.Control
                    name="name"
                    type="string"
                    value={gp.name}
                    required
                    onChange={(e: any) => {
                      gpInputChange(e, index);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Date (*)</Form.Label>
                  <Form.Control
                    name="date"
                    type="date"
                    value={gp.date}
                    required
                    onChange={(e: any) => {
                      gpInputChange(e, index);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <Form.Label>Initials (*)</Form.Label>
                  <Form.Control
                    name="initials"
                    type="string"
                    value={gp.initials}
                    required
                    onChange={(e: any) => {
                      gpInputChange(e, index);
                    }}
                  />
                </Form.Group>
                <Form.Group className="form-group" as={Col} md="4">
                  <Form.Label>Flag (*)</Form.Label>
                  <Form.Select
                    value={gp.flag}
                    name="flag"
                    required
                    onChange={(e: any) => {
                      gpInputChange(e, index);
                    }}
                  >
                    <option value="" disabled>
                      None
                    </option>
                    {generalValues?.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group" as={Col} md="4">
                  <Form.Label>Circuit (*)</Form.Label>
                  <Form.Select
                    value={gp.circuit}
                    name="circuit"
                    required
                    onChange={(e: any) => {
                      gpInputChange(e, index);
                    }}
                  >
                    <option value="" disabled>
                      None
                    </option>
                    {generalValues?.circuits.map((circuit) => {
                      return (
                        <option key={circuit.id} value={circuit.id}>
                          {circuit.name}{" "}
                          {circuit.layout != null && `(${circuit.layout})`}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group" as={Col} md="4">
                  <Form.Label>Qualy Scoring</Form.Label>
                  <Form.Select
                    value={gp.scoring}
                    name="scoring"
                    disabled={data.type_championship === "4"}
                    onChange={(e: any) => {
                      gpInputChange(e, index);
                    }}
                  >
                    <option value="">None</option>
                    {generalValues?.scorings.map((scoring) => {
                      return (
                        <option key={scoring.id} value={scoring.id}>
                          {scoring.description}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="5">
              <Row key={index} className="mb-3">
                <Form.Group as={Col} md="12" className="flex races-group">
                  <Form.Label>Races (*)</Form.Label>
                  <Form.Control
                    name="n_races"
                    type="number"
                    value={gp.n_races}
                    min="1"
                    required
                    onChange={(e: any) => {
                      gpInputChange(e, index);
                      setTotalRaces(e, index);
                    }}
                  />
                </Form.Group>
                {gp.hasOwnProperty("Races") &&
                  gp["Races"].map((race: any, index_race: number) => {
                    return (
                      <>
                        <Form.Group as={Col} md={data.type == "1" ? "4" : "3"}>
                          <Form.Label>Type (*)</Form.Label>
                          <Form.Select
                            name="type"
                            value={race.type}
                            required
                            onChange={(e: any) => {
                              raceInputChange(e, index, index_race);
                            }}
                          >
                            <option value="" disabled>
                              None
                            </option>
                            <option value="1">1 (Main Race)</option>
                            <option value="2">2 (Sprint Race)</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md={data.type == "1" ? "4" : "3"}>
                          <Form.Label>
                            {data.type_races == "2" ? "Hours" : "Laps"} (*)
                          </Form.Label>
                          <Form.Control
                            name={"lapstime"}
                            type="number"
                            value={race.lapstime}
                            required
                            onChange={(e: any) => {
                              raceInputChange(e, index, index_race);
                            }}
                          />
                        </Form.Group>
                        {data.type_races == "1" && (
                          <Form.Group as={Col} md="4">
                            <Form.Label>Valid Laps (*)</Form.Label>
                            <Form.Control
                              name="valid_laps"
                              type="number"
                              value={race.valid_laps}
                              required
                              onChange={(e: any) => {
                                raceInputChange(e, index, index_race);
                              }}
                            />
                          </Form.Group>
                        )}

                        <Form.Group className="form-group" as={Col} md="6">
                          <Form.Label>Scoring (*)</Form.Label>
                          <Form.Select
                            value={race.scoring}
                            name="scoring"
                            disabled={data.type_championship === "4"}
                            required
                            onChange={(e: any) => {
                              raceInputChange(e, index, index_race);
                            }}
                          >
                            <option value="" disabled>
                              None
                            </option>
                            {generalValues?.scorings.map((scoring) => {
                              return (
                                <option key={scoring.id} value={scoring.id}>
                                  {scoring.description}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </>
                    );
                  })}
              </Row>
            </Form.Group>
          </Row>
        );
      })}
    </>
  );
};

export default GrandsPrix;
