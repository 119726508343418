import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";

interface GeneralValues {
  simulators: { name: string }[];
  style_sheets: string[];
  teams: { [key: string]: any }[];
  users: { [key: string]: any }[];
  circuits: { [key: string]: any }[];
  flags: { [key: string]: any }[];
  scorings: { [key: string]: any }[];
}
const TeamsAssignments = ({
  generalValues,
  teams,
  setTeams,
  inputChange,
  n_teams,
  refsTeamsImg,
  has_classes,
  classes,
  data,
  setData,
}: {
  generalValues: GeneralValues | undefined;
  teams: Team[];
  setTeams: (teams: any) => void;
  inputChange: ({ target }: { target: any }) => void;
  n_teams: string;
  refsTeamsImg: any;
  has_classes: boolean;
  classes: Classes[];
  data: { [key: string]: string };
  setData: (data: any) => void;
}) => {
  const teamInputChange = ({ target }: { target: any }, index: number) => {
    type options = "team_id" | "engine" | "main_color" | "secondary_color";
    const { name, value }: { name: options; value: string } = target;
    setTeams((prev_teams: Team[]) => {
      let aux_teams = prev_teams.slice();
      aux_teams[index][name] = value;
      return aux_teams;
    });
  };

  const assignmentInputChange = (
    { target }: { target: any },
    index: number,
    index_assignment: number
  ) => {
    const { name, value } = target;
    setTeams((prev_teams: Team[]) => {
      let aux_teams = prev_teams.slice();
      aux_teams[index]["Assignments"][index_assignment][name] = value;
      return aux_teams;
    });
  };
  const setTotalAssignments = ({ target }: { target: any }, index: number) => {
    const { name, value } = target;
    setTeams((prev_teams: Team[]) => {
      let aux_teams = prev_teams.slice();
      if (!aux_teams[index].hasOwnProperty("Assignments"))
        aux_teams[index]["Assignments"] = [];

      const diff = parseInt(value) - prev_teams[index]["Assignments"].length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          aux_teams[index]["Assignments"].push({
            driver: "TBA",
            number: "",
          });
        }
      } else if (diff < 0) {
        for (let i = diff; i < 0; i++) {
          aux_teams[index]["Assignments"].pop();
        }
      }
      return aux_teams;
    });
  };

  useEffect(() => {
    if (data.type_championship === "2")
      setData({
        ...data,
        teams: "0",
      });
    updateTeamsRows("0");
  }, [data.type_championship]);

  const updateTeamsRows = (value: string) => {
    setTeams((prev_teams: Team[]) => {
      const diff = parseInt(value) - prev_teams.length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          prev_teams.push({
            team_id: "",
            engine: "",
            logo: "",
            main_color: "#000000",
            secondary_color: "#000000",
            Assignments: [],
            class: "",
          });
        }
      } else if (diff < 0) {
        for (let i = diff; i < 0; i++) {
          prev_teams.pop();
        }
      }
      return prev_teams;
    });
  };

  const logoSelect = (team_id: string) => {
    const team = generalValues?.teams.filter(
      (team: any) => team.id === parseInt(team_id)
    );
    if (team != null && team.length > 0)
      return team[0].logos.map((logo: { logo: string }, index: number) => {
        return (
          <option key={index} value={logo.logo}>
            {logo.logo}
          </option>
        );
      });
  };

  return (
    <>
      <h4 className="text-center backgrounded-title">Teams</h4>
      <Form.Group className="form-group">
        <Form.Label>
          Number of Teams (*)
          {data.type_championship === "2" && (
            <span
              className="profile-bubble"
              data-tooltip="In case external championship, we don't define teams (despite we can use them in the standings) just to be sure they won't be in our statistics."
              data-flow="right"
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="label-icon" />
            </span>
          )}
          {data.type_championship === "4" && (
            <span
              className="profile-bubble"
              data-tooltip="In case event, we can create teams or let us as 0 and the system will create them automatically as PLS championship for example."
              data-flow="right"
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="label-icon" />
            </span>
          )}
        </Form.Label>
        <Form.Control
          name="teams"
          type="number"
          value={n_teams}
          disabled={data.type_championship === "2" ? true : false}
          onChange={(e: any) => {
            inputChange(e);
            updateTeamsRows(e.target.value);
          }}
        />
      </Form.Group>
      {teams.map((team: Team, index: number) => {
        const ref_ = (el: any) => (refsTeamsImg.current[index] = el);
        return (
          <Row key={index} className="mb-3">
            <Form.Group className="form-group" as={Col} md="2">
              <Form.Label>Team (*)</Form.Label>
              <Form.Select
                name="team_id"
                value={team.team_id}
                required
                onChange={(e: any) => {
                  teamInputChange(e, index);
                }}
              >
                <option value="" disabled>
                  None
                </option>
                {generalValues?.teams.map((team) => {
                  return (
                    <option key={team.id} value={team.id}>
                      {team.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="2">
              <Form.Label>Engine (*)</Form.Label>
              <Form.Control
                name="engine"
                type="text"
                required
                value={team.engine}
                onChange={(e: any) => {
                  teamInputChange(e, index);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group text-center" as={Col} md="1">
              <Form.Label>Main (*)</Form.Label>
              <div className="flex-center">
                <Form.Control
                  name="main_color"
                  type="color"
                  required
                  value={team.main_color}
                  onChange={(e: any) => {
                    teamInputChange(e, index);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group text-center" as={Col} md="1">
              <Form.Label>Sec. (*)</Form.Label>
              <div className="flex-center">
                <Form.Control
                  name="secondary_color"
                  type="color"
                  required
                  value={team.secondary_color}
                  onChange={(e: any) => {
                    teamInputChange(e, index);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="form-group"
              as={Col}
              md={has_classes ? "3" : "4"}
            >
              <Form.Label>Car Image (*)</Form.Label>
              <Form.Control type="file" accept="image/*" required ref={ref_} />
            </Form.Group>
            {has_classes && (
              <Form.Group className="form-group" as={Col} md="2">
                <Form.Label>
                  Class {data.has_classes === "1" && "(*)"}
                </Form.Label>
                <Form.Select
                  name="class"
                  value={team.class}
                  required={data.has_classes === "1"}
                  onChange={(e: any) => {
                    teamInputChange(e, index);
                  }}
                >
                  <option value="" disabled={data.has_classes === "1"}>
                    None
                  </option>
                  {classes.map((class_) => {
                    return <option value={class_.name}>{class_.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            )}
            <Form.Group
              className="form-group"
              as={Col}
              md={has_classes ? "1" : "2"}
            >
              <Form.Label>Logo (*)</Form.Label>
              <Form.Select
                name="logo"
                value={team.logo}
                onChange={(e: any) => {
                  teamInputChange(e, index);
                }}
              >
                <option value="">Default</option>
                {logoSelect(team.team_id)}
              </Form.Select>
            </Form.Group>
          </Row>
        );
      })}
      <h4 className="text-center backgrounded-title">Assignments</h4>
      {teams.map((team: Team, index: number) => {
        return (
          <Row key={index} className="mb-3">
            <Form.Group className="form-group" as={Col} md="3">
              <Form.Label>Team (*)</Form.Label>
              <Form.Select value={team.team_id} disabled>
                <option value="" disabled>
                  None
                </option>
                {generalValues?.teams.map((team) => {
                  return (
                    <option key={team.id} value={team.id}>
                      {team.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="1">
              <Form.Label># (*)</Form.Label>
              <Form.Control
                name="n_assignments"
                type="number"
                required
                onChange={(e: any) => {
                  setTotalAssignments(e, index);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="8">
              {team.hasOwnProperty("Assignments") &&
                team["Assignments"].map(
                  (assignment: any, index_assignment: number) => {
                    return (
                      <Row key={index} className="mb-3">
                        <Form.Group as={Col} md="2">
                          <Form.Label>
                            Driver Nº {index_assignment + 1}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="flex" as={Col} md="4">
                          <Form.Label className="label-assignments">
                            Nº Driver
                          </Form.Label>
                          <Form.Control
                            name="number"
                            type="number"
                            value={assignment.number}
                            onChange={(e: any) => {
                              assignmentInputChange(e, index, index_assignment);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="flex" as={Col} md="6">
                          <Form.Label className="label-assignments">
                            Driver (*)
                          </Form.Label>
                          <Form.Select
                            name="driver"
                            value={assignment.driver}
                            required
                            onChange={(e: any) => {
                              assignmentInputChange(e, index, index_assignment);
                            }}
                          >
                            <option value="TBA">TBA</option>
                            {generalValues?.users.map((driver) => {
                              return (
                                <option key={driver.id} value={driver.id}>
                                  {driver.username}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Row>
                    );
                  }
                )}
            </Form.Group>
          </Row>
        );
      })}
    </>
  );
};

export default TeamsAssignments;
