import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { i18n } from "../../../../App";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../../../core/consts";
import { Link } from "react-router-dom";

const Language = ({ collapsed }: { collapsed: boolean }) => {
  const { t } = useTranslation();
  const [showDropdownLanguage, setShowDropdownLanguage] = useState(false);
  const setLanguage = (language: string) => {
    window.localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  };
  useEffect(() => {
    if (window.localStorage.getItem("language") !== null) {
      const language = window.localStorage.getItem("language");
      if (language !== null) {
        i18n.changeLanguage(language);
      }
    }
  }, []);

  const getLanguageURL = (language: string) => {
    const routes = i18n.getResourceBundle(
      i18n.language.split("-")[0],
      "routes"
    );
    const currentPathname = window.location.pathname;
    const currentRouteKey = Object.keys(routes).find(
      (key) => routes[key] === currentPathname
    );
    if (currentRouteKey === undefined) {
      //maybe we have a parameter on the URL
      const explode = currentPathname.split("/");
      const pages = Object.keys(routes);
      let page_translated = "";
      pages.map((page) => {
        if (currentPathname.indexOf(`${t("routes:" + page)}/`) === 0) {
          let params = explode[explode.length - 1];
          if (page === "News")
            params = explode[explode.length - 2] + "/" + params;
          if (page === "Home" && t("routes:Home", { lng: language }) === "/")
            page_translated = `${t("routes:" + page, {
              lng: language,
            })}${params}`;
          else
            page_translated = `${t("routes:" + page, {
              lng: language,
            })}/${params}`;
          return;
        }
      });
      //case we are in some of the championship index pages
      if (page_translated === "") {
        if (t("routes:Home", { lng: language }) === "/")
          page_translated = currentPathname;
        else
          page_translated = `${t("routes:Home", {
            lng: language,
          })}${currentPathname}`;
      }
      return page_translated;
    }
    return t(`routes:${currentRouteKey}`, { lng: language });
  };

  return (
    <NavDropdown
      title={t("Language")}
      show={collapsed ? undefined : showDropdownLanguage}
      onMouseEnter={() => {
        setShowDropdownLanguage(true);
      }}
      onMouseLeave={() => {
        setShowDropdownLanguage(false);
      }}
    >
      {LANGUAGES.map((language) => {
        return (
          <NavDropdown.Item
            as={Link}
            to={getLanguageURL(language.code)}
            onClick={() => {
              setLanguage(language.code);
              setShowDropdownLanguage(false);
            }}
            key={language.code}
            href="#"
          >
            {language.title}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

export default Language;
