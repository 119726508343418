import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const DQDriver = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - DQ Driver";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [dataForm, setDataForm] = useState({
    championship: "",
    driver: "",
    team: "",
  });
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState<{
    drivers: { username: string; assignment_id: number }[];
    champs: { id: number; name: string }[];
  }>();
  const [teams, setTeams] = useState<{ name: string; assignment_id: number }[]>(
    []
  );

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/team/getStandingsAssignments`,
        {
          params: { championship: dataForm.championship },
        }
      )
      .then(({ data }) => {
        setData(data);
        if (dataForm.championship == "")
          setDataForm({
            ...dataForm,
            championship: data.champs[0].id.toString(),
          });
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
        setUploading(false);
        throw new Error(response);
      });
  }, [dataForm.championship]);

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);
    formDataFiles.append("old_assignment", dataForm.driver);
    formDataFiles.append("championship", dataForm.championship);

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/dqDriver`,
          formDataFiles
        )
        .then(() => {
          toast.success("Driver disqualified successfully");
          setUploading(false);
          setValidated(false);
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  const getTeamsFromDriver = (value: string) => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/team/getTeamsFromDriverSeason`,
        {
          params: {
            driver: value,
            championship: dataForm.championship,
          },
        }
      )
      .then(({ data }) => {
        setTeams(data.teams);
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
        setUploading(false);
        throw new Error(response);
      });
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="DQ Driver" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Championships (*)</Form.Label>
              <Form.Select
                name="championship"
                value={dataForm.championship}
                required
                onChange={(e) => {
                  inputChange(e);
                }}
              >
                <option disabled value={""}>
                  None
                </option>
                {data?.champs.map((champ) => {
                  return (
                    <option value={champ.id} key={champ.id}>
                      {champ.name}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Driver (*)</Form.Label>
              <Form.Select
                name="driver"
                required
                value={dataForm.driver}
                onChange={(e) => {
                  inputChange(e);
                  getTeamsFromDriver(e.target.value);
                }}
              >
                <option value="" disabled>
                  None
                </option>
                {data?.drivers.map((driver) => {
                  return (
                    <option
                      value={driver.assignment_id}
                      key={driver.assignment_id}
                    >
                      {driver.username}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                DQ Driver
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DQDriver;
