import React, {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  SetStateAction,
} from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  CountriesOptions,
  SpanishRegionsOptions,
} from "../../../components/SelectRegions";
import { md5Cipher } from "../../../utils/MD5Cipher";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";
import CropperUser from "../../../components/CropperUser";
import Select from "react-select";

interface User {
  id: number;
  username: string;
  name: string;
  surname: string;
  password: string;
  birthday: string;
  mail: string;
  initials: string;
  flag_name: string;
  type_user: number;
}

const UpdateDriver = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Update Driver";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refPicture = useRef<any>(null);
  const [picture, setPicture] = useState<any>("");
  const [file2, setFile2] = useState("");
  const [uploading, setUploading] = useState(false);

  const [selectedUser, setSelectedUser] = useState<User>();
  const [users, setUsers] = useState<User[]>();
  const [selectedOption, setSelectedOption] = useState(
    users?.find((user) => user.id === selectedUser?.id) || null
  );

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/user/getUsers`
      )
      .then(({ data }) => {
        setSelectedUser(data[0]);
        setUsers(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  const changeGPData = (event: any) => {
    const user = users?.filter((gp) => gp.id === parseInt(event.target.value));
    if (user != null) setSelectedUser(user[0]);
  };

  const inputChange = ({ target }: { target: any }) => {
    if (selectedUser == null) return;
    const { name, value } = target;
    setSelectedUser({
      ...selectedUser,
      [name]: value,
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);
    if (picture != null) formDataFiles.append("driver_picture", file2);

    if (selectedUser == null) return;
    formDataFiles.append("user_id", selectedUser.id.toString());
    formDataFiles.append("username", selectedUser.username);
    if (selectedUser.name !== null && selectedUser.name !== "")
      formDataFiles.append("name", selectedUser.name);
    if (selectedUser.surname !== null && selectedUser.surname !== "")
      formDataFiles.append("surname", selectedUser.surname);
    if (selectedUser.password !== null && selectedUser.password !== "")
      formDataFiles.append("password", md5Cipher(selectedUser.password));
    if (selectedUser.birthday !== null && selectedUser.birthday !== "")
      formDataFiles.append("birthday", selectedUser.birthday);
    if (selectedUser.mail !== null && selectedUser.mail !== "")
      formDataFiles.append("mail", selectedUser.mail);
    if (selectedUser.initials !== null && selectedUser.initials !== "")
      formDataFiles.append("initials", selectedUser.initials);
    formDataFiles.append("flag_name", selectedUser.flag_name);
    formDataFiles.append("type_user", selectedUser.type_user.toString());

    setUploading(true);
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/user/updateDriver`,
        formDataFiles
      )
      .then(() => {
        toast.success("Driver updated successfully");
        setUploading(false);
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
        setUploading(false);
        throw new Error(response);
      });
  };

  // Mapea las opciones para que sean compatibles con react-select
  const options: any = users?.map((user) => ({
    value: user.id,
    label: user.username,
  }));

  const handleChange = (option: any) => {
    setSelectedOption(option);

    // Crea un evento sintético para pasar los datos al formulario original
    const syntheticEvent = {
      target: { name: "id", value: option?.value },
    };

    inputChange(syntheticEvent);
    changeGPData(syntheticEvent);
  };

  if (!access) return <ErrorPage />;
  if (users == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Update Driver" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>User (*)</Form.Label>
              <Select
                name="id"
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder="Select an user..."
                isClearable
                required
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Username (*)</Form.Label>
              <Form.Control
                name="username"
                type="text"
                required
                onChange={(e) => inputChange(e)}
                value={
                  selectedUser?.username == null ? "" : selectedUser?.username
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                onChange={(e) => inputChange(e)}
                value={selectedUser?.name == null ? "" : selectedUser?.name}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Surname</Form.Label>
              <Form.Control
                name="surname"
                type="text"
                onChange={(e) => inputChange(e)}
                value={
                  selectedUser?.surname == null ? "" : selectedUser?.surname
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                onChange={(e) => inputChange(e)}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Birthday</Form.Label>
              <Form.Control
                name="birthday"
                type="date"
                onChange={(e) => inputChange(e)}
                value={
                  selectedUser?.birthday == null ? "" : selectedUser?.birthday
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="8">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="mail"
                type="text"
                pattern=".+@.+"
                onChange={(e) => inputChange(e)}
                value={selectedUser?.mail == null ? "" : selectedUser?.mail}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="4">
              <Form.Label>Initials</Form.Label>
              <Form.Control
                name="initials"
                type="text"
                onChange={(e) => inputChange(e)}
                value={
                  selectedUser?.initials == null ? "" : selectedUser?.initials
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="8">
              <Form.Label>Region (*)</Form.Label>
              <Form.Select
                name="flag_name"
                value={selectedUser?.flag_name}
                required
                onChange={(e) => {
                  inputChange(e);
                }}
              >
                <option disabled value="">
                  None
                </option>
                <CountriesOptions />
                <SpanishRegionsOptions />
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="4">
              <Form.Label>Rol (*)</Form.Label>
              <Form.Select
                name="type_user"
                onChange={(e) => inputChange(e)}
                value={
                  selectedUser?.type_user == null ? "" : selectedUser?.type_user
                }
              >
                <option value="1">Normal</option>
                <option value="2">Admin</option>
                <option value="3">Steward</option>
                <option value="4">Reporter</option>
                <option value="0">Banned</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Picture</Form.Label>
            <Form.Control
              name="driver_picture"
              type="file"
              accept="image/*"
              ref={refPicture}
              onChange={() => setPicture(refPicture.current.files[0])}
            />
            <Form.Control.Feedback type="invalid">
              Field required
            </Form.Control.Feedback>
            <CropperUser file={picture} setFile2={setFile2} />
          </Form.Group>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update Driver
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateDriver;
