import React, { useState, useRef } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const CreateGame = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Create Game";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refPicture = useRef<any>(null);
  const [picture, setPicture] = useState();
  const [name, setName] = useState("");
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("name", name);
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);

    if (picture != null) formDataFiles.append("game_icon", picture);

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/createGame`,
          formDataFiles
        )
        .then(() => {
          toast.success("Game created successfully");
          setUploading(false);
          setValidated(false);
          setName("");
        })
        .catch(({ response }) => {
          toast.error("Error: Game duplicated");
          setUploading(false);
          setValidated(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Create Game" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>Game Name (*)</Form.Label>
              <Form.Control
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>Game Icon (*)</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                required
                ref={refPicture}
                onChange={() => setPicture(refPicture.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Create Game
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateGame;
