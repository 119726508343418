import { useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";

const IndividualSessions = ({
  secIndividual,
  setSecIndividual,
}: {
  secIndividual: any;
  setSecIndividual: any;
}) => {
  const [dates, setDates] = useState(1);

  const handleDatesChange = (e: any) => {
    const newNumberOfDates = parseInt(e.target.value, 10);
    if (newNumberOfDates < 1) return;

    // Crear un nuevo array con el número de fechas indicado, llenando con objetos
    const updatedDatesArray = Array.from(
      { length: newNumberOfDates },
      (_, index) => ({
        date: "", // Valor por defecto para 'date'
        ini_time: "", // Valor por defecto para 'ini_time'
        end_time: "", // Valor por defecto para 'end_time'
      })
    );
    // Actualizar el estado de secIndividual
    setSecIndividual({
      ...secIndividual,
      dates: updatedDatesArray,
    });
  };

  const jsxRaceDates = () => {
    const JSXelements = [];
    for (var i = 0; i < secIndividual.dates.length; i++) {
      const date = new Date(secIndividual.dates[i].date);
      // We get date
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Sumamos 1 porque los meses comienzan desde 0
      const day = date.getUTCDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const renderElement = (i: number) => {
        return (
          <Form.Group className="form-group" as={Col} md="4">
            <Form.Label>Session {i + 1} Time (start-end) (CET)</Form.Label>
            <Form.Control
              type="date"
              required={true}
              value={formattedDate}
              onChange={(e: any) => {
                const updatedDates = [...secIndividual.dates];
                updatedDates[i] = {
                  ...updatedDates[i],
                  date: e.target.value,
                };
                setSecIndividual({
                  ...secIndividual,
                  dates: updatedDates,
                });
              }}
            ></Form.Control>
            <Form.Control
              type="time"
              required={true}
              onChange={(e: any) => {
                const updatedDates = [...secIndividual.dates];
                updatedDates[i] = {
                  ...updatedDates[i],
                  ini_time: e.target.value,
                };
                setSecIndividual({
                  ...secIndividual,
                  dates: updatedDates,
                });
              }}
              value={secIndividual.dates[i].ini_time}
            />
            <Form.Control.Feedback type="invalid">
              Field required
            </Form.Control.Feedback>
            <Form.Control
              type="time"
              required={true}
              onChange={(e: any) => {
                const updatedDates = [...secIndividual.dates];
                updatedDates[i] = {
                  ...updatedDates[i],
                  end_time: e.target.value,
                };
                setSecIndividual({
                  ...secIndividual,
                  dates: updatedDates,
                });
              }}
              value={secIndividual.dates[i].end_time}
            />
            <Form.Control.Feedback type="invalid">
              Field required
            </Form.Control.Feedback>
          </Form.Group>
        );
      };
      JSXelements.push(renderElement(i));
    }
    return JSXelements;
  };

  return (
    <>
      <Form.Group className="form-group" as={Col} md="4">
        <Form.Label>Time Slots (min)</Form.Label>
        <Form.Control
          required
          type="number"
          min="1"
          value={secIndividual.slots}
          onChange={(e: any) => {
            if (e.target.value < 1) return;
            setSecIndividual({
              ...secIndividual,
              slots: e.target.value,
            });
          }}
        />
        <Form.Control.Feedback type="invalid">
          Field required
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group" as={Col} md="3">
        <Form.Label>Race Dates</Form.Label>
        <Form.Control
          required
          type="number"
          min="1"
          value={dates}
          onChange={(e: any) => {
            if (e.target.value < 1) return;
            setDates(e.target.value);
            handleDatesChange(e);
          }}
        />
        <Form.Control.Feedback type="invalid">
          Field required
        </Form.Control.Feedback>
      </Form.Group>
      {jsxRaceDates()}
      {}
    </>
  );
};

export default IndividualSessions;
