import React from "react";
import { useState } from "react";
import { Nav, Form, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../../../components/UserContext";

const Login = ({ toast }: { toast: any }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [body, setBody] = useState({ username: "", password: "" });
  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setBody({
      ...body,
      [name]: value,
    });
  };

  const { login, setUserConnection } = useUser() as any;

  const handleSignUp = () => {
    handleClose();
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Nav.Link onClick={handleShow}>{t("Login")}</Nav.Link>
      <Modal size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Login")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("Username")}</Form.Label>
              <Form.Control
                name="username"
                type="text"
                value={body.username}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>{t("Password")}</Form.Label>
              <Form.Control
                name="password"
                type="password"
                value={body.password}
                onChange={inputChange}
              />
            </Form.Group>
            {error === true && <span className="error">{t("LogInError")}</span>}
            <Link
              to={t("routes:Home")}
              className="driver"
              onClick={handleSignUp}
            >
              {t("SignUpFromLogIn")}
            </Link>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              login(body, function (response: any) {
                if (response === true) {
                  toast.success(t("LogInSuccess"));
                  setUserConnection();
                } else setError(true);
                body.password = "";
              });
            }}
          >
            {t("Login")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Login;
