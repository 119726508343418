import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { i18n } from "../../../App";
import Flag from "../../../components/Flag";

const DataTableSummary = ({ summary }: { summary: SummaryNationality[] }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<NationalitySummaryDataTable[]>([]);
  const [theme, setTheme] = useState("light");
  const columns = [
    {
      name: "#",
      selector: (row: NationalitySummaryDataTable) => row.pos,
      sortable: true,
      width: "65px",
    },
    {
      name: t("Country"),
      selector: (row: NationalitySummaryDataTable) => row.country,
      width: "190px",
    },
    {
      name: t("Points"),
      selector: (row: NationalitySummaryDataTable) => row.points,
      sortable: true,
    },
    {
      name: t("Races"),
      selector: (row: NationalitySummaryDataTable) => row.races,
      sortable: true,
    },
    {
      name: t("Wins"),
      selector: (row: NationalitySummaryDataTable) => row.wins,
      sortable: true,
    },
    {
      name: t("Podiums"),
      selector: (row: NationalitySummaryDataTable) => row.podiums,
      sortable: true,
    },
    {
      name: t("Poles"),
      selector: (row: NationalitySummaryDataTable) => row.poles,
      sortable: true,
    },
    {
      name: t("HotLaps"),
      selector: (row: NationalitySummaryDataTable) => row.fastest_laps,
      sortable: true,
    },
    {
      name: t("Drivers"),
      selector: (row: NationalitySummaryDataTable) => row.drivers,
      sortable: true,
    },
  ];

  useEffect(() => {
    const theme_ = window.localStorage.getItem("theme");
    setTheme(theme_ != null && theme_ === "light" ? "dark" : "light");
    const rows_: NationalitySummaryDataTable[] = [];
    summary?.forEach((row, index) => {
      rows_.push({
        pos: index + 1,
        country: (
          <>
            <Flag flag_code={row.code} name={t(`countries:${row.name}`)} />
            {t(`countries:${row.name}`)}
          </>
        ),
        points: row.points,
        races: row.races,
        poles: row.poles,
        wins: row.wins,
        podiums: row.podiums,
        fastest_laps: row.fastest_laps,
        drivers: row.drivers,
      });
    });
    setRows(rows_);
  }, [window.localStorage.getItem("theme"), i18n.language, summary]);

  return (
    <div id="summary-nationalities">
      <DataTable
        columns={columns}
        data={rows}
        pagination
        theme={theme}
        paginationPerPage={20}
        paginationRowsPerPageOptions={[20, 50, 100]}
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: t("All"),
          rowsPerPageText: t("RowsPerPage"),
          rangeSeparatorText: t("of"),
        }}
      />
    </div>
  );
};

export default DataTableSummary;
