import {
  faArrowRight,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import Title from "../../../components/Admin/Title";
import LoadingContent from "../../../components/LoadingContent";
import { useUser } from "../../../components/UserContext";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";

const DriverPhoto = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Driver Photo";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [data, setData] =
    useState<{ driver: string; id: number; hasImage?: boolean }[]>();
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/user/getDriverImageRequests`
      )
      .then(({ data }) => {
        setData(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [update]);

  const post = (driver: number, type: number) => {
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/user/imageRequestTo`,
        {
          driver,
          type,
          user,
          token: JSON.parse(token).token,
        }
      )
      .then(() => {
        const type_ = type === 1 ? "replaced" : "deleted";
        toast.success("Driver image " + type_ + " successfully");
        setUpdate(!update);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  };

  if (!access) return <ErrorPage />;
  if (data == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Driver Photos" />
        <div id="requests-container">
          {data.length > 0 ? (
            data.map((driver) => {
              return (
                <div className="driverphoto-request">
                  <p className="backgrounded-title">
                    <b>{driver.driver}</b>
                  </p>
                  <img
                    className="old-photo driver-photo"
                    src={
                      driver.hasImage != null && driver.hasImage
                        ? `/assets/Drivers/${driver.id}.png`
                        : require(`../../../assets/Images/NoPhoto.png`)
                    }
                  />
                  <FontAwesomeIcon icon={faArrowRight} />
                  <img
                    className="new-photo driver-photo"
                    src={`/assets/Drivers/Requests/${driver.id}.png`}
                  />
                  <div className="requests-buttons">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      title="Accept"
                      className="green"
                      onClick={() => post(driver.id, 1)}
                    />
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      title="Reject"
                      className="red"
                      onClick={() => post(driver.id, 0)}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-center">
              There are no driver photo requests yet.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DriverPhoto;
