import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import FormNews from "../../../components/News/FormNews";

const CreateNew = () => {
  const { typeUser } = useIsAdmin();
  document.title = "LVF1 - Create New";

  if (typeUser !== 2 && typeUser !== 4) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container no-width-limit">
        <Title text="Create New" />
        <small className="mandatory-fields">(*) Required fields</small>
        <FormNews
          data={{
            title_en: "",
            title_es: "",
            caption_es: "",
            caption_en: "",
            championship_id: -1,
            content_es: "",
            content_en: "",
            grand_prix_event_id: null,
            id: -1,
          }}
          title={"Create New"}
        />
      </div>
    </div>
  );
};

export default CreateNew;
