export const LANGUAGES = [
  {
    title: "Español",
    code: "es",
  },
  {
    title: "English",
    code: "en",
  },
  {
    title: "Italiano",
    code: "it",
  },
  {
    title: "Française",
    code: "fr",
  },
  {
    title: "Deutsch",
    code: "de",
  },
  {
    title: "Magyar",
    code: "hu",
  },
];
