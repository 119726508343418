import "../../assets/style/grandprix_tables.css";
import React, { useEffect, useState } from "react";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import GrandPrixSessionTitle from "../../components/GrandPrix/GrandPrixSessionTitle";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LoadingContent from "../../components/LoadingContent";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";

interface RaceData {
  [key: string]: {
    Incidents: Incidents;
    Chat: Chat[];
    Penalty: Penalty[];
  };
}

interface Incidents {
  Summary: any;
  Detailed: any;
}
interface Chat {
  et: string;
  $t: string;
}
interface Penalty {
  driver: string;
  received_served: string;
  type_penalty: string;
  description: string;
  time: string;
}

const Incidents = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingCharts, setLoadingCharts] = useState(true);
  let { grandprix } = useParams();
  const [incidents, setIncidents] = useState<RaceData>({});
  const [charts, setCharts] = useState<any>([]);
  //const [update, setUpdate] = useState(0);

  useEffect(() => {
    //First, we remove the previous charts in case they exist
    charts.forEach((chart: any) => {
      chart.dispose();
    });
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getIncidents`,
        {
          params: {
            name: grandprix,
          },
        }
      )
      .then(({ data }) => {
        setIncidents(data);
        setLoading(false);
        Object.keys(data).forEach((key) => {
          setTimeout(() => {
            var div = document.getElementById(`incidentsdiv${key}`);
            if (div) {
              var root = am5.Root.new(`incidentsdiv${key}`);
              drawIncidentsChart(root, data[key].Incidents.Summary);
              //We save the charts in case we have to remove it later
              setCharts((charts: any) => [...charts, root]);
            }
          }, 0);
        });
        setLoadingCharts(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        setLoadingCharts(false);
        throw new Error(response);
      });
    //if (update == 0) setUpdate(1);
  }, [window.localStorage.getItem("theme")]);

  const drawIncidentsChart = (root: am5.Root, data: []) => {
    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Material.new(root),
    ]);

    // Create chart
    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: am5.percent(50),
        x: am5.percent(30),
      })
    );
    // Create series
    const defaultColour =
      window.localStorage.getItem("theme") === "dark" ? "#FFFFFF" : "#000000";
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "incidents",
        categoryField: "driver",
        legendLabelText: `[${defaultColour}][bold]{driver}`,
        legendValueText: `[${defaultColour}]{incidents}`,
        /*legendLabelText: "[{fill}]{driver}[/]",
        legendValueText: "[bold {fill}]{incidents}[/]",*/
      })
    );
    series.slices.template.set("tooltipText", "[bold]{driver}:[/] {incidents}");

    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);

    series.labels.template.setAll({
      textType: "circular",
      centerX: 0,
      centerY: 0,
    });

    // Set data
    series.data.setAll(data);

    // Create legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        layout: root.verticalLayout,
        height: am5.percent(90),
        x: am5.percent(-30),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );
    legend.data.setAll(series.dataItems);

    //With this we prevent showing labels and ticks when we press legend category
    legend.itemContainers.each(function (itemContainer: any) {
      itemContainer.events.removeType("click");
      itemContainer.events.on("click", function (event: any) {
        var target = event.target;
        var dataItem = target.dataItem;
        if (dataItem) {
          var seriesDataItem = dataItem.dataContext;
          if (seriesDataItem) {
            var slice = seriesDataItem.get("slice");
            if (slice && slice.set) {
              if (slice.get("active")) {
                slice.set("active", false);
              } else {
                slice.set("active", true);
              }
            }
          }
        }
      });
    });

    // Play initial series animation
    series.appear(1000, 100);

    return () => root.dispose();
  };

  if (loading || loadingCharts) return <LoadingContent />;
  else if (grandprix == null || Object.keys(incidents).length === 0)
    return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={8} />
      {!loading &&
        Object.keys(incidents).map((key) => {
          return (
            <div key={key} className="gp-incidents-container flex">
              <div className="incidents-container frame-turqoise">
                <GrandPrixSessionTitle
                  session={key}
                  race_length={Object.entries(incidents).length}
                  suffix={t("Incidents")}
                />
                <div
                  key={key}
                  id={`incidentsdiv${key}`}
                  style={{ width: "100%", height: "calc(100vh - 280px)" }}
                ></div>
              </div>
              <div className="penalties-chat-container">
                {incidents[key] != null &&
                  incidents[key].Penalty.length > 0 && (
                    <div className="chat-penalty-container frame-turqoise">
                      <GrandPrixSessionTitle
                        session={key}
                        race_length={Object.entries(incidents).length}
                        suffix={t("Penalties")}
                      />
                      <div className="text-penalties-container">
                        <table>
                          <thead className="text-center">
                            <tr>
                              <th>{t("Time")}</th>
                              <th>{t("Driver")}</th>
                              <th>{t("Penalty")}</th>
                              <th>{t("Status")}</th>
                              <th>{t("Description")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {incidents[key].Penalty.map(
                              (chat: Penalty, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td className="text-center">{chat.time}</td>
                                    <td>{chat.driver}</td>
                                    <td className="text-center">
                                      {chat.type_penalty}
                                    </td>
                                    <td
                                      className={`text-center ${
                                        chat.received_served === "Served"
                                          ? "background-green"
                                          : "background-red"
                                      }`}
                                    >
                                      {chat.received_served}
                                    </td>
                                    <td>{chat.description}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                {incidents[key] != null &&
                  incidents[key].Chat != null &&
                  incidents[key].Chat.length > 0 && (
                    <div className="chat-penalty-container frame-turqoise">
                      <GrandPrixSessionTitle
                        session={key}
                        race_length={Object.entries(incidents).length}
                        suffix={t("Chat")}
                      />
                      <div className="text-penalties-container">
                        <table>
                          <thead className="text-center">
                            <tr>
                              <th>{t("Time")}</th>
                              <th>{t("Message")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {incidents[key].Chat.map(
                              (chat: Chat, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td className="text-center">{chat.et}</td>
                                    <td>{chat.$t}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Incidents;
