import React from "react";
import { useTranslation } from "react-i18next";

const Flag = ({
  flag_code,
  name,
}: {
  flag_code: string | null;
  name: string | null;
}) => {
  const { t } = useTranslation();
  if (flag_code == null) flag_code = "unk";
  if (name == null) name = "Undefined";
  return (
    <img
      className="flag"
      src={`/assets/Flags/${flag_code}.png`}
      alt={t(`countries:${name}`)}
      title={t(`countries:${name}`)}
    />
  );
};

export default Flag;
