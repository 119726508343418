import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const TeamCorrelations = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Team Correlations";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [dataForm, setDataForm] = useState<
    {
      xml_team_name: string;
      team_name: string;
    }[]
  >([]);
  const [teams, setTeams] = useState<{ name: string }[]>([]);
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/team/getCorrelations`
      )
      .then(({ data }) => {
        setDataForm(data.correlations);
        setTeams(data.teams);
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
        setUploading(false);
        throw new Error(response);
      });
  }, [updateData]);

  const inputChange = ({ target }: { target: any }, index: number) => {
    type options = "xml_team_name" | "team_name";
    const { name, value }: { name: options; value: string } = target;
    setDataForm((prev_data: { xml_team_name: string; team_name: string }[]) => {
      let aux_data = prev_data.slice();
      aux_data[index][name] = value;
      return aux_data;
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/updateCorrelations`,
          {
            correlations: dataForm,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(() => {
          toast.success("Team correlations updated successfully");
          setUploading(false);
          setValidated(false);
          setUpdateData(!updateData);
        })
        .catch(({ response }) => {
          //toast.error(response.data.message);
          toast.error("Error: Team duplicated!");
          setUploading(false);
          setValidated(false);
          setUpdateData(!updateData);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  const deleteCorrelation = (index: number) => {
    const aux_dataForm = JSON.parse(JSON.stringify(dataForm));
    aux_dataForm.splice(index, 1);
    setDataForm(aux_dataForm);
  };

  const createCorrelation = () => {
    const aux_dataForm = JSON.parse(JSON.stringify(dataForm));
    aux_dataForm.unshift({ xml_team_name: "", team_name: "" });
    setDataForm(aux_dataForm);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Team Correlations" />
        <small className="mandatory-fields">(*) Required fields</small>
        <div className="form-group text-center">
          <Button variant="secondary" onClick={() => createCorrelation()}>
            Create Correlation
          </Button>
        </div>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          {dataForm.map((team, index) => (
            <Row key={index} className="mb-3">
              <Form.Group className="form-group" as={Col} md="5">
                <Form.Label>Team Name (*)</Form.Label>
                <Form.Select
                  value={team.team_name}
                  name="team_name"
                  required
                  onChange={(e: any) => {
                    inputChange(e, index);
                  }}
                >
                  <option value="" disabled>
                    None
                  </option>
                  {teams.map((team) => {
                    return (
                      <option key={team.name} value={team.name}>
                        {team.name}
                      </option>
                    );
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  Field required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" as={Col} md="6">
                <Form.Label>XML Team Name (*)</Form.Label>
                <Form.Control
                  name="xml_team_name"
                  type="text"
                  required
                  onChange={(e) => inputChange(e, index)}
                  value={team.xml_team_name}
                />
                <Form.Control.Feedback type="invalid">
                  Field required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group icon-container" as={Col} md="1">
                <FontAwesomeIcon
                  title="Delete"
                  className="red pointer"
                  icon={faTrash}
                  onClick={() => deleteCorrelation(index)}
                />
              </Form.Group>
            </Row>
          ))}

          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update Team Correlations
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default TeamCorrelations;
