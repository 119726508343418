import "../../assets/style/grandprix_tables.css";
import React, { useEffect, useState } from "react";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import GrandPrixDriver from "../../components/GrandPrix/GrandPrixDriver";
import GrandPrixTeam from "../../components/GrandPrix/GrandPrixTeam";
import GrandPrixPosition from "../../components/GrandPrix/GrandPrixPosition";
import GrandPrixSessionTitle from "../../components/GrandPrix/GrandPrixSessionTitle";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ResultsHeader from "./ResultsHeader";
import LoadingContent from "../../components/LoadingContent";
import { t } from "i18next";
import useSeasonStyles from "../../hooks/useSeasonStyles";
import {
  ClassFilter,
  getClassArraySimple,
} from "../../components/GrandPrix/ClassFilter";

const Leaders = () => {
  useTranslation();
  const [loading, setLoading] = useState(true);
  let { grandprix } = useParams();
  const style = useSeasonStyles(grandprix);
  const [classFilter, setClassFilter] = useState<string | null>(null);
  const [leaders, setLeaders] = useState<{
    [key: string]: string | number;
  }>({});
  const [championship, setChampionship] = useState({
    type_races: -1,
    has_teams: -1,
    championship_id: -1,
  });

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getLeaders`,
        {
          params: {
            name: grandprix,
          },
        }
      )
      .then(({ data }) => {
        setLeaders(data.leaders);
        setChampionship(data.championship);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, []);

  if (loading) return <LoadingContent />;
  else if (grandprix == null || Object.keys(leaders).length === 0)
    return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={5} />
      {Object.entries(leaders).map((item, index) => {
        const session = item[0];
        const results: any = item[1];
        let testDriver: any = null;
        let testLap: any = null;
        let classArray = getClassArraySimple(results);
        try {
          testDriver = results[0];
          testLap = results[0].laps[0];
        } catch (e) {}
        return (
          <div key={index} className={`session ld-session ${style}`}>
            <div className="session-content">
              <div className="session-title text-center">
                <GrandPrixSessionTitle
                  session={session}
                  race_length={Object.entries(leaders).length}
                  suffix={t("Leaders")}
                />
                {classArray.length > 1 && (
                  <ClassFilter
                    classes={classArray}
                    classFilter={classFilter}
                    setClassFilter={setClassFilter}
                    race={leaders}
                    setRace={setLeaders}
                  />
                )}
              </div>
              <ResultsHeader
                testDriver={testDriver}
                page="Leaders"
                typeRaces={championship.type_races}
                hasTeams={championship.has_teams}
              />
              <div className="session-drivers">
                {results.map((driver: any, index: number) => {
                  if (classFilter != null && driver.class != classFilter)
                    return <></>;
                  return (
                    <div key={index} className="gp-driver-laps-container">
                      <div
                        className={`gp-driver-row flex ${
                          driver.team === "LigaVirtualF1 Team"
                            ? "lvf1-driver"
                            : ""
                        }`}
                      >
                        <GrandPrixPosition driver={driver} session={session} />
                        <GrandPrixDriver driver={driver} />
                        {championship.has_teams === 1 && (
                          <GrandPrixTeam driver={driver} />
                        )}
                        {driver.class !== null &&
                          driver.class_color != null && (
                            <div className="gp-sector text-center">
                              <label
                                className="class_content"
                                style={{ background: driver.class_color }}
                              >
                                {driver.class.toUpperCase()}
                              </label>
                            </div>
                          )}
                        <div className="gp-laps text-center">
                          <label>{driver.laps}</label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Leaders;
