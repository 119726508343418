import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const GrandPrixTeam = (props: any) => {
  const driver = props.driver;
  const { t } = useTranslation();
  if (driver.team_flag == null || driver.team_flag === "unk")
    return (
      <div className="gp-team">
        <span>{driver.team}</span>
      </div>
    );
  return (
    <div className="gp-team flex-center">
      <Link to={`${t("routes:Team")}/${encodeURIComponent(driver.team)}`}>
        {driver.team}
      </Link>
      {driver.team_id != null && (
        <div className="team-logo-container">
          <img
            title={driver.team}
            src={`/assets/Teams/${driver.team_id}.png?dummy=${Math.floor(
              Math.random() * 1001
            )}`}
          />
        </div>
      )}
    </div>
  );
};

export default GrandPrixTeam;
