const lastSunday = (month: number) => {
  var date = new Date(new Date().getFullYear(), month, 1, 12);
  let weekday = date.getDay();
  let dayDiff = weekday === 0 ? 7 : weekday;
  date.setDate(date.getDate() - dayDiff);
  let day = date.getDate();
  return day < 9 ? "0" + day : day;
};

//We check if the race date is between the summer time so we have to decrease 1 hour
const transformHour = (date: string) => {
  const date_split1 = date.split("T");
  const date_split2 = date_split1[0].split("-");
  const summer_o = lastSunday(3);
  const summer_f = lastSunday(10);
  var dateFrom = `${summer_o}/03/${new Date().getFullYear()}`;
  var dateTo = `${summer_f}/10/${new Date().getFullYear()}`;
  var dateCheck = `${date_split2[2]}/${date_split2[1]}/${date_split2[0]}`;

  var d1 = dateFrom.split("/");
  var d2 = dateTo.split("/");
  var c = dateCheck.split("/");

  var from = new Date(parseInt(d1[2]), parseInt(d1[1]) - 1, parseInt(d1[0])); // -1 because months are from 0 to 11
  var to = new Date(parseInt(d2[2]), parseInt(d2[1]) - 1, parseInt(d2[0]));
  var check = new Date(parseInt(c[2]), parseInt(c[1]) - 1, parseInt(c[0]));

  if (check > from && check < to) {
    const date_split3 = date_split1[1].split(":");
    const hour_transformed = parseInt(date_split3[0]) - 1;
    const hour_transformed_string =
      hour_transformed < 10 ? "0" + hour_transformed : hour_transformed;
    return `${date_split1[0]}T${hour_transformed_string}:${date_split3[1]}:${date_split3[2]}`;
  }

  return date;
};

export { transformHour };
