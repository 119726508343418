import React, { useState, useRef } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import { CountriesOptions } from "../../../components/SelectRegions";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const CreateTeam = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Create Team";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refPicture = useRef<any>(null);
  const [picture, setPicture] = useState();
  const [dataForm, setDataForm] = useState({
    name: "",
    flag: "",
  });
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);
    formDataFiles.append("name", dataForm.name);
    formDataFiles.append("flag", dataForm.flag);
    if (picture != null) formDataFiles.append("team_logo", picture);

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/createTeam`,
          formDataFiles
        )
        .then(() => {
          toast.success("Team created successfully");
          setUploading(false);
          setValidated(false);
          setDataForm({ ...dataForm, name: "", flag: "" });
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Create Team" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Team Name (*)</Form.Label>
              <Form.Control
                name="name"
                type="text"
                required
                onChange={(e) => inputChange(e)}
                value={dataForm.name}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Team Flag (*)</Form.Label>
              <Form.Select
                name="flag"
                value={dataForm.flag}
                required
                onChange={(e) => {
                  inputChange(e);
                }}
              >
                <option disabled value={""}>
                  None
                </option>
                <CountriesOptions />
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>Logo Picture (*)</Form.Label>
              <Form.Control
                name="driver_picture"
                type="file"
                accept="image/*"
                required
                ref={refPicture}
                onChange={() => setPicture(refPicture.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Create Team
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateTeam;
