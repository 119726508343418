import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LoadingContent from "../../components/LoadingContent";
import DriverStanding from "../../components/Standings/DriverStanding";
import TeamStanding from "../../components/Standings/TeamStanding";
import DriverStats from "../../components/Standings/DriverStats";
import TeamStats from "../../components/Standings/TeamStats";
import Scoring from "../../components/Standings/Scoring";
import ErrorPage from "../ErrorPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Standings = ({ season }: { season?: string }) => {
  const { t } = useTranslation();
  const [standingsData, setStandingsData] = useState<StandingsData>();
  const [error, setError] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/detailedStandings`,
        {
          params: { season: season },
        }
      )
      .then(({ data }) => {
        setStandingsData(data);
        document.title = "LVF1 - " + data.champ_data.name;
      })
      .catch(({ response }) => {
        setError(true);
        throw new Error(response);
      });
  }, [season]);

  if (error) return <ErrorPage />;
  if (standingsData == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container standingsdiv-container page-transition">
      <div className="standings-title">
        {standingsData.prev_season != null && (
          <Link to={`${t("routes:Season")}/${standingsData.prev_season}`}>
            <FontAwesomeIcon icon={faArrowCircleLeft} />
          </Link>
        )}
        {standingsData.champ_data.name}
        {standingsData.next_season != null && (
          <Link to={`${t("routes:Season")}/${standingsData.next_season}`}>
            <FontAwesomeIcon icon={faArrowCircleRight} />
          </Link>
        )}
      </div>
      <DriverStanding standingsData={standingsData} />
      <br></br>
      {standingsData.has_teams === 1 && (
        <>
          <TeamStanding standingsData={standingsData} />
          <br></br>
        </>
      )}
      <DriverStats
        data={standingsData.driver_stats}
        champData={standingsData.champ_data}
      />
      <br></br>

      {standingsData.has_teams === 1 && (
        <>
          <TeamStats
            data={standingsData.team_stats}
            champData={standingsData.champ_data}
          />
          <br></br>
        </>
      )}
      <Scoring scorings={standingsData.scorings} />
    </div>
  );
};

export default Standings;
