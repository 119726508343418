import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-image-gallery/styles/css/image-gallery.css";
import LoadingContent from "../../components/LoadingContent";
import AttendancePerRace from "./races/AttendancePerRace";
import CarouselSelector from "./races/CarouselSelector";

const Races = () => {
  const { t } = useTranslation();
  document.title = `LVF1 - ${t("Races")}`;
  const [attendance, setAttendance] = useState<Data>();
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/historic/getRaces`
      )
      .then(async ({ data }) => {
        setData(data.grandsprix);
        setAttendance(data.attendance);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  if (data == null || attendance == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="historic-races-container" className="full-width">
        <CarouselSelector data={data} />
        <AttendancePerRace data={attendance} />
      </div>
    </div>
  );
};

export default Races;
