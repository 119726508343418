import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LoadingContent from "../../../components/LoadingContent";

const CarouselSelector = ({ data }: { data: any }) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<ReactImageGalleryItem[]>([]);
  const [championships, setChampionships] = useState<string[]>([]);
  const [racesChampionship, setRacesChampionship] = useState<
    { index: number; name: string }[]
  >([]);
  const [races, setRaces] = useState<{ name: string; champ_name: string }[]>(
    []
  );
  const [raceSelected, setRaceSelected] = useState(0);
  const [indexImageSelected, setIndexImageSelected] = useState(0);
  const [loading, setLoading] = useState(true);

  const getImage = (gp: { id: number; hasImage?: boolean }) => {
    if (gp.hasImage != null && gp.hasImage)
      return `/assets/ImagesGP/${gp.id}.jpg`;
    else return require(`../../../assets/Images/NoImage.png`);
  };

  useEffect(() => {
    let new_images: ReactImageGalleryItem[] = [];
    let championships: string[] = [];
    for (let gp of data) {
      if (!championships.includes(gp.champ_name) && gp.champ_name != "Events")
        championships.push(gp.champ_name);
      new_images.push({
        original: getImage(gp),
        thumbnail: getImage(gp),
        description: gp.name,
        originalAlt: gp.name,
      });
    }
    championships.push("Events");
    setChampionships(championships);
    setImages(new_images);
    setRaces(data);
  }, []);

  const setRacesSelectedChampionship = ({ target }: { target: any }) => {
    const { value }: { value: string } = target;
    let races_selected: { index: number; name: string }[] = [];
    races.forEach((race, index) => {
      if (race.champ_name === value)
        races_selected.push({ index, name: race.name });
    });
    setRacesChampionship(races_selected);
    setRaceSelected(0);
  };

  const showImageGPSelected = ({ target }: { target: any }) => {
    const { value }: { value: string } = target;
    setIndexImageSelected(parseInt(value));
    setRaceSelected(parseInt(value));
  };

  return (
    <div id="carousel-container" className="frame-turqoise">
      <h3 className="text-center frame-title">{t("Races").toUpperCase()}</h3>
      <div id="race-selector-container" className="flex-center">
        <div>
          <span>{t("Championship")}</span>
          <select
            className="form-control"
            onChange={(e: any) => {
              setRacesSelectedChampionship(e);
            }}
            defaultValue={0}
          >
            <option value={0} disabled>
              {t("None")}
            </option>
            {championships.map((champ) => {
              return <option key={champ}>{champ}</option>;
            })}
          </select>
        </div>
        <div>
          <span>{t("Race")}</span>
          <select
            className="form-control"
            onChange={(e: any) => {
              showImageGPSelected(e);
            }}
            value={raceSelected}
          >
            <option value={0} disabled>
              {t("None")}
            </option>
            {racesChampionship.map((race) => {
              return (
                <option key={race.index} value={race.index}>
                  {race.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {loading && (
        <div id="race-image-loading">
          <LoadingContent />
        </div>
      )}
      <ImageGallery
        onImageLoad={() => setLoading(false)}
        startIndex={indexImageSelected}
        items={images}
        onClick={(e: any) => {
          window.open(
            `${t("routes:GrandPrix")}/${encodeURIComponent(
              e.target.nextElementSibling.outerText.replace(/ /g, "")
            )}`,
            "_blank"
          );
        }}
      />
    </div>
  );
};

export default CarouselSelector;
