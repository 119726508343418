import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getColor,
  teamLogo,
  teamBackground,
  getStandingsPositionStyle,
} from "../../utils/Standings";
import Flag from "../Flag";
import Legend from "./Legend";
import StandingsResult from "./StandingsResult";
import THRaces from "./THRaces";

const DriverStanding = ({
  standingsData,
}: {
  standingsData: StandingsData;
}) => {
  const { t } = useTranslation();
  const getDriverNameLink = (driver: DriverStanding) => {
    let nickname = driver.username;
    if (driver.username == null) nickname = "TBA";
    //external championship
    if (driver.flag_code === null)
      return (
        <label
          style={{
            color: getColor(driver.team_id, driver.main_color),
          }}
        >
          {nickname}
        </label>
      );
    return (
      <Link
        className="full-white-driver"
        to={`${t("routes:Driver")}/${encodeURIComponent(nickname)}`}
        style={{
          color: getColor(driver.team_id, driver.main_color),
        }}
      >
        {nickname}
      </Link>
    );
  };

  return (
    <div className="frame-turqoise">
      <h3 className="text-center frame-title">
        {t("DriverStandings").toUpperCase()}
      </h3>
      <div className="flex-container">
        <div className="flex">
          <table className="standings-table">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">{t("Driver")}</th>
                <THRaces results={standingsData.results} />
                <th>{t("Points")}</th>
              </tr>
            </thead>
            <tbody>
              {standingsData.driver_standings.length === 0 ? (
                <tr className="no-standing-drivers">
                  <td colSpan={standingsData.results.length + 3}>
                    {t("NoStandingData")}
                  </td>
                </tr>
              ) : (
                standingsData.driver_standings.map((driver, index) => {
                  const class_ = driver.position === 1 &&
                    driver.catalog != null &&
                    driver.class_color != null &&
                    driver.class != null && (
                      <tr key={"C" + index}>
                        <td
                          colSpan={100}
                          style={{ background: driver.class_color }}
                          className="class-tr-separator"
                        >
                          {driver.class.toUpperCase()}
                        </td>
                      </tr>
                    );
                  const driversInPosition =
                    standingsData.driver_standings.filter(
                      (driver_) =>
                        driver_.position === driver.position &&
                        driver_.class === driver.class
                    );
                  const index_driver = driversInPosition.indexOf(driver);
                  return (
                    <React.Fragment key={index}>
                      {index_driver === 0 && class_}
                      <tr>
                        {index_driver === 0 && (
                          <td
                            rowSpan={driversInPosition.length}
                            className="standings-pos"
                          >
                            {driver.position}
                          </td>
                        )}
                        <td className="no-td-padding standings-teamdriver">
                          <div
                            className="teamdriver-results-container"
                            style={{
                              background: teamBackground(
                                driver.team_id,
                                driver.main_color
                              ),
                            }}
                          >
                            <div className="logo-results-container flex-center">
                              {!/^[0-9]+$/.test(driver.team_id) ? (
                                <Flag
                                  flag_code={driver.flag_code}
                                  name={driver.flag_name}
                                />
                              ) : (
                                teamLogo(
                                  driver.team_id,
                                  driver.team_logo,
                                  driver.team_name
                                )
                              )}
                            </div>
                            {getDriverNameLink(driver)}
                          </div>
                        </td>
                        {standingsData.results.map((result, index1) => {
                          return result.Races.map((race, index) => {
                            const driver_result = race.Results.filter(
                              (driver_) => driver_.username === driver.username
                            );
                            const driver_result_Q = result.hasOwnProperty(
                              "Qualy"
                            )
                              ? result.Qualy.filter(
                                  (driver_) => driver_.name === driver.username
                                )
                              : null;

                            return (
                              <StandingsResult
                                key={index + "-" + index1}
                                driver_result={driver_result}
                                poleman={result.Poleman}
                                fastest={race.hot_lap_usernames}
                                most_leader={race.most_leader}
                                leaders={race.leaders}
                                qualy={driver_result_Q}
                                isDrivers={true}
                              />
                            );
                          });
                        })}
                        {index_driver === 0 && (
                          <td
                            rowSpan={driversInPosition.length}
                            className={`standings-tab-points ${
                              typeof driver.position === "number" &&
                              getStandingsPositionStyle(
                                driver.is_dsq ? "DSQ" : driver.position
                              )
                            }`}
                          >
                            <b>{driver.points}</b>
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
            <tfoot>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">{t("Driver")}</th>
                <THRaces results={standingsData.results} />
                <th>{t("Points")}</th>
              </tr>
            </tfoot>
          </table>
          <Legend
            champ_data={standingsData.champ_data}
            gps_with_qualy_points={standingsData.gps_with_qualy_points}
            one_point_qualy={standingsData.one_point_qualy}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverStanding;
