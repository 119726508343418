import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { DriverLink } from "../../../components/FlagLink";

interface Driver {
  class_name: string;
  class_color: string;
  nickname: string;
  username: string;
  type_race: number;
  flag_code: string;
  flag_name: string;
}

const SummaryDrivers = ({
  code,
  translation,
  drivers,
  icon,
}: {
  code: string;
  translation: string;
  drivers: any;
  icon: any;
}) => {
  const { t } = useTranslation();
  //Checking if there is more than 1 races
  const uniqueTypes = new Set(drivers.map((driver: any) => driver.type_race));
  //We iterate over races
  return drivers.map((driver: Driver, index: number) => {
    const driversWithClass = drivers.filter(
      (driver_: Driver) =>
        driver_.class_name === driver.class_name &&
        driver_.type_race === driver.type_race
    );
    const index_class = driversWithClass.indexOf(driver);
    if (index_class > 0 && uniqueTypes.size === 1) return <></>;
    return (
      <div key={`${code}${index}`} className="line-results">
        <FontAwesomeIcon icon={icon} />
        <b className="flex">
          {t(translation)}
          {uniqueTypes.size > 1 ? " R" + driver.type_race : ""}
          {driver.class_name != null && (
            <div className="margin-left text-center">
              <label
                className="class_content"
                style={{
                  background:
                    driver.class_color != null ? driver.class_color : "#179387",
                }}
              >
                {driver.class_name.toUpperCase()}
              </label>
            </div>
          )}
          {" :"}
        </b>
        {driversWithClass.map((driver: Driver, index: number) => {
          return (
            <>
              <DriverLink
                flag_code={driver.flag_code}
                flag_name={driver.flag_name}
                name={driver.username}
                nickname={driver.nickname}
              />
              {driversWithClass.length > 1 &&
                index !== driversWithClass.length - 1 && (
                  <label className="separator">{"—"}</label>
                )}
            </>
          );
        })}
      </div>
    );
  });
};

export default SummaryDrivers;
