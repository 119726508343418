import React from "react";
import { i18n } from "../App";

const CountriesOptions = () => {
  /* WE TAKE THE COUNTRY LIST FROM THE TRANSLATIONS COUNTRIES FILE */
  const countries = Object.entries(
    i18n.getResourceBundle(i18n.language, "countries")
  );
  countries.sort(function (a: any, b: any) {
    //To sort the countries deppending on the current language
    return ("" + a[1]).localeCompare(b[1]);
  });
  return (
    <>
      {countries.map((country: any) => {
        return (
          <option key={country[0]} value={country[0]}>
            {country[1]}
          </option>
        );
      })}
    </>
  );
};

const SpanishRegionsOptions = () => {
  return (
    <>
      <option value="Andalucía">Andalucía</option>
      <option value="Aragón">Aragón</option>
      <option value="Asturias">Asturias</option>
      <option value="Islas Baleares">Islas Baleares</option>
      <option value="País Vasco">País Vasco</option>
      <option value="Islas Canarias">Islas Canarias</option>
      <option value="Cantabria">Cantabria</option>
      <option value="Castilla-La Mancha">Castilla-La Mancha</option>
      <option value="Castilla y León">Castilla y León</option>
      <option value="Catalunya">Catalunya</option>
      <option value="Extremadura">Extremadura</option>
      <option value="Galicia">Galicia</option>
      <option value="Comunidad de Madrid">Comunidad de Madrid</option>
      <option value="Región de Murcia">Región de Murcia</option>
      <option value="Navarra">Navarra</option>
      <option value="La Rioja">La Rioja</option>
      <option value="Comunidad Valenciana">Comunidad Valenciana</option>
      <option value="Ceuta">Ceuta</option>
      <option value="Melilla">Melilla</option>
    </>
  );
};
export { CountriesOptions, SpanishRegionsOptions };
