import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Flag from "../Flag";

const SummaryData = ({
  data,
  games,
  order_data,
  isTeam,
  expanded,
}: {
  data: { [key: string]: any }[];
  games: Games[];
  order_data: OrderData;
  isTeam: boolean;
  expanded: boolean;
}) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<ColumnData[]>([]);
  const [rows, setRows] = useState<{ [key: string]: any }[]>([]);
  const [theme, setTheme] = useState("light");
  const [photoURL1, setPhotoURL1] = useState<string>(
    require(`../../assets/Images/NoPhoto.png`)
  );
  const [photoURL2, setPhotoURL2] = useState<string>(
    require(`../../assets/Images/NoPhoto.png`)
  );
  const [photoURL3, setPhotoURL3] = useState<string>(
    require(`../../assets/Images/NoPhoto.png`)
  );

  useEffect(() => {
    const theme_ = window.localStorage.getItem("theme");
    setTheme(theme_ != null && theme_ === "light" ? "dark" : "light");
    buildRows();
    buildingColumns();

    const fetchData = async () => {
      const photo1 = await getPhoto(data[0]);
      if (photo1 != "") setPhotoURL1(photo1);

      const photo2 = await getPhoto(data[1]);
      if (photo2 != "") setPhotoURL2(await getPhoto(data[1]));

      const photo3 = await getPhoto(data[2]);
      if (photo3 != "") setPhotoURL3(await getPhoto(data[2]));
    };
    fetchData();
  }, [data, expanded, window.localStorage.getItem("theme")]);

  const getPhoto = (data: { [key: string]: any }) => {
    const folder = isTeam ? "Teams" : "Drivers";
    const logo =
      data.hasOwnProperty("logo") && data.logo != null ? "_" + data.logo : "";
    const imagePath = `/assets/${folder}/${data.id}${logo}.png`;

    return new Promise<string>((resolve, reject) => {
      const image = new Image();
      image.src = imagePath;

      image.onload = () => {
        // La imagen se ha cargado correctamente
        resolve(imagePath);
      };

      image.onerror = () => {
        // Hubo un error al cargar la imagen, devuelve una imagen de respaldo
        resolve("");
      };
    });
  };

  const getURL = (name: string) => {
    if (isTeam) return `${t("routes:Team")}/${encodeURIComponent(name)}`;
    else return `${t("routes:Driver")}/${encodeURIComponent(name)}`;
  };

  const buildRows = () => {
    let rows_: { [key: string]: any }[] = [];
    let last_points = -1;
    data.forEach((row, index) => {
      //Default rows for every page
      let row_: { [key: string]: any } = {
        pos: index + 1,
        name: (
          <Link to={getURL(row.name)} className="driver">
            <Flag name={row.flag_name} flag_code={row.code} />
            {row.name}
          </Link>
        ),
        object: row,
      };
      //Now we get the defined variables from the order_data variable
      order_data.expanded.forEach((variable) => {
        //Case last gp -> we need to create the link
        if (variable.col === "last_gp") {
          row_[variable.col] = (
            <Link
              className="driver"
              to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                row[variable.col].replace(/ /g, "")
              )}`}
              title={row[variable.col]}
            >
              {row[variable.col]}
            </Link>
          );
        }
        //Case balance (podiums)
        else if (variable.col === "balance") {
          row_[variable.col] = (
            <div className="podium-stats">
              <img alt="P1" src={require(`../../assets/Images/P1.png`)} />
              <span>{row.total_p1}</span>
              <img alt="P2" src={require(`../../assets/Images/P2.png`)} />
              <span>{row.total_p2}</span>
              <img alt="P3" src={require(`../../assets/Images/P3.png`)} />
              <span>{row.total_p3}</span>
            </div>
          );
        }
        //Case best/worst position
        else if (
          variable.col === "best_position" ||
          variable.col === "worst_position"
        ) {
          const position =
            variable.col === "best_position"
              ? row.best_position
              : row.worst_position;
          const champ =
            variable.col === "best_position"
              ? row.best_position_champ
              : row.worst_position_champ;
          row_[variable.col] = (
            <>
              <span>{position}º</span> (
              <Link
                className="driver"
                to={`${t("routes:Season")}/${encodeURIComponent(
                  champ.replace(/ /g, "")
                )}`}
              >
                {champ}
              </Link>
              )
            </>
          );
        }
        //Case Interval (consecutively categories)
        else if (variable.col === "interval") {
          row_[variable.col] = (
            <>
              {row.best_streak_start === "" ? (
                <></>
              ) : (
                <>
                  <Link
                    to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                      row.best_streak_start.replace(/ /g, "")
                    )}`}
                    className="driver"
                  >
                    {row.best_streak_start}
                  </Link>
                  <span> / </span>
                  {row.best_streak_end === "" ? (
                    <span className="present">{t("Present")}</span>
                  ) : (
                    <Link
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        row.best_streak_end.replace(/ /g, "")
                      )}`}
                      className="driver"
                    >
                      {row.best_streak_end}
                    </Link>
                  )}
                </>
              )}
            </>
          );
        } else row_[variable.col] = row[variable.col];
      });
      rows_.push(row_);
      last_points = row.total_points;
    });
    setRows(rows_);
  };

  const buildingColumns = () => {
    //Default columns for every page
    let columns_: ColumnData[] = [
      {
        name: "#",
        selector: (row: { [key: string]: any }) => row.pos,
        sortable: true,
        width: "60px",
      },
      {
        name: isTeam ? t("Team") : t("Driver"),
        selector: (row: { [key: string]: any }) => row.name,
        width: "240px",
      },
    ];
    //Case expanded: we add every defined column on the order_data variable
    if (expanded) {
      order_data.expanded.forEach((variable) => {
        columns_.push(getColumnObject(variable));
      });
    } else {
      //Case summary: we just show the summary defined columns
      order_data.summary.forEach((variable) => {
        const data_variable = order_data.expanded.find(
          (var_) => var_.col === variable
        );
        if (data_variable == null) return;
        columns_.push(getColumnObject(data_variable));
      });
    }
    //Now we add the games columns
    if (expanded && data[0].games_data != null) {
      games.forEach((game) => {
        columns_.push({
          name: (
            <img title={game.name} src={`/assets/Games/${game.name}.png`} />
          ),
          selector: (row: any) => {
            const game_row = row.object.games_data_transformed.find(
              (game_: { game_id: number; total: number }) =>
                game_.game_id === game.id
            );
            if (game_row == null) return 0;
            return game_row.total;
          },
          sortable: true,
          width: "80px",
        });
      });
    }

    setColumns(columns_);
  };

  const getColumnObject = (data_variable: {
    col: string;
    translation: string;
    width: string;
  }) => {
    //Case last gp -> we need to sort it by date
    if (data_variable.col === "last_gp") {
      return {
        name: t(data_variable.translation),
        selector: (row: any) => row[data_variable.col],
        sortFunction: (a: any, b: any) => {
          return (
            new Date(a.object.last_date).getTime() -
            new Date(b.object.last_date).getTime()
          );
        },
        width: t(data_variable.width),
      };
    }
    //Case percentage (we need the number to sort it)
    else if (data_variable.col === "percentage_transformed") {
      return {
        name: t(data_variable.translation),
        selector: (row: any) => row[data_variable.col],
        sortFunction: (a: any, b: any) => {
          return a.object.percentage - b.object.percentage;
        },
        width: t(data_variable.width),
      };
    }
    //Case no sort
    else if (
      data_variable.col === "balance" ||
      data_variable.col === "interval"
    ) {
      return {
        name: t(data_variable.translation),
        selector: (row: any) => row[data_variable.col],
        width: t(data_variable.width),
      };
    } else if (
      data_variable.col === "best_position" ||
      data_variable.col === "worst_position"
    ) {
      return {
        name: t(data_variable.translation),
        selector: (row: any) => row[data_variable.col],
        sortFunction: (a: any, b: any) => {
          return a.object[data_variable.col] - b.object[data_variable.col];
        },
        width: t(data_variable.width),
      };
    }
    //Default case
    return {
      name: t(data_variable.translation),
      selector: (row: any) => row[data_variable.col],
      sortable: true,
      width: t(data_variable.width),
    };
  };

  return (
    <>
      <div className="flex-center">
        <div className="podium-container">
          <Link
            className="podium-1 podium-item flex-center"
            style={{
              background: data[0].hasOwnProperty("main_color")
                ? data[0].main_color
                : "",
            }}
            to={getURL(data[0].name)}
            title={data[0].name}
          >
            <img src={photoURL1} alt={data[0].name} />
          </Link>
          <Link
            className="podium-2 podium-item flex-center"
            style={{
              background: data[1].hasOwnProperty("main_color")
                ? data[1].main_color
                : "",
            }}
            to={getURL(data[1].name)}
            title={data[1].name}
          >
            <img src={photoURL2} alt={data[1].name} />
          </Link>

          <Link
            className="podium-3 podium-item flex-center"
            style={{
              background: data[2].hasOwnProperty("main_color")
                ? data[2].main_color
                : "",
            }}
            to={getURL(data[2].name)}
            title={data[2].name}
          >
            <img src={photoURL3} alt={data[2].name} />
          </Link>
        </div>
      </div>
      {
        <div className="flex-center">
          <div className="table-stats-container">
            <DataTable
              columns={columns}
              data={rows}
              pagination
              theme={theme}
              paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
              paginationComponentOptions={{
                selectAllRowsItem: true,
                selectAllRowsItemText: t("All"),
                rowsPerPageText: t("RowsPerPage"),
                rangeSeparatorText: t("of"),
              }}
            />
          </div>
        </div>
      }
    </>
  );
};

export default SummaryData;
