import React from "react";
import { NavDropdown, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../../components/UserContext";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { LANGUAGES } from "../../../../core/consts";

const Logout = ({ toast }: { toast: any }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);
  const handleCloseLogoutModal = () => setShow(false);
  const handleShowLogoutModal = () => setShow(true);

  const { logout } = useUser() as any;

  return (
    <>
      <NavDropdown.Item onClick={handleShowLogoutModal}>
        {t("LogOut")}
      </NavDropdown.Item>
      <Modal size="sm" show={show} onHide={handleCloseLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("LogOut")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("ConfirmLogOut")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoutModal}>
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (i18n.language === LANGUAGES[0].code) navigate("/");
              else navigate("/" + i18n.language);
              toast.success(t("LogOutSuccess"));
              logout();
            }}
          >
            {t("LogOut")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Logout;
