import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flag from "../../../../components/Flag";
import { Link } from "react-router-dom";
import getTextColor from "../../../../utils/DynamicContrast";
import { useTranslation } from "react-i18next";
import { teamLogo } from "../../../../utils/Standings";
import React from "react";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
const DriverTopStandings = ({
  lastGP,
  scrollStandings,
}: {
  lastGP: {
    driver_standings: any;
    team_standings: [];
    has_teams: false;
  };
  scrollStandings: (standings: number, direction: number, id: number) => void;
}) => {
  const { t } = useTranslation();

  const showStandingTable = () => {
    let jsx: any = [];
    let last_class: any = null;
    let total = 0;

    const catalogs = new Set();

    for (const obj of lastGP.driver_standings) {
      catalogs.add(obj.catalog);
    }
    lastGP.driver_standings.forEach((row: any, index: number) => {
      if (row.class !== last_class || index === 0) {
        jsx.push(
          <div className="class-container" key={index}>
            {catalogs.size > 1 && (
              <div className="flex-center">
                <label
                  className="class_content"
                  style={{ background: row.class_color }}
                >
                  {row.class != null && row.class.toUpperCase()}
                </label>
              </div>
            )}
            <div
              className={`general-standings-container ${
                catalogs.size > 2 ? "has-many-classes" : ""
              }`}
            >
              <button
                className="scroll-button scroll-down"
                onClick={(e) => scrollStandings(0, 1, row.catalog)}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </button>
              <button
                className="scroll-button scroll-up hide"
                onClick={(e) => scrollStandings(0, 0, row.catalog)}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </button>
              <div
                className={`standings-container dStandings driver-standings-${
                  row.catalog
                } ${lastGP.has_teams ? "" : "secondaryChamp"}`}
              >
                {lastGP.driver_standings
                  .filter((row_: any) => row_.class === row.class)
                  .map(function (obj: any, index: number) {
                    const drivers =
                      obj.drivers == null
                        ? ["TBA"]
                        : obj.drivers.split("*LVF1*");
                    const initials_drivers =
                      obj.initials_drivers == null
                        ? ["TBA"]
                        : obj.initials_drivers.split("*LVF1*");
                    return (
                      <div className="standings-team flex" key={index}>
                        <span className="standings-position flex-center">
                          {obj.position}
                        </span>
                        <div
                          className={`teamdriver-container ${
                            obj.main_color == null && "secondarychamp-driver"
                          }`}
                          style={{
                            background:
                              obj.main_color == null
                                ? "#3bd8c9"
                                : obj.main_color,
                          }}
                        >
                          {drivers.map((name: string, index_driver: number) => {
                            return (
                              <React.Fragment key={index_driver}>
                                {(obj.main_color == null ||
                                  index_driver === 0) && (
                                  <div className="logo-container flex-center">
                                    {isNaN(parseInt(obj.team_id)) ? (
                                      <Flag
                                        flag_code={obj.flag_code}
                                        name={obj.flag_name}
                                      />
                                    ) : (
                                      teamLogo(
                                        obj.team_id,
                                        obj.team_logo,
                                        obj.team_name
                                      )
                                    )}
                                  </div>
                                )}
                                {index_driver > 0 && drivers.length > 1 && (
                                  <label>—</label>
                                )}
                                <Link
                                  title={
                                    initials_drivers.length > 1 ? name : ""
                                  }
                                  to={
                                    name == "TBA"
                                      ? "#"
                                      : `${t(
                                          "routes:Driver"
                                        )}/${encodeURIComponent(name)}`
                                  }
                                  style={{
                                    color:
                                      obj.main_color == null
                                        ? "#000000"
                                        : getTextColor(obj.main_color),
                                  }}
                                >
                                  {initials_drivers.length > 1
                                    ? initials_drivers[index_driver]
                                    : name}
                                </Link>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        <span className="standings-points flex-center">
                          {obj.points}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
        last_class = row.class;
        total++;
      }
    });

    return jsx;
  };
  {
    return (
      <div className="standings-class-container">
        {lastGP.driver_standings.length === 0 && (
          <label className="no-data-yet">{t("NoDataYet")}</label>
        )}
        {showStandingTable()}
      </div>
    );
  }
};

export default DriverTopStandings;
