import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import GrandPrixTyre from "../../../components/GrandPrix/GrandPrixTyre";
import { getColor, teamLogo, teamBackground } from "../../../utils/Standings";
import { Link } from "react-router-dom";
import Flag from "../../../components/Flag";
import { timeOrLaps } from "../../../utils/Livetiming";
import ServerWeather from "../../../components/LiveTiming/ServerWeather";
import SettingsRF2 from "./SettingsRF2";
import SettingsAC from "./SettingsAC";

const LiveTimingTable = ({
  tableData,
  serverTiming,
  width,
  height,
  typeRaces,
  gameId,
}: {
  tableData: CircleDriver[];
  serverTiming: ServerTiming;
  width: number;
  height: number;
  typeRaces: number;
  gameId: number;
}) => {
  const { t } = useTranslation();
  const noSectors = gameId === 5 && typeRaces === 1;
  const columns: any[] = [];
  // Common column configuration
  const commonColumns = [
    {
      name: "#",
      selector: (row: CircleDriver) => row.live_place,
      width: "45px",
    },
    {
      name: t("Driver"),
      selector: (row: CircleDriver) => (
        <div
          className="teamdriver-results-container text-left"
          style={{
            background: teamBackground(row.team_id, row.main_color),
          }}
        >
          <div className="logo-results-container flex-center">
            {row.team_id == null &&
            row.code != null &&
            row.flag_name != null ? (
              <Flag flag_code={row.code} name={row.flag_name} />
            ) : (
              teamLogo(row.team_id, row.logo, row.team_name)
            )}
          </div>
          <Link
            className="full-white-driver"
            to={`${t("routes:Driver")}/${encodeURIComponent(row.live_name)}`}
            style={{
              color: getColor(row.team_id, row.main_color),
            }}
          >
            {row.live_name}
          </Link>
        </div>
      ),
      width: "180px",
    },
  ];
  //Case: AC
  if (gameId === 5) {
    columns.push(
      ...commonColumns,
      {
        name: t("HotLap"),
        selector: (row: CircleDriver) => row.live_bestLapTimeConverted,
        //width: "120px",
      },
      {
        name: t("ToLead"),
        selector: (row: CircleDriver) => row.toLeader,
        //width: "80px",
      },
      {
        name: t("Laps"),
        selector: (row: CircleDriver) => row.live_laps,
        //width: "60px",
      }
    );
  }
  // Case: hillclimb
  else if (serverTiming.trackdata_name === "") {
    columns.push(
      ...commonColumns,
      {
        name: "-",
        className: "a",
        selector: (row: CircleDriver) => {
          if (
            row.liveTimeConverted === "0" &&
            row.live_bestLapTimeConverted != ""
          )
            return (
              <small className="fin-small">
                <img
                  alt="CheckedFlag"
                  src={require(`../../../assets/Images/checked_flag.png`)}
                />
              </small>
            );
          else if (row.liveTimeConverted === "0")
            return <small className="ret-small">Ret</small>;
          else if (row.sectors_converted.s2 !== "")
            return <small className="s3-small">S3</small>;
          else if (row.sectors_converted.s1 !== "")
            return <small className="s2-small">S2</small>;
          else return <small className="s1-small">S1</small>;
        },
        width: "40px",
      },
      {
        name: t("HotLap"),
        selector: (row: CircleDriver) => {
          let className = null;
          if (row.liveTimeConverted === "0") className = "ret-small";
          else if (row.sectors_converted.s2 !== "") className = "s3-small";
          else if (row.sectors_converted.s1 !== "") className = "s2-small";
          else className = "s1-small";
          if (row.live_bestLapTimeConverted == "")
            return (
              <small className="progressbar-container">
                <div>
                  <span
                    className={`progressBar-liveTiming ${className}`}
                    style={{ width: row.live_trackposition + "%" }}
                  ></span>
                </div>
              </small>
            );
          return <>{row.live_bestLapTimeConverted}</>;
        },
        //width: "120px",
      }
    );
  } else if (typeRaces === 2) {
    // Case: Endurance
    columns.push(
      ...commonColumns,
      {
        name: t("Driver"),
        selector: (row: CircleDriver) => <b>{row.driver}</b>,
        width: "70px",
      },
      {
        name: t("HotLap"),
        selector: (row: CircleDriver) => row.live_bestLapTimeConverted,
        //width: "120px",
      },
      {
        name: t("ToLead"),
        selector: (row: CircleDriver) => row.toLeader,
        //width: "80px",
      },
      {
        name: t("Tyres"),
        selector: (row: CircleDriver) => (
          <GrandPrixTyre tyre={row.live_currtire} />
        ),
        //width: "60px",
      },
      {
        name: t("Laps"),
        selector: (row: CircleDriver) => row.live_laps,
        //width: "60px",
      }
    );
  } else {
    // Default case
    columns.push(
      ...commonColumns,
      {
        name: t("HotLap"),
        selector: (row: CircleDriver) => row.live_bestLapTimeConverted,
        //width: "120px",
      },
      {
        name: t("ToLead"),
        selector: (row: CircleDriver) => row.toLeader,
        //width: "80px",
      },
      {
        name: t("Tyres"),
        selector: (row: CircleDriver) => (
          <GrandPrixTyre tyre={row.live_currtire} />
        ),
        //width: "60px",
      },
      {
        name: t("Laps"),
        selector: (row: CircleDriver) => row.live_laps,
        //width: "60px",
      }
    );
  }

  const has_classes =
    tableData.find((fl) => fl.class_name != null && fl.class_name != "") !=
    null;
  if (width > 1105 && !noSectors) {
    columns.splice(
      4,
      0,
      {
        name: t("Sector") + " 1",
        selector: (row: CircleDriver) => row.sectors_converted.s1,
        //width: "80px",
      },
      {
        name: t("Sector") + " 2",
        selector: (row: CircleDriver) => row.sectors_converted.s2,
        //width: "80px",
      },
      {
        name: t("Sector") + " 3",
        selector: (row: CircleDriver) => row.sectors_converted.s3,
        //width: "80px",
      }
    );
    //Case HillClimb LivetimingRace (results)
    if (serverTiming.trackdata_name !== "") {
      columns.splice(7, 0, {
        name: t("LastLap"),
        selector: (row: CircleDriver) => row.liveTimeConverted,
        //width: "80px",
      });
    }
  } else if (noSectors) {
    columns.splice(7, 0, {
      name: t("LastLap"),
      selector: (row: CircleDriver) => row.liveTimeConverted,
      //width: "80px",
    });
  }

  if (has_classes)
    columns.splice(2, 0, {
      name: t("Class"),
      width: "80px",
      selector: (row: FastestLap) =>
        row.class_color == null ? (
          <></>
        ) : (
          <label
            className="class_livetiming"
            style={{ background: row.class_color }}
          >
            {row.class_name?.toUpperCase()}
          </label>
        ),
      sortFunction: (a: FastestLap, b: FastestLap) => {
        const aValue = a.class_catalog || Number.POSITIVE_INFINITY;
        const bValue = b.class_catalog || Number.POSITIVE_INFINITY;

        return aValue - bValue;
      },
    });

  const conditionalRowStyles = [
    {
      when: (row: CircleDriver) => row.connected === false,
      style: {
        opacity: 0.3,
      },
    },
    {
      when: (row: CircleDriver) => row.connected === true,
      style: {
        opacity: 1,
      },
    },
  ];

  return (
    <>
      {/* TITLE */}
      <p className="secondary-title backgrounded-title ">
        Live Timing {timeOrLaps(serverTiming, tableData)}
        <ServerWeather serverTiming={serverTiming} />
      </p>
      {/* TABLE */}
      <div
        className={`overflowed ${
          serverTiming.trackdata_name === "" ? "hcDT" : ""
        }`}
        style={{ height: height - 42 }}
      >
        <DataTable
          columns={columns}
          data={tableData}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      {gameId === 4 && (
        <SettingsRF2
          serverTiming={serverTiming}
          tableData={tableData}
          typeRaces={typeRaces}
        />
      )}
      {gameId === 5 && (
        <SettingsAC
          serverTiming={serverTiming}
          trackDataId={serverTiming.trackdata_id}
        />
      )}
    </>
  );
};

export default LiveTimingTable;
