import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import IndexChamp from "../../../components/IndexChamp";

const Index = () => {
  const { t } = useTranslation();
  const theme = window.localStorage.getItem("theme");
  const [mainChampionship, setMainChampionship] = useState("");
  const [secChampionship, setSecChampionship] = useState("");
  const [selected, setSelected] = useState(-1);
  const mainStyle = useSpring({
    backgroundImage:
      theme == "light"
        ? `url(/assets/Index/MainLight.jpg)`
        : `url(/assets/Index/MainDark.jpg)`,
    width: selected === 1 ? "100%" : selected === 2 ? "0%" : "70%",
  });
  const animationProps = useSpring({
    opacity: selected === 2 ? "0" : "1",
    delay: 1000, // Retraso de 1000ms (1 segundo)
  });
  const secStyle = useSpring({
    backgroundImage:
      theme == "light"
        ? `url(/assets/Index/SecLight.jpg)`
        : `url(/assets/Index/SecDark.jpg)`,
    width: selected === 2 ? "100%" : selected === 1 ? "0%" : "30%",
  });

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getCurrentChampionships`
      )
      .then(({ data }) => {
        setMainChampionship(data.main_champ.name);
        setSecChampionship(data.second_champ.name);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  return (
    <div id="index-container" className="flex">
      <IndexChamp
        id={1}
        defaultWidth={"70%"}
        text={mainChampionship}
        selected={selected}
        setSelected={setSelected}
      />
      <IndexChamp
        id={2}
        defaultWidth={"30%"}
        text={secChampionship}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

export default Index;
