import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";
import Classes from "../championship/add-championship/parts/Classes";

const CreateClass = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Team Logo";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [classes, setClasses] = useState<Classes[]>([]);
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);
    formDataFiles.append("classes", JSON.stringify(classes));

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/createClass`,
          formDataFiles
        )
        .then(() => {
          toast.success("Class created successfully");
          setUploading(false);
          setValidated(false);
          setClasses([]);
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };
  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Create Class" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Classes classes={classes} setClasses={setClasses} />
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Create Team Logo
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateClass;
