import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

interface GP {
  name: string;
  id: number;
  press_conference: string;
  pole_video: string;
  gp_video: string;
  milestones_en: string;
  milestones_es: string;
}

const UpdateGPResults = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Update GP Results";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refPicture = useRef<any>(null);
  const [picture, setPicture] = useState();
  const [uploading, setUploading] = useState(false);

  const [selectedGP, setSelectedGP] = useState({
    id: -1,
    press_conference: "",
    pole_video: "",
    gp_video: "",
    milestones_en: "",
    milestones_es: "",
  });
  const [lastGrandsPrix, setLastGrandsPrix] = useState<GP[]>();

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getLastGrandsPrix`
      )
      .then(({ data }) => {
        setSelectedGP(data[0]);
        setLastGrandsPrix(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  const changeGPData = (event: ChangeEvent<HTMLSelectElement>) => {
    const gp_data = lastGrandsPrix?.filter(
      (gp) => gp.id === parseInt(event.target.value)
    );
    if (gp_data != null) setSelectedGP(gp_data[0]);
  };

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setSelectedGP({
      ...selectedGP,
      [name]: value,
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    if (picture != null) formDataFiles.append("gp_picture", picture);

    formDataFiles.append("grand_prix", selectedGP.id.toString());
    formDataFiles.append("press_conference", selectedGP.press_conference);
    formDataFiles.append("pole_video", selectedGP.pole_video);
    formDataFiles.append("gp_video", selectedGP.gp_video);
    formDataFiles.append("milestones_en", selectedGP.milestones_en);
    formDataFiles.append("milestones_es", selectedGP.milestones_es);
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);

    setUploading(true);
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/updateGPResults`,
        formDataFiles
      )
      .then(() => {
        toast.success("Grand Prix updated successfully");
        setUploading(false);
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
        setUploading(false);
        throw new Error(response);
      });
  };

  if (!access) return <ErrorPage />;
  if (lastGrandsPrix == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Update GP Results" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Grand Prix (*)</Form.Label>
              <Form.Select
                name="id"
                required
                value={selectedGP.id}
                onChange={(e) => {
                  inputChange(e);
                  changeGPData(e);
                }}
              >
                {lastGrandsPrix.map((gp) => {
                  return (
                    <option key={gp.id} value={gp.id}>
                      {gp.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>GP Picture</Form.Label>
              <Form.Control
                name="picture"
                type="file"
                accept="image/*"
                ref={refPicture}
                onChange={() => setPicture(refPicture.current.files[0])}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Press Conference</Form.Label>
            <Form.Control
              name="press_conference"
              type="text"
              onChange={(e) => inputChange(e)}
              value={
                selectedGP?.press_conference == null
                  ? ""
                  : selectedGP?.press_conference
              }
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Pole Video</Form.Label>
            <Form.Control
              name="pole_video"
              type="text"
              onChange={(e) => inputChange(e)}
              value={
                selectedGP?.pole_video == null ? "" : selectedGP?.pole_video
              }
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>GP Video</Form.Label>
            <Form.Control
              name="gp_video"
              type="text"
              onChange={(e) => inputChange(e)}
              value={selectedGP?.gp_video == null ? "" : selectedGP?.gp_video}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Milestones [EN]</Form.Label>
            <Form.Control
              as="textarea"
              name="milestones_en"
              onChange={(e) => inputChange(e)}
              value={
                selectedGP?.milestones_en == null
                  ? ""
                  : selectedGP?.milestones_en
              }
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Milestones [ES]</Form.Label>
            <Form.Control
              as="textarea"
              name="milestones_es"
              onChange={(e) => inputChange(e)}
              value={
                selectedGP?.milestones_es == null
                  ? ""
                  : selectedGP?.milestones_es
              }
            />
          </Form.Group>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update GP
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateGPResults;
