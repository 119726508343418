import useIsAdmin from "../../../hooks/useIsAdmin";
import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../../components/UserContext";
import { useTranslation } from "react-i18next";

const SettingsAC = ({
  serverTiming,
  trackDataId,
}: {
  serverTiming: ServerTiming;
  trackDataId: number;
}) => {
  const { t } = useTranslation();
  const { access } = useIsAdmin();
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [isOpen, setIsOpen] = useState(false);
  const [command, setCommand] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [body, setBody] = useState("");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const inputChange = ({ target }: { target: any }) => {
    const { value } = target;
    setBody(value);
  };

  const action = (action: string) => {
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/acCommand`,
        {
          server: serverTiming,
          action,
          user,
          body,
          command,
          trackDataId,
          token: JSON.parse(token).token,
        }
      )
      .then(() => {
        toast.success("Config updated successfully");
      })
      .catch(({ response }) => {
        toast.error(response);
        throw new Error(response);
      });
  };

  if (!access) return <></>;
  return (
    <>
      <div className={`dropdown-container-lt ${isOpen ? "open" : ""}`}>
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleMenu}
          className="menu-icon"
        />
        {
          <div className={`dropdown-menu-lt ${isOpen ? "open" : ""}`}>
            <a
              onClick={() => {
                handleShow();
                setCommand("next_session");
                setBody("");
              }}
              className="dropdown-item"
            >
              Next Session
            </a>
            <a
              onClick={() => {
                handleShow();
                setCommand("restart_session");
                setBody("");
              }}
              className="dropdown-item"
            >
              Restart Session
            </a>
            <a
              onClick={() => {
                handleShow();
                setCommand("send_message");
                setBody("");
              }}
              className="dropdown-item"
            >
              Chat message
            </a>
          </div>
        }
      </div>{" "}
      <Modal size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send command to server</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to send the command <b>{command}</b> to the
          server{" "}
          <b>
            {serverTiming.trackdata_name}, ID({serverTiming.trackdata_id})
          </b>
          ?
          {command === "send_message" && (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Body</Form.Label>
              <Form.Control
                name="body"
                type="text"
                value={body}
                onChange={inputChange}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              action(command);
              handleClose();
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SettingsAC;
