import "../../../assets/style/calendar.css";
import "react-calendar/dist/Calendar.css";
import React, { useEffect, useState, useCallback } from "react";
import Calendar from "react-calendar";
import axios from "axios";
import { i18n } from "../../../App";
import { useTranslation } from "react-i18next";

const CalendarContent = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState<
    { name: string; date: Date; type_championship: number }[]
  >([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main/calendar`
      )
      .then(({ data }) => {
        setEvents(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  const findDay = (date: Date, type: number) => {
    return events.find((x) => {
      return (
        date.getFullYear() === new Date(x.date).getFullYear() &&
        date.getMonth() === new Date(x.date).getMonth() &&
        date.getDate() === new Date(x.date).getDate() &&
        x.type_championship === type
      );
    });
  };

  const setClass = useCallback(
    (date: any) => {
      const dateobj_type1 = findDay(date, 1);
      const dateobj_type2 = findDay(date, 3);
      const dateobj_type3 = findDay(date, 4);
      if (dateobj_type1 != null) return "day-selected";
      else if (dateobj_type2 != null) return "day-selected-sec";
      else if (dateobj_type3 != null) return "day-selected-eve";
      return "";
    },
    [events]
  );

  const setTitle = useCallback(
    (date: any) => {
      const dateobj = events.find((x) => {
        return (
          date.getDate() === new Date(x.date).getDate() &&
          date.getMonth() === new Date(x.date).getMonth() &&
          date.getFullYear() === new Date(x.date).getFullYear()
        );
      });
      return dateobj ? (
        <div className="titleContainer flex-center square-bubble">
          <span className="spanTitle">{dateobj.name}</span>
        </div>
      ) : null;
    },
    [events]
  );

  const handleMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
    const titleContainer: any =
      e.currentTarget.querySelector(".titleContainer");
    if (titleContainer) {
      titleContainer.style.opacity = "1";
    }
  };

  const handleMouseOut = (e: React.MouseEvent<HTMLDivElement>) => {
    const titleContainer: any =
      e.currentTarget.querySelector(".titleContainer");
    if (titleContainer) {
      titleContainer.style.opacity = "0";
    }
  };

  return (
    <div id="calendar-div" className="column-around">
      <div>
        <Calendar
          tileClassName={({ date }) => setClass(date)}
          tileContent={({ date }) => (
            <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              {setTitle(date)}
            </div>
          )}
          locale={i18n.language}
        />
      </div>
    </div>
  );
};

export default CalendarContent;
