import axios from "axios";
import { useEffect, useState } from "react";
import { useUser } from "../components/UserContext";

const useIsBanned = () => {
  const { user, logout } = useUser() as any;
  const [banned, setBanned] = useState(false);
  const checkBanned = () => {
    const userLogged = window.localStorage.getItem("userLogged");
    if (userLogged !== null) {
      let user_logged = JSON.parse(userLogged);
      if (user_logged.type === 0) return false;
      return axios
        .get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/user/checkBanned`,
          {
            params: user_logged,
          }
        )
        .then(({ data }) => {
          if (data === "LOGOUT") {
            logout();
            window.location.reload();
          }
          if (data === "NOK") return true;
          return false;
        })
        .catch(() => {
          return false;
        });
    } else return false;
  };

  useEffect(() => {
    const access = async () => {
      const access_value = await checkBanned();
      setBanned(access_value);
    };
    access();
  }, [user]);

  return { banned };
};

export default useIsBanned;
