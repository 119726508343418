import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

interface TeamChampionship {
  name: string;
  championship_id: number;
  team_championship_id: number;
}
interface User {
  username: string;
  driver_id: number;
  team_championship_id: number;
  number: number;
  team_number: number;
  id: number;
}

const RemoveAssignment = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Remove Assignment";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [assignment, setAssignment] = useState({
    championship: "",
    team: "",
    driver: "",
    isTBA: "",
    forced: "",
  });
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [teams, setTeams] = useState<TeamChampionship[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [data, setData] = useState<{
    champs: { name: string; id: number }[];
    assignments: TeamChampionship[];
    users: User[];
  }>();

  const setTeamsFromChampionship = (value: string) => {
    //setAssignment({ ...assignment, team: "", driver: "" });
    const teams_filtered = data?.assignments.filter(
      (assignment) => parseInt(value) === assignment.championship_id
    );
    if (teams_filtered != null) setTeams(teams_filtered);
  };

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setAssignment({
      ...assignment,
      [name]: value,
    });
  };

  const handleCheckboxChange = ({ target }: { target: any }) => {
    const { name, checked } = target;
    setAssignment({
      ...assignment,
      [name]: checked,
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/removeAssignment`,
          {
            assignment,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(() => {
          toast.success("Assignment deleted successfully");
          setUploading(false);
          setValidated(false);
          setAssignment({ ...assignment, team: "", driver: "" });
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/driver/removeAssignment`
      )
      .then(({ data }) => {
        setData(data);
        setTeams(
          data?.assignments.filter(
            (assignment: { championship_id: number }) =>
              parseInt(data.champs[0].id) === assignment.championship_id
          )
        );
        setAssignment({
          ...assignment,
          championship: data.champs[0].id,
        });
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });
  }, []);

  const setDriversFromTeamChampionship = (value: string) => {
    const users_filtered = data?.users.filter(
      (assignment) => parseInt(value) === assignment.team_championship_id
    );
    if (users_filtered != null) setUsers(users_filtered);
  };

  if (!access) return <ErrorPage />;
  if (data == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Remove Assignment" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="3">
              <Form.Label>Championship (*)</Form.Label>
              <Form.Select
                name="championship"
                required
                value={assignment.championship}
                onChange={(e: any) => {
                  inputChange(e);
                  setTeamsFromChampionship(e.target.value);
                }}
              >
                {data?.champs.map((champ) => {
                  return (
                    <option value={champ.id} key={champ.id}>
                      {champ.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="3">
              <Form.Label>Team (*)</Form.Label>
              <Form.Select
                name="team"
                required
                value={assignment.team}
                onChange={(e) => {
                  inputChange(e);
                  setDriversFromTeamChampionship(e.target.value);
                }}
              >
                <option value="" disabled>
                  None
                </option>
                {teams.map((team) => {
                  return (
                    <option
                      value={team.team_championship_id}
                      key={team.team_championship_id}
                    >
                      {team.name}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="4">
              <Form.Label>Driver (*)</Form.Label>
              <Form.Select
                name="driver"
                required
                value={assignment.driver}
                onChange={(e) => {
                  inputChange(e);
                }}
              >
                <option value="" disabled>
                  None
                </option>
                {users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      #{user.number} - {user.username}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="2">
              <Form.Label>Set as TBA</Form.Label>
              <Form.Check
                type="checkbox"
                name="isTBA"
                value={assignment.isTBA}
                onChange={(e) => {
                  handleCheckboxChange(e);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label className="flex">
                <Form.Check
                  type="checkbox"
                  name="forced"
                  value={assignment.forced}
                  onChange={(e) => {
                    handleCheckboxChange(e);
                  }}
                />
                Force (it will assign their DNPs to the new titular driver)
              </Form.Label>
            </Form.Group>
          </Row>

          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Remove assignment
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RemoveAssignment;
