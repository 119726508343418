import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Title from "../../../components/Admin/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTrash,
  faUpDown,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import {
  defaultTheme,
  OrderGroup,
  OrderItem,
  arrayMove,
} from "react-draggable-order";
import axios from "axios";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const Downloads = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Admin Downloads";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [listDownloads, setListDownloads] = useState<
    {
      name: string;
      category: string;
      link: string;
      type_championship: number;
    }[]
  >([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getDownloads`
      )
      .then(({ data }) => {
        setListDownloads(data.downloads);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [validated]);

  const inputChange = ({ target }: { target: any }, index: number) => {
    type options = "name" | "link";
    const { name, value }: { name: options; value: string } = target;
    setListDownloads(
      (
        prev_downloads: {
          name: string;
          category: string;
          link: string;
          type_championship: number;
        }[]
      ) => {
        let aux_downloads = prev_downloads.slice();
        aux_downloads[index][name] = value;
        return aux_downloads;
      }
    );
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/updateDownloads`,
          {
            listDownloads,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(({ data }) => {
          toast.success("Downloads updated successfully");
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          throw new Error(response);
        });
    } else setValidated(true);
  };
  const removeDownload = (index_: number) => {
    setListDownloads(listDownloads.filter((item, index) => index_ !== index));
  };

  const createDownload = () => {
    setListDownloads([
      ...listDownloads,
      { name: "", category: "", link: "", type_championship: 1 },
    ]);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Downloads" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <div className="text-right">
            <FontAwesomeIcon
              icon={faCirclePlus}
              className="create-button grey"
              title="Add Download"
              onClick={() => createDownload()}
            />
          </div>
          <OrderGroup {...defaultTheme.group}>
            {listDownloads.map(
              (
                x: {
                  name: string;
                  category: string;
                  link: string;
                  type_championship: number;
                },
                i
              ) => (
                <OrderItem
                  key={i}
                  {...defaultTheme.item}
                  index={i}
                  onMove={(to) =>
                    setListDownloads(arrayMove(listDownloads, i, to))
                  }
                >
                  <div {...defaultTheme.content}>
                    <Row>
                      <Form.Group className="form-group" as={Col} md="1">
                        <OrderItem.Handle {...defaultTheme.handle}>
                          <FontAwesomeIcon
                            icon={faUpDown}
                            className="draggable-icon"
                          />
                        </OrderItem.Handle>
                      </Form.Group>
                      <Form.Group className="form-group" as={Col} md="2">
                        <Form.Label>Tipo (*)</Form.Label>
                        <Form.Select
                          required
                          name="type_championship"
                          value={x.type_championship}
                          onChange={(e: any) => {
                            inputChange(e, i);
                          }}
                        >
                          <option value="1">Main</option>
                          <option value="3">HillClimb</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="form-group" as={Col} md="2">
                        <Form.Label>Category (*)</Form.Label>
                        <Form.Control
                          value={x.category}
                          name="category"
                          required
                          onChange={(e: any) => {
                            inputChange(e, i);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group" as={Col} md="2">
                        <Form.Label>Name (*)</Form.Label>
                        <Form.Control
                          value={x.name}
                          name="name"
                          required
                          onChange={(e: any) => {
                            inputChange(e, i);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group" as={Col} md="4">
                        <Form.Label>Link (*)</Form.Label>
                        <Form.Control
                          value={x.link}
                          name="link"
                          required
                          onChange={(e: any) => {
                            inputChange(e, i);
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        className="form-group text-center"
                        as={Col}
                        md="1"
                      >
                        <OrderItem.Handle {...defaultTheme.handle}>
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="trash-icon red"
                            onClick={() => removeDownload(i)}
                            title="Delete Download"
                          />
                        </OrderItem.Handle>
                      </Form.Group>
                    </Row>
                  </div>
                </OrderItem>
              )
            )}
          </OrderGroup>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update Downloads
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Downloads;
