import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";

const CropperUser = ({
  file,
  setFile2,
}: {
  file: any;
  setFile2: (file: string) => void;
}) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [url, setUrl] = useState("");

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    setFile2(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
  };

  useEffect(() => {
    if (file != "") {
      const objectUrl = URL.createObjectURL(file);
      setUrl(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);
  if (url == "") return <></>;
  return (
    <div className="cropper-container2">
      <Cropper
        src={url}
        style={{ maxWidth: "100%", maxHeight: "100vh" }}
        crop={onCrop}
        ref={cropperRef}
        dragMode={"move"}
        guides={true}
        toggleDragModeOnDblclick={false}
        aspectRatio={218 / 288}
      />
    </div>
  );
};

export default CropperUser;
