import axios from "axios";
import { useEffect, useState } from "react";
import { useUser } from "../components/UserContext";

const useIsAdmin = () => {
  const { user } = useUser() as any;
  const [access, setAccess] = useState(true);
  const [typeUser, setTypeUser] = useState(-1);

  const checkAdminAccess = (type: number = 2) => {
    const userLogged = window.localStorage.getItem("userLogged");
    if (userLogged !== null) {
      let user_logged = JSON.parse(userLogged);
      if (user_logged.type !== type) return false;
      return axios
        .get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/user/checkAdminAccess`,
          {
            params: user_logged,
          }
        )
        .then(({ data }) => {
          if (data === "OK") return true;
          return false;
        })
        .catch(({ response }) => {
          return false;
        });
    } else return false;
  };

  const getTypeUser = () => {
    const userLogged = window.localStorage.getItem("userLogged");
    if (userLogged !== null) {
      let user_logged = JSON.parse(userLogged);
      return user_logged.type;
    }
    return -1;
  };

  useEffect(() => {
    const access = async () => {
      const access_value = await checkAdminAccess();
      setAccess(access_value);
      setTypeUser(await getTypeUser());
    };
    access();
  }, [user]);

  return { access, typeUser };
};

export default useIsAdmin;
