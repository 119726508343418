import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import {
  getColor,
  teamLogo,
  teamBackground,
  getStandingsPositionStyle,
} from "../../../utils/Standings";
import { Link } from "react-router-dom";
import StandingsResult from "../../../components/Standings/StandingsResult";
import { i18n } from "../../../App";

const DriverResults = ({
  summary,
  maxRaces,
  driver,
}: {
  summary: DriverSummary[];
  maxRaces: number;
  driver: string;
}) => {
  const { t } = useTranslation();
  const [cols, setCols] = useState<{}[]>([]);
  const [rows, setRows] = useState<DriverResultsRow[]>([]);

  const setColsArray = () => {
    let cols_ = [];
    //Season - Game - Team
    cols_.push(
      {
        name: t("Championship"),
        selector: (row: DriverResultsRow) => row.season,
        sortFunction: (a: any, b: any) => a.season_id - b.season_id,
        width: "100px",
      },
      {
        name: t("Game"),
        selector: (row: DriverResultsRow) => {
          if (row.game == null) return <></>;
          return (
            <img
              src={`/assets/Games/${row.game}.png`}
              alt={row.game}
              title={row.game}
              className="game-icon"
            />
          );
        },
        width: "70px",
      },
      {
        name: t("Teams"),
        selector: (row: DriverResultsRow) => row.teams,
        width: "130px",
      }
    );
    for (let i = 0; i < maxRaces; i++) {
      cols_.push({
        name: i + 1,
        selector: (row: DriverResultsRow) => {
          let race_count = 0;
          let teams_array: any = row.teams_array;
          if (teams_array == null) {
            teams_array = [
              {
                name: "HillClimb",
                main_color: "#FFFFFF",
                id: null,
                logo: null,
              },
            ];
          }
          for (let result of row.results) {
            for (let race of result.Races) {
              if (race_count === i) {
                let JSX = [];
                for (let team_ of teams_array) {
                  const driver_ = race.Results.filter(
                    (driver_result) =>
                      driver_result.username === driver &&
                      (driver_result.team_name === team_.name ||
                        teams_array[0].name === "HillClimb")
                  );
                  const driver_result_Q = result.hasOwnProperty("Qualy")
                    ? result.Qualy.filter((driver_) => driver_.name === driver)
                    : null;
                  let driver_found = null;
                  if (driver_[0] == null) {
                    for (let team__ of teams_array) {
                      const driver__ = race.Results.filter(
                        (driver_result) =>
                          driver_result.username === driver &&
                          driver_result.team_name === team__.name
                      );
                      if (driver__[0] != null) driver_found = driver__;
                    }
                    if (driver_found != null)
                      JSX.push(
                        <div
                          key={`${i}a${result.id}`}
                          className={`standings-results driver-results-gp`}
                        ></div>
                      );
                  }
                  if (driver_found == null) {
                    if (
                      driver_[0] != null ||
                      teams_array[0].name === team_.name
                    ) {
                      JSX.push(
                        <StandingsResult
                          key={`${i}b${result.id}`}
                          driver_result={driver_}
                          poleman={result.Poleman}
                          fastest={race.hot_lap_usernames}
                          most_leader={race.most_leader}
                          leaders={race.leaders}
                          qualy={driver_result_Q}
                          isDrivers={true}
                          gp={
                            <Link
                              to={`${t(
                                "routes:GrandPrix"
                              )}/${encodeURIComponent(
                                result.name.replace(/ /g, "")
                              )}`}
                              className="driver"
                              title={result.name}
                            >
                              {result.initials}
                            </Link>
                          }
                        />
                      );
                    } else
                      JSX.push(
                        <div
                          key={`${i}c${result.id}`}
                          className={`standings-results driver-results-gp`}
                        ></div>
                      );
                  }
                }
                return <>{JSX}</>;
              }
              race_count++;
            }
          }

          return "";
        },
        width: "35px",
      });
    }
    // Points - Position
    cols_.push(
      {
        name: t("Points"),
        selector: (row: DriverResultsRow) => row.pointsJSX,
        width: "70px",
        sortFunction: (a: { points: number }, b: { points: number }) => {
          return a.points - b.points;
        },
      },
      {
        name: t("Position"),
        selector: (row: DriverResultsRow) => row.position,
        sortFunction: (
          a: { position_num: number | string },
          b: { position_num: number | string }
        ) => {
          if (
            typeof a.position_num === "number" &&
            typeof b.position_num === "number"
          )
            return a.position_num - b.position_num;
          else return 0;
        },
        width: "80px",
      }
    );
    setCols(cols_);
  };

  useEffect(() => {
    setColsArray();

    const rows_: DriverResultsRow[] = [];
    summary.forEach((summary: DriverSummary, index: number) => {
      let teams_JSX;
      if (summary.hasOwnProperty("teams_array")) {
        teams_JSX = summary.teams_array?.map((team, index) => {
          return (
            <div
              key={index}
              className="teamdriver-results-container text-left"
              style={{
                background: teamBackground(team.id, team.main_color),
                color: getColor(
                  team.id == null ? null : team.id.toString(),
                  team.main_color
                ),
              }}
            >
              <div className="logo-results-container flex-center">
                {teamLogo(team.id, team.logo, team.name)}
              </div>
              {team.id == null ? (
                <label>{team.name}</label>
              ) : (
                <Link
                  className="full-white-driver"
                  to={`${t("routes:Team")}/${encodeURIComponent(team.name)}`}
                  style={{
                    color: getColor(
                      team.id == null ? null : team.id.toString(),
                      team.main_color
                    ),
                  }}
                >
                  {team.name}
                </Link>
              )}
            </div>
          );
        });
      } else {
        teams_JSX = (
          <div
            key={index}
            className="teamdriver-results-container text-left"
            style={{ background: summary.teams != null ? "" : "#000000" }}
          >
            {summary.teams != null && (
              <div className="logo-results-container flex-center">
                {teamLogo(null, null, summary.teams)}
              </div>
            )}
            <div className="logo-results-container flex-center"></div>
            <label
              className="full-white-driver"
              style={{
                color: summary.teams != null ? "" : "#ffffff",
              }}
            >
              {summary.teams == null ? summary.name : summary.teams}
            </label>
          </div>
        );
      }
      if (summary.name !== "EVENTS")
        rows_.push({
          season: (
            <Link
              to={`${t("routes:Season")}/${encodeURIComponent(
                summary.name.replace(/ /g, "")
              )}`}
              className="driver"
            >
              {summary.name}
            </Link>
          ),
          season_id: summary.championship_id,
          teams: teams_JSX,
          points: summary.points === null ? "-" : summary.points,
          pointsJSX: (
            <div
              className={`${
                typeof summary.position === "number" &&
                getStandingsPositionStyle(
                  summary.is_dsq ? "DSQ" : summary.position
                )
              }`}
            >
              {summary.points}
            </div>
          ),
          position:
            summary.position === null ? (
              "-"
            ) : (
              <div
                className={`${
                  typeof summary.position === "number" &&
                  getStandingsPositionStyle(
                    summary.is_dsq ? "DSQ" : summary.position
                  )
                }`}
              >
                {summary.position + "º"}
              </div>
            ),
          game: summary.game,
          position_num: summary.position === null ? "-" : summary.position,
          results: summary.results,
          teams_array: summary.teams_array,
        });
    });
    setRows(rows_);
  }, [i18n.language]);

  return (
    <div className="driver-results">
      <div className="driver-results-table-container">
        <DataTable columns={cols} data={rows} />
      </div>
    </div>
  );
};

export default DriverResults;
