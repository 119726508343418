import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import "../assets/style/team.css";
import StandingsResult from "../components/Standings/StandingsResult";
import { DriverLink } from "../components/FlagLink";
import LoadingContent from "../components/LoadingContent";
import getTextColor from "../utils/DynamicContrast";
import THRaces from "../components/Standings/THRaces";
import { getStandingsPositionStyle } from "../utils/Standings";
import EventResults from "./driver/parts/EventResults";

const Team = () => {
  let { team } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [teamData, setTeamData] = useState<TeamData[]>();
  const [mainColor, setMainColor] = useState("#FFFFFF");
  const [maxCols, setMaxCols] = useState(0);
  const [teamLogo, setTeamLogo] = useState(null);
  const [stats, setStats] = useState<TeamDataStats>();
  const [events, setEvents] = useState<EventResults[]>([]);
  document.title = "LVF1 - " + team;
  useEffect(() => {
    if (team != null)
      axios
        .get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/team/getTeamDataResults`,
          {
            params: { team },
          }
        )
        .then(({ data }) => {
          setTeamData(data.standings);
          setMaxCols(data.max_races);
          setStats(data.stats);
          setEvents(data.event_results);
          setMainColor(
            data.standings.reduce((ultimo: any, objeto: any) => {
              if (objeto.main_color !== null) {
                return objeto;
              }
              return ultimo;
            }, null).main_color
          );
          setTeamLogo(
            data.standings.reduce((last: any, obj: any) => {
              if (obj.logo !== null) {
                return `${obj.team_id}_${obj.logo}`;
              }
              return obj.team_id;
            }, null)
          );
        })
        .catch(({ response }) => {
          setError(true);
          throw new Error(response);
        });
  }, [team]);

  const getRaceHeaderCols = () => {
    let jsx: JSX.Element[] = [];
    for (let i = 1; i <= maxCols; i++) {
      jsx.push(<th key={i}>{i}</th>);
    }
    return <>{jsx}</>;
  };

  const emptyCols = (total: number) => {
    let jsx: JSX.Element[] = [];
    for (let i = 1; i <= total; i++) {
      jsx.push(<td key={i}></td>);
    }
    return <>{jsx}</>;
  };

  const getDriverResults = (race: TeamData, driver: string) => {
    let jsx: JSX.Element[] = [];
    race.results.forEach((race_result) => {
      race_result.Races.forEach((race_results: Race, index) => {
        const driver_ = race_results.Results.filter(
          (driver_result) => driver_result.username === driver
        );
        const driver_result_Q = race_result.hasOwnProperty("Qualy")
          ? race_result.Qualy.filter((driver_) => driver_.name === driver)
          : null;

        if (driver_[0] != null && driver_[0].team_name === team)
          jsx.push(
            <StandingsResult
              key={`${index}-${race_result.id}`}
              driver_result={driver_}
              poleman={race_result.Poleman}
              fastest={race_results.hot_lap_usernames}
              most_leader={race_results.most_leader}
              leaders={race_results.leaders}
              qualy={driver_result_Q}
              isDrivers={true}
            />
          );
        else jsx.push(<td key={`${index}-${race_result.id}`}></td>);
      });
    });

    return <>{jsx}</>;
  };

  const body = () => {
    let jsx: JSX.Element[] = [];
    teamData?.forEach((teamData, index_season) => {
      const rowspan = teamData.driversOrder.length + 1;
      jsx.push(
        <tr key={`${index_season}`}>
          <td rowSpan={rowspan} className="text-center">
            <Link
              className="driver"
              to={`${t("routes:Season")}/${encodeURIComponent(
                teamData.name.replace(/ /g, "")
              )}`}
              title={teamData.name}
            >
              {teamData.year}
            </Link>
          </td>
          <td rowSpan={rowspan} className="text-center">
            <img
              src={`/assets/Games/${teamData.game}.png`}
              alt={teamData.game}
              title={teamData.game}
              className="game-icon"
            />
          </td>
          <td className="text-center">
            <img
              src={
                teamData.has_image
                  ? `/assets/TeamChampionship/${
                      teamData.team_championship_id
                    }.png?dummy=${Math.floor(Math.random() * 1001)}`
                  : require(`../assets/Images/NoTeam.png`)
              }
              className="team-car-image"
              alt={team}
              title={team}
            />
          </td>
          <THRaces results={teamData.results} />

          {emptyCols(maxCols - getResultsLength(teamData.results))}
          <td
            rowSpan={rowspan}
            className={`text-center ${getStandingsPositionStyle(
              teamData.position
            )}`}
          >
            <b>{teamData.position}º</b>
          </td>
          <td
            rowSpan={rowspan}
            className={`text-center ${getStandingsPositionStyle(
              teamData.position
            )}`}
          >
            <b>{teamData.points}</b>
          </td>
        </tr>
      );
      teamData.driversOrder.forEach((driver, index) => {
        jsx.push(
          <tr
            key={`${index}-${teamData.team_championship_id}`}
            className={
              teamData.driversOrder.length === index + 1 ? "team-delimiter" : ""
            }
          >
            <td className="team-driver-td">
              <DriverLink
                flag_code={driver.flag_code}
                flag_name={driver.flag_name}
                name={driver.username}
                nickname={driver.username}
              />
            </td>
            {getDriverResults(teamData, driver.username)}
            {emptyCols(maxCols - getResultsLength(teamData.results))}
          </tr>
        );
      });
    });
    return <>{jsx}</>;
  };

  const getResultsLength = (results: Result[]) => {
    const total = results.reduce((previousValue: number, currentValue: any) => {
      return previousValue + currentValue.Races.length;
    }, 0);
    return total;
  };

  const teamLogoJSX = () => {
    if (teamData == null) return <></>;
    return (
      <div className="flex-between">
        <div
          className="text-left team-stats-container"
          style={{
            color: getTextColor(mainColor),
          }}
        >
          <p>
            <b>{t("RaceParticipations")}:</b> {stats?.gps}
          </p>
          <p>
            <b>{t("Wins")}:</b> {stats?.wins}
          </p>
          <p>
            <b>{t("Championships")}:</b> {stats?.championships}
          </p>
        </div>
        <div id="team-logo-results">
          <img
            src={`/assets/Teams/${teamLogo}.png`}
            alt="Logo"
            className="team-logo-results"
          />
          <h3
            style={{
              color: getTextColor(mainColor),
            }}
          >
            {team}
          </h3>
        </div>
        <div
          className="text-right team-stats-container"
          style={{
            color: getTextColor(mainColor),
          }}
        >
          <p>
            <b>{t("HotLaps")}:</b> {stats?.fastest_laps}
          </p>
          <p>
            <b>{t("Poles")}:</b> {stats?.poles}
          </p>
          <p>
            <b>{t("Podiums")}:</b> {stats?.podiums}
          </p>
        </div>
      </div>
    );
  };

  if (error) return <ErrorPage />;
  if (teamData == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container page-transition">
      <div
        id="team-logo-mobile"
        style={{
          background: mainColor,
        }}
      >
        {teamLogoJSX()}
      </div>
      <div className="flex-container">
        <div className="flex">
          <table className="data-standings team-table">
            <thead className="text-center">
              <tr id="team-logo-pc">
                <td
                  colSpan={maxCols + 5}
                  style={{
                    background: mainColor,
                  }}
                >
                  {teamLogoJSX()}
                </td>
              </tr>
              <tr>
                <th>{t("Year")}</th>
                <th>{t("Game")}</th>
                <th>{t("Drivers")}</th>
                {getRaceHeaderCols()}
                <th>{t("Position")}</th>
                <th>{t("Points")}</th>
              </tr>
            </thead>
            <tbody>{body()}</tbody>
          </table>
        </div>
        {events.length != 0 && (
          <>
            <h3 className="results-title">{t("EventsResults")}</h3>
            <EventResults events={events} />
          </>
        )}
      </div>
    </div>
  );
};

export default Team;
