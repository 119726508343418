import { faCloud, faCloudRain, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const ServerWeather = ({ serverTiming }: { serverTiming: ServerTiming }) => {
  const { t } = useTranslation();
  if (serverTiming.trackdata_name === "") return <></>;
  return (
    <>
      {" "}
      <span>
        {serverTiming.trackdata_maxWet > 0 ? (
          <span className="rain-data">
            <span title={t("RainPercentage")}>
              {Math.trunc(serverTiming.trackdata_rain * 100) + "%"}
            </span>{" "}
            (
            <small title={t("MinWet")}>
              min:
              {Math.trunc(serverTiming.trackdata_minWet * 100) + "%"}
            </small>
            ,{" "}
            <small title={t("MaxWet")}>
              max:
              {Math.trunc(serverTiming.trackdata_maxWet * 100) + "%"}
            </small>
            )
          </span>
        ) : (
          ""
        )}
        <span>{parseInt(`${serverTiming.trackdata_ambientTemp}`)}ºC</span>
      </span>
      <FontAwesomeIcon
        icon={
          serverTiming.trackdata_cloudiness > 0
            ? faCloud
            : serverTiming.trackdata_rain > 0
            ? faCloudRain
            : faSun
        }
      />
    </>
  );
};

export default ServerWeather;
