import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SmallNew = ({
  title,
  image,
  id,
  championship,
}: {
  title: string;
  image: string;
  id: string;
  championship: string | null;
}) => {
  const { t } = useTranslation();
  const titleH3 = useRef<HTMLHeadingElement>(null);
  const navigate = useNavigate();

  return (
    <div
      className="news-item"
      onClick={() => {
        navigate(
          `${t("routes:News")}/${
            championship == null ? "LVF1" : championship.replace(/ /g, "")
          }/${id}`
        );
      }}
    >
      <div>
        <div className="imagen">
          <img className="imagen" src={image} alt={title} />
        </div>
      </div>
      <div className="contenedor-h3">
        <h3 ref={titleH3} className="texto">
          {title}
        </h3>
      </div>
    </div>
  );
};

export default SmallNew;
