import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { i18n } from "../../../App";
import { DriverLink } from "../../../components/FlagLink";

const DataTableMapChart = ({
  driverSelected,
}: {
  driverSelected: DriversChartData;
}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<NationalityDataTable[]>([]);
  const [theme, setTheme] = useState("light");
  const columns = [
    {
      name: "#",
      selector: (row: NationalityDataTable) => row.pos,
      sortable: true,
      width: "65px",
    },
    {
      name: t("Drivers"),
      selector: (row: NationalityDataTable) => row.driver,
      width: "190px",
    },
    {
      name: t("Points"),
      selector: (row: NationalityDataTable) => row.points,
      sortable: true,
    },
    {
      name: t("Races"),
      selector: (row: NationalityDataTable) => row.races,
      sortable: true,
    },
    {
      name: t("Wins"),
      selector: (row: NationalityDataTable) => row.wins,
      sortable: true,
    },
    {
      name: t("Podiums"),
      selector: (row: NationalityDataTable) => row.podiums,
      sortable: true,
    },
    {
      name: t("Poles"),
      selector: (row: NationalityDataTable) => row.poles,
      sortable: true,
    },
    {
      name: t("HotLaps"),
      selector: (row: NationalityDataTable) => row.fastest_laps,
      sortable: true,
    },
    {
      name: t("LastGP"),
      selector: (row: NationalityDataTable) => row.last_race_transformed,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.last_race).getTime() - new Date(b.last_race).getTime()
        );
      },
    },
  ];

  useEffect(() => {
    const theme_ = window.localStorage.getItem("theme");
    setTheme(theme_ != null && theme_ === "light" ? "dark" : "light");
    const rows_: NationalityDataTable[] = [];
    driverSelected.data_table?.forEach((row, index) => {
      rows_.push({
        pos: index + 1,
        driver: (
          <DriverLink
            flag_code={driverSelected.code}
            flag_name={driverSelected.name}
            nickname={row.username}
            name={row.username}
          />
        ),
        points: row.points,
        races: row.races,
        poles: row.poles,
        wins: row.wins,
        podiums: row.podiums,
        fastest_laps: row.fastest_laps,
        last_race: row.last_race,
        last_race_transformed:
          row.last_race === "0"
            ? ""
            : new Date(row.last_race).toLocaleString(i18n.language, {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              }),
      });
    });
    setRows(rows_);
  }, [window.localStorage.getItem("theme")]);

  return (
    <DataTable
      columns={columns}
      data={rows}
      pagination={rows.length > 20}
      theme={theme}
      paginationPerPage={20}
      paginationRowsPerPageOptions={[20, 50, 100]}
      paginationComponentOptions={{
        selectAllRowsItem: true,
        selectAllRowsItemText: t("All"),
        rowsPerPageText: t("RowsPerPage"),
        rangeSeparatorText: t("of"),
      }}
    />
  );
};

export default DataTableMapChart;
