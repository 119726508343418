import "../../assets/style/cards.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { i18n } from "../../App";
import DidYouKnowCard from "../../components/Historic/DidYouKnowCard";
import LoadingContent from "../../components/LoadingContent";
const DidYouKnow = () => {
  const { t } = useTranslation();
  document.title = `LVF1 - ${t("DidYouKnow")}`;
  const [data, setData] = useState<DidYouKnowData>();

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/historic/getDidYouKnow`
      )
      .then(({ data }: { data: DidYouKnowData }) => {
        setData(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  const LinkText = (props: any) => {
    return (
      <a className="driver" href={props.to}>
        {props.text}
      </a>
    );
  };

  const showDriverLinks = (data: string) => {
    const split = data.split("*LVF1*");
    return (
      <React.Fragment>
        {split.map((driver, index) => {
          return (
            <>
              <LinkText
                to={`${t("routes:Driver")}/${encodeURIComponent(driver)}`}
                text={`${index === 0 ? " " : ""}${driver}`}
              />
              {index !== split.length - 1 && " - "}
            </>
          );
        })}
      </React.Fragment>
    );
  };

  if (data == null) return <LoadingContent />;
  return (
    <div
      id="didyouknow-container"
      className="full-height padding-md app-container flex-center-top page-transition"
    >
      <div className="full-width frame-turqoise">
        <h3 className="text-center frame-title">
          {t("DidYouKnow").toUpperCase()}
        </h3>
        <div className="dyimages-container">
          <DidYouKnowCard
            gp={data.attendance.id}
            gp_name={data.attendance.name}
            hasImage={data.attendance.hasImage}
            title={t("AttendanceRecord")}
            text={
              <Trans
                i18nKey={
                  data.attendance.class === ""
                    ? "AttendanceRecordText"
                    : "AttendanceRecordText2"
                }
                values={{
                  drivers: data.attendance.total,
                  class: data.attendance.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.attendance.name.replace(/ /g, "")
                      )}`}
                      text={data.attendance.name}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.low_attendance.id}
            gp_name={data.low_attendance.name}
            hasImage={data.low_attendance.hasImage}
            title={t("LowAttendanceRecord")}
            text={
              <Trans
                i18nKey={
                  data.low_attendance.class === ""
                    ? "LowAttendanceRecordText"
                    : "LowAttendanceRecordText2"
                }
                values={{
                  drivers: data.low_attendance.total,
                  class: data.low_attendance.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.low_attendance.name.replace(/ /g, "")
                      )}`}
                      text={data.low_attendance.name}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.most_finishers.id}
            gp_name={data.most_finishers.name}
            hasImage={data.most_finishers.hasImage}
            title={t("MostFinishersRecord")}
            text={
              <Trans
                i18nKey={
                  data.most_finishers.class === ""
                    ? "MostFinishersRecordText"
                    : "MostFinishersRecordText2"
                }
                values={{
                  drivers:
                    data.most_finishers.class === ""
                      ? data.most_finishers.total_drivers
                      : data.most_finishers.total_teams,
                  total:
                    data.most_finishers.class === ""
                      ? data.most_finishers.total_drivers_finishers
                      : data.most_finishers.total_teams_finishers,
                  class: data.most_finishers.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.most_finishers.name.replace(/ /g, "")
                      )}`}
                      text={data.most_finishers.name}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.most_retirements.id}
            gp_name={data.most_retirements.name}
            hasImage={data.most_retirements.hasImage}
            title={t("MostRetirementsRecord")}
            text={
              <Trans
                i18nKey={
                  data.most_retirements.class === ""
                    ? "MostRetirementsRecordText"
                    : "MostRetirementsRecordText2"
                }
                values={{
                  drivers:
                    data.most_retirements.class === ""
                      ? data.most_retirements.total_drivers
                      : data.most_retirements.total_teams,
                  total:
                    data.most_retirements.class === ""
                      ? data.most_retirements.total_drivers_finishers
                      : data.most_retirements.total_teams_finishers,
                  class: data.most_retirements.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.most_retirements.name.replace(/ /g, "")
                      )}`}
                      text={data.most_retirements.name}
                    />
                  ),
                }}
              />
            }
          />

          <DidYouKnowCard
            gp={data.longest_race.id}
            gp_name={data.longest_race.name}
            hasImage={data.longest_race.hasImage}
            title={t("LongestRace")}
            text={
              <Trans
                i18nKey={
                  data.longest_race.username.split(",").length > 1
                    ? "LongestRaceText2"
                    : "LongestRaceText"
                }
                values={{
                  time: data.longest_race.time,
                  class: data.shortest_race.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.longest_race.name.replace(/ /g, "")
                      )}`}
                      text={data.longest_race.name}
                    />
                  ),
                  linkDriver: showDriverLinks(data.longest_race.username),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.shortest_race.id}
            gp_name={data.shortest_race.name}
            hasImage={data.shortest_race.hasImage}
            title={t("ShortestRace")}
            text={
              <Trans
                i18nKey={
                  data.shortest_race.username.split(",").length > 1
                    ? "ShortestRaceText2"
                    : "ShortestRaceText"
                }
                values={{
                  time: data.shortest_race.time,
                  class: data.shortest_race.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.shortest_race.name.replace(/ /g, "")
                      )}`}
                      text={data.shortest_race.name}
                    />
                  ),
                  linkDriver: showDriverLinks(data.shortest_race.username),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.shortest_race_gap.id}
            gp_name={data.shortest_race_gap.name}
            hasImage={data.shortest_race_gap.hasImage}
            title={t("DidYouKnowText11Tit")}
            text={
              <Trans
                i18nKey={
                  data.shortest_race_gap.p1.split(",").length > 1
                    ? "DidYouKnowText11Text2"
                    : "DidYouKnowText11Text"
                }
                values={{
                  time: data.shortest_race_gap.time,
                  class: data.shortest_race_gap.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.shortest_race_gap.name.replace(/ /g, "")
                      )}`}
                      text={data.shortest_race_gap.name}
                    />
                  ),
                  linkDriver1: showDriverLinks(data.shortest_race_gap.p1),
                  linkDriver2: showDriverLinks(data.shortest_race_gap.p2),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.longest_race_gap.id}
            gp_name={data.longest_race_gap.name}
            hasImage={data.longest_race_gap.hasImage}
            title={t("DidYouKnowText12Tit")}
            text={
              <Trans
                i18nKey={
                  data.longest_race_gap.p1.split(",").length > 1
                    ? "DidYouKnowText12Text2"
                    : "DidYouKnowText12Text"
                }
                values={{
                  time: data.longest_race_gap.time,
                  class: data.longest_race_gap.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.longest_race_gap.name.replace(/ /g, "")
                      )}`}
                      text={data.longest_race_gap.name}
                    />
                  ),
                  linkDriver1: showDriverLinks(data.longest_race_gap.p1),
                  linkDriver2: showDriverLinks(data.longest_race_gap.p2),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.most_leaders.id}
            gp_name={data.most_leaders.name}
            hasImage={data.most_leaders.hasImage}
            title={t("DidYouKnowText13Tit")}
            text={
              <Trans
                i18nKey={
                  data.most_leaders.class === ""
                    ? "DidYouKnowText13Text"
                    : "DidYouKnowText13Text2"
                }
                values={{
                  total:
                    data.most_leaders.class === ""
                      ? data.most_leaders.total
                      : data.most_leaders.total2,
                  class: data.most_leaders.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.most_leaders.name.replace(/ /g, "")
                      )}`}
                      text={data.most_leaders.name}
                    />
                  ),
                }}
              />
            }
            array_drivers={data.most_leaders.team_drivers_array}
            class_name={data.most_leaders.class}
            has_profile={data.most_leaders.has_profile}
          />
          <DidYouKnowCard
            gp={data.closest_pole.id}
            gp_name={data.closest_pole.name}
            hasImage={data.closest_pole.hasImage}
            title={t("ClosestPole")}
            text={
              <Trans
                i18nKey={
                  data.closest_pole.class != null
                    ? "ClosestPoleText2"
                    : "ClosestPoleText"
                }
                values={{
                  time: parseFloat(data.closest_pole.time).toFixed(3) + "s",
                  class: data.closest_pole.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.closest_pole.name.replace(/ /g, "")
                      )}`}
                      text={data.closest_pole.name}
                    />
                  ),
                  linkDriver1: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.closest_pole.p1
                      )}`}
                      text={data.closest_pole.p1}
                    />
                  ),
                  linkDriver2: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.closest_pole.p2
                      )}`}
                      text={data.closest_pole.p2}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.longest_pole.id}
            gp_name={data.longest_pole.name}
            hasImage={data.longest_pole.hasImage}
            title={t("ByFarPole")}
            text={
              <Trans
                i18nKey={
                  data.longest_pole.class != null
                    ? "ByFarPoleText2"
                    : "ByFarPoleText"
                }
                values={{
                  time: parseFloat(data.longest_pole.time).toFixed(3) + "s",
                  class: data.longest_pole.class,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.longest_pole.name.replace(/ /g, "")
                      )}`}
                      text={data.longest_pole.name}
                    />
                  ),
                  linkDriver1: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.longest_pole.p1
                      )}`}
                      text={data.longest_pole.p1}
                    />
                  ),
                  linkDriver2: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.longest_pole.p2
                      )}`}
                      text={data.longest_pole.p2}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            gp={data.common_circuit.id}
            gp_name={data.common_circuit.name}
            hasImage={data.common_circuit.hasImage}
            title={t("UsualCircuit")}
            text={
              <Trans
                i18nKey="UsualCircuitText"
                values={{
                  circuit: data.common_circuit.circuit,
                  total: data.common_circuit.total,
                }}
                components={{
                  bold: <strong />,
                  linkGP: (
                    <LinkText
                      to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data.common_circuit.name.replace(/ /g, "")
                      )}`}
                      text={data.common_circuit.name}
                    />
                  ),
                }}
              />
            }
          />
        </div>
        <div className="pins-container">
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText1"
                values={{
                  total: data.didyouknow1.drivers.length,
                  date1: new Date(data.didyouknow1.date_ini).toLocaleString(
                    i18n.language,
                    {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    }
                  ),
                  date2: new Date(data.didyouknow1.date_fin).toLocaleString(
                    i18n.language,
                    {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    }
                  ),
                }}
                components={{
                  bold: <strong />,
                }}
              />
            }
            array_drivers={data.didyouknow1.drivers}
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText2"
                values={{
                  total: data.didyouknow2.total,
                }}
                components={{
                  bold: <strong />,
                }}
              />
            }
            array_drivers={data.didyouknow2.drivers}
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText3"
                values={{
                  total: data.didyouknow3.drivers.length,
                  date1: new Date(data.didyouknow3.date_ini).toLocaleString(
                    i18n.language,
                    {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    }
                  ),
                  date2: new Date(data.didyouknow3.date_fin).toLocaleString(
                    i18n.language,
                    {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    }
                  ),
                }}
                components={{
                  bold: <strong />,
                }}
              />
            }
            array_drivers={data.didyouknow3.drivers}
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText4"
                values={{
                  total1: data.didyouknow4.circuits,
                  total2: data.didyouknow4.circuits_countries,
                  total3: data.didyouknow4.drivers,
                  total4: data.didyouknow4.driver_countries,
                }}
                components={{
                  bold: <strong />,
                }}
              />
            }
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText6"
                values={{
                  total: data.didyouknow6.total,
                }}
                components={{
                  bold: <strong />,
                  linkSeason: (
                    <LinkText
                      to={`${t("routes:Season")}/${encodeURIComponent(
                        data.didyouknow6.season.replace(/ /g, "")
                      )}`}
                      text={data.didyouknow6.season}
                    />
                  ),
                }}
              />
            }
            array_drivers={data.didyouknow6.drivers_array}
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText7"
                values={{
                  total: data.didyouknow7.total,
                }}
                components={{
                  bold: <strong />,
                  linkSeason: (
                    <LinkText
                      to={`${t("routes:Season")}/${encodeURIComponent(
                        data.didyouknow7.season.replace(/ /g, "")
                      )}`}
                      text={data.didyouknow7.season}
                    />
                  ),
                }}
              />
            }
            array_drivers={data.didyouknow7.drivers_array}
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText8"
                values={{
                  total: data.didyouknow8.races,
                  position: data.didyouknow8.best_position,
                }}
                components={{
                  bold: <strong />,
                  linkDriver1: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.didyouknow8.username
                      )}`}
                      text={data.didyouknow8.username}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText9"
                values={{
                  total: data.didyouknow9.races,
                  position: data.didyouknow9.best_position,
                }}
                components={{
                  bold: <strong />,
                  linkDriver1: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.didyouknow9.username
                      )}`}
                      text={data.didyouknow9.username}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText10"
                values={{
                  total: data.didyouknow10.races,
                  position: data.didyouknow10.best_position,
                }}
                components={{
                  bold: <strong />,
                  linkDriver1: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.didyouknow10.username
                      )}`}
                      text={data.didyouknow10.username}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText14"
                values={{
                  total: data.didyouknow14.streak,
                }}
                components={{
                  bold: <strong />,
                  linkDriver1: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.didyouknow14.driver
                      )}`}
                      text={data.didyouknow14.driver}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText15"
                values={{
                  total: data.didyouknow15.streak,
                }}
                components={{
                  bold: <strong />,
                  linkDriver1: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.didyouknow15.driver
                      )}`}
                      text={data.didyouknow15.driver}
                    />
                  ),
                }}
              />
            }
          />
          <DidYouKnowCard
            text={
              <Trans
                i18nKey="DidYouKnowText16"
                values={{
                  total: data.didyouknow16.streak,
                }}
                components={{
                  bold: <strong />,
                  linkDriver1: (
                    <LinkText
                      to={`${t("routes:Driver")}/${encodeURIComponent(
                        data.didyouknow16.driver
                      )}`}
                      text={data.didyouknow16.driver}
                    />
                  ),
                }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DidYouKnow;
