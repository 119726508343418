import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_lang_DE from "@amcharts/amcharts5-geodata/lang/DE";
import am5geodata_lang_EN from "@amcharts/amcharts5-geodata/lang/EN";
import am5geodata_lang_ES from "@amcharts/amcharts5-geodata/lang/ES";
import am5geodata_lang_FR from "@amcharts/amcharts5-geodata/lang/FR";
import am5geodata_lang_HU from "@amcharts/amcharts5-geodata/lang/HU";
import am5geodata_lang_IT from "@amcharts/amcharts5-geodata/lang/IT";
import { i18n } from "../../App";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Flag from "../Flag";
import DataTableMapChart from "../../views/historic/nationalities/DataTableMapChart";

const MapChart = ({
  id,
  race_data,
  driver_data,
  spanish_data,
  circuits,
}: {
  id: string;
  race_data?: RacesChartData[];
  driver_data?: DriversChartData[];
  spanish_data?: DriversChartData[];
  circuits?: CircuitsChartData[];
}) => {
  const { t } = useTranslation();
  const [raceSelected, setRaceSelected] = useState<RacesChartData>();
  const [driverSelected, setDriverSelected] = useState<DriversChartData>();
  const [showRaces, setShowRaces] = useState(false);
  const [showDrivers, setShowDrivers] = useState(false);
  const handleCloseRaces = () => setShowRaces(false);
  const handleShowRaces = () => setShowRaces(true);
  const handleCloseDrivers = () => setShowDrivers(false);
  const handleShowDrivers = () => setShowDrivers(true);

  const getLocalLanguage = () => {
    if (i18n.language === "de") return am5geodata_lang_DE;
    else if (i18n.language === "es") return am5geodata_lang_ES;
    else if (i18n.language === "fr") return am5geodata_lang_FR;
    else if (i18n.language === "hu") return am5geodata_lang_HU;
    else if (i18n.language === "it") return am5geodata_lang_IT;
    return am5geodata_lang_EN;
  };

  const pickHex = (color1: number[], color2: number[], weight: number) => {
    var p = weight;
    var w = p * 2 - 1;
    var w1 = (w / 1 + 1) / 2;
    var w2 = 1 - w1;
    var rgb = [
      Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2),
    ];
    return rgb;
  };
  const componentToHex = (c: number) => {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };
  const rgbToHex = (r: number, g: number, b: number) => {
    return "0x" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  };

  useEffect(() => {
    const chart_data = race_data != null ? race_data : driver_data;
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        wheelY: "zoom",
        minZoomLevel: 0.5,
        maxZoomLevel: 70,
        projection: am5map.geoMercator(),
        layout: root.horizontalLayout,
      })
    );

    const heatLegend = chart.children.push(
      am5.HeatLegend.new(root, {
        orientation: "vertical",
        startColor: am5.color(0x64ffda),
        endColor: am5.color(0x37474f),
        stepCount: 1,
      })
    );
    heatLegend.startLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get("startColor"),
    });

    heatLegend.endLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get("endColor"),
    });

    am5.net
      .load("https://cdn.amcharts.com/lib/5/geodata/json/worldLow.json", chart)
      .then((result: any) => {
        var geodata = am5.JSONParser.parse(result.response);
        var data = [];
        if (chart_data == null) return;
        for (var i = 0; i < geodata.features.length; i++) {
          var bool = false;
          for (var j = 0; j < chart_data.length; j++) {
            if (
              chart_data[j]["code"].toUpperCase() === geodata.features[i].id
            ) {
              bool = true;
              data.push({
                id: geodata.features[i].id,
                value: chart_data[j].total,
              });
            }
          }
          if (!bool) {
            data.push({
              id: geodata.features[i].id,
              value: 0,
            });
          }
        }
        polygonSeries.set("geoJSON", geodata);
        polygonSeries.set("exclude", ["AQ"]);
        polygonSeries.set("geodataNames", getLocalLanguage());
        polygonSeries.data.setAll(data);
      });

    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        calculateAggregates: true,
        valueField: "value",
      })
    );
    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
    });
    polygonSeries.events.on("datavalidated", () => {
      heatLegend.set("startValue", polygonSeries.getPrivate("valueHigh"));
      heatLegend.set("endValue", polygonSeries.getPrivate("valueLow"));
    });
    polygonSeries.mapPolygons.template.events.on(
      "pointerover",
      function (ev: any) {
        heatLegend.showValue(ev.target.dataItem.get("value"));
      }
    );
    polygonSeries.mapPolygons.template.states.create("hover", {
      fillOpacity: 0.7,
    });

    polygonSeries.set("heatRules", [
      {
        target: polygonSeries.mapPolygons.template,
        dataField: "value",
        min: am5.color(0xc0c0c0),
        max: am5.color(0x64ffda),
        key: "fill",
        customFunction: (sprite: any, min, max, value) => {
          //Transforms due to drivers chart (too many difference between 1st value and the rest)
          if (value == 0) {
            sprite.set("fill", am5.color(0xc0c0c0));
          } else if (value == polygonSeries.getPrivate("valueHigh")) {
            sprite.set("fill", am5.color(0x64ffda));
          } else if (chart_data != null && chart_data?.length > 0) {
            //Case every value except 0s and greater
            var max_value = chart_data[1].total;
            var uivalue: number = (value * 1) / max_value;

            var result = pickHex([77, 182, 172], [51, 71, 79], uivalue);
            sprite.set(
              "fill",
              am5.color(eval(rgbToHex(result[0], result[1], result[2])))
            );
          }
        },
      },
    ]);
    if (spanish_data != null) {
      /* CASE CLICK ON DRIVERS CHART*/
      polygonSeries.mapPolygons.template.events.on("click", (ev: any) => {
        var country = ev.target.dataItem.get("id");
        var map;
        switch (country) {
          case "ES":
            map = "spainLow.json";
            polygonSeries.zoomToDataItem(ev.target.dataItem);
            homeButton.show();
            break;
        }
        const country_data = driver_data?.find(
          (country_) => country_.code === country.toLowerCase()
        );
        if (country_data != null) {
          setShowDrivers(true);
          setDriverSelected(country_data);
        }

        if (map) {
          am5.net
            .load("https://cdn.amcharts.com/lib/5/geodata/json/" + map, chart)
            .then((result: any) => {
              var geodata = am5.JSONParser.parse(result.response);
              var data = [];
              for (var i = 0; i < geodata.features.length; i++) {
                var bool = false;
                for (var j = 0; j < spanish_data.length; j++) {
                  if (
                    spanish_data[j].code.toUpperCase() ===
                    geodata.features[i].id
                  ) {
                    bool = true;
                    data.push({
                      id: geodata.features[i].id,
                      value: spanish_data[j].total,
                    });
                  }
                }
                if (!bool) {
                  data.push({
                    id: geodata.features[i].id,
                    value: 0,
                  });
                }
              }

              countrySeries.set("geoJSON", geodata);
              polygonSeries.set("geodataNames", getLocalLanguage());
              countrySeries.data.setAll(data);

              countrySeries.show();
              polygonSeries.hide();
            });
        }
      });
      let countrySeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          visible: false,
          calculateAggregates: true,
          valueField: "value",
        })
      );
      countrySeries.mapPolygons.template.setAll({
        tooltipText: "{name}",
        interactive: true,
      });

      countrySeries.events.on("datavalidated", function () {
        heatLegend.set("startValue", countrySeries.getPrivate("valueHigh"));
        heatLegend.set("endValue", countrySeries.getPrivate("valueLow"));
      });
      countrySeries.mapPolygons.template.events.on("pointerover", (ev: any) => {
        heatLegend.showValue(ev.target.dataItem.get("value"));
      });

      countrySeries.mapPolygons.template.states.create("hover", {
        fillOpacity: 0.7,
      });

      countrySeries.set("heatRules", [
        {
          target: countrySeries.mapPolygons.template,
          dataField: "value",
          min: am5.color(0x37474f),
          max: am5.color(0x64ffda),
          key: "fill",
        },
      ]);

      countrySeries.mapPolygons.template.events.on("click", (ev: any) => {
        var country = ev.target.dataItem.get("id");
        const country_data = spanish_data?.find(
          (country_) => country_.code === country.toLowerCase()
        );
        if (country_data != null) {
          setShowDrivers(true);
          setDriverSelected(country_data);
        }
      });

      // Add a button to go back to continents view
      var homeButton = chart.children.push(
        am5.Button.new(root, {
          paddingTop: 10,
          paddingBottom: 10,
          x: am5.percent(100),
          centerX: am5.percent(100),
          opacity: 0,
          interactiveChildren: false,
          icon: am5.Graphics.new(root, {
            svgPath:
              "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8",
            fill: am5.color(0xffffff),
          }),
        })
      );
      homeButton.hide();

      homeButton.events.on("click", function () {
        chart.goHome();
        polygonSeries.show();
        countrySeries.hide();
        homeButton.hide();
        heatLegend.set("startValue", polygonSeries.getPrivate("valueHigh"));
        heatLegend.set("endValue", polygonSeries.getPrivate("valueLow"));
      });
    } else {
      /* CASE CLICK ON GRAND PRIX CHART*/
      polygonSeries.mapPolygons.template.events.on("click", (ev: any) => {
        var country = ev.target.dataItem.get("id");
        const country_data = race_data?.find(
          (country_) => country_.code === country.toLowerCase()
        );
        if (country_data != null) {
          setShowRaces(true);
          setRaceSelected(country_data);
        }
      });

      var pointSeries = chart.series.push(
        am5map.MapPointSeries.new(root, {
          latitudeField: "lat",
          longitudeField: "long",
        })
      );

      pointSeries.bullets.push(function (
        root_,
        mappointseries_,
        data_item: any
      ) {
        var circle = am5.Circle.new(root, {
          radius: data_item.dataContext.radius,
          fill: am5.color(0xb71c1c),
          tooltipText: "{name}",
        });

        circle.events.on("click", function (ev: any) {
          var circuit = ev.target.dataItem.dataContext.name;
          let races: any[] = [];
          let code = "";
          race_data?.forEach((country_) => {
            country_.data_table?.forEach((race) => {
              if (race.circuit === circuit) {
                races.push(race);
                code = country_.code;
              }
            });
          });

          setShowRaces(true);
          setRaceSelected({
            code: code,
            total: 0,
            name: circuit,
            data_table: races,
          });
        });

        return am5.Bullet.new(root, {
          sprite: circle,
        });
      });

      if (circuits != null) {
        pointSeries.data.setAll(circuits);
      }
    }

    chart.appear(500, 300);
    return () => root.dispose();
  }, [i18n.language, circuits, race_data, driver_data, spanish_data]);

  return (
    <>
      <div
        id={id}
        className="map-chart"
        style={{ width: "100%", height: "calc(100vh - 230px)" }}
      ></div>

      {/* MODAL GRAND PRIX WITH IMAGES */}
      {raceSelected != null && (
        <Modal size="xl" show={showRaces} onHide={handleCloseRaces}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Flag flag_code={raceSelected.code} name={raceSelected.name} />
              {t(`countries:${raceSelected.name}`)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row">
            {raceSelected.data_table?.map((data_) => {
              return (
                <div
                  key={data_.id}
                  className="race-image-map col-sm-12 col-md-6 col-lg-4"
                >
                  <h5>
                    <a
                      className="driver"
                      href={`${t("routes:GrandPrix")}/${encodeURIComponent(
                        data_.name.replace(/ /g, "")
                      )}`}
                      target="_blank"
                    >
                      {data_.name}
                    </a>
                  </h5>
                  <img
                    src={
                      data_.hasImage != null && data_.hasImage
                        ? `/assets/ImagesGP/${data_.id}.jpg`
                        : require(`../../assets/Images/NoImage.png`)
                    }
                    alt={data_.name}
                  />
                </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}

      {/* MODAL DRIVERS */}
      {driverSelected != null && (
        <Modal
          size="xl"
          show={showDrivers}
          onHide={handleCloseDrivers}
          className="historic-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Flag
                flag_code={driverSelected.code}
                name={driverSelected.name}
              />
              {t(`countries:${driverSelected.name}`)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row">
            <DataTableMapChart driverSelected={driverSelected} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default MapChart;
