import { i18n } from "../App";
import { transformHour } from "../utils/DateTransformations";

const DateFormat = ({
  date,
  format,
  disaggregated,
}: {
  date: string[] | string;
  format: string;
  disaggregated?: boolean;
}) => {
  const getDateFormat = (date_: string) => {
    let arrayDates: any[] = [];
    if (typeof date === "string") arrayDates = [date];
    else arrayDates = date;

    if (format === "short")
      return (
        <span className="dateFormat">
          {new Date(date_)
            .toLocaleString(i18n.language, {
              day: "2-digit",
              month: "long",
            })
            .toUpperCase()}
        </span>
      );
    else if (format === "short_year")
      return (
        <span className="dateFormat">
          {new Date(date_)
            .toLocaleString(i18n.language, {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })
            .toUpperCase()}
        </span>
      );
    else if (format === "full") {
      return (
        <span className="dateFormat">
          {new Date(transformHour(date_)).toLocaleString(i18n.language, {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })}
        </span>
      );
    } else if (format === "full-v2") {
      const optDate: any = {
        weekday: "long",
        day: "numeric",
        month: "long",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const optTime: any = {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      const fechaFormateada = new Date(transformHour(date_)).toLocaleString(
        i18n.language,
        optDate
      );
      const horaFormateada = new Date(transformHour(date_)).toLocaleString(
        i18n.language,
        optTime
      );

      return `${fechaFormateada}, ${horaFormateada}`;
    } else {
      return (
        <span className="dateFormat">
          {new Date(date_).toLocaleString(i18n.language, {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })}
        </span>
      );
    }
  };

  if (disaggregated && typeof date !== "string" && date.length > 1) {
    return (
      <div className="dates-container">
        {date.map((date, index) => {
          return (
            <span key={index}>
              <span className={`session-${index + 1}`}>{index + 1}</span>
              {getDateFormat(date)}
            </span>
          );
        })}
      </div>
    );
  } else if (typeof date !== "string" && date.length > 1)
    return (
      <>
        {getDateFormat(date[0])}
        <label className="dateFormat-separator">{"—"}</label>
        {getDateFormat(date[date.length - 1])}
      </>
    );
  else if (typeof date === "string") return <>{getDateFormat(date)}</>;
  else return <>{getDateFormat(date[0])}</>;
};

export default DateFormat;
