import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ClaimsView = ({
  user,
  claims,
  getSessionName,
  setClaims,
  update,
  setUpdateClaims,
  grandprix,
}: {
  user: string;
  claims: ClaimGP[];
  getSessionName: (session: number) => string;
  setClaims: Dispatch<SetStateAction<ClaimGP[]>>;
  update: (claim_: ClaimGP) => void;
  setUpdateClaims: Dispatch<SetStateAction<boolean>>;
  grandprix: string;
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setClaimToDelete(-1);
    setShow(false);
  };
  const handleShow = (claim_id: number) => {
    setClaimToDelete(claim_id);
    setShow(true);
  };
  const [claimToDelete, setClaimToDelete] = useState(-1);

  const handleMouseOver = (index: number) => {
    setClaims((prev_claims: ClaimGP[]) => {
      let aux = prev_claims.slice();
      if (aux[index].claimer === user) aux[index].hover = true;
      return aux;
    });
  };

  const handleMouseOut = (index: number) => {
    setClaims((prev_claims: ClaimGP[]) => {
      let aux = prev_claims.slice();
      aux[index].hover = false;
      return aux;
    });
  };

  const deleteClaim = () => {
    let token = window.localStorage.getItem("userLogged");
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/deleteClaim`,
        {
          claimToDelete,
          user,
          token: JSON.parse(token).token,
          grandprix,
        }
      )
      .then(({ data }) => {
        toast.success(`${t("ClaimDeleted")}`);
        setUpdateClaims((prev_value: boolean) => !prev_value);
        handleClose();
      })
      .catch(({ response }) => {
        toast.error(`${t(response.data)}`);
        throw new Error(response);
      });
  };
  return (
    <div id="claims-container" className="frame-turqoise">
      <label className="frame-title">{t("Claims").toUpperCase()}</label>
      {claims.length === 0 ? (
        <p className="text-center">{t("NoClaimsYet")}</p>
      ) : (
        claims.map((claim, index) => {
          return (
            <div
              className="claim"
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={() => handleMouseOut(index)}
            >
              <FontAwesomeIcon icon={faAngleRight} />{" "}
              <b>
                {claim.username} {claim.time} ({getSessionName(claim.session)}):
              </b>{" "}
              {claim.description}
              {claim.claimer === user && (
                <div
                  className={`flex flex-around update-claim ${
                    claim.hover ? "hover" : ""
                  }`}
                >
                  <Button
                    variant="outline-primary"
                    onClick={() => update(claim)}
                  >
                    {t("Update")}
                  </Button>
                  <Button
                    variant="outline-danger"
                    onClick={() => handleShow(claim.claim_id)}
                  >
                    {t("Delete")}
                  </Button>
                </div>
              )}
            </div>
          );
        })
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("Delete")} {t("Claim")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("DeleteClaimConfirmation")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button variant="danger" type="submit" onClick={() => deleteClaim()}>
            {t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClaimsView;
