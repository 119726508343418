import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Title from "../../../components/Admin/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

interface Championship {
  id: number;
  name: string;
}

const RemoveChampionship = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Remove Championship";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [championships, setChampionships] = useState<Championship[]>([]);
  const [championship, setChampionship] = useState("");

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/removeChampionship`,
          {
            championship,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(({ data }) => {
          setChampionships(data.championships);
          setChampionship("");
          toast.success("Championship removed successfully");
          setValidated(false);
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          throw new Error(response);
        });
    } else setValidated(true);
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getChampionshipsToDelete`
      )
      .then(({ data }) => {
        setChampionships(data.championships);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Remove Championship" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <label className="text-center full-width">
            You can only delete championships that have not yet been run.
          </label>
          <Form.Group className="form-group">
            <Form.Label>Championship (*)</Form.Label>
            <Form.Select
              value={championship}
              name="flag"
              required
              onChange={(e: any) => {
                setChampionship(e.target.value);
              }}
            >
              <option value="" disabled>
                None
              </option>
              {championships != null &&
                championships.map((championship) => {
                  return (
                    <option value={championship.id} key={championship.id}>
                      {championship.name}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Remove Championship
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RemoveChampionship;
