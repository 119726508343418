import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import FormNews from "../../../components/News/FormNews";
import { useEffect, useState } from "react";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { Form, Col, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useUser } from "../../../components/UserContext";

const UpdateNews = () => {
  const { typeUser } = useIsAdmin();
  document.title = "LVF1 - Update News";

  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [show, setShow] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(true);
  const [dataForm, setDataForm] = useState({
    title_en: "",
    title_es: "",
    caption_es: "",
    caption_en: "",
    championship_id: -1,
    content_es: "",
    content_en: "",
    grand_prix_event_id: null,
    id: -1,
  });
  const [news, setNews] = useState<{ id: number; title_en: string }[]>([]);
  const [id, setId] = useState(-1);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/news/getNews`,
        {
          params: {
            id,
          },
        }
      )
      .then(({ data }) => {
        setDataForm(data.first_new);
        setNews(data.array_news);
        setId(data.first_new.id);
        setLoading(false);
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });
  }, [id, deleted]);

  const deleteNew = () => {
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/deleteNew`,

        {
          id,
          user,
          token: JSON.parse(token).token,
        }
      )
      .then(() => {
        handleClose();
        setDeleted(!deleted);
        toast.success(`New deleted successfully`);
      })
      .catch(({ response }) => {
        toast.error("Error!");
        throw new Error(response);
      });
  };

  if (loading) return <LoadingContent />;
  if (typeUser !== 2 && typeUser !== 4) return <ErrorPage />;
  return (
    <>
      <div className="full-height padding-md app-container flex-center-top page-transition">
        <div className="form-container no-width-limit">
          <Title text="Update New" />
          <small className="mandatory-fields">(*) Required fields</small>
          <Form.Group className="form-group" as={Col} md="12">
            <Form.Label>New Title (*)</Form.Label>
            <div className="flex">
              <select
                value={id}
                className="form-control"
                required
                onChange={(e) => {
                  setId(parseInt(e.target.value));
                }}
              >
                {news.map((new_) => {
                  return (
                    <option key={new_.id} value={new_.id}>
                      {new_.title_en}
                    </option>
                  );
                })}
              </select>
              <FontAwesomeIcon
                title="Delete"
                className="red pointer delete-form-icon"
                icon={faTrash}
                onClick={() => handleShow()}
              />
            </div>
            <Form.Control.Feedback type="invalid">
              Field required
            </Form.Control.Feedback>
          </Form.Group>
          <FormNews data={dataForm} title={"Update New"} />
        </div>
      </div>
      <Modal size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the news item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => deleteNew()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateNews;
