import React from "react";
import Flag from "./Flag";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DriverLink = ({
  flag_code,
  flag_name,
  name,
  nickname,
}: {
  flag_code: string | null;
  flag_name: string | null;
  name: string;
  nickname?: string | null;
}) => {
  const { t } = useTranslation();
  const path: string = t("routes:Driver");
  return JSXLink({ flag_code, flag_name, name, nickname, path });
};

const TeamLink = ({
  flag_code,
  flag_name,
  name,
  nickname,
}: {
  flag_code: string | null;
  flag_name: string | null;
  name: string;
  nickname?: string | null;
}) => {
  const { t } = useTranslation();
  const path: string = t("routes:Team");
  return JSXLink({ flag_code, flag_name, name, nickname, path });
};

const JSXLink = ({
  flag_code,
  flag_name,
  name,
  nickname,
  path,
}: {
  flag_code: string | null;
  flag_name: string | null;
  name: string;
  nickname?: string | null;
  path: string;
}) => {
  if (flag_code == "unk")
    return (
      <span>
        <Flag flag_code={flag_code} name={flag_name} /> {name}
      </span>
    );
  return (
    <Link to={`${path}/${encodeURIComponent(name)}`} className="driver">
      <Flag flag_code={flag_code} name={flag_name} />
      {name}
    </Link>
  );
};

export { DriverLink, TeamLink };
