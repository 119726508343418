import { animated, useSpring } from "react-spring";
import { useNavigate } from "react-router-dom";
import ChampionshipIndex from "./ChampionshipIndex";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const IndexChamp = ({
  id,
  defaultWidth,
  text,
  selected,
  setSelected,
}: {
  id: number;
  defaultWidth: string;
  text: string;
  selected: number;
  setSelected: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = window.localStorage.getItem("theme");
  const style = useSpring({
    backgroundImage:
      theme == "light" || theme == null
        ? `url(/assets/Index/${id == 1 ? "MainLight" : "SecLight"}.jpg)`
        : `url(/assets/Index/${id == 1 ? "MainDark" : "SecDark"}.jpg)`,
    width: selected === -1 ? defaultWidth : selected === id ? "100%" : "0%",
    opacity: selected === id ? "0" : "1",
  });

  useEffect(() => {
    if (selected !== id) return;
    const timer = setTimeout(() => {
      if (t("routes:Home") == "/")
        navigate(`${encodeURI(text.replace(/ /g, ""))}`);
      else navigate(`${t("routes:Home")}/${encodeURI(text.replace(/ /g, ""))}`);
    }, 100); // Esperar 1 segundo (ajusta el tiempo según tus necesidades)

    return () => {
      clearTimeout(timer); // Limpiar el temporizador si el componente se desmonta antes de que se ejecute
    };
  }, [selected]);

  return (
    <>
      <animated.div style={style}>
        <div
          className={`centerBox ${
            selected !== -1 && selected !== id ? "hidden" : ""
          }`}
        >
          <animated.div className="categoryWrapper">
            <button onClick={() => setSelected(id)}>
              <span>
                <span>
                  <span data-attr-span={text}>{text}</span>
                </span>
              </span>
            </button>
          </animated.div>
        </div>
      </animated.div>
    </>
  );
};

export default IndexChamp;
