import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import {
  CountriesOptions,
  SpanishRegionsOptions,
} from "../../../components/SelectRegions";
import $ from "jquery";
import { md5Cipher } from "../../../utils/MD5Cipher";
import axios from "axios";
import CropperUser from "../../../components/CropperUser";

const Cover = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState<any>("");
  const [text, setText] = useState("");
  const [usernameRepeated, setUsernameRepeated] = useState(false);
  const [mailRepeated, setMailRepeated] = useState(false);
  const [file2, setFile2] = useState("");

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setBody({
      ...body,
      [name]: value,
    });
    if (name === "username") checkRepeatedUsername(value);
    else if (name === "mail") checkRepeatedMail(value);
  };
  const [body, setBody] = useState({
    mail: "",
    birthday: "",
    password: "",
    password_conf: "",
    username: "",
    name: "",
    surname: "",
    country: "",
    region: "",
    origin: "",
  });
  const checkPasswordInvalidity = () => {
    if (body.password.length >= 8 && body.password !== body.password_conf) {
      $("#password_conf-error").text(t("PasswordConfError"));
      return true;
    }
  };

  const checkPasswordInvalidityLength = () => {
    if (body.password !== "" && body.password.length < 8) {
      $("#password-error").text(t("PasswordShortError"));
      return true;
    }
    return false;
  };

  const checkRegionInvalidity = () => {
    if (body.region === "" && body.country === "España") {
      return true;
    }
    return false;
  };

  const handleChange = (e: any) => {
    const file = e.target.files[0]; // accessing file
    setFile(file);
    setText(file.name);
  };

  const submitForm = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      const formData = new FormData();
      formData.append("file", file2);
      formData.append("username", body.username);
      formData.append("name", body.name);
      formData.append("surname", body.surname);
      formData.append("birthday", body.birthday);
      formData.append("mail", body.mail);
      formData.append("country", body.country);
      formData.append("region", body.region);
      formData.append("origin", body.origin);
      formData.append("password", md5Cipher(body.password));

      if (
        !checkPasswordInvalidity() &&
        !checkPasswordInvalidityLength() &&
        !checkRegionInvalidity() &&
        !(await checkRepeatedUsername(body.username)) &&
        !(await checkRepeatedMail(body.mail))
      ) {
        axios
          .post(
            `${
              process.env.NODE_ENV === "development"
                ? "http://localhost:5500"
                : "https://ligavirtualf1.es:5500"
            }/api/user/createUser`,
            formData
          )
          .then(({ data }) => {
            toast.success(`${t("SignUpOK")}`);
            handleClose();
          })
          .catch(({ response }) => {
            throw new Error(response);
          });
      }
    }
    setValidated(true);
  };

  /* WE ONLY SHOW REGION IF THE COUNTRY IS SPAIN */
  const checkCountry = ({ target }: { target: any }) => {
    const { value } = target;
    if (value === "España") {
      $("#country-group").removeClass("col-md-12").addClass("col-md-6");
      $("#region-group").show();
    } else {
      $("#country-group").removeClass("col-md-6").addClass("col-md-12");
      $("#region-group").hide();
    }
  };

  /* USERNAME REPEATED CHECKS */
  const checkRepeatedUsername = async (username: string) => {
    await axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/user/getUser`,
        {
          params: {
            user: username,
          },
        }
      )
      .then(({ data }) => {
        if (data === "") {
          setUsernameRepeated(false);
        } else {
          setUsernameRepeated(true);
        }
      })
      .catch(() => {});
    return usernameRepeated;
  };

  const checkUsernameInvalidity = () => {
    if (usernameRepeated) $("#username-error").text(t("RepeatedUsernameError"));
    else if (body.username === "")
      $("#username-error").text(t("FieldRequired"));
    return usernameRepeated;
  };

  /* MAIL REPEATED CHECKS */
  const checkRepeatedMail = async (mail: string) => {
    await axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/user/getUserByMail`,
        {
          params: {
            mail: mail,
          },
        }
      )
      .then(({ data }) => {
        if (data === "") setMailRepeated(false);
        else setMailRepeated(true);
      })
      .catch(() => {});
    return mailRepeated;
  };

  const checkMailInvalidity = () => {
    if (mailRepeated) $("#mail-error").text(t("RepeatedMailError"));
    else if (body.mail === "") $("#mail-error").text(t("FieldRequired"));
    else $("#mail-error").text(t("EmailError"));
    return mailRepeated;
  };
  return (
    <>
      <div id="main-image" className="flex-center">
        <div id="main-inner-text">
          <span>{t("Slogan")}</span>
          <div className="button-container margintopx1">
            <button className="button-lvf1-pushable" onClick={handleShow}>
              <span className="button-lvf1-shadow"></span>
              <span className="button-lvf1-edge"></span>
              <span className="button-lvf1-front text highlight-button">
                {t("JoinLVF1").toUpperCase()}
              </span>
            </button>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Modal.Header closeButton>
            <Modal.Title>{t("SignUp")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <small className="mandatory-fields top-margin">
              (*) {t("RequiredFields")}
            </small>
            <Form.Group className="form-group">
              <Form.Label>
                {t("Username")} (*){" "}
                <span
                  className="profile-bubble"
                  data-tooltip={t("UsernameHelp")}
                  data-flow="right"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="label-icon"
                  />
                </span>
              </Form.Label>
              <Form.Control
                name="username"
                type="text"
                value={body.username}
                required
                onChange={inputChange}
                isInvalid={checkUsernameInvalidity()}
              />
              <Form.Control.Feedback id="username-error" type="invalid">
                {t("FieldRequired")}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>{t("Name")} (*)</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  value={body.name}
                  onChange={inputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("FieldRequired")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>{t("Surname")} (*)</Form.Label>
                <Form.Control
                  name="surname"
                  type="text"
                  value={body.surname}
                  onChange={inputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("FieldRequired")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group id="country-group" as={Col} md="12">
                <Form.Label>{t("Country")} (*)</Form.Label>
                <Form.Select
                  name="country"
                  value={body.country}
                  required
                  onChange={(e) => {
                    inputChange(e);
                    checkCountry(e);
                  }}
                >
                  <option disabled value={""}>
                    {t("None")}
                  </option>
                  <CountriesOptions />
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("FieldRequired")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group id="region-group" as={Col} md="6" className="hide">
                <Form.Label>{t("Region")} (*)</Form.Label>
                <Form.Select
                  name="region"
                  value={body.region}
                  onChange={inputChange}
                  isInvalid={checkRegionInvalidity()}
                >
                  <option disabled value={""}>
                    {t("None")}
                  </option>
                  <SpanishRegionsOptions />
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("FieldRequired")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group className="form-group" as={Col} md="7">
                <Form.Label>E-mail (*)</Form.Label>
                <Form.Control
                  name="mail"
                  type="text"
                  value={body.mail}
                  onChange={inputChange}
                  required
                  pattern=".+@.+"
                  isInvalid={checkMailInvalidity()}
                />
                <Form.Control.Feedback id="mail-error" type="invalid">
                  {t("EmailError")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" as={Col} md="5">
                <Form.Label>{t("Birthdate")}</Form.Label>
                <Form.Control
                  name="birthday"
                  type="date"
                  value={body.birthday}
                  onChange={inputChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>{t("Password")} (*)</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  value={body.password}
                  onChange={inputChange}
                  required
                  isInvalid={checkPasswordInvalidityLength()}
                />
                <Form.Control.Feedback id="password-error" type="invalid">
                  {t("FieldRequired")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>{t("ConfirmPassword")} (*)</Form.Label>
                <Form.Control
                  name="password_conf"
                  type="password"
                  value={body.password_conf}
                  onChange={inputChange}
                  required
                  isInvalid={checkPasswordInvalidity()}
                />
                <Form.Control.Feedback id="password_conf-error" type="invalid">
                  {t("FieldRequired")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group className="form-group">
              <Form.Label className="relative">
                {t("WhereDidYouFindUs")} (*)
              </Form.Label>
              <Form.Select
                name="origin"
                required
                onChange={inputChange}
                defaultValue=""
              >
                <option value="" disabled>
                  {t("None")}
                </option>
                <option value="Google">Google</option>
                <option value="Youtube">Youtube</option>
                <option value="Friend">{t("FromAFriend")}</option>
                <option value="GridFinder">Grid Finder</option>
                <option value="Twitter">Twitter</option>
                <option value="Instagram">Instagram</option>
                <option value="Other">{t("Other")}</option>
              </Form.Select>
              <Form.Control.Feedback id="origin-error" type="invalid">
                {t("FieldRequired")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="relative">
                {t("DriverPhoto")}
                <span
                  className="profile-bubble"
                  data-tooltip={t("DriverPhotoHelp")}
                  data-flow="right"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="label-icon"
                  />
                </span>
              </Form.Label>
              <div className="file-container">
                <label htmlFor="filePicker">{t("ChoosePhoto")}</label>
                <label>{text}</label>
                <Form.Control
                  name="photo"
                  type="file"
                  accept="image/*"
                  onChange={handleChange}
                  id="filePicker"
                  style={{ display: "none" }}
                />
                <CropperUser file={file} setFile2={setFile2} />
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button variant="primary" type="submit">
              {t("SignUp")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Cover;
