import { Trans, useTranslation } from "react-i18next";
import CircuitDataWeather from "./Championship/CircuitDataWeather";

const LapsInfo = ({ circuitData }: { circuitData: CircuitDataWeather }) => {
  const { t } = useTranslation();

  return (
    <>
      {circuitData.race_data.map((race, index) => {
        let race_text = "";
        if (circuitData.race_data.length === 2 && race.type_race === 1)
          race_text = t("MainRace") + ": ";
        else if (circuitData.race_data.length === 2 && race.type_race === 2)
          race_text = t("SprintRace") + ": ";
        return (
          <small className="race-laps" key={index}>
            {race.valid_laps != null ? (
              <Trans
                i18nKey="HillClimbLaps"
                values={{
                  laps: race.laps,
                  valid_laps: race.valid_laps,
                }}
                components={{
                  bold: <strong />,
                }}
              />
            ) : race.laps != null ? (
              `${race_text} ${race.laps} ${t("Laps").toUpperCase()}`
            ) : (
              `${race_text} ${race.time} ${t("THours").toUpperCase()}`
            )}
          </small>
        );
      })}
    </>
  );
};

export default LapsInfo;
