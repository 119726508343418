import { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../components/UserContext";
import axios from "axios";
import { toast } from "react-toastify";
import React from "react";
import { i18n } from "../../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { teamLogo } from "../../../utils/Standings";

const REntriesSlot = ({
  raceData,
  slots,
  setUpdate,
  setUpdateNotifications,
  update,
}: {
  raceData: RaceEntries;
  slots: [];
  setUpdate: Dispatch<SetStateAction<boolean>>;
  setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
  update: boolean;
}) => {
  const { t } = useTranslation();
  const { user } = useUser() as any;
  const [sortedDays, setSortedDays] = useState<any>(null);
  const [sessionsByDay, setSessionsByDay] = useState<any>(null);
  const [absents, setAbsents] = useState<DriverEntry[]>([]);
  const [unconfirmed, setUnconfirmed] = useState<DriverEntry[]>([]);

  if (i18n.language != "en") require("moment/locale/" + i18n.language);

  useEffect(() => {
    // Obtener la zona horaria del usuario (por ejemplo, "Europe/Madrid")
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // La zona horaria de Reino Unido (BST en verano, GMT en invierno)
    const ukTimeZone = "Europe/London";

    // Agrupar las sesiones por día, teniendo en cuenta la zona horaria del usuario
    const sessionsByDay: { [key: string]: any[] } = {};

    slots.forEach((session: any) => {
      // Parsear la fecha y hora del backend como si estuvieran en la zona horaria de Reino Unido
      const ukDateTime = moment.tz(
        `${session.day}T${session.time}`,
        ukTimeZone
      );

      // Convertir la hora de Reino Unido a la zona horaria del usuario
      const userDateTime = ukDateTime.clone().tz(userTimeZone);

      // Formatear el día según la zona horaria del usuario
      const userDay = userDateTime.format("YYYY-MM-DD");

      if (!sessionsByDay[userDay]) {
        sessionsByDay[userDay] = [];
      }

      // Añadir la sesión convertida
      sessionsByDay[userDay].push({
        ...session,
        userTime: userDateTime.format("HH:mm"),
      });
    });

    // Obtener las fechas ordenadas
    setSortedDays(Object.keys(sessionsByDay).sort());
    setSessionsByDay(sessionsByDay);

    let absents: DriverEntry[] = [];
    let unconfirmed: DriverEntry[] = [];
    raceData.drivers.forEach((item) => {
      item.drivers.forEach((driver) => {
        if (driver.state === 3) absents.push(driver);
        if (driver.state === 0) unconfirmed.push(driver);
      });
    });
    setAbsents(absents);
    setUnconfirmed(unconfirmed);
  }, [slots]);

  const sendState = () => {
    let token = window.localStorage.getItem("userLogged");
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/setDriverState`,
        {
          user,
          token: JSON.parse(token).token,
          state: 3,
          session: null,
          typeChamp: 3,
        }
      )
      .then(() => {
        toast.success(`${t("RaceEntryAbsent")}`);
        setUpdate(!update);
        setUpdateNotifications((prev) => {
          return !prev;
        });
      })
      .catch(({ response }) => {
        toast.error(`${t("Error!")}`);
        throw new Error(response);
      });
  };

  const shortUsername = (username: string) => {
    // Verifica si el nombre contiene un espacio
    const names = username.split(" ");

    // Si contiene un espacio, toma la primera letra del primer nombre y el apellido
    if (names.length > 1) {
      return `${names[0].charAt(0)} ${names[1]}`;
    }
    return username;
  };

  if (sortedDays == null || sessionsByDay == null) return <></>;

  const maxSessionsPerDay = Math.max(
    ...sortedDays.map((day: any) => sessionsByDay[day].length)
  );

  const registerSlot = (slot: any) => {
    let token = window.localStorage.getItem("userLogged");
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/setDriverSlot`,
        {
          user,
          token: JSON.parse(token).token,
          slot,
        }
      )
      .then(({ data }) => {
        toast.success(`${t("RaceEntrySigned")}`);
        setUpdateNotifications((prev) => {
          return !prev;
        });
        setUpdate(!update);
      })
      .catch(({ response }) => {
        toast.error(`${t("Error!")}`);
        throw new Error(response);
      });
  };
  moment.locale(i18n.language);
  return (
    <div id="race-entries-slot" className="flex flex-center">
      <div>
        <p className="text-center timezone">{` ${t("Time Zone")}: ${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`}</p>
        <table>
          <thead>
            <tr>
              {sortedDays.map((day: any) => (
                <th key={day}>{moment(day).format("dddd DD MMM")}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Iteramos sobre la cantidad máxima de sesiones en un día */}
            {Array.from({ length: maxSessionsPerDay }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                {sortedDays.map((day: any) => {
                  // Obtener la sesión para la celda actual
                  const session = sessionsByDay[day][rowIndex];
                  let isPast = false;
                  if (session != null) {
                    const now = moment().tz("Europe/London"); // Hora actual en UK para la comparación
                    const iter = moment.tz(
                      `${session.day}T${session.time}`,
                      "Europe/London"
                    );
                    isPast = now.isAfter(iter);
                  }
                  const classOpacity = isPast ? "med-opacity" : "";
                  return (
                    <td
                      key={day}
                      className={
                        sessionsByDay[day][rowIndex] != null &&
                        sessionsByDay[day][rowIndex].driver != null &&
                        sessionsByDay[day][rowIndex].driver.username == user
                          ? classOpacity + " active"
                          : classOpacity
                      }
                      style={{
                        background:
                          session != null &&
                          session.titular != null &&
                          session.titular.main_color,
                        color:
                          session != null &&
                          session.titular != null &&
                          session.titular.secondary_color,
                      }}
                    >
                      {sessionsByDay[day][rowIndex] ? (
                        <>
                          <label>
                            {sessionsByDay[day][rowIndex].userTime} →
                          </label>
                          {sessionsByDay[day][rowIndex].driver == null ? (
                            !isPast && (
                              <button
                                className="btn btn-success btn-register-slot"
                                onClick={() =>
                                  registerSlot(sessionsByDay[day][rowIndex])
                                }
                              >
                                {t("Register")}
                              </button>
                            )
                          ) : (
                            <>
                              {session.titular != null &&
                                teamLogo(
                                  session.titular.team_id,
                                  session.titular.logo,
                                  session.titular.team_name
                                )}
                              <label className="driver-slot">
                                {shortUsername(
                                  sessionsByDay[day][
                                    rowIndex
                                  ].driver.username.toUpperCase()
                                )}
                              </label>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>

        <div id="slot-absents">
          <p>{t("Absent Drivers")}</p>
          <div className="text-center">
            <button className="btn btn-danger" onClick={sendState}>
              {t("Absence")}
            </button>
          </div>
          <div className="text-center absents">
            {absents.length === 0 ? (
              <label className="none">{t("None")}</label>
            ) : (
              absents.map((driver, index) => {
                return (
                  <label
                    style={{
                      background: driver.main_color,
                      color: driver.secondary_color,
                    }}
                  >
                    {teamLogo(
                      driver.team_id,
                      driver.team_logo,
                      driver.team_name
                    )}
                    <label className="driver-slot">
                      {shortUsername(driver.username.toUpperCase())}
                    </label>
                  </label>
                );
              })
            )}
          </div>
        </div>
        <div id="slot-unconf">
          <p>{t("Unconfirmed Drivers")}</p>
          <div className="text-center unconfirmed">
            {unconfirmed.length === 0 ? (
              <label className="none">{t("None")}</label>
            ) : (
              unconfirmed.map((driver, index) => {
                return (
                  <label
                    style={{
                      background: driver.main_color,
                      color: driver.secondary_color,
                    }}
                  >
                    {teamLogo(
                      driver.team_id,
                      driver.team_logo,
                      driver.team_name
                    )}
                    <label className="driver-slot">
                      {shortUsername(driver.username.toUpperCase())}
                    </label>
                  </label>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default REntriesSlot;
