import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Scoring = ({ scorings }: { scorings: Scorings[] }) => {
  const { t } = useTranslation();
  const [maxLength, setMaxLength] = useState<Scorings>(scorings[0]);

  useEffect(() => {
    setMaxLength(
      scorings.reduce((prev_value, cur_value) => {
        const bonus = cur_value.bonus !== null ? 1 : 0;
        if (cur_value.points.length + bonus > prev_value.points.length)
          return cur_value;
        return prev_value;
      }, scorings[0])
    );
  }, [scorings]);

  const emptyCols = (total: number) => {
    const max_length =
      maxLength.bonus !== null
        ? maxLength.points.length + 1
        : maxLength.points.length;
    let rows: JSX.Element[] = [];
    for (let i = total; i < max_length; i++) {
      rows.push(
        <td key={i} className="text-center standings-OUT_OF_POINTS">
          0
        </td>
      );
    }
    return <>{rows}</>;
  };

  const getColor = (scoring: Scorings, index: number) => {
    const final_index = scoring.bonus !== null ? index + 2 : index + 1;
    if (final_index === 1) return "standings-WINNER";
    else if (final_index === 2) return "standings-SECOND";
    else if (final_index === 3) return "standings-THIRD";
    else return "standings-POINTS";
  };

  return (
    <div className="frame-turqoise">
      <h3 className="text-center frame-title">
        {t("PointsSystem").toUpperCase()}
      </h3>
      <div className="flex-container">
        <div className="flex">
          <div className="data-standings">
            <table className="scoring-table">
              <thead>
                <tr>
                  <th>{t("Points")}</th>
                  <>
                    {maxLength.bonus !== null && <th>BONUS</th>}
                    {maxLength.points.map((points, index) => {
                      return <th key={index}>{index + 1}</th>;
                    })}
                  </>
                </tr>
              </thead>
              <tbody>
                {scorings.map((scoring, index) => {
                  if (scoring.id != 11)
                    return (
                      <tr key={index}>
                        <td>{scoring.description}</td>
                        {scoring.bonus !== null && (
                          <td className="text-center standings-BONUS">
                            {scoring.bonus}
                          </td>
                        )}
                        {scoring.points.map((points, index) => {
                          return (
                            <td
                              key={index}
                              className={`text-center ${getColor(
                                scoring,
                                scoring.bonus !== null ? index - 1 : index
                              )}`}
                            >
                              {points}
                            </td>
                          );
                        })}
                        {emptyCols(
                          scoring.points.length +
                            (scoring.bonus !== null ? 1 : 0)
                        )}
                      </tr>
                    );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scoring;
