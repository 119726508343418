const sortAssignments = (data: any) => {
  data.sort((a: any, b: any) => {
    if (a.drivers[0].catalog < b.drivers[0].catalog) return -1;
    if (a.drivers[0].catalog > b.drivers[0].catalog) return 1;

    // Ahora se compara por 'number'
    const numberA = parseInt(a.drivers[0].number, 10);
    const numberB = parseInt(b.drivers[0].number, 10);

    const isNumberA = !isNaN(numberA);
    const isNumberB = !isNaN(numberB);

    if (isNumberA && isNumberB) return numberA - numberB;
    else if (isNumberA) return -1;
    else if (isNumberB) return 1;
    else return 0;
  });
};

export { sortAssignments };
