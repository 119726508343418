import { useEffect, useState } from "react";
import IndexFooter from "./Championship/IndexFooter";
import News from "./Championship/News";
import NextGPChampionship from "./Championship/NextGPChampionship";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingContent from "./LoadingContent";

const ChampionshipIndex = () => {
  let { champ } = useParams();
  const [id, setId] = useState(0);
  const [hasTeams, setHasTeams] = useState(true);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getTypeChamp`,
        {
          params: {
            champ,
          },
        }
      )
      .then(({ data }) => {
        setId(data.type);
        setHasTeams(data.has_teams);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [champ]);

  const [fullHeight, setFullHeight] = useState(false);

  if (id === 0 || champ == null) return <LoadingContent />;
  return (
    <div className="full-height app-container page-transition champ-index-container">
      <NextGPChampionship id={id} text={champ} setFullHeight={setFullHeight} />
      <News id={id} text={champ} fullHeight={fullHeight} />
      {id < 4 && <IndexFooter id={id} text={champ} hasTeams={hasTeams} />}
    </div>
  );
};
export default ChampionshipIndex;
