import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DateFormat from "../../../components/DateFormat";
import {
  getStandingsPositionColor,
  getStandingsPositionStyle,
} from "../../../utils/Standings";

const EventResults = ({ events }: { events: EventResults[] }) => {
  const { t } = useTranslation();
  const [theme, setTheme] = useState("light");
  useEffect(() => {
    let theme_storaged = window.localStorage.getItem("theme");
    if (theme_storaged !== null) {
      setTheme(theme_storaged);
    }
  }, []);

  const getBestPosition = (positions: string) => {
    let bestPos = Infinity;
    positions.split("|").forEach((pos) => {
      if (!Number.isNaN(Number(pos)) && bestPos > parseInt(pos))
        bestPos = parseInt(pos);
    });
    if (bestPos == Infinity) return positions.split("|")[0];
    return bestPos;
  };

  return (
    <div className="events-container flex">
      {events.map((event, index) => {
        const positions = event.position.split("|");
        let gradientColors;
        if (positions.length === 3) {
          const first = getStandingsPositionColor(positions[0], theme);
          const second = getStandingsPositionColor(positions[1], theme);
          const third = getStandingsPositionColor(positions[2], theme);
          gradientColors = {
            background: `linear-gradient(to right, ${first} 0%, ${first} 48%, ${second} 55%, ${second} 65%, ${third} 83%, ${third} 100%)`,
          };
        } else if (positions.length === 2) {
          const first = getStandingsPositionColor(positions[0], theme);
          const second = getStandingsPositionColor(positions[1], theme);
          gradientColors = {
            background: `linear-gradient(to right, ${first} 0%, ${first} 48%, ${second} 83%, ${second} 100%)`,
          };
        }
        return (
          <div
            key={index}
            className={
              positions.length === 1
                ? `${getStandingsPositionStyle(
                    getBestPosition(event.position)
                  )}`
                : ""
            }
            style={positions.length > 1 ? gradientColors : {}}
          >
            <Link
              to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                event.name.replace(/ /g, "")
              )}`}
              className="driver"
              title={event.name}
            >
              {event.name}
            </Link>
            <DateFormat date={event.date.toString()} format={"normal"} />
            <div className="flex">
              <img
                src={`/assets/Games/${event.game}.png`}
                alt={event.game}
                title={event.game}
                className="game-icon"
              />
              <div className="full-width flex-center">
                <span className={`event-pos`}>
                  {positions.map((pos) => {
                    return (
                      <label>
                        {pos}
                        {!Number.isNaN(Number(pos)) ? "º" : ""}
                      </label>
                    );
                  })}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default EventResults;
