import "../../assets/style/home.css";
import "react-calendar/dist/Calendar.css";
import Cover from "./parts/SloganSignUp";
import CalendarContent from "./parts/CalendarContent";
import WebData from "./parts/WebData";
import WebOverview from "./parts/WebOverview";
import NextGP from "./parts/nextgp/NextGP";
import LastGP from "./parts/LastGP";
import TopStandings from "./parts/TopStandings";
import React from "react";
import { useUser } from "../../components/UserContext";
import { useTranslation } from "react-i18next";
import Index from "./parts/Index";

const Home = () => {
  const { t } = useTranslation();
  const { user } = useUser() as any;
  document.title = "Liga Virtual F1";

  return (
    <div className="home-container">
      {user === null ? (
        <Cover />
      ) : (
        <>
          {/*<div id="lastgp-standings" className="flex">
            <LastGP />
            {<TopStandings />}
          </div></div>*/}
        </>
      )}

      {/*<NextGP />*/}
      <Index />

      {user === null && (
        <>
          <WebData />
          <div id="secondary-image" className="flex-center">
            <span>{t("AreYouUp")}</span>
          </div>
          <WebOverview />
        </>
      )}
    </div>
  );
};

export default Home;
