import React from "react";
import { useTranslation } from "react-i18next";

const Legend = ({
  champ_data,
  gps_with_qualy_points,
  one_point_qualy,
}: {
  champ_data: ChampData;
  gps_with_qualy_points: number;
  one_point_qualy: number;
}) => {
  const { t } = useTranslation();

  const getTextPoints = (value: number) => {
    if (value === 0) return <></>;
    const translation = value === 1 ? t("Point") : t("Points");
    return (
      <b>
        ({value} {translation.toLowerCase()})
      </b>
    );
  };
  return (
    <table className="legend">
      <thead>
        <tr>
          <td colSpan={2}>{t("Legend")}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="standings-WINNER">{t("Gold")}</td>
          <td>{t("GoldTEXT")}</td>
        </tr>
        <tr>
          <td className="standings-SECOND">{t("Silver")}</td>
          <td>{t("SilverTEXT")}</td>
        </tr>
        <tr>
          <td className="standings-THIRD">{t("Bronze")}</td>
          <td>{t("BronzeTEXT")}</td>
        </tr>
        <tr>
          <td className="standings-POINTS">{t("Green")}</td>
          <td>{t("GreenTEXT")}</td>
        </tr>
        <tr>
          <td className="standings-OUT_OF_POINTS">{t("Blue")}</td>
          <td>
            <label>{t("BlueTEXT")}</label>
            <label>{t("BlueTEXT2")}</label>
          </td>
        </tr>
        <tr>
          <td className="standings-Ret">{t("Purple")}</td>
          <td>{t("PurpleTEXT")}</td>
        </tr>
        <tr>
          <td className="standings-DNQ">{t("Red")}</td>
          <td>{t("RedTEXT")}</td>
        </tr>
        <tr>
          <td className="standings-DSQ">{t("Black")}</td>
          <td>{t("BlackTEXT")}</td>
        </tr>
        <tr>
          <td className="standings-DNP">{t("White")}</td>
          <td>
            <label>{t("WhiteTEXT")}</label>
            <label>{t("WhiteTEXT2")}</label>
            <label>{t("WhiteTEXT3")}</label>
            <label>{t("WhiteTEXT4")}</label>
            <label>{t("WhiteTEXT5")}</label>
          </td>
        </tr>
        <tr>
          <td className="bold">{t("Bold")}</td>
          <td>
            {t("Pole")} {one_point_qualy > 0 && getTextPoints(1)}
          </td>
        </tr>
        <tr>
          <td className="italic">{t("Italic")}</td>
          <td>
            {t("HotLap")} {getTextPoints(champ_data.hot_lap_points)}
          </td>
        </tr>
        <tr>
          <td>†</td>
          <td>
            {t("Dagger")} ({champ_data.finish_percentage}%)
          </td>
        </tr>
        {gps_with_qualy_points > 0 && (
          <tr>
            <td>
              <sup>Y</sup>X
            </td>
            <td>{t("QualyResultTEXT")}</td>
          </tr>
        )}
        {champ_data.leaders_points > 0 && (
          <tr>
            <td>
              X<sup>L</sup>
            </td>
            <td>
              {t("LeaderTEXT")} {getTextPoints(champ_data.leaders_points)}
            </td>
          </tr>
        )}
        {champ_data.most_leader_points > 0 && (
          <tr>
            <td>
              X<sup>L*</sup>
            </td>
            <td>
              {t("GreaterLeader")}{" "}
              {getTextPoints(champ_data.most_leader_points)}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Legend;
