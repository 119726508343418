import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";
import { CountriesOptions } from "../../../components/SelectRegions";

interface TeamChampionship {
  name: string;
  championship_id: number;
  team_championship_id: number;
}
interface User {
  username: string;
  driver_id: number;
  team_championship_id: number;
  number: number;
  team_number: number;
}

const UpdateTeam = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Update Team";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refPicture = useRef<any>(null);
  const refPictureLivery = useRef<any>(null);
  const [picture, setPicture] = useState();
  const [pictureLivery, setPictureLivery] = useState();
  const [dataForm, setDataForm] = useState({
    championship: "",
    team: "",
    team_name: "",
    logo: "",
    flag: "",
    main_color: "",
    sec_color: "",
  });
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [teams, setTeams] = useState<TeamChampionship[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [data, setData] = useState<{
    champs: { name: string; id: number }[];
    assignments: TeamChampionship[];
    users: User[];
  }>();

  const setTeamsFromChampionship = (value: string) => {
    //setAssignment({ ...assignment, team: "", driver: "" });
    const teams_filtered = data?.assignments.filter(
      (assignment) => parseInt(value) === assignment.championship_id
    );
    if (teams_filtered != null) setTeams(teams_filtered);
  };

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);
    formDataFiles.append("championship", dataForm.championship);
    formDataFiles.append("team", dataForm.team);
    formDataFiles.append("team_name", dataForm.team_name);
    formDataFiles.append("main_color", dataForm.main_color);
    formDataFiles.append("sec_color", dataForm.sec_color);
    formDataFiles.append("flag", dataForm.flag);
    formDataFiles.append("logo", dataForm.logo);
    if (picture != null) formDataFiles.append("logo_picture", picture);
    if (pictureLivery != null) formDataFiles.append("livery", pictureLivery);

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/createTeamLogo`,
          formDataFiles
        )
        .then(() => {
          toast.success("Team Logo added successfully");
          setUploading(false);
          setValidated(false);
          setDataForm({
            ...dataForm,
            team: "",
            logo: "",
            team_name: "",
            main_color: "",
            sec_color: "",
          });
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/team/teamLogo`
      )
      .then(({ data }) => {
        setData(data);
        setTeams(
          data?.assignments.filter(
            (assignment: { championship_id: number }) =>
              parseInt(data.champs[0].id) === assignment.championship_id
          )
        );
        setDataForm({
          ...dataForm,
          championship: data.champs[0].id,
        });
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });
  }, []);

  const setDataFormValues = (team: any) => {
    setDataForm({
      ...dataForm,
      team: team.team_championship_id,
      team_name: team.name,
      flag: team.flag,
      main_color: team.main_color,
      sec_color: team.secondary_color,
    });
  };

  if (!access) return <ErrorPage />;
  if (data == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Update Team" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Championship (*)</Form.Label>
              <Form.Select
                name="championship"
                required
                value={dataForm.championship}
                onChange={(e: any) => {
                  inputChange(e);
                  setTeamsFromChampionship(e.target.value);
                }}
              >
                {data?.champs.map((champ) => {
                  return (
                    <option value={champ.id} key={champ.id}>
                      {champ.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Team (*)</Form.Label>
              <Form.Select
                name="team"
                required
                value={dataForm.team}
                onChange={(e) => {
                  inputChange(e);
                  const selectedTeam = teams.find(
                    (team) =>
                      team.team_championship_id === parseInt(e.target.value)
                  );
                  if (selectedTeam) setDataFormValues(selectedTeam);
                }}
              >
                <option value="" disabled>
                  None
                </option>
                {teams.map((team) => {
                  return (
                    <option
                      value={team.team_championship_id}
                      key={team.team_championship_id}
                    >
                      {team.name}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Name (*)</Form.Label>
              <Form.Control
                name="team_name"
                type="text"
                required
                onChange={(e) => inputChange(e)}
                value={dataForm.team_name}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>{" "}
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Team Flag (*)</Form.Label>
              <Form.Select
                name="flag"
                value={dataForm.flag}
                required
                onChange={(e) => {
                  inputChange(e);
                }}
              >
                <option disabled value={""}>
                  None
                </option>
                <CountriesOptions />
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group text-center" as={Col} md="2">
              <Form.Label>Main Color(*)</Form.Label>
              <div className="flex-center">
                <Form.Control
                  name="main_color"
                  type="color"
                  required
                  value={dataForm.main_color}
                  onChange={(e: any) => {
                    inputChange(e);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group text-center" as={Col} md="2">
              <Form.Label>Sec. Color (*)</Form.Label>
              <div className="flex-center">
                <Form.Control
                  name="sec_color"
                  type="color"
                  required
                  value={dataForm.sec_color}
                  onChange={(e: any) => {
                    inputChange(e);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="8">
              <Form.Label>Livery</Form.Label>
              <Form.Control
                name="livery"
                type="file"
                accept="image/*"
                ref={refPictureLivery}
                onChange={() =>
                  setPictureLivery(refPictureLivery.current.files[0])
                }
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="5">
              <Form.Label>
                Logo
                <span
                  className="profile-bubble"
                  data-tooltip="Empty: main logo. Other values are usually colors: 'blue', 'red'..."
                  data-flow="right"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="label-icon"
                  />
                </span>
              </Form.Label>
              <Form.Control
                name="logo"
                type="text"
                onChange={(e) => inputChange(e)}
                value={dataForm.logo}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="7">
              <Form.Label>Logo Picture</Form.Label>
              <Form.Control
                name="driver_picture"
                type="file"
                accept="image/*"
                ref={refPicture}
                onChange={() => setPicture(refPicture.current.files[0])}
              />
            </Form.Group>
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update Team
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateTeam;
