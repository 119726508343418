import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import "react-image-gallery/styles/css/image-gallery.css";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { i18n } from "../../../App";

const AttendancePerRace = ({ data }: { data: Data }) => {
  const { t } = useTranslation();

  useEffect(() => {
    var root = am5.Root.new("chartdiv");

    //Colours deppending on the drak/light mode (labels and grid)
    const defaultColour =
      window.localStorage.getItem("theme") === "dark" ? "#FFFFFF" : "#000000";
    const myTheme = am5.Theme.new(root);
    myTheme.rule("Label").setAll({
      fill: am5.color(defaultColour),
    });
    myTheme.rule("Grid").setAll({
      stroke: am5.color(defaultColour),
    });

    root.setThemes([
      am5themes_Animated.new(root),
      myTheme,
      am5themes_Material.new(root),
    ]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
      })
    );

    // Define data
    let chart_data = data.drivers;

    // Create Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 1,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );
    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 100,
        }),
        maxPrecision: 0,
      })
    );

    // Create series
    function createSeries(name: string, field: string) {
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: t(name),
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "num",
          calculateAggregates: true,
          tooltip: am5.Tooltip.new(root, {}),
          legendLabelText: "[bold {stroke}]{name}:[/]",
          legendRangeLabelText: "[{stroke}]{name}:[/]",
          legendValueText: "[bold {stroke}]{valueY}[/]",
          legendRangeValueText:
            "[{stroke}]{valueYAverage.formatNumber('#.#')}[/]",
        })
      );
      if (name !== "Events") {
        series.fills.template.setAll({
          fillOpacity: 0.7,
          visible: true,
        });
      }
      series
        .get("tooltip")
        ?.label.set(
          "text",
          `[bold]{name}[/]\n${t("Race")} {valueX}: {valueY} ${t(
            "Drivers"
          ).toLocaleLowerCase()}`
        );
      series.data.setAll(chart_data);

      series.bullets.push(function (root) {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 2,
            fill: series.get("fill"),
          }),
        });
      });
    }

    data.series.forEach((serie) => {
      createSeries(serie.name, serie.id);
    });

    // Add cursor
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
        xAxis: xAxis,
      })
    );

    var legend = chart.children.push(am5.Legend.new(root, {}));

    legend.data.setAll(chart.series.values);

    let scrollbarX = am5xy.XYChartScrollbar.new(root, {
      orientation: "horizontal",
      height: 50,
    });

    chart.set("scrollbarX", scrollbarX);

    return () => root.dispose();
  }, [i18n.language, window.localStorage.getItem("theme")]);

  return (
    <div id="attendance-container" className="frame-turqoise">
      <h3 className="text-center frame-title">
        {t("AttendancePerRace").toUpperCase()}
      </h3>
      <div id="attendance-chart-container">
        <div id="chartdiv" style={{ width: "100%", height: 600 }}></div>
      </div>
    </div>
  );
};

export default AttendancePerRace;
