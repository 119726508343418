import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Form, Col, Row } from "react-bootstrap";

const Classes = ({
  classes,
  setClasses,
}: {
  classes: Classes[];
  setClasses: (classes: any) => void;
}) => {
  const [nclass, setNClass] = useState(0);

  const classInputChange = (
    name: string,
    value: string | number,
    index: number
  ) => {
    setClasses((prev_classes: Classes[]) => {
      let aux_classes: any = prev_classes.slice();
      aux_classes[index][name] = value;
      return aux_classes;
    });
  };

  const updateClassesRows = ({ target }: { target: any }) => {
    const { name, value } = target;
    setClasses((prev_classes: Classes[]) => {
      const diff = parseInt(value) - prev_classes.length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          prev_classes.push({
            name: "",
            catalog: i + 1,
            color: "#000000",
          });
        }
      } else if (diff < 0) {
        for (let i = diff; i < 0; i++) {
          prev_classes.pop();
        }
      }
      return prev_classes;
    });
  };

  return (
    <>
      <h4 className="text-center backgrounded-title">Classes</h4>
      <Form.Group className="form-group">
        <Form.Label>Number of Classes (*)</Form.Label>
        <Form.Control
          name="nclass"
          type="number"
          required
          value={nclass}
          onChange={(e: any) => {
            setNClass(e.target.value);
            updateClassesRows(e);
          }}
        />
      </Form.Group>
      {classes.map((class_: Classes, index: number) => {
        return (
          <Row key={index} className="mb-3 AQ">
            <Form.Group as={Col} md="2" className="form-titlerow">
              <Form.Label>Class {index + 1}</Form.Label>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Name (*)</Form.Label>
              <Form.Control
                name="name"
                type="string"
                value={class_.name}
                required
                onChange={(e: any) => {
                  classInputChange("name", e.target.value, index);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>
                Catalog (*)
                <span
                  className="profile-bubble"
                  data-tooltip="To sort classes. The fastest class has the lowest catalog number."
                  data-flow="right"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="label-icon"
                  />
                </span>
              </Form.Label>
              <Form.Control
                name="catalog"
                type="number"
                value={class_.catalog}
                required
                onChange={(e: any) => {
                  classInputChange("catalog", parseInt(e.target.value), index);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group text-center" as={Col} md="2">
              <Form.Label>Color (*)</Form.Label>
              <div className="flex-center">
                <Form.Control
                  name="color"
                  type="color"
                  required
                  value={class_.color}
                  onChange={(e: any) => {
                    classInputChange("color", e.target.value, index);
                  }}
                />
              </div>
            </Form.Group>
          </Row>
        );
      })}
    </>
  );
};

export default Classes;
