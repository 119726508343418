const timeOrLaps = (serverTiming: ServerTiming, tableData: CircleDriver[]) => {
  if (serverTiming == null || serverTiming.trackdata_name === "") return "";
  let title = serverTiming.trackdata_session;
  if (serverTiming.trackdata_session == "R1") {
    let laps = serverTiming.trackdata_maxLaps;
    let first_laps = tableData[0] == null ? 0 : tableData[0].live_laps;
    title = `${title}, LAP ${first_laps} / ${laps}`;
  } else {
    let timeleft =
      serverTiming.trackdata_endET - serverTiming.trackdata_currentET;
    title = `${title}, ${new Date(timeleft * 1000)
      .toISOString()
      .slice(11, 19)}`;
  }
  return `(${title})`;
};

const getSessionName = (session: string) => {
  if (session.startsWith("P")) return "PRACTICE " + session.split("P")[1];
  if (session.startsWith("Q")) return "QUALIFY " + session.split("Q")[1];
  if (session.startsWith("R")) return "RACE " + session.split("R")[1];
  return session;
};

const getShortDriverName = (driver: string) => {
  if (driver.includes(" ")) {
    let split = driver.split(" ");
    return `${split[0][0]} ${split[1]}`;
  }
  return driver;
};

const getSessionData = (data: any, server: number) => {
  const data_return: any = {};
  if (data == null) return data_return;
  data_return.server_timing = data.server_timing.find(
    (row_: any) => row_.trackdata_id == server
  );
  data_return.server_live = data.server_live.filter(
    (row_: any) => parseInt(row_.live_server) == server
  );
  if (!Array.isArray(data_return.server_live))
    data_return.server_live = [data_return.server_live];
  data_return.fastest_laps = data.fastest_laps.filter(
    (row_: any) => parseInt(row_.hotlap_server) == server
  );
  if (!Array.isArray(data_return.fastest_laps))
    data_return.fastest_laps = [data_return.fastest_laps];
  return data_return;
};

export { timeOrLaps, getSessionName, getShortDriverName, getSessionData };
