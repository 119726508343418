import React, { useEffect, useState } from "react";
import axios from "axios";
import getTextColor from "../../../utils/DynamicContrast";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { teamLogo } from "../../../utils/Standings";
import Flag from "../../../components/Flag";
import DriverTopStandings from "./standings/DriverTopStandings";
import TeamTopStandings from "./standings/TeamTopStandings";

const TopStandings = ({
  id,
  text,
  hasTeams,
}: {
  id: number;
  text: string;
  hasTeams: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [firstDriver, setFirstDriver] = useState(1);
  const [firstTeam, setFirstTeam] = useState(1);

  const [lastGP, setLastGP] = useState<any>({
    driver_standings: [],
    team_standings: [],
    has_teams: false,
  });
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main/currentstandings`,
        {
          params: {
            server: id == 1 ? 1 : 3,
          },
        }
      )
      .then(({ data }) => {
        setLastGP(data);
        setFirstDriver(1);
        setFirstTeam(1);
        $(`.scroll-up`).hide();
        $(`.scroll-down`).show();
        $(`.general-standings-container > div`).animate({ scrollTop: `0` }, 0);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [id]);

  const scrollStandings = (
    standings: number,
    direction: number,
    id2: number = 0
  ) => {
    const element =
      standings === 1
        ? $(`.team-standings-${id2}`)
        : $(`.driver-standings-${id2}`);
    direction === 1
      ? element.animate({ scrollTop: `+=${hasTeams ? "145" : "290"}` }, 500)
      : element.animate({ scrollTop: `-=${hasTeams ? "145" : "290"}` }, 500);

    element.parent().find(".scroll-down").show();
    element.parent().find(".scroll-up").show();
    if (standings === 1 && direction === 1) {
      // Teams scrolling down
      if (firstTeam + 10 > lastGP.team_standings.length)
        element.parent().find(".scroll-down").hide();
      setFirstTeam(firstTeam + 5);
    } else if (standings === 1 && direction === 0) {
      // Teams scrolling up
      if (firstTeam - 10 <= 0) element.parent().find(".scroll-up").hide();
      setFirstTeam(firstTeam - 5);
    } else if (standings === 0 && direction === 1) {
      // Drivers scrolling down
      if (firstDriver + 10 >= lastGP.driver_standings.length)
        element.parent().find(".scroll-down").hide();
      setFirstDriver(firstDriver + 5);
    } else if (standings === 0 && direction === 0) {
      // Drivers scrolling up
      if (firstDriver - 10 <= 0) element.parent().find(".scroll-up").hide();
      setFirstDriver(firstDriver - 5);
    }
  };

  const goStandings = () => {
    if (id == 1) navigate(t("routes:Standings"));
    else
      navigate(
        t("routes:Season") + "/" + encodeURIComponent(text.replace(/ /g, ""))
      );
  };

  return (
    <div id="next-gp-standings" className="column-around">
      <div>
        <p className="main-title low-bottom-margin">
          {t("DriverStandings").toUpperCase()}
        </p>

        <DriverTopStandings lastGP={lastGP} scrollStandings={scrollStandings} />
        {lastGP.has_teams && (
          <>
            <p className="main-title low-bottom-margin">
              {t("TeamStandings").toUpperCase()}
            </p>
            <TeamTopStandings
              lastGP={lastGP}
              scrollStandings={scrollStandings}
            />
          </>
        )}

        <Button
          text={t("ExpandStandings")}
          onClick={() => {
            goStandings();
          }}
        />
      </div>
    </div>
  );
};

export default TopStandings;
