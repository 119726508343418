import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DidYouKnowCard = ({
  gp,
  gp_name,
  title,
  text,
  has_profile,
  class_name,
  array_drivers,
  hasImage,
}: {
  gp?: number;
  gp_name?: string;
  title?: string;
  text: ReactNode;
  has_profile?: number;
  class_name?: string;
  array_drivers?: string[];
  hasImage?: boolean;
}) => {
  const { t } = useTranslation();
  if (gp == null)
    return (
      <div className="paper-container">
        <div className="paper">
          <div className="pin">
            <div className="shadow"></div>
            <div className="metal"></div>
            <div className="bottom-circle"></div>
          </div>
          <p>
            {text}{" "}
            {array_drivers?.map((driver, index) => {
              const comma = index === array_drivers.length - 1 ? "." : ", ";
              return (
                <React.Fragment key={index}>
                  <Link
                    className="driver"
                    to={`${t("routes:Driver")}/${encodeURIComponent(driver)}`}
                  >
                    {driver}
                  </Link>
                  {comma}
                </React.Fragment>
              );
            })}
          </p>
        </div>
      </div>
    );
  return (
    <div className="card">
      <figure className="card__thumb">
        <img
          src={
            hasImage != null && hasImage
              ? `/assets/ImagesGP/${gp}.jpg`
              : require(`../../assets/Images/NoImage.png`)
          }
          alt={title}
          className="card__image"
        />
        <figcaption className="card__caption">
          <h2 className="card__title">{title}</h2>
          <p className="card__snippet">
            {text}{" "}
            {array_drivers?.map((driver, index) => {
              const comma = index === array_drivers.length - 1 ? "." : ", ";
              const path =
                class_name === "" ? t("routes:Driver") : t("routes:Team");
              if (has_profile == 0)
                return (
                  <React.Fragment key={index}>
                    <b>{driver}</b>
                    {comma}
                  </React.Fragment>
                );
              return (
                <React.Fragment key={index}>
                  <Link
                    className="driver"
                    to={`${path}/${encodeURIComponent(driver)}`}
                  >
                    {driver}
                  </Link>
                  {comma}
                </React.Fragment>
              );
            })}
          </p>
          {gp_name != null && (
            <Link
              to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                gp_name.replace(/ /g, "")
              )}`}
              className="card__button"
            >
              {t("Results")}
            </Link>
          )}
        </figcaption>
      </figure>
    </div>
  );
};

export default DidYouKnowCard;
