import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../assets/style/standings.css";
import Flag from "../Flag";

const THRaces = ({ results }: { results: Result[] }) => {
  const { t } = useTranslation();
  return (
    <>
      {results.map((result, index) => {
        return (
          <th colSpan={result.Races.length} key={index}>
            <div className="column-around gp-link">
              <Link
                to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                  result.name.replace(/ /g, "")
                )}`}
                className="driver"
                title={result.name}
              >
                {result.initials}
              </Link>
              <Flag flag_code={result.code} name={result.flag_name} />
            </div>
          </th>
        );
      })}
    </>
  );
};

export default THRaces;
