import React, { useEffect, useState } from "react";
//import DataTable from "react-data-table-component-footer";
import { useTranslation } from "react-i18next";
import {
  getColor,
  teamLogo,
  teamBackground,
  getStandingsPositionStyle,
} from "../../../utils/Standings";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const SummaryTable = ({ summary }: { summary: DriverSummary[] }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<DriverSummaryRow[]>([]);
  const [footer, setFooter] = useState({
    season: <label>TOTAL</label>,
    teams: "-",
    races: 0,
    wins: 0,
    poles: 0,
    podiums: 0,
    fastest_laps: 0,
    points: 0,
    position: "-",
  });

  useEffect(() => {
    const rows_: DriverSummaryRow[] = [];
    let total_values = {
      races: 0,
      wins: 0,
      poles: 0,
      podiums: 0,
      fastest_laps: 0,
      points: 0,
    };
    summary.forEach((summary: DriverSummary, index_parent: number) => {
      total_values.races += summary.gps;
      total_values.wins += summary.wins;
      total_values.poles += summary.poles;
      total_values.podiums += summary.podiums;
      total_values.fastest_laps += summary.fastest_laps;
      total_values.points += summary.points;

      let teams_JSX;
      if (summary.hasOwnProperty("teams_array")) {
        teams_JSX = summary.teams_array?.map((team, index) => {
          return (
            <div
              key={`${index}-${index_parent}`}
              className="teamdriver-results-container text-left"
              style={{
                background: teamBackground(team.id, team.main_color),
                color: getColor(
                  team.id == null ? null : team.id.toString(),
                  team.main_color
                ),
              }}
            >
              <div className="logo-results-container flex-center">
                {teamLogo(team.id, team.logo, team.name)}
              </div>
              {team.id == null ? (
                <label>{team.name}</label>
              ) : (
                <Link
                  className="full-white-driver"
                  to={`${t("routes:Team")}/${encodeURIComponent(team.name)}`}
                  style={{
                    color: getColor(
                      team.id == null ? null : team.id.toString(),
                      team.main_color
                    ),
                  }}
                >
                  {team.name}
                </Link>
              )}
            </div>
          );
        });
      } else {
        teams_JSX = (
          <div
            key={`N-${index_parent}`}
            className={`${
              summary.name === "EVENTS" ? "events-team" : ""
            } teamdriver-results-container text-left`}
            style={{
              background:
                summary.teams != null || summary.name === "EVENTS"
                  ? ""
                  : "#000000",
            }}
          >
            {summary.teams != null && (
              <div className="logo-results-container flex-center">
                {teamLogo(null, null, summary.teams)}
              </div>
            )}
            <div className="logo-results-container flex-center"></div>
            <label
              className="full-white-driver"
              style={{
                color: summary.teams != null ? "" : "#ffffff",
              }}
            >
              {summary.name === "EVENTS"
                ? t("Events")
                : summary.teams == null
                ? summary.name
                : summary.teams}
            </label>
          </div>
        );
      }

      rows_.push({
        season:
          summary.name === "EVENTS" ? (
            <label>EVENTS</label>
          ) : (
            <Link
              to={`${t("routes:Season")}/${encodeURIComponent(
                summary.name.replace(/ /g, "")
              )}`}
              className="driver"
            >
              {summary.name}
            </Link>
          ),
        season_id: summary.championship_id,
        teams: teams_JSX,
        races: summary.gps,
        wins: summary.wins,
        poles: summary.poles,
        podiums: summary.podiums,
        fastest_laps: summary.fastest_laps,
        points: summary.points === null ? "-" : summary.points,
        position:
          summary.position === null ? (
            "-"
          ) : (
            <div
              className={`${
                typeof summary.position === "number" &&
                getStandingsPositionStyle(
                  summary.is_dsq ? "DSQ" : summary.position
                )
              }`}
            >
              {summary.position + "º"}
            </div>
          ),
        position_num: summary.position === null ? "-" : summary.position,
      });
    });
    setFooter({
      season: <label>TOTAL</label>,
      teams: "-",
      races: total_values.races,
      wins: total_values.wins,
      poles: total_values.poles,
      podiums: total_values.podiums,
      fastest_laps: total_values.fastest_laps,
      points: total_values.points,
      position: "-",
    });
    rows_.push({
      season: <label>TOTAL</label>,
      season_id: 1,
      teams: <span>-</span>,
      races: total_values.races,
      wins: total_values.wins,
      poles: total_values.poles,
      podiums: total_values.podiums,
      fastest_laps: total_values.fastest_laps,
      points: total_values.points,
      position: "-",
      position_num: "-",
    });
    setRows(rows_);
  }, []);

  const columnas = [
    {
      name: t("Championship"),
      selector: (row: { season: any }) => row.season,
      sortFunction: (a: any, b: any) => a.season_id - b.season_id,
      width: "130px",
    },
    {
      name: t("Teams"),
      selector: (row: { teams: any }) => row.teams,
      width: "180px",
    },
    {
      name: t("Races"),
      selector: (row: { races: number }) => row.races,
      sortable: true,
      width: "100px",
    },
    {
      name: t("Wins"),
      selector: (row: { wins: number }) => row.wins,
      sortable: true,
      width: "100px",
    },
    {
      name: t("Poles"),
      selector: (row: { poles: number }) => row.poles,
      sortable: true,
      width: "100px",
    },
    {
      name: t("Podiums"),
      selector: (row: { podiums: number }) => row.podiums,
      sortable: true,
      width: "100px",
    },
    {
      name: t("HotLaps"),
      selector: (row: { fastest_laps: number }) => row.fastest_laps,
      sortable: true,
      width: "100px",
    },
    {
      name: t("Points"),
      selector: (row: { points: number | string }) => row.points,
      sortable: true,
      width: "100px",
    },
    {
      name: t("Position"),
      selector: (row: { position: number | string }) => row.position,
      sortFunction: (
        a: { position_num: number | string },
        b: { position_num: number | string }
      ) => {
        if (
          typeof a.position_num === "number" &&
          typeof b.position_num === "number"
        )
          return a.position_num - b.position_num;
        else return 0;
      },
      width: "100px",
    },
  ];

  return (
    <div className="driver-summary">
      <div className="driver-summary-table-container">
        {
          <DataTable
            columns={columnas}
            data={rows}
            fixedHeader
            fixedHeaderScrollHeight="232px"
          />
        }
      </div>
    </div>
  );
};

export default SummaryTable;
