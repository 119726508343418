import { useState } from "react";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import CircuitForm from "../../../components/Admin/CircuitForm";

const CreateCircuit = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Create Circuit";
  const [dataForm, setDataForm] = useState({
    name: "",
    layout: "",
    turns: "",
    length: "",
    latitude: "",
    longitude: "",
    id: "",
    stage: false,
  });

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Create Circuit" />
        <small className="mandatory-fields">(*) Required fields</small>
        <CircuitForm dataForm={dataForm} setDataForm={setDataForm} />
      </div>
    </div>
  );
};

export default CreateCircuit;
