import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/style/raceentries.css";
import ErrorPage from "../ErrorPage";
import CircuitDataWeather from "../../components/Championship/CircuitDataWeather";
import LoadingContent from "../../components/LoadingContent";
import REntriesSecondary from "./parts/REntriesSecondary";
import REntriesTeams from "./parts/REntriesTeams";
import { useParams } from "react-router-dom";
import { sortAssignments } from "../../utils/General";
import REntriesSlots from "./parts/REntriesSlots";
import { useUser } from "../../components/UserContext";

const RaceEntries = ({
  setUpdateNotifications,
}: {
  setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { user } = useUser() as any;
  let { champ } = useParams();
  document.title = "LVF1 - " + t("RaceEntries");
  const [update, setUpdate] = useState(true);
  const [error, setError] = useState(false);
  const [typeChamp, setTypeChamp] = useState(0);
  const [typeRaces, setTypeRaces] = useState(0);
  const [dates, setDates] = useState([]);
  const [slots, setSlots] = useState(null);
  const [availableSlots, setAvailableSlots] = useState(false);
  const [datesO, setDatesO] = useState([]);
  const [raceData, setRaceData] = useState<RaceEntries>();

  useEffect(() => {
    let token = window.localStorage.getItem("userLogged");
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getEntryListData`,
        {
          params: {
            user,
            token: token == null ? "" : JSON.parse(token).token,
            champ,
          },
        }
      )
      .then(({ data }) => {
        const type_champ = data.nextgp.type_championship;
        setTypeChamp(type_champ);
        const type_races = data.nextgp.type_races;
        setTypeRaces(type_races);
        setSlots(data.slots);
        setAvailableSlots(data.availableSlots);
        setDates(
          type_champ === 1
            ? data.config.main_race
            : type_champ === 3
            ? data.config.secondary_race
            : data.config.event_race
        );
        setDatesO(
          type_champ === 1
            ? data.config_o.main_race
            : type_champ === 3
            ? data.config_o.secondary_race
            : data.config_o.event_race
        );
        if (data.drivers != null) sortAssignments(data.drivers);
        setRaceData(data);
      })
      .catch(({ response }) => {
        setError(true);
        throw new Error(response);
      });
  }, [update, champ]);

  if (error) return <ErrorPage />;
  if (raceData == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div
        id="race-entries-container"
        className={`no-second-padding frame-turqoise ${
          raceData.drivers_sec == null && "higher-racentry"
        }`}
      >
        <h3 className="text-center frame-title">
          {t("RaceEntries").toUpperCase()}
        </h3>
        <div
          className={`flex-center full-width align-top ${
            slots != null ? "slots" : ""
          }`}
        >
          <CircuitDataWeather
            typeRaces={typeRaces}
            circuitData={{
              flag_code: raceData.nextgp.flag_code,
              flag_name: raceData.nextgp.flag_name,
              gp_name: raceData.nextgp.gp_name,
              circuit_id: raceData.nextgp.circuit_id,
              gp_race_event_id: raceData.nextgp.gp_race_event_id,
              circuit_name: raceData.nextgp.circuit_name,
              lat: raceData.nextgp.lat,
              lon: raceData.nextgp.lon,
              race_data: raceData.race_laps,
              dates: dates,
            }}
            hasSlots={slots != null}
            typeChamp={typeChamp}
          />
          {slots == null ? (
            <div id="race-entries-right">
              {raceData.drivers_sec == null ? (
                <REntriesTeams
                  raceData={raceData}
                  setUpdateNotifications={setUpdateNotifications}
                  setUpdate={setUpdate}
                  update={update}
                  dates={dates}
                  datesO={datesO}
                  typeChamp={typeChamp}
                />
              ) : (
                <REntriesSecondary
                  raceData={raceData}
                  setUpdateNotifications={setUpdateNotifications}
                  setUpdate={setUpdate}
                  update={update}
                  dates={dates}
                  datesO={datesO}
                  typeChamp={typeChamp}
                />
              )}
            </div>
          ) : (
            <REntriesSlots
              raceData={raceData}
              slots={slots}
              setUpdate={setUpdate}
              setUpdateNotifications={setUpdateNotifications}
              update={update}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RaceEntries;
