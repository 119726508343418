import React from "react";
import { useParams } from "react-router-dom";
import Standings from "./championship/Standings";

const Season = () => {
  let { season } = useParams();
  return <Standings season={season} />;
};

export default Season;
