import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../UserContext";
import { Editor } from "@tinymce/tinymce-react";

interface GP {
  name: string;
  id: number;
  championship_id: number;
}

const FormNews = ({
  data,
  title,
}: {
  data: {
    title_en: string;
    title_es: string;
    caption_es: string;
    caption_en: string;
    championship_id: number;
    content_es: string;
    content_en: string;
    grand_prix_event_id: number | null;
    id: number;
  };
  title: string;
}) => {
  const apiKey = "a44aqey838dxped7t9p9xvow876atxc7r93lyzo9ovl84c2p";
  const plugins = [
    "image",
    "code",
    "table",
    "link",
    "media",
    "codesample",
    "paste",
  ];
  const toolbar = [
    {
      name: "Menu",
      items: [
        "undo",
        "redo",
        "formatselect",
        "bold",
        "italic",
        "backcolor",
        "image",
        "alignleft",
        "aligncenter",
        "alignright",
        "alignjustify",
        "bullist",
        "numlist",
        "outdent",
        "indent",
        "removeformat",
        "paste",
        "pastetext",
        "link",
        "media",
      ],
    },
  ];
  const editorRef1 = useRef<Editor>(null);
  const [initEditor1, setInitEditor1] = useState(false);
  const [initEditor2, setInitEditor2] = useState(false);

  const editorRef2 = useRef<Editor>(null);
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refPicture = useRef<any>(null);
  const [picture, setPicture] = useState<any>();
  const [dataForm, setDataForm] = useState({
    title_en: data.title_en,
    title_es: data.title_es,
    caption_es: data.caption_es,
    caption_en: data.caption_en,
    championship_id: data.championship_id,
    content_es: data.content_es,
    content_en: data.content_en,
    grand_prix_event_id: data.grand_prix_event_id,
    id: -1,
  });
  const [uploading, setUploading] = useState(false);
  const [contentES, setContentES] = useState(data.content_es);
  const [contentEN, setContentEN] = useState(data.content_en);

  const [lastGrandsPrix, setLastGrandsPrix] = useState<GP[]>([]);
  const [lastGrandsPrixSelected, setLastGrandsPrixSelected] = useState<GP[]>(
    []
  );
  const [championships, setChampionships] = useState<ChampData[]>([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getChampionshipsAndGPs`
      )
      .then(({ data }) => {
        const handleBeforeUnload = (e: any) => {
          e.preventDefault();
          e.returnValue = ""; // Mostrar un mensaje personalizado al usuario
        };

        setChampionships(data.championships);
        setLastGrandsPrix(data.gps);

        setLastGrandsPrixSelected(
          data.gps.filter(
            (gp: GP) =>
              gp.championship_id ===
              parseInt(dataForm.championship_id.toString())
          )
        );

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        };
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [dataForm.championship_id]);

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  useEffect(() => {
    setDataForm(data);
    setContentES(data.content_es);
    setContentEN(data.content_en);
    if (initEditor1) {
      editorRef1?.current?.editor?.setContent(data.content_es);
    }
    if (initEditor2) {
      editorRef2?.current?.editor?.setContent(data.content_en);
    }
  }, [data, initEditor1, initEditor2]);

  const submitForm = (event: any) => {
    if (token == null) return;
    const method = title === "Update New" ? "updateNew" : "createNew";
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("title_en", dataForm.title_en);
    formDataFiles.append("title_es", dataForm.title_es);
    formDataFiles.append("caption_es", dataForm.caption_es);
    formDataFiles.append("caption_en", dataForm.caption_en);
    formDataFiles.append(
      "grand_prix_event_id",
      dataForm.grand_prix_event_id == null
        ? ""
        : dataForm.grand_prix_event_id?.toString()
    );
    formDataFiles.append(
      "championship_id",
      dataForm.championship_id.toString()
    );
    if (picture != null) formDataFiles.append("image", picture);
    formDataFiles.append("content_es", contentES);
    formDataFiles.append("content_en", contentEN);
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);

    if (method === "updateNew") formDataFiles.append("id", dataForm.id + "");

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/${method}`,
          formDataFiles
        )
        .then(() => {
          const text = title === "Create New" ? "created" : "updated";
          setUploading(false);
          setDataForm({
            ...dataForm,
            title_en: "",
            title_es: "",
            caption_es: "",
            caption_en: "",
            championship_id: -1,
            content_es: "",
            content_en: "",
            grand_prix_event_id: null,
          });
          setContentEN("");
          editorRef1?.current?.editor?.setContent("");
          setContentES("");
          editorRef2?.current?.editor?.setContent("");
          toast.success(`New ${text} successfully`);
        })
        .catch(({ response }) => {
          toast.error("Error!");
          setUploading(false);
          throw new Error(response);
        });
    }
  };

  const copyEditor = () => {
    editorRef2?.current?.editor?.setContent(contentES);
  };

  return (
    <Form noValidate onSubmit={submitForm}>
      <Row className="mb-3">
        <Form.Group className="form-group" as={Col} md="6">
          <Form.Label>Title [ES](*)</Form.Label>
          <Form.Control
            name="title_es"
            type="text"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.title_es}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="6">
          <Form.Label>Title [EN](*)</Form.Label>
          <Form.Control
            name="title_en"
            type="text"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.title_en}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="6">
          <Form.Label>Caption [ES](*)</Form.Label>
          <Form.Control
            name="caption_es"
            type="text"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.caption_es}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="6">
          <Form.Label>Caption [EN](*)</Form.Label>
          <Form.Control
            name="caption_en"
            type="text"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.caption_en}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="4">
          <Form.Label>Image (*)</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            required={title === "Create New"}
            ref={refPicture}
            onChange={() => setPicture(refPicture.current.files[0])}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="4">
          <Form.Label>Championship</Form.Label>
          <Form.Select
            name="championship_id"
            value={dataForm.championship_id}
            required
            onChange={(e) => {
              inputChange(e);
            }}
          >
            <option>None</option>
            {championships.map((championship) => {
              return (
                <option key={championship.id} value={championship.id}>
                  {championship.name}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="4">
          <Form.Label>Grand Prix</Form.Label>
          <Form.Select
            name="grand_prix_event_id"
            value={
              dataForm.grand_prix_event_id == null
                ? ""
                : dataForm.grand_prix_event_id
            }
            onChange={(e) => {
              inputChange(e);
            }}
          >
            <option value={""}>None</option>
            {lastGrandsPrixSelected.map((gp) => {
              return (
                <option key={gp.id} value={gp.id}>
                  {gp.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="12">
          <Form.Label>Spanish New(*)</Form.Label>
          <button
            className="btn btn-secondary copy-to-english-btn"
            onClick={copyEditor}
            type="button"
          >
            Copy to English
          </button>
          <Editor
            ref={editorRef1}
            apiKey={apiKey}
            init={{
              setup: (editor) => {
                editor.on("init", () => {
                  setInitEditor1(true);
                });
                editor.on("change", () => {
                  setContentES(editor.getContent());
                });
              },
              height: 500,
              menubar: false,
              plugins,
              toolbar,
              toolbar_mode: "sliding",
              images_upload_handler: async (blobInfo) => {
                return new Promise(async (resolve, reject) => {
                  let imageFile = new FormData();
                  imageFile.append("image", blobInfo.blob());
                  try {
                    const { data } = await axios.post(
                      `${
                        process.env.NODE_ENV === "development"
                          ? "http://localhost:5500"
                          : "https://ligavirtualf1.es:5500"
                      }/api/admin/uploadNewPhoto`,
                      imageFile
                    );
                    resolve(data);
                  } catch (error) {
                    reject();
                  }
                  return;
                });
              },
            }}
          />
        </Form.Group>

        <Form.Group className="form-group" as={Col} md="12">
          <Form.Label>English New(*)</Form.Label>
          <Editor
            ref={editorRef2}
            apiKey={apiKey}
            init={{
              setup: (editor) => {
                editor.on("init", () => {
                  setInitEditor2(true);
                });
                editor.on("change", () => {
                  setContentEN(editor.getContent());
                });
              },
              height: 500,
              menubar: false,
              plugins,
              toolbar: [],
              toolbar_mode: "sliding",
            }}
          />
        </Form.Group>
      </Row>
      <div className="text-center">
        {uploading ? (
          <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
        ) : (
          <Button variant="primary" type="submit">
            {title}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default FormNews;
