import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Flag from "../../components/Flag";
import LoadingContent from "../../components/LoadingContent";
import ErrorPage from "../ErrorPage";
import "../../assets/style/driver.css";
import SummaryTable from "./parts/SummaryTable";
import $ from "jquery";
import DriverResults from "./parts/DriverResults";
import EventResults from "./parts/EventResults";

const Driver = () => {
  let { username } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [maxRaces, setMaxRaces] = useState(0);
  const [user, setUser] = useState<User>();
  const [summary, setSummary] = useState<DriverSummary[]>([]);
  const [events, setEvents] = useState<EventResults[]>([]);
  document.title = "LVF1 - " + username;

  useEffect(() => {
    if (username != null)
      axios
        .get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/driver/getDriverResults`,
          {
            params: { username },
          }
        )
        .then(({ data }) => {
          setUser(data.user);
          setSummary(data.summary);
          setMaxRaces(data.max_races);
          setEvents(data.event_results);
          $(".rdt_TableBody").animate(
            { scrollTop: $(document).height() },
            1000
          );
        })
        .catch(({ response }) => {
          setError(true);
          throw new Error(response);
        });
  }, [username]);

  if (error || username == null) return <ErrorPage />;
  if (user == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container  page-transition">
      <h3 className="h3-driver-name">
        <Flag flag_code={user.flag_code} name={user.flag_name} />
        {username}
      </h3>
      <div id="photo-summary-container" className="flex">
        <div>
          <img
            className="driver-photo"
            src={
              user.hasImage != null && user.hasImage
                ? `/assets/Drivers/${user.id}.png?dummy=${Math.floor(
                    Math.random() * 1001
                  )}`
                : require(`../../assets/Images/NoPhoto.png`)
            }
            alt={username}
          />
        </div>
        <SummaryTable summary={summary} />
      </div>
      <h3 className="results-title">{t("Results")}</h3>
      <DriverResults summary={summary} maxRaces={maxRaces} driver={username} />
      {events.length != 0 && (
        <>
          <h3 className="results-title">{t("EventsResults")}</h3>
          <EventResults events={events} />
        </>
      )}
    </div>
  );
};

export default Driver;
