import React from "react";
import getTextColor from "./DynamicContrast";
import { lightTheme, darkTheme } from "../utils/Themes";

const teamLogo = (
  team_id: string | number | null,
  logo: string | null,
  team_name: string
) => {
  if (team_id == null || team_id == "-1") {
    return (
      <img
        className="full-width"
        src={require(`../assets/Images/Unknown.png`)}
        alt={team_name}
      />
    );
  } else if (!Number.isNaN(Number(team_id))) {
    const logo_string = logo != null ? "_" + logo : "";
    return (
      <img
        className="full-width"
        src={`/assets/Teams/${team_id}${logo_string}.png`}
        alt={team_name}
      />
    );
  } else if (team_name === "LigaVirtualF1 Team") {
    return (
      <img
        className="full-width"
        src={require(`../assets/Images/Logo.png`)}
        alt={team_name}
      />
    );
  } else {
    return (
      <img
        className="full-width"
        src={require(`../assets/Images/Unknown.png`)}
        alt={team_name}
      />
    );
  }
};

const teamBackground = (team_id: string | null, main_color: string | null) => {
  console.log(team_id);
  if (team_id === "LigaVirtualF1 Team") return "#3bd8c9";
  else if (main_color == null || team_id == null) return "";
  else return main_color;
};

const getColor = (team_id: string | null, main_color: string | null) => {
  if (team_id === "LigaVirtualF1 Team") return "black";
  else if (main_color === null || team_id == null || team_id == "-1") return "";
  else if (!Number.isNaN(Number(team_id))) return getTextColor(main_color);
  return "";
};

const getStandingsPositionStyle = (position: number | string) => {
  if (!Number.isNaN(Number(position))) {
    position = parseInt(position.toString());
  }
  if (position === 1) return "standings-WINNER";
  else if (position === 2) return "standings-SECOND";
  else if (position === 3) return "standings-THIRD";
  else if (position === "Ret") return "standings-Ret";
  else if (position === "DSQ" || position === "DQ") return "standings-DSQ";
  else if (position === "DNQ") return "standings-DNQ";
};

const getStandingsPositionColor = (
  position: number | string,
  theme_: string
) => {
  const theme = theme_ === "light" ? lightTheme : darkTheme;
  if (!Number.isNaN(Number(position))) {
    position = parseInt(position.toString());
  }
  if (position === 1) return theme.winnerColor;
  else if (position === 2) return theme.secondColor;
  else if (position === 3) return theme.thirdColor;
  else if (position === "Ret") return theme.retColor;
  else if (position === "DSQ" || position === "DQ") return theme.dnqColor;
  else if (position === "DNQ") return theme.dnqColor;
  else if (position === "DNS") return theme.othersColor;
  else return theme.pointsColor;
};

export {
  teamLogo,
  teamBackground,
  getColor,
  getStandingsPositionStyle,
  getStandingsPositionColor,
};
