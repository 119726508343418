import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { md5Cipher } from "../utils/MD5Cipher";

const UserContext = React.createContext(null);

export function UserProvider(props: any) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (user !== null) {
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/user/setUserLastConnection`,
          {
            username: user,
          }
        )
        .then(({ data }) => {})
        .catch(({ response }) => {});
    }
  }, [user]);

  interface bodyRequest {
    username: string;
    password: string;
  }
  const login = (body: bodyRequest, callback: any) => {
    body.password = md5Cipher(body.password);
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/user/login`,
        body
      )
      .then(({ data }) => {
        window.localStorage.setItem("userLogged", JSON.stringify(data));
        setUser(data.username);
        callback(true);
      })
      .catch(({ response }) => {
        if ((response.status = 400)) {
          callback(response);
        }
      });
  };

  const logout = () => {
    window.localStorage.removeItem("userLogged");
    setUser(null);
  };

  const setUserConnection = () => {
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/user/setConnection`,
        {
          user: user,
        }
      )
      .then(({ data }) => {})
      .catch(({ response }) => {});
  };

  const value = useMemo(() => {
    return { user, login, setUser, logout, setUserConnection };
  }, [user]);

  return <UserContext.Provider value={value} {...props} />;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUser should be inside UserContext provider");
  }
  return context;
}
