import { Form } from "react-bootstrap";
import { getDescriptionRace, GP } from "../../../../utils/Admin";
import { useEffect, useState } from "react";
import axios from "axios";

const SelectRaceLivetimingFile = ({
  gp,
  checkboxState,
  handleCheckboxChange,
}: {
  gp: GP | undefined;
  checkboxState: any;
  handleCheckboxChange: (event: any) => void;
}) => {
  const [recordsData, setRecordsData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/getRecordFiles`,
        {
          params: {
            type_championship: gp?.type_championship,
            type_races: gp?.type_races,
          },
        }
      )
      .then(({ data }) => {
        setRecordsData(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [gp]);

  const generateJSX = (gp: any) => {
    if (!gp) return null;

    const type_race = gp.type_race.split(" ");
    const jsxElements = [];

    if (type_race != null) {
      for (let i = 0; i < type_race.length; i++) {
        jsxElements.push(
          <Form.Group className="form-group" key={i}>
            <Form.Label>
              Livetiming Record File {gp.type_races === 1 && "s"}
              {getDescriptionRace(gp, type_race[i])}
            </Form.Label>
            {recordsData.map((record: any) => (
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    <b>{record.file}</b> - <i>{record.content}</i>
                  </span>
                }
                name={i + "-" + record.file}
                checked={checkboxState[i + "-" + record.file] || false}
                onChange={handleCheckboxChange}
                key={i + "-" + record.file}
              />
            ))}
          </Form.Group>
        );
      }
    }

    return <>{jsxElements}</>;
  };

  return <>{generateJSX(gp)}</>;
};

export default SelectRaceLivetimingFile;
