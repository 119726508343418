import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getColor,
  teamLogo,
  teamBackground,
  getStandingsPositionStyle,
} from "../../utils/Standings";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";

interface Rows {
  pos: number;
  JSXDriver: ReactElement<any, any>;
  JSXClass: ReactElement<any, any>;
  gps: number;
  poles: number;
  podiums: number;
  wins: number;
  points: number;
  fastestlaps: number;
  laps_lead: number;
  pointsJSX: any;
  catalog: null | number;
}

const TeamStats = ({
  data,
  champData,
}: {
  data: TeamStats[];
  champData: ChampData;
}) => {
  const { t } = useTranslation();

  const getTeamNameLink = (team: TeamStats) => {
    //external championship
    if (team.main_color === null) {
      return (
        <label
          style={{
            color: getColor(team.team_id.toString(), team.main_color),
          }}
        >
          {team.team}
        </label>
      );
    }
    return (
      <Link
        className="full-white-driver"
        to={`${t("routes:Team")}/${encodeURIComponent(team.team)}`}
        style={{
          color: getColor(team.team_id.toString(), team.main_color),
        }}
      >
        {team.team}
      </Link>
    );
  };

  const rows: Rows[] = [];

  {
    data.map((team, index) => {
      rows.push({
        pos: team.position,
        JSXDriver: (
          <div
            className="teamdriver-results-container text-left"
            style={{
              background: teamBackground(team.team_id, team.main_color),
            }}
          >
            <div className="logo-results-container flex-center">
              {teamLogo(team.team_id, team.logo, team.team)}
            </div>
            {getTeamNameLink(team)}
          </div>
        ),
        JSXClass: (
          <React.Fragment>
            {team.class !== null && (
              <label
                className="class_content"
                style={{
                  background:
                    team.class_color != null ? team.class_color : "#179387",
                }}
              >
                {team.class.toUpperCase()}
              </label>
            )}
          </React.Fragment>
        ),
        catalog: team.catalog,
        gps: team.gps === null ? 0 : team.gps,
        poles: team.poles === null ? 0 : team.poles,
        podiums: team.podiums === null ? 0 : team.podiums,
        wins: team.wins === null ? 0 : team.wins,
        fastestlaps: team.fastestlaps === null ? 0 : team.fastestlaps,
        laps_lead: team.laps_lead === null ? 0 : team.laps_lead,
        points: team.points === null ? 0 : team.points,
        pointsJSX: (
          <div
            className={`${
              typeof team.position === "number" &&
              getStandingsPositionStyle(team.is_dsq ? "DSQ" : team.position)
            }`}
          >
            {team.points}
          </div>
        ),
      });
    });
  }

  const columnas =
    champData.type_championship === 3
      ? [
          {
            name: "#",
            selector: (row: { pos: number }) => row.pos,
            sortable: true,
            width: "60px",
          },
          {
            name: t("Team"),
            selector: (row: { JSXDriver: any }) => row.JSXDriver,
            width: "205px",
          },
          {
            name: t("Class"),
            selector: (row: { JSXClass: any }) => row.JSXClass,
            width: "100px",
            sortable: true,
            sortFunction: (a: Rows, b: Rows) => {
              if (a.catalog == null || b.catalog == null) return -1;
              return a.catalog - b.catalog;
            },
          },
          {
            name: t("GPs"),
            selector: (row: { gps: number }) => row.gps,
            sortable: true,
            width: "100px",
          },
          {
            name: t("Wins"),
            selector: (row: { wins: number }) =>
              row.wins === 0 ? "" : row.wins,
            sortable: true,
            width: "120px",
          },
          {
            name: t("Podiums"),
            selector: (row: { podiums: number }) =>
              row.podiums === 0 ? "" : row.podiums,
            sortable: true,
            width: "120px",
          },
          {
            name: t("HotLaps"),
            selector: (row: { fastestlaps: number }) =>
              row.fastestlaps === 0 ? "" : row.fastestlaps,
            sortable: true,
            width: "130px",
          },
          {
            name: t("Points"),
            selector: (row: { pointsJSX: any }) => row.pointsJSX,
            sortable: true,
            width: "100px",
            sortFunction: (a: Rows, b: Rows) => {
              return a.points - b.points;
            },
          },
        ]
      : [
          {
            name: "#",
            selector: (row: { pos: number }) => row.pos,
            sortable: true,
            width: "60px",
          },
          {
            name: t("Team"),
            selector: (row: { JSXDriver: any }) => row.JSXDriver,
            width: "205px",
          },
          {
            name: t("Class"),
            selector: (row: { JSXClass: any }) => row.JSXClass,
            width: "100px",
            sortable: true,
            sortFunction: (a: Rows, b: Rows) => {
              if (a.catalog == null || b.catalog == null) return -1;
              return a.catalog - b.catalog;
            },
          },
          {
            name: t("GPs"),
            selector: (row: { gps: number }) => row.gps,
            sortable: true,
            width: "100px",
          },
          {
            name: t("Poles"),
            selector: (row: { poles: number }) =>
              row.poles === 0 ? "" : row.poles,
            sortable: true,
            width: "120px",
          },
          {
            name: t("Wins"),
            selector: (row: { wins: number }) =>
              row.wins === 0 ? "" : row.wins,
            sortable: true,
            width: "120px",
          },
          {
            name: t("Podiums"),
            selector: (row: { podiums: number }) =>
              row.podiums === 0 ? "" : row.podiums,
            sortable: true,
            width: "120px",
          },
          {
            name: t("HotLaps"),
            selector: (row: { fastestlaps: number }) =>
              row.fastestlaps === 0 ? "" : row.fastestlaps,
            sortable: true,
            width: "130px",
          },
          {
            name: t("LapsLead"),
            selector: (row: { laps_lead: number }) =>
              row.laps_lead === 0 ? "" : row.laps_lead,
            sortable: true,
            width: "130px",
          },
          {
            name: t("Points"),
            selector: (row: { pointsJSX: any }) => row.pointsJSX,
            sortable: true,
            width: "100px",
            sortFunction: (a: Rows, b: Rows) => {
              return a.points - b.points;
            },
          },
        ];

  return (
    <div className="frame-turqoise">
      <h3 className="text-center frame-title">
        {t("TeamStatsStandings").toUpperCase()}
      </h3>
      <div className="flex-container">
        <div className="flex">
          <div
            className={`data-standings ${
              data.length > 0 && data[0].class == null ? "hide-class" : ""
            }`}
          >
            <DataTable columns={columnas} data={rows} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamStats;
