import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";
import { transformHour } from "../../../../../utils/DateTransformations";

const CountDown = ({ date }: { date: string[] }) => {
  const { t } = useTranslation();
  const getTimeRemaining = (): number => {
    /* Z specifies the UTC */
    const dateString = transformHour(date[date.length - 1]);
    const localDate = new Date(dateString);
    return localDate.getTime();
  };

  // Random component
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    // Render a countdown
    return (
      <div id="countdown">
        <div className="flex-center">
          <div className="countdown-days column-around">
            <span className="countdown-title">{t("TDays").toUpperCase()}</span>
            <span className="countdown-value">{days}</span>
          </div>
          <div className="countdown-days column-around">
            <span className="countdown-title">{t("THours").toUpperCase()}</span>
            <span className="countdown-value">{zeroPad(hours)}</span>
          </div>
          <div className="countdown-days column-around">
            <span className="countdown-title">
              {t("TMinutes").toUpperCase()}
            </span>
            <span className="countdown-value">{zeroPad(minutes)}</span>
          </div>
          <div className="countdown-days column-around">
            <span className="countdown-title">
              {t("TSeconds").toUpperCase()}
            </span>
            <span className="countdown-value">{zeroPad(seconds)}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {date.length > 0 && (
        <>
          <Countdown date={getTimeRemaining()} renderer={renderer} />
          {/*<label className="small-text">
            <DateFormat date={date} showTime={true} />
          </label>*/}
        </>
      )}
    </div>
  );
};

export default CountDown;
