import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

const ClaimsPenalties = ({
  sessionsPlayed,
  getSessionName,
  penalties,
  claims,
}: {
  sessionsPlayed: number[];
  getSessionName: (session: number) => string;
  penalties: PenaltiesGP[];
  claims: ClaimGP[];
}) => {
  const { t } = useTranslation();

  const getPenaltiesJSX = (penalty: PenaltiesGP[], claim: ClaimGP) => {
    if (penalty.length === 0)
      return (
        <p>
          <b>{t("Penalty")}: </b>
          {claim.reviewed === 1 ? t("NFA") : t("Pending")}
        </p>
      );
    return penalty.map((penalty, index) => {
      return (
        <p key={index}>
          <b>{t("Penalty")}: </b>
          {penalty.driver} {penaltyText(penalty)} ({penalty.description})
        </p>
      );
    });
  };
  const penaltyText = (penalty: PenaltiesGP) => {
    let penalty_text = "";
    if (penalty.type_penalty === 1) penalty_text = `+${penalty.value_penalty}s`;
    else if (penalty.type_penalty === 2)
      penalty_text = `-${penalty.value_penalty}pts`;
    else if (penalty.type_penalty === 3) penalty_text = `DQ`;
    else if (penalty.type_penalty === 4)
      penalty_text = `+${penalty.value_penalty} Qualy pos`;
    else if (penalty.type_penalty === 5) penalty_text = `Pit Lane Start`;
    else if (penalty.type_penalty === 6)
      penalty_text = `Banned from the championship`;
    else if (penalty.type_penalty === 7) penalty_text = `Banned from LVF1`;
    else if (penalty.type_penalty === 8) penalty_text = `DQ Championship`;
    return penalty_text;
  };
  return (
    <div id="penalties-container" className="full-width">
      <label className="label-title-gp">
        {t("Claims").toUpperCase()} & {t("Penalties").toUpperCase()}
      </label>
      <div id="claim-penalty-container" className="column-around">
        {claims.length === 0 && <p>{t("NoClaimsForThisRace")}</p>}
        {claims.map((claim, index) => {
          const penalty = penalties.filter(
            (penalty) => penalty.claim_id === claim.claim_id
          );
          return (
            <div className="claim-penalty" key={index}>
              <b>
                {t("Claim")}
                {" #C"}
                {index + 1}
              </b>
              <p>
                <b>{t("Offender")}: </b> {claim.username}
              </p>
              <p>
                <b>{t("Incident")}: </b> {claim.time} (
                {getSessionName(claim.session)})
              </p>
              {getPenaltiesJSX(penalty, claim)}
            </div>
          );
        })}
        {penalties
          .filter((penalty) => penalty.claim_id === null)
          .map((penalty, index) => {
            return (
              <div className="claim-penalty" key={index}>
                <b>
                  {t("Claim")}
                  {" #S"}
                  {index + 1}
                </b>
                <p>
                  <b>{t("Penalty")}: </b> {penalty.driver}{" "}
                  {penaltyText(penalty)} ({penalty.description})
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ClaimsPenalties;
