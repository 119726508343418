import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "../../components/Historic/MenuItem";
import SummaryData from "../../components/Historic/SummaryData";
import LoadingContent from "../../components/LoadingContent";
import { animated, useSpring } from "react-spring";
import Button from "../../components/Button";
import "../../assets/style/stats.css";
import { useLocation } from "react-router-dom";
import { i18n } from "../../App";

const Stats = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<{
    games: Games[];
    driver_data: PointsData[];
    team_data: PointsData[];
    order_data: OrderData;
  }>();
  const menuItems = [
    {
      name: "Points",
      translation: t("Points"),
      URLtranslation: t("routes:Points"),
    },
    {
      name: "Races",
      translation: t("Races"),
      URLtranslation: t("routes:Races"),
    },
    {
      name: "Poles",
      translation: t("Poles"),
      URLtranslation: t("routes:Poles"),
    },
    {
      name: "Wins",
      translation: t("Wins"),
      URLtranslation: t("routes:Wins"),
    },
    {
      name: "Podiums",
      translation: t("Podiums"),
      URLtranslation: t("routes:Podiums"),
    },
    {
      name: "HotLaps",
      translation: t("HotLaps"),
      URLtranslation: t("routes:HotLaps"),
    },
    {
      name: "AveragePoints",
      translation: t("AveragePoints"),
      URLtranslation: t("routes:AveragePoints"),
    },
    {
      name: "Titles",
      translation: t("Titles"),
      URLtranslation: t("routes:Titles"),
    },
    {
      name: "HatTricks",
      translation: t("HatTricks"),
      URLtranslation: t("routes:HatTricks"),
    },
    {
      name: "FinalizingConsecutively",
      translation: t("FinalizingConsecutively"),
      URLtranslation: t("routes:FinalizingConsecutively"),
    },
    {
      name: "WithdrawnConsecutively",
      translation: t("WithdrawnConsecutively"),
      URLtranslation: t("routes:WithdrawnConsecutively"),
    },
  ];
  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState(-1);
  const { hash } = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(() => {
    if (hash == null) return "Points";
    const item_selected = menuItems.find(
      (item) => item.URLtranslation === hash.substring(1)
    );
    if (item_selected == null) return "Points";
    else return item_selected.name;
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/historic/getStatsData`,
        {
          params: {
            menu: selectedMenu,
          },
        }
      )
      .then(({ data }) => {
        document.title = `LVF1 - ${t("Statistics")} ${t(selectedMenu)}`;
        window.history.pushState(
          t(selectedMenu),
          t(selectedMenu),
          t("routes:Stats") + "#" + t(`routes:${selectedMenu}`)
        );
        setData(data);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, [selectedMenu, i18n.language]);

  const driversStyle = useSpring({
    width: expand === 1 ? "100%" : expand === 2 ? "0%" : "50%",
  });
  const teamsStyle = useSpring({
    width: expand === 2 ? "100%" : expand === 1 ? "0%" : "50%",
  });

  if (data == null || loading) return <LoadingContent />;

  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="stats-container" className="full-width frame-turqoise">
        <h3 className="text-center frame-title">
          {t("Statistics").toUpperCase()} {t(selectedMenu).toUpperCase()}
        </h3>
        <div className="menu-icons hover14 flex">
          {menuItems.map((item) => {
            return (
              <MenuItem
                key={item.name}
                onClick={() => setSelectedMenu(item.name)}
                icon={item.name}
                title={item.translation}
                selected={selectedMenu === item.name}
              />
            );
          })}
        </div>
        <div id="stats-data-container" className="flex">
          <animated.div
            id="drivers-stats"
            style={driversStyle}
            className={expand === -1 ? "borderRight" : ""}
          >
            <h3 className="text-center">{t("Drivers").toUpperCase()}</h3>
            <SummaryData
              data={data.driver_data}
              games={data.games}
              order_data={data.order_data}
              isTeam={false}
              expanded={expand === 1}
            />
            <Button
              onClick={() => setExpand(expand === 1 ? -1 : 1)}
              text={
                expand === 1 ? t("AbbreviateStatistics") : t("ExpandStatistics")
              }
            />
          </animated.div>

          <animated.div id="teams-stats" style={teamsStyle}>
            <h3 className="text-center">{t("Teams").toUpperCase()}</h3>
            <SummaryData
              data={data.team_data}
              games={data.games}
              order_data={data.order_data}
              isTeam={true}
              expanded={expand === 2}
            />
            <Button
              onClick={() => setExpand(expand === 2 ? -1 : 2)}
              text={
                expand === 2 ? t("AbbreviateStatistics") : t("ExpandStatistics")
              }
            />
          </animated.div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
