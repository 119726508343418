import "../assets/style/regulation.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { i18n } from "../App";

import {
  SpecialZoomLevel,
  Worker,
  Viewer,
  ViewMode,
} from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
const Regulation = () => {
  let { champ } = useParams();
  const { t } = useTranslation();
  const [typeChamp, setTypeChamp] = useState(1);
  document.title = "LVF1 - " + t("Regulation");

  const zoomPluginInstance = zoomPlugin();
  const { Zoom } = zoomPluginInstance;

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      fullScreenPlugin: {
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
        },
      },
    },
  });

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getTypeChamp`,
        {
          params: {
            champ,
          },
        }
      )
      .then(({ data }) => {
        setTypeChamp(data.type);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [champ]);

  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="regulation" className="flex-center frame-turqoise">
        <h3 className="text-center frame-title">
          {t("Regulation").toUpperCase()}
        </h3>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "750px",
              width: "100%",
            }}
          >
            <Viewer
              fileUrl={`/assets/Regulation/${typeChamp}/Regulation_${
                i18n.language === "es" ? "ES" : "EN"
              }.pdf`}
              defaultScale={SpecialZoomLevel.PageFit}
              viewMode={
                window.innerWidth < 600
                  ? ViewMode.SinglePage
                  : ViewMode.DualPage
              }
              plugins={[defaultLayoutPluginInstance, zoomPluginInstance]}
            />
          </div>
        </Worker>
      </div>
    </div>
  );
};

export default Regulation;
