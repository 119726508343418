import "./assets/style/fonts.css";
import "./assets/style/scrollbar.css";
import "./assets/style/app.css";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from "./views/layouts/header/Header";
import Footer from "./views/layouts/Footer";
import i18n from "i18next";
import { LANGUAGES } from "./core/consts";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { UserProvider } from "./components/UserContext";
import { lightTheme, darkTheme, GlobalStyles } from "./utils/Themes";
import styled, { ThemeProvider } from "styled-components";
import ScrollToTop from "./utils/ScrollToTop";
import AppContent from "./views/AppContent";
import { ToastContainer } from "react-toastify";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

interface LanguageResource {
  [key: string]: { ["translation"]: any; ["countries"]: any; ["routes"]: any };
}
let object: LanguageResource = {};
LANGUAGES.map((language) => {
  object[language.code] = {
    translation: require(`./translations/${language.code}/global.json`),
    countries: require(`./translations/${language.code}/countries.json`),
    routes: require(`./translations/${language.code}/routes.json`),
  };
  return true;
});
const queryClient = new QueryClient();
const url = window.location.pathname.split("/");
let url_lang =
  url.length > 1 &&
  LANGUAGES.filter((lang) => {
    return lang.code === url[1];
  }).length > 0
    ? url[1]
    : LANGUAGES[0].code;
window.localStorage.setItem("language", url_lang);
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: object,
    lng: url_lang,
    fallbackLng: url_lang,
    interpolation: { escapeValue: false },
    nsSeparator: ":", //it allows translation keys with spaces
  });
const StyledApp = styled.div``;

const App = () => {
  const [theme, setTheme] = useState("light");
  const [updateNotifications, setUpdateNotifications] = useState(false);
  useEffect(() => {
    let theme_storaged = window.localStorage.getItem("theme");
    if (theme_storaged !== null) {
      setTheme(theme_storaged);
    }
  }, []);

  const handleTheme = (theme: string) => {
    setTheme(theme);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
          <GlobalStyles />
          <StyledApp>
            <Router>
              <ScrollToTop />
              <Header
                handleTheme={handleTheme}
                updateNotifications={updateNotifications}
              />
              <AppContent setUpdateNotifications={setUpdateNotifications} />
              <ToastContainer theme="dark" />
              <Footer />
            </Router>
          </StyledApp>
        </ThemeProvider>
      </UserProvider>
    </QueryClientProvider>
  );
};

export default App;

export { i18n };
