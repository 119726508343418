import { faHourglass2 } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DateFormat from "../../../components/DateFormat";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";

const ClaimForm = ({
  user,
  dateText,
  sessionsPlayed,
  drivers,
  getSessionName,
  claim,
  setClaim,
  grandprix,
  setUpdateClaims,
}: {
  user: string;
  dateText: string[];
  sessionsPlayed: number[];
  drivers: { id: number; user: string }[];
  getSessionName: (session: number) => string;
  claim: ClaimPost;
  setClaim: Dispatch<SetStateAction<ClaimPost>>;
  grandprix: string;
  setUpdateClaims: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);

  const cancelUpdate = () => {
    setClaim({
      driver: "",
      session: "",
      minute: "",
      observations: "",
      updating: -1,
    });
  };

  const submitForm = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false) && user != null) {
      let token = window.localStorage.getItem("userLogged");
      if (token == null) return;
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/grandprix/postClaim`,
          {
            claim,
            user,
            token: JSON.parse(token).token,
            grandprix,
          }
        )
        .then(() => {
          toast.success(`${t("ClaimSubmitted")}`);
          cancelUpdate();
          setUpdateClaims((prev_value: boolean) => !prev_value);
          setValidated(false);
        })
        .catch(({ response }) => {
          console.log(response);
          toast.error(`${t(response.data)}`);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setClaim({
      ...claim,
      [name]: value,
    });
  };

  return (
    <Form
      id="claim-form"
      className="frame-turqoise"
      noValidate
      validated={validated}
      onSubmit={submitForm}
    >
      <label className="frame-title">{t("ClaimForm").toUpperCase()}</label>
      <label className="deathline flex-center">
        <FontAwesomeIcon icon={faHourglass2} />
        <label className="small-text">{t("Deadline")}:</label>
        <DateFormat date={dateText} format={"full"} />
      </label>
      <small className="mandatory-fields top-margin">
        (*) {t("RequiredFields")}
      </small>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("Session")} (*)</Form.Label>
          <Form.Select
            name="session"
            value={claim.session}
            required
            onChange={(e) => {
              inputChange(e);
            }}
          >
            <option disabled value={""}>
              {t("None")}
            </option>
            {sessionsPlayed.map((session) => (
              <option key={session} value={session}>
                {getSessionName(session)}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t("FieldRequired")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("Drivers")} (*)</Form.Label>
          <Form.Select
            name="driver"
            value={claim.driver}
            required
            onChange={(e) => {
              inputChange(e);
            }}
          >
            <option disabled value={""}>
              {t("None")}
            </option>
            {drivers.map((driver) => (
              <option key={driver.id} value={driver.id}>
                {driver.user}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t("FieldRequired")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("Minute")} (*)</Form.Label>
          <Form.Control
            name="minute"
            type="text"
            value={claim.minute}
            onChange={inputChange}
            required
            maxLength={45}
          />
          <Form.Control.Feedback type="invalid">
            {t("FieldRequired")}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12">
          <Form.Label>{t("Observations")} (*)</Form.Label>
          <Form.Control
            name="observations"
            as="textarea"
            type="text"
            value={claim.observations}
            onChange={inputChange}
            required
            maxLength={255}
          />
          <Form.Control.Feedback type="invalid">
            {t("FieldRequired")}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      {
        <div className="flex flex-around text-center">
          <Button variant="primary" type="submit">
            {claim.updating === -1 ? t("Post") : t("Update")}
          </Button>{" "}
          {claim.updating !== -1 && (
            <Button variant="secondary" onClick={cancelUpdate}>
              {t("Cancel")}
            </Button>
          )}
          {user === null && validated && (
            <label className="show-block invalid-feedback">
              {t("NoLoggedToClaim")}
            </label>
          )}
        </div>
      }
    </Form>
  );
};

export default ClaimForm;
