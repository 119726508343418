import { useEffect, useState } from "react";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import CircuitForm from "../../../components/Admin/CircuitForm";
import axios from "axios";
import { Form } from "react-bootstrap";

interface Circuit {
  id: number;
  name: string;
  layout: string;
  turns: number;
  length: number;
  lat: number;
  lon: number;
}

const UpdateCircuit = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Update Circuit";
  const [dataForm, setDataForm] = useState({
    name: "",
    layout: "",
    turns: "",
    length: "",
    latitude: "",
    longitude: "",
    id: "",
    stage: false,
  });
  const [circuits, setCircuits] = useState<Circuit[]>([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/circuit/circuits`
      )
      .then(({ data }) => {
        setCircuits(data);
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });
  }, []);

  const updateDataForm = ({ target }: { target: any }) => {
    const { value } = target;
    const circuit_ = circuits.find((circuit) => circuit.id == parseInt(value));
    if (circuit_ != null) {
      setDataForm({
        name: circuit_.name,
        layout: circuit_.layout == null ? "" : circuit_.layout,
        turns: `${circuit_.turns}`,
        length: `${circuit_.length}`,
        latitude: `${circuit_.lat}`,
        longitude: `${circuit_.lon}`,
        id: value,
        stage: false,
      });
    }
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Update Circuit" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form.Group className="form-group">
          <Form.Label>Circuit (*)</Form.Label>
          <Form.Select
            value={dataForm.id}
            name="circuit"
            required
            onChange={(e) => updateDataForm(e)}
          >
            <option value="" disabled>
              None
            </option>
            {circuits.map((circuit) => {
              return (
                <option key={circuit.id} value={circuit.id}>
                  {circuit.name}{" "}
                  {circuit.layout != null && `(${circuit.layout})`}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <CircuitForm
          dataForm={dataForm}
          setDataForm={setDataForm}
          isUpdate={true}
        />
      </div>
    </div>
  );
};

export default UpdateCircuit;
