import axios from "axios";
import io from "socket.io-client";
import { useEffect } from "react";

const Test = () => {
  useEffect(() => {}, []);

  return <label>a</label>;
};

export default Test;
