import React, { useEffect, useRef, useState } from "react";
import CircuitLayout from "../../../components/CircuitLayout";
import { Resizable } from "react-resizable";
import "../../../assets/style/livetiming.css";
import ErrorPage from "../../ErrorPage";
import LoadingContent from "../../../components/LoadingContent";
import { useTranslation } from "react-i18next";
import LiveTimingTable from "../parts/LiveTimingTable";
import { useWindowSize } from "../../../hooks/useWindowSize";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Resizables = ({
  gp,
  circleDrivers,
  serverTiming,
}: {
  gp: {
    circuit_id: number;
    date: Date;
    gp_race_event_id: number;
    type_championship: number;
    type_races: number;
    game_id: number;
  };
  circleDrivers: CircleDriver[];
  serverTiming: ServerTiming;
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [rows, setRows] = useState<CircleDriver[]>([]);
  const scale = serverTiming.trackdata_name === "" ? 0.5 : 0.35;
  const [circuitLengths, setCircuitLengths] = useState({
    width: window.innerWidth * scale - 40,
    height: 500,
  });
  const [liveTimingsLengths, setLiveTimingsLengths] = useState({
    width: window.innerWidth * (1 - scale) - 40,
    height: 500,
  });
  const [update, setUpdate] = useState(false);
  const [render, setRender] = useState(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const debounceRefCircuit = useRef<NodeJS.Timeout>();
  const debounceRefLive = useRef<NodeJS.Timeout>();
  const debounceRefWindow = useRef<NodeJS.Timeout>();
  const onResize = (event: any, { size }: { size: any }) => {
    if (debounceRefCircuit.current) clearTimeout(debounceRefCircuit.current);
    const diff = size.width - circuitLengths.width;
    setCircuitLengths({ width: size.width, height: size.height });
    setLiveTimingsLengths({
      ...liveTimingsLengths,
      width: liveTimingsLengths.width - diff,
    });
    //In case 1 second passed with no changes => we update circuit layout
    debounceRefCircuit.current = setTimeout(() => {
      setUpdate(!update);
    }, 1000);
  };

  const onResizeTimings = (event: any, { size }: { size: any }) => {
    if (debounceRefLive.current) clearTimeout(debounceRefLive.current);
    const diff = size.width - liveTimingsLengths.width;
    setLiveTimingsLengths({ width: size.width, height: size.height });
    setCircuitLengths({
      ...circuitLengths,
      width: circuitLengths.width - diff,
    });
    //In case 1 second passed with no changes => we update circuit layout
    debounceRefLive.current = setTimeout(() => {
      setUpdate(!update);
    }, 1000);
  };

  useEffect(() => {
    let rows_: any = [];
    circleDrivers.forEach((driver: CircleDriver) => {
      rows_.push(driver);
    });
    rows_.sort(
      (a: CircleDriver, b: CircleDriver) => a.live_place - b.live_place
    );
    setRows(rows_);
  }, [circleDrivers]);

  useEffect(() => {
    setCircuitLengths({
      width: window.innerWidth * scale - 40,
      height: window.innerWidth < 1100 ? 310 : 500,
    });
    setLiveTimingsLengths({
      width: window.innerWidth * (1 - scale) - 40,
      height: 500,
    });
    setUpdate(!update);
  }, [render, windowWidth, windowHeight]);

  useEffect(() => {
    if (debounceRefWindow.current) clearTimeout(debounceRefWindow.current);
    debounceRefWindow.current = setTimeout(() => {
      setRender(!render);
    }, 1000);
  }, [window.innerWidth]);
  if (error) return <ErrorPage />;
  if (serverTiming == null) return <LoadingContent />;
  return (
    <>
      <div id="live-timing-page" className="flex form-group">
        {gp != null && gp.circuit_id !== null && (
          <Resizable
            height={circuitLengths.height}
            width={circuitLengths.width}
            onResize={onResize}
          >
            <div
              className="box box-circuit-container corner-borders"
              style={{
                width: circuitLengths.width + "px",
                height: circuitLengths.height + "px",
              }}
            >
              <p className="secondary-title backgrounded-title">
                {serverTiming.trackdata_name === ""
                  ? t("Circuit")
                  : serverTiming.trackdata_name}
              </p>
              <div
                style={{
                  width: circuitLengths.width + "px",
                  height: circuitLengths.height - 35 + "px",
                }}
                id="circuit-livetiming-container"
              >
                <CircuitLayout
                  circuit_id={gp.circuit_id}
                  id_canvas={`circuit-${gp.gp_race_event_id}`}
                  update={update}
                  circleDrivers={circleDrivers}
                  height={windowWidth < 1100 ? 290 : circuitLengths.height - 35}
                />
              </div>
            </div>
          </Resizable>
        )}
        <Resizable
          height={liveTimingsLengths.height}
          width={
            gp != null && gp.circuit_id !== null
              ? liveTimingsLengths.width
              : window.innerWidth
          }
          onResize={onResizeTimings}
          resizeHandles={["sw"]}
        >
          <div
            className="box corner-borders livetiming-table-container"
            style={{
              width:
                (gp != null && gp.circuit_id !== null
                  ? liveTimingsLengths.width
                  : window.innerWidth) + "px",
              height: liveTimingsLengths.height + "px",
            }}
          >
            <LiveTimingTable
              tableData={rows}
              serverTiming={serverTiming}
              width={
                gp != null && gp.circuit_id !== null
                  ? liveTimingsLengths.width
                  : window.innerWidth
              }
              height={liveTimingsLengths.height}
              typeRaces={gp.type_races}
              gameId={gp.game_id}
            />
          </div>
        </Resizable>
      </div>
    </>
  );
};

export default Resizables;
