import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useUser } from "../../../components/UserContext";
import { toast } from "react-toastify";
import DateFormat from "../../../components/DateFormat";

const REntriesTeams = ({
  raceData,
  setUpdateNotifications,
  setUpdate,
  update,
  dates,
  datesO,
  typeChamp,
}: {
  raceData: RaceEntries;
  setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
  setUpdate: Dispatch<SetStateAction<boolean>>;
  update: boolean;
  dates: string[];
  datesO: string[];
  typeChamp: number;
}) => {
  const { t } = useTranslation();
  const { user } = useUser() as any;
  const [showDropdownG, setShowDropdownG] = useState(false);
  const [showDropdownO, setShowDropdownO] = useState(false);
  const [userState, setUserState] = useState(-1);

  const driverJSX = (driver: ReserveDriver | DriverEntry) => {
    return (
      <>
        <Link
          className={`driver ${
            driver.state === 1
              ? "green"
              : driver.state === 2
              ? "orange"
              : driver.state === 3
              ? "red"
              : ""
          }`}
          title={driver.username}
          to={`${t("routes:Driver")}/${encodeURIComponent(driver.username)}`}
        >
          <span>{driver.initials}</span>
          {driver.session != null &&
            driver.state !== 3 &&
            driver.state !== 0 && (
              <span className={`session-${driver.session}`}>
                {driver.session}
              </span>
            )}
        </Link>
      </>
    );
  };

  const sendState = (state: number, session?: number | null) => {
    let token = window.localStorage.getItem("userLogged");
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/setDriverState`,
        {
          user,
          token: JSON.parse(token).token,
          state,
          session,
          typeChamp,
        }
      )
      .then(() => {
        if (state === 1) toast.success(`${t("RaceEntrySigned")}`);
        else if (state === 2) toast.success(`${t("RaceEntryDoubt")}`);
        else if (state === 3) toast.success(`${t("RaceEntryAbsent")}`);
        setUpdate(!update);
        setUpdateNotifications((prev) => {
          return !prev;
        });
      })
      .catch(({ response }) => {
        toast.error(`${t("Error!")}`);
        throw new Error(response);
      });
  };
  const handleButtonClickG = () => {
    setShowDropdownG((prevState: any) => !prevState); // Alterna la visibilidad del desplegable
  };
  const handleButtonClickO = () => {
    setShowDropdownO((prevState: any) => !prevState); // Alterna la visibilidad del desplegable
  };
  const handleClickOutsideG = (event: any) => {
    const classes = event.target.className.split(" ");
    // If clicked outside the dropdown, hide the dropdown.
    setShowDropdownG(classes.includes("button-green"));
  };
  const handleClickOutsideO = (event: any) => {
    const classes = event.target.className.split(" ");
    // If clicked outside the dropdown, hide the dropdown.
    setShowDropdownO(classes.includes("button-orange"));
  };
  useEffect(() => {
    raceData.drivers.forEach((team) => {
      team.drivers.forEach((driver) => {
        if (driver.username == user) {
          setUserState(driver.state);
        }
      });
    });
    // Adds the listener when the component is mounted
    document.addEventListener("click", handleClickOutsideG);
    document.addEventListener("click", handleClickOutsideO);

    // Cleans the listener when the component is disassembled
    return () => {
      document.removeEventListener("click", handleClickOutsideG);
      document.removeEventListener("click", handleClickOutsideO);
    };
  }, []);

  return (
    <div id="race-entries-right">
      {user == null ? (
        <p className="red bold">{t("LogInToSignUp")}</p>
      ) : (
        <div id="buttons-container" className="flex-between">
          <Button
            className="button-green"
            onClick={() => {
              if (dates.length === 1) sendState(1);
              handleButtonClickG();
            }}
          >
            {t("Register")}
          </Button>
          {dates.length > 1 && showDropdownG && (
            <div className="dropdown-dates">
              {dates.map((date, index) => {
                let state_index: number | null = index + 1;
                if (dates.length === 1) state_index = null;
                return (
                  <p onClick={() => sendState(1, state_index)}>
                    <span className={`session-${index + 1}`}>{index + 1}</span>
                    <DateFormat
                      date={date}
                      format={"full-v2"}
                      disaggregated={true}
                    />
                  </p>
                );
              })}
            </div>
          )}

          <Button
            className="button-orange"
            onClick={() => {
              if (dates.length === 1 || userState === 1) sendState(2);
              handleButtonClickO();
            }}
          >
            {t("Doubt")}
          </Button>
          {dates.length > 1 &&
          showDropdownO &&
          (userState === 0 || userState === 3) ? (
            <div className="dropdown-dates">
              {dates.map((date, index) => {
                let state_index: number | null = index + 1;
                if (dates.length === 1) state_index = null;
                return (
                  <p onClick={() => sendState(2, state_index)}>
                    <span className={`session-${index + 1}`}>{index + 1}</span>
                    <DateFormat
                      date={date}
                      format={"full-v2"}
                      disaggregated={true}
                    />
                  </p>
                );
              })}
            </div>
          ) : null}

          <Button className="button-red" onClick={() => sendState(3)}>
            {t("Absence")}
          </Button>
        </div>
      )}

      <div id="driver-entries-container">
        <p className="backgrounded-title text-center">
          {t("Teams")} & {t("Drivers")}
        </p>
        {raceData.drivers.map((team, index: number) => {
          return (
            <div className="flex" key={index}>
              <Link
                to={`${t("routes:Team")}/${encodeURIComponent(
                  team.drivers[0].team_name
                )}`}
              >
                <img
                  alt={team.drivers[0].team_name}
                  title={team.drivers[0].team_name}
                  src={`/assets/TeamChampionship/${
                    team.team_championship_id
                  }.png?dummy=${Math.floor(Math.random() * 1001)}`}
                />
              </Link>
              <div>
                {team.drivers.map((driver_, index: number) => {
                  return (
                    <div key={index} className="num-driver">
                      <span
                        style={{
                          background: driver_.main_color,
                          color: driver_.secondary_color,
                        }}
                      >
                        {driver_.number == null ? "#" : driver_.number}
                      </span>
                      {driver_.username == null ? (
                        <span>TBA</span>
                      ) : (
                        driverJSX(driver_)
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {raceData.reserve_drivers?.length > 0 && (
        <div id="reserve-drivers-container">
          <p className="backgrounded-title text-center">
            {t("ReserveDrivers")}
          </p>
          <div className="text-center">
            {raceData.reserve_drivers?.map((driver, index) => {
              return <span key={index}>{driverJSX(driver)}</span>;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default REntriesTeams;
