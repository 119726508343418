import axios from "axios";
import { useEffect, useState } from "react";
import { useUser } from "../components/UserContext";

const useSeasonStyles = (grandprix: string | undefined) => {
  const { user } = useUser() as any;
  const [style, setStyle] = useState("");
  const getStyleSheet = () => {
    return axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getStyles`,
        {
          params: {
            name: grandprix,
          },
        }
      )
      .then(async ({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  };

  useEffect(() => {
    const access = async () => {
      const access_value = await getStyleSheet();
      setStyle(access_value);
    };
    access();
  }, [user]);

  return style;
};

export default useSeasonStyles;
