import "../../assets/style/grandprix_tables.css";
import React, { useEffect, useState } from "react";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ResultsHeader from "./ResultsHeader";
import QualifyingRow from "./qualifying/QualifyingRow";
import LoadingContent from "../../components/LoadingContent";
import useSeasonStyles from "../../hooks/useSeasonStyles";
import {
  ClassFilter,
  getClassArray,
  getClassArraySimple,
} from "../../components/GrandPrix/ClassFilter";

const Qualifying = () => {
  useTranslation();
  const [activeDriver, setActiveDriver] = useState({});
  const [loading, setLoading] = useState(true);
  const [classFilter, setClassFilter] = useState<string | null>(null);
  const [championship, setChampionship] = useState({
    type_races: -1,
    has_teams: -1,
    championship_id: -1,
  });
  let { grandprix } = useParams();
  const style = useSeasonStyles(grandprix);
  const [qualifying, setQualifying] = useState<{
    [key: string]: string | number;
  }>({});

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getQualifying`,
        {
          params: {
            name: grandprix,
          },
        }
      )
      .then(({ data }) => {
        setChampionship(data.championship);
        delete data.championship;
        setQualifying(data.qualifyingResults);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, []);
  if (loading) return <LoadingContent />;
  else if (grandprix == null || Object.keys(qualifying).length === 0)
    return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={2} />
      {Object.entries(qualifying).map((item, index) => {
        const session = item[0];
        const results: any = item[1];
        let testDriver: any = null;
        let testLap: any = null;
        let classArray = getClassArraySimple(results);
        try {
          testDriver = results[0];
          testLap = results[0].laps[0];
        } catch (e) {
          //Case no LOGs
        }
        return (
          <div key={index} className={`session rq-session ${style}`}>
            <div className="session-content">
              <div className="session-title text-center">
                <label>{session}</label>
                {classArray.length > 1 && (
                  <ClassFilter
                    classes={classArray}
                    classFilter={classFilter}
                    setClassFilter={setClassFilter}
                    race={qualifying}
                    setRace={setQualifying}
                  />
                )}
              </div>
              <ResultsHeader
                testDriver={testDriver}
                page="Qualifying"
                typeRaces={championship.type_races}
                hasTeams={championship.has_teams}
              />
              <div className="session-drivers">
                {results.map((item: any, index: number) => {
                  if (classFilter != null && item.class != classFilter)
                    return <></>;
                  return (
                    <QualifyingRow
                      key={index}
                      driver={item}
                      testLap={testLap}
                      testDriver={testDriver}
                      activeDriver={activeDriver}
                      setActiveDriver={setActiveDriver}
                      session={session}
                      index={index}
                      championship={championship.championship_id}
                      total={results.length}
                      hasTeams={championship.has_teams}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Qualifying;
