import React, { useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

interface GeneralValues {
  simulators: { name: string; id: number }[];
  style_sheets: string[];
  teams: { [key: string]: any }[];
  users: { [key: string]: any }[];
  circuits: { [key: string]: any }[];
  flags: { [key: string]: any }[];
  scorings: { [key: string]: any }[];
}

const Championship = ({
  generalValues,
  inputChange,
  data,
}: {
  generalValues: GeneralValues | undefined;
  inputChange: ({ target }: { target: any }) => void;
  data: { [key: string]: string };
}) => {
  return (
    <>
      <h4 className="text-center backgrounded-title">
        {data.type_championship === "4" ? "Event" : "Championship"}
      </h4>
      <Row className="mb-3">
        <Form.Group className="form-group" as={Col} md="4">
          <Form.Label>Name (*)</Form.Label>
          <Form.Control
            name="name"
            type="text"
            value={data.name}
            required
            onChange={(e: any) => {
              inputChange(e);
            }}
          />
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="4">
          <Form.Label>Year (*)</Form.Label>
          <Form.Control
            name="year"
            type="number"
            value={data.year}
            required
            onChange={(e: any) => {
              inputChange(e);
            }}
          />
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="4">
          <Form.Label>Game (*)</Form.Label>
          <Form.Select
            name="game"
            value={data.game}
            required
            onChange={(e: any) => {
              inputChange(e);
            }}
          >
            <option value="" disabled>
              None
            </option>
            {generalValues?.simulators.map((simulator) => {
              return (
                <option key={simulator.id} value={simulator.id}>
                  {simulator.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="6">
          <Form.Label>Type (*)</Form.Label>
          <Form.Select
            name="type_championship"
            value={data.type_championship}
            required
            onChange={(e: any) => {
              inputChange(e);
            }}
          >
            <option value="1">LVF1 Championship</option>
            <option value="3">LVF1 Secondary Championship</option>
            <option value="4">LVF1 Event</option>
            <option value="2">External Championship</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="6">
          <Form.Label>Style Sheet (*)</Form.Label>
          <Form.Select
            name="styles_file"
            value={data.styles_file}
            required
            onChange={(e: any) => {
              inputChange(e);
            }}
          >
            <option value="" disabled>
              None
            </option>
            {generalValues?.style_sheets.map((sheet) => {
              return (
                <option key={sheet} value={sheet}>
                  {sheet}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        {data.type_championship !== "4" && (
          <>
            <Form.Group
              className="form-group"
              as={Col}
              md={parseInt(data.hot_lap_points) > 0 ? "3" : "3"}
            >
              <Form.Label>HotLap Points (*)</Form.Label>
              <Form.Control
                name="hot_lap_points"
                type="number"
                value={data.hot_lap_points}
                required
                onChange={(e: any) => {
                  inputChange(e);
                }}
              />
            </Form.Group>
            {parseInt(data.hot_lap_points) > 0 && (
              <Form.Group className="form-group" as={Col} md="3">
                <Form.Label>HotLap Minimum Pos. To Score (*)</Form.Label>
                <Form.Control
                  name="hot_lap_points_min_pos"
                  type="number"
                  value={data.hot_lap_points_min_pos}
                  required
                  onChange={(e: any) => {
                    inputChange(e);
                  }}
                />
              </Form.Group>
            )}

            <Form.Group
              className="form-group"
              as={Col}
              md={parseInt(data.hot_lap_points) > 0 ? "2" : "3"}
            >
              <Form.Label>Leaders Points (*)</Form.Label>
              <Form.Control
                name="leaders_points"
                type="number"
                value={data.leaders_points}
                required
                onChange={(e: any) => {
                  inputChange(e);
                }}
              />
            </Form.Group>
            <Form.Group
              className="form-group"
              as={Col}
              md={parseInt(data.hot_lap_points) > 0 ? "2" : "3"}
            >
              <Form.Label>Most Leader Points (*)</Form.Label>
              <Form.Control
                name="most_leader_points"
                type="number"
                value={data.most_leader_points}
                required
                onChange={(e: any) => {
                  inputChange(e);
                }}
              />
            </Form.Group>
          </>
        )}
        <Form.Group
          className="form-group"
          as={Col}
          md={parseInt(data.hot_lap_points) > 0 ? "2" : "3"}
        >
          <Form.Label>Finish Percentage (*)</Form.Label>
          <Form.Control
            name="finish_percentage"
            type="number"
            required
            value={data.finish_percentage}
            onChange={(e: any) => {
              inputChange(e);
            }}
          />
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="2">
          <Form.Label>
            Type Race{data.type_championship !== "4" ? "s" : ""} (*)
          </Form.Label>
          <Form.Select
            name="type_races"
            value={data.type_races}
            required
            onChange={(e: any) => {
              inputChange(e);
            }}
          >
            <option value="0">Normal</option>
            <option value="1">Time Trial</option>
            <option value="2">Endurance</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="2">
          <Form.Label>Team management (*)</Form.Label>
          <Form.Select
            name="has_teams"
            value={data.has_teams}
            required
            onChange={(e: any) => {
              inputChange(e);
            }}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="2">
          <Form.Label>
            Team classes (*)
            <span
              className="profile-bubble"
              data-tooltip="Case car drivers have different classes"
              data-flow="right"
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="label-icon" />
            </span>
          </Form.Label>
          <Form.Select
            name="has_classes"
            value={data.has_classes}
            required
            onChange={(e: any) => {
              inputChange(e);
            }}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Form.Select>
        </Form.Group>
        {data.type_championship !== "4" && (
          <>
            <Form.Group className="form-group" as={Col} md="3">
              <Form.Label>
                Top Scoreable Results
                <span
                  className="profile-bubble"
                  data-tooltip="If not null, the number of races (the best ones) which will score points on the championship for each driver."
                  data-flow="right"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="label-icon"
                  />
                </span>
              </Form.Label>
              <Form.Control
                name="top_scoreable_results"
                type="number"
                value={data.top_scoreable_results}
                onChange={(e: any) => {
                  inputChange(e);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="3">
              <Form.Label>
                Team Scoreable Drivers
                <span
                  className="profile-bubble"
                  data-tooltip="If not null, the number of drivers (the best ones) who will score points for the team on each race."
                  data-flow="right"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="label-icon"
                  />
                </span>
              </Form.Label>
              <Form.Control
                name="team_scoreable_drivers"
                type="number"
                value={data.team_scoreable_drivers}
                onChange={(e: any) => {
                  inputChange(e);
                }}
              />
            </Form.Group>
          </>
        )}
      </Row>
    </>
  );
};

export default Championship;
