import axios from "axios";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import LoadingContent from "../LoadingContent";
import { Link, useNavigate } from "react-router-dom";
import SmallNew from "../News/SmallNew";
import { i18n } from "../../App";

interface LastGP {
  grand_prix: {
    gp_name: string;
    circuit_id: number;
    date: string;
    flag_code: string;
    flag_name: string;
    circuit_name: string;
    gp_race_event_id: number;
    grand_prix_event_id: number;
    laps: number;
    championship: string | null;
  };
  race_results: RaceResult[];
}
interface New {
  title: string;
  image: string;
  id: string;
  content_es_html: string;
  content_en_html: string;
  championship: string | null;
}

const News = ({
  id,
  text,
  fullHeight,
}: {
  id: number;
  text: string;
  fullHeight: boolean;
}) => {
  document.title = "LVF1 - " + text;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lastGP, setLastGP] = useState<LastGP>();
  const [news, setNews] = useState<New[]>([]);
  const [newLastRace, setNewLastRace] = useState<New | null>();
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main/lastgp`,
        {
          params: { server: id, language: i18n.language },
        }
      )
      .then(({ data }) => {
        setLastGP(data);
        setNews(data.news);
        setNewLastRace(data.newLastRace);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [id, i18n.language]);

  if (lastGP == null) return <LoadingContent />;
  return (
    <div id="news-container" className={`flex ${fullHeight && "fullHeight"}`}>
      <div
        id="last-gp-container"
        className={`frame-turqoise`}
        onClick={() => {
          if (newLastRace == null)
            navigate(
              `${t("routes:GrandPrix")}/${encodeURIComponent(
                lastGP.grand_prix.gp_name.replace(/ /g, "")
              )}`
            );
          else
            navigate(
              `${t("routes:News")}/${
                newLastRace.championship == null
                  ? "LVF1"
                  : newLastRace.championship.replace(/ /g, "")
              }/${newLastRace.id}`
            );
        }}
      >
        <div>
          <h4>
            {newLastRace != null ? (
              newLastRace.title
            ) : (
              <Trans
                i18nKey="LastGPNewTitle"
                values={{
                  gp: lastGP.grand_prix.gp_name,
                  driver: lastGP.race_results[0].username,
                }}
                components={{
                  bold: <strong />,
                }}
              />
            )}
          </h4>
          <img
            src={
              newLastRace != null
                ? newLastRace.image
                : `/assets/ImagesGP/${lastGP.grand_prix.grand_prix_event_id}.jpg`
            }
          />
          <span>
            {newLastRace != null ? (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "es"
                        ? newLastRace.content_es_html
                        : newLastRace.content_en_html,
                  }}
                />
              </>
            ) : (
              <Trans
                i18nKey="LastGPNewDesc"
                values={{
                  gp: lastGP.grand_prix.gp_name,
                  driver: lastGP.race_results[0].username,
                  driver2: lastGP.race_results[1].username,
                  driver3: lastGP.race_results[2].username,
                  laps: lastGP.grand_prix.laps,
                }}
                components={{
                  bold: <strong />,
                }}
              />
            )}
          </span>
        </div>
      </div>
      <div id="individual-news-container">
        <div className="news-container">
          {news.map((new_, index) => {
            if (newLastRace == null || newLastRace.id !== new_.id)
              return <SmallNew key={index} {...new_} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default News;
