import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DateFormat from "../components/DateFormat";
import { DriverLink } from "../components/FlagLink";

const DriversLicenses = () => {
  const { t } = useTranslation();
  document.title = "LVF1 - " + t("DriversLicenses");
  const [rows, setRows] = useState<DriverLicenseRow[]>([]);
  //const [licenses, setLicenses] = useState<DriverLicense[]>();

  const getJSXPoints = (penalty: PointResult) => {
    if (
      penalty.value_penalty !== penalty.value_transformed &&
      penalty.value_transformed > 0
    ) {
      return (
        <b>
          <span className="strike">{penalty.value_penalty}</span>{" "}
          {penalty.value_transformed} pts{" "}
        </b>
      );
    } else {
      return <b>{penalty.value_penalty} pts </b>;
    }
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/driver/getLicenses`
      )
      .then(({ data }: { data: DriverLicense[] }) => {
        let rows_: DriverLicenseRow[] = [];
        data.forEach((driver: DriverLicense) => {
          rows_.push({
            driver: (
              <DriverLink
                flag_code={driver.flag_code}
                flag_name={driver.flag_name}
                name={driver.username}
                nickname={driver.username}
              />
            ),
            points: driver.points,
            points_history: (
              <div className="license-penalty-container">
                {driver.points_results.map((point_result) => {
                  return (
                    <span
                      className={`background-red ${
                        point_result.value_transformed === 0
                          ? "med-opacity"
                          : ""
                      }`}
                      title={point_result.name}
                    >
                      <Link
                        to={`${t(
                          "routes:Findings"
                        )}/${point_result.name.replace(/ /g, "")}`}
                      >
                        {getJSXPoints(point_result)}
                        (
                        <DateFormat
                          date={point_result.date}
                          format={"normal"}
                        />
                        )
                      </Link>
                    </span>
                  );
                })}
              </div>
            ),
          });
        });
        setRows(rows_);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  const columns = [
    {
      name: t("Drivers"),
      selector: (row: DriverLicenseRow) => row.driver,
      width: "250px",
    },
    {
      name: t("RemainingPoints"),
      selector: (row: DriverLicenseRow) => row.points,
      sortable: true,
      width: "220px",
    },
    {
      name: t("Penalties"),
      selector: (row: DriverLicenseRow) => row.points_history,
    },
  ];

  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="drivers-licenses" className="no-second-padding frame-turqoise">
        <h3 className="text-center frame-title">
          {t("DriversLicenses").toUpperCase()}
        </h3>
        <DataTable columns={columns} data={rows} />
      </div>
    </div>
  );
};

export default DriversLicenses;
