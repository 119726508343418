import { useTranslation } from "react-i18next";
import CalendarContent from "../../views/home/parts/CalendarContent";
import TopStandings from "../../views/home/parts/TopStandings";

const IndexFooter = ({
  id,
  text,
  hasTeams,
}: {
  id: number;
  text: string;
  hasTeams: boolean;
}) => {
  const { t } = useTranslation();
  document.title = "LVF1 - " + text;

  return (
    <div id="indexfooter-container">
      <div className="flex frame-turqoise">
        <h4 className="frame-title">
          {t("Calendar").toUpperCase()} & {t("Standings").toUpperCase()}
        </h4>
        <CalendarContent />
        <TopStandings id={id} text={text} hasTeams={hasTeams} />
      </div>
    </div>
  );
};

export default IndexFooter;
