import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

interface GP {
  [key: string]: {
    races_data: GPDataRaces[];
    gp_data: GPData;
  };
}

interface GPDataRaces {
  lapstime: string | null;
  valid_laps: string | null;
  scoring_variant_id: string | null;
  type_race: string | null;
  hot_lap_assignment_id: number | null;
  gp_race_event_id: number | null;
}

interface GPData {
  id: number;
  name: string;
  circuit_id: string;
  date: string;
  flag_id: string;
  initials: string;
  grand_prix_id: number | null;
  scoring_variant_id: number | null;
  type_races: number | null;
}

interface Circuit {
  id: number;
  name: string;
  layout: string | null;
}

interface Flag {
  id: number;
  name: string;
}

interface Scoring {
  id: number;
  description: string;
}

const UpdateGP = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Update GP";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const refPicture = useRef<any>(null);
  const [uploading, setUploading] = useState(false);
  const [validated, setValidated] = useState(false);

  const [selectedGP, setSelectedGP] = useState<GPData>();
  const [selectedGPRaces, setSelectedGPRaces] = useState<GPDataRaces[]>([]);

  const [grandsPrix, setGrandsPrix] = useState<GP>();
  const [circuits, setCircuits] = useState<Circuit[]>();
  const [flags, setFlags] = useState<Flag[]>();
  const [scoring, setScoring] = useState<Scoring[]>();

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getGrandsPrix`
      )
      .then(({ data }) => {
        const max_key = Object.keys(data.grands_prix).pop();
        if (max_key != null) {
          //We convert the date to the input format
          data.grands_prix[max_key].gp_data.date = getDateString(
            data.grands_prix[max_key].gp_data.date
          );
          setSelectedGP(data.grands_prix[max_key].gp_data);
          setSelectedGPRaces(data.grands_prix[max_key].races_data);
        }
        setGrandsPrix(data.grands_prix);
        setCircuits(data.circuits);
        setFlags(data.flags);
        setScoring(data.scoring);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  const getDateString = (date: string) => {
    let [date_] = new Date(date).toISOString().split("T");
    return date_;
  };
  const changeGPData = (event: ChangeEvent<HTMLSelectElement>) => {
    if (grandsPrix == null) return;
    const gp_data = Object.keys(grandsPrix).filter(
      (gp) => grandsPrix[gp].gp_data.id === parseInt(event.target.value)
    );
    if (gp_data != null) {
      grandsPrix[gp_data[0]].gp_data.date = getDateString(
        grandsPrix[gp_data[0]].gp_data.date
      );
      setSelectedGP(grandsPrix[gp_data[0]].gp_data);
      setSelectedGPRaces(grandsPrix[gp_data[0]].races_data);
    }
  };

  const inputChange = ({ target }: { target: any }, index?: number) => {
    const { name, value } = target;
    if (index != null) {
      if (selectedGPRaces != null) {
        setSelectedGPRaces((previous: GPDataRaces[]) =>
          previous.map((race: GPDataRaces, key: number) =>
            index !== key ? race : { ...race, [name]: value }
          )
        );
      }
    } else {
      if (selectedGP != null)
        setSelectedGP({
          ...selectedGP,
          [name]: value,
        });
    }
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/updateGP`,
          {
            selectedGP,
            selectedGPRaces,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(() => {
          toast.success("Grand Prix updated successfully");
          setUploading(false);
          setValidated(false);
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  const removeRace = (index: number) => {
    if (index !== -1) {
      setSelectedGPRaces([
        ...selectedGPRaces.slice(0, index),
        ...selectedGPRaces.slice(index + 1),
      ]);
    }
  };

  const newRace = () => {
    //If it is an event we set scoring_variant_id to null
    let scoring = null;
    if (selectedGP?.type_races !== 2) scoring = "";
    setSelectedGPRaces([
      ...selectedGPRaces,
      {
        lapstime: null,
        valid_laps: null,
        scoring_variant_id: scoring,
        type_race: null,
        hot_lap_assignment_id: null,
        gp_race_event_id: null,
      },
    ]);
  };
  if (!access) return <ErrorPage />;
  if (grandsPrix == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Update GP" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Grand Prix (*)</Form.Label>
              <Form.Select
                name="id"
                required
                value={selectedGP?.id}
                onChange={(e) => {
                  inputChange(e);
                  changeGPData(e);
                }}
              >
                {Object.keys(grandsPrix).map((gp: string) => {
                  return (
                    <option
                      key={grandsPrix[parseInt(gp)].gp_data.id}
                      value={grandsPrix[parseInt(gp)].gp_data.id}
                    >
                      {grandsPrix[parseInt(gp)].gp_data.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Name (*)</Form.Label>
              <Form.Control
                name="name"
                type="text"
                required
                onChange={(e) => inputChange(e)}
                value={selectedGP?.name}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Circuit (*)</Form.Label>
              <Form.Select
                name="circuit_id"
                required
                value={selectedGP?.circuit_id}
                onChange={(e) => {
                  inputChange(e);
                }}
              >
                {circuits?.map((circuit: Circuit) => {
                  return (
                    <option key={circuit.id} value={circuit.id}>
                      {circuit.name}{" "}
                      {circuit.layout != null && ` - (${circuit.layout})`}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Date (*)</Form.Label>
              <Form.Control
                name="date"
                type="date"
                required
                onChange={(e) => inputChange(e)}
                value={selectedGP?.date}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {selectedGP?.flag_id != null && ( //Case non event
            <Row className="mb-3">
              <Form.Group className="form-group" as={Col} md="4">
                <Form.Label>Flag (*)</Form.Label>
                <Form.Select
                  name="flag_id"
                  required
                  value={selectedGP?.flag_id}
                  onChange={(e) => {
                    inputChange(e);
                  }}
                >
                  {flags?.map((flag: Flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-group" as={Col} md="2">
                <Form.Label>Initials (*)</Form.Label>
                <Form.Control
                  name="initials"
                  type="text"
                  required
                  onChange={(e) => inputChange(e)}
                  value={selectedGP?.initials}
                />
                <Form.Control.Feedback type="invalid">
                  Field required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" as={Col} md="6">
                <Form.Label>Qualy Scoring</Form.Label>
                <Form.Select
                  name="scoring_variant_id"
                  value={
                    selectedGP?.scoring_variant_id == null
                      ? ""
                      : selectedGP?.scoring_variant_id
                  }
                  onChange={(e) => inputChange(e)}
                >
                  <option value="">None</option>
                  {scoring?.map((scoring: Scoring) => {
                    return (
                      <option key={scoring.id} value={scoring.id}>
                        {scoring.description}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Field required
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          )}
          <p className="race-upd-title">
            RACES
            <Button variant="secondary right" onClick={newRace}>
              Add new race
            </Button>
          </p>
          {selectedGPRaces?.map((gPRace, index) => {
            return (
              <Row key={index} className="mb-3">
                <Form.Group
                  className="form-group"
                  as={Col}
                  md={gPRace.scoring_variant_id != null ? "4" : "7"}
                >
                  <Form.Label>Type (*)</Form.Label>
                  <Form.Select
                    name="type_race"
                    required
                    value={gPRace.type_race == null ? "" : gPRace.type_race}
                    onChange={(e) => {
                      inputChange(e, index);
                    }}
                  >
                    <option value="" disabled>
                      None
                    </option>
                    <option value="1">1 (Main Race)</option>
                    <option value="2">2 (Sprint Race)</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Field required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group"
                  as={Col}
                  md={gPRace.scoring_variant_id != null ? "2" : "4"}
                >
                  <Form.Label>
                    {selectedGP?.type_races === 2 ? "Time" : "Laps"} (*)
                  </Form.Label>
                  <Form.Control
                    name="lapstime"
                    type="number"
                    required
                    onChange={(e) => inputChange(e, index)}
                    value={gPRace.lapstime == null ? "" : gPRace.lapstime}
                  />
                  <Form.Control.Feedback type="invalid">
                    Field required
                  </Form.Control.Feedback>
                </Form.Group>
                {selectedGP?.type_races == 1 && (
                  <Form.Group className="form-group" as={Col} md="2">
                    <Form.Label>Valid Laps (*)</Form.Label>
                    <Form.Control
                      name="valid_laps"
                      type="number"
                      required
                      onChange={(e) => inputChange(e, index)}
                      value={gPRace.valid_laps == null ? "" : gPRace.valid_laps}
                    />
                    <Form.Control.Feedback type="invalid">
                      Field required
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                {gPRace.scoring_variant_id != null && (
                  <Form.Group
                    className="form-group"
                    as={Col}
                    md={gPRace?.hot_lap_assignment_id == null ? "3" : "4"}
                  >
                    <Form.Label>Scoring (*)</Form.Label>
                    <Form.Select
                      name="scoring_variant_id"
                      required
                      value={
                        gPRace.scoring_variant_id == null
                          ? ""
                          : gPRace.scoring_variant_id
                      }
                      onChange={(e) => {
                        inputChange(e, index);
                      }}
                    >
                      <option value="" disabled>
                        None
                      </option>
                      {scoring?.map((scoring: Scoring) => {
                        return (
                          <option key={scoring.id} value={scoring.id}>
                            {scoring.description}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Field required
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                {gPRace?.hot_lap_assignment_id == null && (
                  <Form.Group
                    className="form-group trash-container"
                    as={Col}
                    md="1"
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="red"
                      onClick={() => removeRace(index)}
                    />
                  </Form.Group>
                )}
              </Row>
            );
          })}
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update GP
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateGP;
