import React from "react";
import { useTranslation } from "react-i18next";

const GrandPrixSectors = ({ sectors }: { sectors: any }) => {
  useTranslation();
  return (
    <>
      <div className="gp-sector text-center">
        <label>{sectors != null && sectors.s1 != "" && sectors.s1}</label>
      </div>
      <div className="gp-sector text-center">
        <label>{sectors != null && sectors.s2 != "" && sectors.s2}</label>
      </div>
      <div className="gp-sector text-center">
        <label>{sectors != null && sectors.s3 != "" && sectors.s3}</label>
      </div>
    </>
  );
};

export default GrandPrixSectors;
