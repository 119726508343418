import "../../../assets/style/header.css";
import "../../../utils/Header";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Navbar, Container, Nav, Form, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUser } from "../../../components/UserContext";
import Login from "./parts/Login";
import Logout from "./parts/Logout";
import Language from "./parts/Language";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { i18n } from "../../../App";
import Championship from "./parts/Championship";

const Header = ({
  handleTheme,
  updateNotifications,
}: {
  handleTheme: (theme: string) => void;
  updateNotifications: boolean;
}) => {
  const { t } = useTranslation();
  const [notificationEntries, setNotificationEntries] = useState<boolean[]>([]);
  const [notificationTransfers, setNotificationTransfers] = useState<boolean[]>(
    []
  );
  const [theme, setTheme] = useState("light");
  const [dataLoaded, setDataLoaded] = useState(false);
  const { user, setUser } = useUser() as any;
  const { pathname } = useLocation();
  const [type, setType] = useState(0);
  const [championship, setChampionship] = useState<string>(t("Championship"));
  const [championshipSec, setChampionshipSec] = useState("");
  const [event, setEvent] = useState("");
  const [mainChampActive, setMainChampActive] = useState(true);
  const [secChampActive, setSecChampActive] = useState(true);
  const [eventActive, setEventActive] = useState(true);
  const [config, setConfig] = useState({
    maintenance: false,
    show_main_transfers: false,
    show_secondary_transfers: false,
    show_secondary_champ: false,
    show_event: false,
    claims_over: "",
    main_race: [""],
    secondary_claims_over: "",
    secondary_race: [""],
  });
  const [collapsed, setCollapsed] = useState(false);

  const [showDropdownHistoric, setShowDropdownHistoric] = useState(false);
  const [secChampHasTeams, setSecChampHasTeams] = useState(false);
  const [showDropdownUser, setShowDropdownUser] = useState(false);
  const [eventHasTeams, setEventHasTeams] = useState(false);
  const index_pages = [t("routes:Home")];

  const [championshipPages, setChampionshipPages] = useState<string[]>([]);

  const [championshipSecPages, setChampionshipSecPages] = useState<string[]>(
    []
  );
  const [eventPages, setEventPages] = useState<string[]>([]);

  const historic_pages = [
    t("routes:Stats"),
    t("routes:Seasons"),
    t("routes:Nationalities"),
    t("routes:HistoricRaces"),
    t("routes:DidYouKnow"),
  ];

  const themeToggler = () => {
    let newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    window.localStorage.setItem("theme", newTheme);
    handleTheme(newTheme);
  };

  useEffect(() => {
    handleResize();
    const getTheme = async () => {
      try {
        let theme_storaged = window.localStorage.getItem("theme");
        if (theme_storaged !== null) {
          setTheme(theme_storaged);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDataLoaded(true);
      }
    };
    getTheme();

    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getCurrentChampionships`
      )
      .then(({ data }) => {
        const slash = t("routes:Home") === "/" ? "" : "/";
        setMainChampActive(data.main_champActive);
        setChampionship(data.main_champ.name);
        setChampionshipPages([
          t("routes:Drivers"),
          t("routes:Standings"),
          t("routes:RaceEntries"),
          t("routes:Downloads"),
          t("routes:Transfers"),
          t("routes:Regulation"),
          t("routes:Livetiming"),
          `${t("routes:News")}/${data.main_champ.name.replace(/ /g, "")}`,
          `${t("routes:Home")}${slash}${data.main_champ.name.replace(
            / /g,
            ""
          )}`,
        ]);

        setSecChampHasTeams(data.second_champ.titular_drivers === 1);
        setSecChampActive(data.second_champActive);
        setChampionshipSec(data.second_champ.name);
        setChampionshipSecPages([
          t("routes:Season") +
            "/" +
            encodeURIComponent(data.second_champ.name.replace(/ /g, "")),
          t("routes:RaceEntries") +
            "/" +
            encodeURIComponent(data.second_champ.name.replace(/ /g, "")),
          t("routes:Downloads") +
            "/" +
            encodeURIComponent(data.second_champ.name.replace(/ /g, "")),
          t("routes:Regulation") +
            "/" +
            encodeURIComponent(data.second_champ.name.replace(/ /g, "")),
          t("routes:Livetiming") +
            "/" +
            encodeURIComponent(data.second_champ.name.replace(/ /g, "")),
          t("routes:Drivers") +
            "/" +
            encodeURIComponent(data.second_champ.name.replace(/ /g, "")),
          t("routes:Transfers") +
            "/" +
            encodeURIComponent(data.second_champ.name.replace(/ /g, "")),

          `${t("routes:News")}/${encodeURIComponent(
            data.second_champ.name.replace(/ /g, "")
          )}/`,
          `${t("routes:Home")}${slash}${encodeURIComponent(
            data.second_champ.name.replace(/ /g, "")
          )}`,
        ]);

        setEventHasTeams(data.event.titular_drivers === 1);
        setEventActive(data.eventActive);
        setEvent(data.event.name);
        setEventPages([
          t("routes:Season") +
            "/" +
            encodeURIComponent(data.event.name.replace(/ /g, "")),
          t("routes:RaceEntries") +
            "/" +
            encodeURIComponent(data.event.name.replace(/ /g, "")),
          t("routes:Downloads") +
            "/" +
            encodeURIComponent(data.event.name.replace(/ /g, "")),
          t("routes:Regulation") +
            "/" +
            encodeURIComponent(data.event.name.replace(/ /g, "")),
          t("routes:Livetiming") +
            "/" +
            encodeURIComponent(data.event.name.replace(/ /g, "")),
          t("routes:Drivers") +
            "/" +
            encodeURIComponent(data.event.name.replace(/ /g, "")),
          t("routes:Transfers") +
            "/" +
            encodeURIComponent(data.event.name.replace(/ /g, "")),

          `${t("routes:News")}/${encodeURIComponent(
            data.event.name.replace(/ /g, "")
          )}/`,
          `${t("routes:Home")}${slash}${encodeURIComponent(
            data.event.name.replace(/ /g, "")
          )}`,
        ]);
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });

    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/general/config`
      )
      .then(({ data }) => {
        setConfig(data.config);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }, [i18n.language]);

  useEffect(() => {
    if (user != null)
      axios
        .get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/championship/checkUserListed`,
          {
            params: { user },
          }
        )
        .then(({ data }) => {
          setNotificationEntries(data.status);
          setNotificationTransfers(data.picker);
        })
        .catch(({ response }) => {
          //console.log(response);
          //throw new Error(response);
        });
  }, [updateNotifications, user, config]);

  useEffect(() => {
    const userLogged = window.localStorage.getItem("userLogged");
    if (userLogged !== null) {
      let user_logged = JSON.parse(userLogged);
      setUser(user_logged.username);
      setType(user_logged.type);
    }
  }, [user, type]);

  const handleResize = () => {
    if (window.innerWidth < 992) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Navbar
      collapseOnSelect
      variant="dark"
      expand="lg"
      style={{
        position: pathname === t("routes:Home") ? "absolute" : "fixed",
      }}
      className={pathname === t("routes:Home") ? "navbar-home" : "navbar-fixed"}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to={t("routes:Home")}>
          LV<span className="turquoise">F1</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link
              as={Link}
              to={t("routes:Home")}
              className={
                index_pages.includes(window.location.pathname)
                  ? "active-menu"
                  : ""
              }
              href="#"
            >
              {t("Home")}
            </Nav.Link>
            <Championship
              typeChamp={1}
              isActive={mainChampActive}
              name={championship}
              notiEntries={notificationEntries[0]}
              notiTransfers={notificationTransfers[0]}
              showTransfersMenu={config.show_main_transfers}
              collapsed={collapsed}
              championshipPages={championshipPages}
              showTeams={true}
            />
            {!config.show_secondary_champ ? (
              <></>
            ) : (
              <Championship
                typeChamp={3}
                isActive={secChampActive}
                name={championshipSec}
                notiEntries={notificationEntries[1]}
                notiTransfers={notificationTransfers[1]}
                showTransfersMenu={config.show_secondary_transfers}
                collapsed={collapsed}
                championshipPages={championshipSecPages}
                showTeams={secChampHasTeams}
              />
            )}
            {!config.show_event ? (
              <></>
            ) : (
              <Championship
                typeChamp={4}
                isActive={eventActive}
                name={event}
                notiEntries={notificationEntries[2]}
                notiTransfers={notificationTransfers[2]}
                showTransfersMenu={false}
                collapsed={collapsed}
                championshipPages={eventPages}
                showTeams={eventHasTeams}
              />
            )}

            <NavDropdown
              title={t("Historic")}
              className={
                historic_pages.includes(window.location.pathname)
                  ? "active-menu"
                  : ""
              }
              show={collapsed ? undefined : showDropdownHistoric}
              onMouseEnter={() => {
                !collapsed && setShowDropdownHistoric(true);
              }}
              onMouseLeave={() => {
                !collapsed && setShowDropdownHistoric(false);
              }}
            >
              <NavDropdown.Item
                as={Link}
                to={t("routes:Stats")}
                href="#"
                onClick={() => setShowDropdownHistoric(false)}
              >
                {t("Statistics")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={t("routes:Seasons")}
                href="#"
                onClick={() => setShowDropdownHistoric(false)}
              >
                {t("Seasons")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={t("routes:Nationalities")}
                href="#"
                onClick={() => setShowDropdownHistoric(false)}
              >
                {t("Nationalities")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={t("routes:HistoricRaces")}
                href="#"
                onClick={() => setShowDropdownHistoric(false)}
              >
                {t("Races")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={t("routes:DidYouKnow")}
                href="#"
                onClick={() => setShowDropdownHistoric(false)}
              >
                {t("DidYouKnow")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="d-flex flex-center">
            <Form>
              {dataLoaded && (
                <div
                  id="switch-container"
                  className="flex"
                  title={t("DarkLightMode")}
                >
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    onClick={() => themeToggler()}
                    defaultChecked={theme === "dark"}
                  />
                </div>
              )}
            </Form>
            <Language collapsed={collapsed} />
            {user === null ? (
              <Login toast={toast} />
            ) : (
              <>
                <NavDropdown
                  title={user}
                  id="user-menu"
                  show={collapsed ? undefined : showDropdownUser}
                  onMouseEnter={() => {
                    !collapsed && setShowDropdownUser(true);
                  }}
                  onMouseLeave={() => {
                    !collapsed && setShowDropdownUser(false);
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to={t("routes:EditProfile")}
                    href="#"
                    onClick={() => setShowDropdownUser(false)}
                  >
                    {t("EditProfile")}
                  </NavDropdown.Item>
                  {type > 1 && (
                    <NavDropdown.Item
                      as={Link}
                      to="/Admin"
                      href="#"
                      onClick={() => setShowDropdownUser(false)}
                    >
                      Admin
                    </NavDropdown.Item>
                  )}
                  <Logout toast={toast} />
                </NavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
