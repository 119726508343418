import React from "react";
import DataTable, { ConditionalStyles } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Flag from "../../../components/Flag";
import GrandPrixTyre from "../../../components/GrandPrix/GrandPrixTyre";
import { getColor, teamLogo, teamBackground } from "../../../utils/Standings";

const FastestLaps = ({
  fastest_laps,
  time_107,
  server_id,
  typeRaces,
}: {
  fastest_laps: FastestLap[];
  time_107: string | null;
  server_id: number;
  typeRaces: number;
}) => {
  const { t } = useTranslation();
  const isAssetto =
    fastest_laps.length > 0 &&
    fastest_laps[0].hotlap_topspeed_converted === "-1.0 km/h";
  const noSectors = isAssetto && typeRaces === 1;
  const rows: FastestLap[] = [];

  // Define las columnas comunes
  const commonColumns = [
    {
      name: "#",
      selector: (row: FastestLap) => row.pos,
      sortable: true,
      width: "65px",
    },
    {
      name: t("Driver"),
      selector: (row: FastestLap) => row.driverJSX,
      width: "180px",
    },
    {
      name: t("HotLap"),
      selector: (row: FastestLap) => row.fastest_lap_converted,
      width: "140px",
      sortFunction: (a: FastestLap, b: FastestLap) =>
        a.fastest_lap - b.fastest_lap,
    },
    {
      name: t("Gap"),
      selector: (row: FastestLap) => row.gap,
      width: "110px",
    },
  ];

  // Agregar columnas específicas según las condiciones
  let specificColumns: any[] = [];
  if (isAssetto && noSectors) {
    specificColumns = [
      {
        name: t("Laps"),
        selector: (row: FastestLap) => row.laps,
        sortable: true,
        width: "110px",
      },
    ];
  } else {
    specificColumns = [
      {
        name: t("Sector") + " 1",
        selector: (row: FastestLap) => row.sectors_converted.s1,
        width: "120px",
        sortFunction: (a: FastestLap, b: FastestLap) => a.s1 - b.s1,
      },
      {
        name: t("Sector") + " 2",
        selector: (row: FastestLap) => row.sectors_converted.s2,
        width: "120px",
        sortFunction: (a: FastestLap, b: FastestLap) => a.s2 - b.s2,
      },
      {
        name: t("Sector") + " 3",
        selector: (row: FastestLap) => row.sectors_converted.s3,
        width: "120px",
        sortFunction: (a: FastestLap, b: FastestLap) => a.s3 - b.s3,
      },
      {
        name: t("Laps"),
        selector: (row: FastestLap) => row.laps,
        sortable: true,
        width: "110px",
      },
    ];

    if (!isAssetto) {
      specificColumns.push(
        {
          name: t("TopSpeed"),
          selector: (row: FastestLap) => row.hotlap_topspeed_converted,
          sortable: true,
          width: "120px",
        },
        {
          name: t("Tyres"),
          selector: (row: FastestLap) => row.tiresJSX,
          width: "120px",
        }
      );
    }
  }

  // Combinar columnas comunes y específicas
  const columns = [...commonColumns, ...specificColumns];

  const has_classes =
    fastest_laps.find((fl) => fl.class_name != null && fl.class_name != "") !=
    null;
  if (has_classes)
    columns.splice(2, 0, {
      name: t("Class"),
      selector: (row: FastestLap) => row.classJSX,
      sortFunction: (a: FastestLap, b: FastestLap) => {
        const aValue = a.class_catalog || Number.POSITIVE_INFINITY;
        const bValue = b.class_catalog || Number.POSITIVE_INFINITY;

        return aValue - bValue;
      },
      width: "100px",
    });

  const getDriverNameLink = (driver: FastestLap) => {
    return (
      <Link
        className="full-white-driver"
        to={`${t("routes:Driver")}/${encodeURIComponent(driver.hotlap_name)}`}
        style={{
          color: getColor(driver.team_id, driver.main_color),
        }}
      >
        {driver.hotlap_name}
      </Link>
    );
  };

  {
    fastest_laps.map((driver, index) => {
      rows.push({
        ...driver,
        pos: index + 1,
        classJSX:
          driver.class_color == null ? (
            <></>
          ) : (
            <label
              className="class_livetiming"
              style={{ background: driver.class_color }}
            >
              {driver.class_name?.toUpperCase()}
            </label>
          ),
        tiresJSX: <GrandPrixTyre tyre={driver.hotlap_tire} />,
        driverJSX: (
          <div
            className="teamdriver-results-container text-left"
            style={{
              background: teamBackground(driver.team_id, driver.main_color),
            }}
          >
            <div className="logo-results-container flex-center">
              {driver.team_id == null &&
              driver.code != null &&
              driver.flag_name != null ? (
                <Flag flag_code={driver.code} name={driver.flag_name} />
              ) : (
                teamLogo(driver.team_id, driver.logo, driver.name)
              )}
            </div>
            {getDriverNameLink(driver)}
          </div>
        ),
      });
      driver.style = { background: "red" };
    });
  }

  const conditionalRowStyles = [
    {
      when: () => true,
      style: (row: FastestLap) => ({
        background: row.inside107
          ? getColorTheme(true, false)
          : getColorTheme(true, true),
        color: row.inside107
          ? getColorTheme(false, false)
          : getColorTheme(false, true),
      }),
    },
  ];

  const getColorTheme = (isBackground: boolean, isRed: boolean) => {
    const theme = window.localStorage.getItem("theme");
    if (!isRed || server_id !== 1) {
      if (theme === "dark" && isBackground) return "#242424";
      else if (theme === "dark" && !isBackground) return "#fffafa";
      else if (theme !== "dark" && isBackground) return "#f5f5f5";
      else if (theme !== "dark" && !isBackground) return "#101010";
    } else if (isRed) {
      if (theme === "dark" && isBackground) return "#930d0d";
      else if (theme === "dark" && !isBackground) return "#ffffff";
      else if (theme !== "dark" && isBackground) return "#f53b3b";
      else if (theme !== "dark" && !isBackground) return "#000000";
    }
  };
  return (
    <div className="flex">
      <div className="best-times-standings">
        <DataTable
          columns={columns}
          data={rows}
          conditionalRowStyles={conditionalRowStyles}
        />
        {server_id == 1 && time_107 != "" && (
          <div id="label-107">
            <label style={{ background: getColorTheme(true, true) }}>
              107% Time: {time_107}
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default FastestLaps;
