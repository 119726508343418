import React from "react";
import { Trans, useTranslation } from "react-i18next";
import CircuitLayout from "../CircuitLayout";
import "../../assets/style/raceentries.css";
import ReactWeather, { useVisualCrossing } from "react-open-weather";
import Flag from "../Flag";
import DateFormat from "../DateFormat";
import { i18n } from "../../App";
import LapsInfo from "../LapsInfo";

interface CircuitDataWeather {
  flag_code?: string;
  flag_name?: string;
  gp_name: string;
  circuit_id: number;
  gp_race_event_id: number;
  circuit_name: string;
  lat: number;
  lon: number;
  race_data: {
    laps: number;
    valid_laps: number;
    type_race: number;
    time: number;
  }[];
  dates: string[];
}

const CircuitDataWeather = ({
  typeRaces,
  circuitData,
  hasSlots,
  typeChamp,
}: {
  typeRaces: number;
  circuitData: CircuitDataWeather;
  hasSlots: boolean;
  typeChamp: number;
}) => {
  const { t } = useTranslation();
  const { data, isLoading, errorMessage } = useVisualCrossing({
    key: "QEFSXSYUZ747W5VAMJZP4HUKC",
    lat: circuitData.lat,
    lon: circuitData.lon,
    lang: i18n.language,
    unit: "metric", // values are (metric, standard, imperial)
  });

  //Values are shown only on deploy mode!
  const customStyles = {
    fontFamily: "Helvetica, sans-serif",
    gradientStart: "#0a8489",
    gradientMid: "#18ced5",
    gradientEnd: "#18ced5",
    locationFontColor: "#FFF",
    todayTempFontColor: "#FFF",
    todayDateFontColor: "#ebebeb",
    todayRangeFontColor: "#ebebeb",
    todayDescFontColor: "#ebebeb",
    todayInfoFontColor: "#ebebeb",
    todayIconColor: "#FFF",
    forecastBackgroundColor: "#FFF",
    forecastSeparatorColor: "#DDD",
    forecastDateColor: "#777",
    forecastDescColor: "#777",
    forecastRangeColor: "#777",
    forecastIconColor: "#18ced5",
  };

  return (
    <div id="race-entries-left">
      <h4 className="text-center">
        {circuitData.flag_code != null && circuitData.flag_name && (
          <>
            <Flag
              flag_code={circuitData.flag_code}
              name={circuitData.flag_name}
            />{" "}
          </>
        )}
        {circuitData.gp_name.toUpperCase()}
      </h4>
      {!(hasSlots != null && typeChamp === 3) && (
        <small id="date-entry" className="text-center flex-center">
          <b>
            {circuitData.dates.length > 1 ? t("Dates") : t("Date")}
            {` (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}:
          </b>{" "}
          {
            <DateFormat
              date={circuitData.dates}
              format={"full"}
              disaggregated={true}
            />
          }
        </small>
      )}
      <LapsInfo circuitData={circuitData} />
      <div id="entries-circuit-container">
        {
          <CircuitLayout
            circuit_id={circuitData.circuit_id}
            id_canvas={`circuit-${circuitData.gp_race_event_id}`}
          />
        }
      </div>
      {typeRaces !== 1 && (
        <div id="weather-container" className="flex flex-center">
          <ReactWeather
            theme={customStyles}
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            locationLabel={circuitData.circuit_name}
            unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
            showForecast
            lang={i18n.language}
          />
        </div>
      )}
    </div>
  );
};

export default CircuitDataWeather;
