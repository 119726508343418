import React from "react";
import Flag from "../Flag";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DriverSession as DriverR } from "../../views/grand-prix/race/Race.types";
import { DriverSession as DriverQ } from "../../views/grand-prix/qualifying/Qualifying.types";

const GrandPrixDriver = ({
  driver,
}: {
  driver: DriverR[] | DriverQ[] | DriverR | DriverQ;
}) => {
  const { t } = useTranslation();
  if (!Array.isArray(driver)) driver = [driver];

  const getDriverLinkJSX = (driver: DriverR | DriverQ) => {
    if (driver.driver_flag === "unk" || driver.driver_flag === null)
      return <span>{driver.name}</span>; //case external event
    else if (driver.hasOwnProperty("nickname") && driver.nickname != null)
      //case external event with known driver
      return (
        <Link
          to={`${t("routes:Driver")}/${encodeURIComponent(driver.nickname)}`}
        >
          {driver.name}{" "}
          {driver.hasOwnProperty("penalty") && driver.penalty != null && (
            <span className="driver-penalty">[{driver.penalty}]</span>
          )}
        </Link>
      );
    return (
      <Link to={`${t("routes:Driver")}/${encodeURIComponent(driver.name)}`}>
        {driver.name}{" "}
        {driver.hasOwnProperty("penalty") && driver.penalty != null && (
          <span className="driver-penalty">[{driver.penalty}]</span>
        )}
      </Link>
    );
  };
  return (
    <div className="gp-driver">
      {driver.map((driver_) => {
        return (
          <div className="gp-driver-container">
            <div
              className="bar-team"
              style={{ background: driver_.main_color }}
            ></div>
            {getDriverLinkJSX(driver_)}
            <div className="driver-flag-container">
              <Flag
                flag_code={driver_.driver_flag}
                name={driver_.driver_flag_name}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GrandPrixDriver;
