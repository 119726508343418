import axios from "axios";
import { useEffect, useState } from "react";
import CountDown from "../../views/home/parts/nextgp/parts/CountDown";
import CircuitLayout from "../CircuitLayout";
import "../../assets/style/champ_index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import DateFormat from "../DateFormat";
import LapsInfo from "../LapsInfo";
import LoadingContent from "../LoadingContent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  getSessionData,
  getSessionName,
  getShortDriverName,
  timeOrLaps,
} from "../../utils/Livetiming";
import useInterval from "../../utils/custom-hooks/useInterval";
import ServerWeather from "../LiveTiming/ServerWeather";
import { io } from "socket.io-client";

const NextGPChampionship = ({
  id,
  text,
  setFullHeight,
}: {
  id: number;
  text: string;
  setFullHeight: (fullHeight: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [raceData, setRaceData] = useState<RaceEntries | null>();
  const [dates, setDates] = useState([]);
  const [serverData, setServerData] = useState<ServerData | null>();
  const navigate = useNavigate();
  document.title = "LVF1 - " + text;

  useEffect(() => {
    setLoading(true);
    setRaceData(null);
    setServerData(null);
    setFullHeight(false);
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getEntryListData`,
        {
          params: {
            champ: text,
          },
        }
      )
      .then(({ data }) => {
        setDates(
          id === 1
            ? data.config.main_race
            : id === 3
            ? data.config.secondary_race
            : data.config.event_race
        );
        setRaceData(data);

        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        setFullHeight(false);
        //throw new Error(response);
      });
  }, [id]);

  const goRaceEntries = () => {
    if (id == 1) navigate(t("routes:RaceEntries"));
    else
      navigate(
        t("routes:RaceEntries") +
          "/" +
          encodeURIComponent(text.replace(/ /g, ""))
      );
  };

  const goLiveTiming = () => {
    if (id == 1) navigate(t("routes:Livetiming"));
    else
      navigate(
        t("routes:Livetiming") +
          "/" +
          encodeURIComponent(text.replace(/ /g, ""))
      );
  };

  useEffect(() => {
    // Conectar al servidor de Socket.io
    const newSocket = io(
      `${
        process.env.NODE_ENV === "development"
          ? "http://localhost:5500"
          : "https://ligavirtualf1.es:5500"
      }`
    );

    // Escuchar el evento 'dataUpdate' para recibir los datos actualizados
    newSocket.on(`dataUpdate`, (data_general: any) => {
      const server_id = id === 3 ? 2 : id;
      if (data_general == null) return;
      const data: any = getSessionData(data_general, server_id);
      setServerData(data);
    });

    // Cleaning: disconnect the socket when the component is disassembled.
    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [id]);

  if (loading) return <LoadingContent />;
  if (raceData == null || serverData == null)
    return <div className="nextGP-empty"></div>;
  return (
    <div className="nextGP">
      <div>
        <div id="circuit-info-container">
          <div id="track-container">
            <div id="next-gp-container">
              {raceData.nextgp.circuit_id !== null && (
                <CircuitLayout
                  circuit_id={raceData.nextgp.circuit_id}
                  id_canvas={`circuit-${raceData.nextgp.gp_race_event_id}`}
                />
              )}
            </div>
          </div>
          <div id="name-countdown-container">
            <DateFormat date={dates} format={"short"} />
            <h4>{raceData.nextgp.gp_name.toUpperCase()}</h4>
          </div>
          <div id="homecarousel-container">
            <Carousel
              infiniteLoop
              autoPlay
              interval={5000}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              selectedItem={serverData.server_live.length === 0 ? 0 : 1}
            >
              <div>
                <div className="slider-1">
                  <label>{t("Race").toUpperCase()}</label>
                  <LapsInfo
                    circuitData={{
                      flag_code: raceData.nextgp.flag_code,
                      flag_name: raceData.nextgp.flag_name,
                      gp_name: raceData.nextgp.gp_name,
                      circuit_id: raceData.nextgp.circuit_id,
                      gp_race_event_id: raceData.nextgp.gp_race_event_id,
                      circuit_name: raceData.nextgp.circuit_name,
                      lat: raceData.nextgp.lat,
                      lon: raceData.nextgp.lon,
                      race_data: raceData.race_laps,
                      dates: dates,
                    }}
                  />
                  {raceData.nextgp.date.length > 0 && (
                    <CountDown date={dates} />
                  )}
                </div>
                <button
                  className="buttonDivisorRight btn-hover color-5"
                  onClick={() => {
                    goRaceEntries();
                  }}
                >
                  <span>{t("Register")}!</span>
                </button>
              </div>
              <div>
                <div className="slider-2 flex">
                  <div>
                    <span>
                      {getSessionName(
                        serverData.server_timing.trackdata_session
                      )}
                    </span>
                    <span>
                      {
                        timeOrLaps(
                          serverData.server_timing,
                          serverData.server_live
                        )
                          .split(", ")[1]
                          .split(")")[0]
                      }
                    </span>
                  </div>
                  <div>
                    <div className="medium-border-left"></div>
                    <label className="flex live-container">
                      <span className="small-text">
                        LIVE
                        <div>
                          <div className="icon-container"></div>
                          <FontAwesomeIcon icon={faCircle} />
                        </div>
                      </span>
                      <span className="weather">
                        <ServerWeather
                          serverTiming={serverData.server_timing}
                        />
                      </span>
                    </label>
                    <div className="div-padd">
                      {serverData.server_live.length === 0 ? (
                        <small>{t("NoDriversOnline")}</small>
                      ) : (
                        serverData.server_live
                          .sort((a, b) => a.live_place - b.live_place)
                          .slice(0, 3)
                          .map((driver, index) => {
                            return (
                              <div
                                className="driver-live-container"
                                key={index}
                              >
                                <span className="pos">{driver.live_place}</span>
                                <span
                                  className="bar-color"
                                  style={{ background: driver.main_color }}
                                ></span>
                                <span className="driver-name">
                                  {getShortDriverName(
                                    driver.live_name
                                  ).toUpperCase()}
                                </span>

                                <span className="driver-time">
                                  {driver.live_bestLapTimeConverted == ""
                                    ? "-"
                                    : driver.live_bestLapTimeConverted}
                                </span>
                              </div>
                            );
                          })
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="buttonDivisorRight btn-hover color-5"
                  onClick={() => {
                    goLiveTiming();
                  }}
                >
                  <span>{t("Livetiming")}</span>
                </button>
              </div>
              <div>
                <div className="slider-3">
                  <span id="title-fastestlaps">
                    {t("HotLaps").toUpperCase()}
                  </span>
                  <div className="div-padd">
                    {serverData.fastest_laps.length === 0 ? (
                      <small className="nofastestlaps">
                        {t("NoFastestLaps")}
                      </small>
                    ) : (
                      serverData.fastest_laps.slice(0, 3).map((driver, i) => {
                        return (
                          <div className="driver-live-container" key={i}>
                            <span className="pos">{i + 1}</span>
                            <div className="div-hl-driver">
                              <span
                                className="bar-color"
                                style={{ background: driver.main_color }}
                              ></span>
                              <span className="driver-name">
                                {getShortDriverName(
                                  driver.hotlap_name
                                ).toUpperCase()}
                              </span>
                              <div
                                className="team-logo"
                                style={{ background: driver.main_color }}
                              >
                                {driver.team_id !== null && (
                                  <img
                                    src={`/assets/Teams/${driver.team_id}${
                                      driver.logo != null
                                        ? `_${driver.logo}`
                                        : ``
                                    }.png`}
                                  />
                                )}
                              </div>
                            </div>
                            <span className="driver-time">
                              {driver.fastest_lap_converted == ""
                                ? "-"
                                : driver.fastest_lap_converted}
                            </span>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
                <button
                  className="buttonDivisorRight btn-hover color-5"
                  onClick={() => {
                    goLiveTiming();
                  }}
                >
                  <span>{t("Livetiming")}</span>
                </button>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NextGPChampionship;
