import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const ChangeLivetimingUsername = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Change Livetiming Username";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [postData, setPostData] = useState({
    driver: "",
    new_driver_name: "",
  });
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState<{
    drivers: { live_name: string }[];
  }>();

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setPostData({
      ...postData,
      [name]: value,
    });
  };

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/updateLivetimingDriver`,
          {
            postData,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(() => {
          toast.success("Livetiming driver updated successfully");
          setUploading(false);
          setValidated(false);
          setPostData({ ...postData, driver: "", new_driver_name: "" });
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setUploading(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/driver/getLivetimingDrivers`
      )
      .then(({ data }) => {
        setData(data);
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });
  }, []);

  if (!access) return <ErrorPage />;
  if (data == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Change Livetiming Username" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Old Driver Name (*)</Form.Label>
              <Form.Select
                name="driver"
                required
                value={postData.driver}
                onChange={(e: any) => {
                  inputChange(e);
                }}
              >
                <option value="">None</option>
                {data?.drivers.map((driver) => {
                  return (
                    <option value={driver.live_name} key={driver.live_name}>
                      {driver.live_name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>New Driver Name</Form.Label>
              <Form.Control
                name="new_driver_name"
                type="text"
                onChange={(e) => inputChange(e)}
                value={postData.new_driver_name}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="text-center">
            <small className="mandatory-fields red">
              Leaving the new name empty will delete the driver entries!
            </small>
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update Livetiming Driver Name
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChangeLivetimingUsername;
