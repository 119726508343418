import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { i18n } from "../../App";
import SmallNew from "./SmallNew";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const CarouselNews = ({ id }: { id: number }) => {
  useEffect(() => {
    setTimeout(() => {
      if (window.innerWidth < 600) {
        var arrow: any = document.getElementsByClassName("slick-arrow")[0];
        arrow.click();
      }
    }, 500);
  }, []);

  const { t } = useTranslation();
  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  const [news, setNews] = useState<
    {
      title: string;
      image: string;
      id: string;
      championship: string | null;
    }[]
  >([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/news/getSmallNews`,
        {
          params: { id, language: i18n.language },
        }
      )
      .then(({ data }) => {
        setNews(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [i18n.language]);

  return (
    <div className="carousel-content">
      <h4>{t("MoreNews")}...</h4>
      <Slider {...settings}>
        {news.map((new_, index) => {
          return <SmallNew key={index} {...new_} />;
        })}
      </Slider>
    </div>
  );
};

export default CarouselNews;
