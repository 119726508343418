import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophoneLines,
  faChartColumn,
  faRectangleList,
  faSquareShareNodes,
  faMedal,
  faGamepad,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const WebOverview = () => {
  const { t } = useTranslation();
  return (
    <div id="main-info" className="flex-center">
      <div id="info-container">
        <div className="row">
          <div className="col-xl-4 col-sm-12 reveal">
            <p>
              <FontAwesomeIcon icon={faMedal} />{" "}
              {t("WeAreHistory").toUpperCase()}
            </p>
            <span>{t("WeAreHistoryText")}</span>
          </div>
          <div className="col-xl-4 col-sm-12 reveal">
            <p>
              <FontAwesomeIcon icon={faGamepad} /> RFACTOR 2
            </p>
            <span>{t("SimulatorText")}</span>
          </div>
          <div className="col-xl-4 col-sm-12 reveal">
            <p>
              <FontAwesomeIcon icon={faSquareShareNodes} />{" "}
              {t("SocialMedia").toUpperCase()}
            </p>
            <span>{t("SocialMediaText")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-sm-12 reveal">
            <p>
              <FontAwesomeIcon icon={faMicrophoneLines} /> STREAMINGS
            </p>
            <span>{t("StreamingsText")}</span>
          </div>
          <div className="col-xl-4 col-sm-12 reveal">
            <p>
              <FontAwesomeIcon icon={faRectangleList} />{" "}
              {t("Variety").toUpperCase()}
            </p>
            <span>{t("VarietyText")}</span>
          </div>
          <div className="col-xl-4 col-sm-12 reveal">
            <p>
              <FontAwesomeIcon icon={faChartColumn} />{" "}
              {t("Statistics").toUpperCase()}
            </p>
            <span>{t("StatisticsText")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebOverview;
