import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const WebData = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    years: "XX",
    championships: "XX",
    races: "XX",
    drivers: "XX",
  });
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main`
      )
      .then(({ data }) => {
        setData(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  return (
    <div id="main-data" className="space-around">
      <div className="reveal">
        <span>{data.years}</span>
        <span>{t("Years").toLowerCase()}</span>
      </div>
      <div className="reveal">
        <span>{data.championships}</span>
        <span>{t("Championships").toLowerCase()}</span>
      </div>
      <div className="reveal">
        <span>{data.races}</span>
        <span>{t("Races").toLowerCase()}</span>
      </div>
      <div className="reveal">
        <span>{data.drivers}</span>
        <span>{t("Drivers").toLowerCase()}</span>
      </div>
    </div>
  );
};

export default WebData;
