import { Dispatch, SetStateAction, useEffect } from "react";

const ClassFilter = ({
  classes,
  classFilter,
  setClassFilter,
  race,
  setRace,
  grand_prix_name,
}: {
  classes: { class: string; color: string }[];
  classFilter: string | null;
  setClassFilter: Dispatch<SetStateAction<string | null>>;
  race: {
    [key: string]: string | number;
  };
  setRace: Dispatch<SetStateAction<{ [key: string]: string | number }>>;
  grand_prix_name?: string;
}) => {
  useEffect(() => {
    const updatedCarreras = Object.keys(race).reduce((acc: any, key) => {
      acc[key] = assignPositions(race[key]);
      return acc;
    }, {});
    setRace(updatedCarreras);
  }, [grand_prix_name, classFilter]);

  const assignPositions = (race: any) => {
    let contador = 1;
    return race.map((subArray: any) => {
      //CASE RACE (DIFFERENT FORMAT)
      if (grand_prix_name == null) {
        if (classFilter != null && subArray.class === classFilter) {
          return { ...subArray, pos2: contador++ };
        } else {
          return { ...subArray, pos2: null };
        }
      } else {
        return subArray.map((piloto: any) => {
          if (classFilter != null && piloto.class === classFilter) {
            return { ...piloto, pos2: contador++ };
          } else {
            return { ...piloto, pos2: null };
          }
        });
      }
    });
  };

  return (
    <div className="class-filter flex-center">
      {classes.map((class_: any) => {
        return (
          <div className="gp-sector flex-center text-center">
            <label
              className={`class_content clickable-class ${
                classFilter != null && classFilter !== class_.class
                  ? "med-opacity"
                  : ""
              }`}
              style={{ background: class_.color }}
              onClick={() => {
                if (classFilter === class_.class) setClassFilter(null);
                else setClassFilter(class_.class);
              }}
            >
              {class_.class.toUpperCase()}
            </label>
          </div>
        );
      })}
    </div>
  );
};

const getClassArray = (results: any) => {
  let classArray = results.map((result: any) => ({
    class: result[0].class,
    color: result[0].class_color,
  }));
  classArray = [
    ...new Map(
      classArray.map((item: any) => [JSON.stringify(item), item])
    ).values(),
  ];
  return classArray;
};

const getClassArraySimple = (results: any) => {
  let classArray = results.map((result: any) => ({
    class: result.class,
    color: result.class_color,
  }));
  classArray = [
    ...new Map(
      classArray.map((item: any) => [JSON.stringify(item), item])
    ).values(),
  ];
  return classArray;
};

export { ClassFilter, getClassArray, getClassArraySimple };
