import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import LoadingContent from "../../../components/LoadingContent";
import { toast } from "react-toastify";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

interface Assignment {
  team_number: number;
  team_championship_id: number;
  assignment_id: number;
  number: number;
  username: string;
  main_color: string;
  secondary_color: string;
  team_name: string;
}

const UpdateDriver = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Titular Drivers";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [assignments, setAssignments] = useState<
    {
      team_championship_id: number;
      drivers: Assignment[];
    }[]
  >([]);
  const [totalAssignments, setTotalAssignments] = useState<
    {
      username: string;
      assignment_id: number;
      team_number: number;
      team_championship_id: number;
    }[]
  >([]);
  const [typeChampionship, setTypeChampionship] = useState(1);
  const [update, setUpdate] = useState(true);

  const [changes, setChanges] = useState<{ [key: string]: number }>();
  useEffect(() => {
    setTotalAssignments([]);
    setAssignments([]);
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/driver/getCurrentAssignments`,
        { params: { type_championship: typeChampionship } }
      )
      .then(({ data }) => {
        setTotalAssignments(data.assignments);
        setAssignments(data.final_titular_drivers);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [typeChampionship, update]);

  const submitForm = (event: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/updateTitularDrivers`,
        {
          changes,
          type_championship: typeChampionship,
          user,
          token: JSON.parse(token).token,
        }
      )
      .then(() => {
        toast.success("Titular drivers updated successfully");
        setUpdate(!update);
        setChanges(undefined);
      })
      .catch(({ response }) => {
        toast.error(response.data.message);
        throw new Error(response);
      });
  };

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    const name_assignment = parseInt(name.split("A")[1]);
    if (name_assignment !== parseInt(value)) {
      setChanges({ ...changes, [name_assignment]: value });
    } else if (changes != null) {
      const newItems = { ...changes };
      delete newItems[name_assignment];
      setChanges(newItems);
    }
  };

  if (!access) return <ErrorPage />;
  if (assignments.length == 0) return <LoadingContent />;

  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Titular Drivers" />
        <Form noValidate onSubmit={submitForm}>
          <div id="admin-titular-driver-container" className="form-group">
            <Form.Group className="form-group">
              <Form.Select
                value={typeChampionship}
                required
                onChange={(e: any) => {
                  setTypeChampionship(e.target.value);
                }}
              >
                <option value="1">Main Championship</option>
                <option value="3">Secondary Championship</option>
              </Form.Select>
            </Form.Group>
            {assignments.map((team: any, index: number) => {
              return (
                <div className="flex" key={index}>
                  <div className="team-image-container flex-center">
                    <img
                      alt={team.drivers[0].team_name}
                      title={team.drivers[0].team_name}
                      src={`/assets/TeamChampionship/${
                        team.team_championship_id
                      }.png?dummy=${Math.floor(Math.random() * 1001)}`}
                    />
                  </div>
                  <div className="team-select-container">
                    {team.drivers.map((driver: any, index: number) => {
                      return (
                        <div className="flex" key={index}>
                          <b
                            style={{
                              background: driver.main_color,
                              color: driver.secondary_color,
                            }}
                          >
                            {driver.number}
                          </b>
                          <Form.Select
                            name={"A" + driver.assignment_id}
                            required
                            onChange={(e) => {
                              inputChange(e);
                            }}
                            className="form-control"
                            style={{
                              background: driver.main_color,
                              color: driver.secondary_color,
                            }}
                            defaultValue={
                              driver.username == null
                                ? "TBA"
                                : driver.assignment_id
                            }
                          >
                            <option value="TBA">TBA</option>
                            {totalAssignments
                              .filter(
                                (assignment) =>
                                  assignment.team_championship_id ===
                                    team.team_championship_id &&
                                  driver.team_number === assignment.team_number
                              )
                              .map((driver) => {
                                if (driver.username != null)
                                  return (
                                    <option value={driver.assignment_id}>
                                      {driver.username}
                                    </option>
                                  );
                              })}
                          </Form.Select>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-center">
            <Button variant="primary" type="submit">
              Update Drivers
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateDriver;
