import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const LoadingContent = () => {
  return (
    <div className="height-100 column-around">
      <FontAwesomeIcon icon={faSpinner} />
    </div>
  );
};

export default LoadingContent;
