import "../../assets/style/grandprix_tables.css";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Resizables from "../../views/livetiming/parts/Resizables";
import useInterval from "../../utils/custom-hooks/useInterval";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-input-slider";
import {
  faStop,
  faPause,
  faPlay,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";

const GrandPrixRecord = ({
  gp,
  data_race,
  length,
  session,
}: {
  gp: {
    circuit_id: number;
    date: Date;
    gp_race_event_id: number;
    type_race: number;
    type_races: number;
    type_championship: number;
    game_id: number;
  };
  data_race: { server_timing: ServerTiming; server_live: CircleDriver[] }[];
  length: number;
  session: string;
}) => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [isRewind, setIsRewind] = useState(false);
  const [slider, setSlider] = useState(0);
  const [interval, setInterval] = useState(1000);

  useEffect(() => {
    //We set slider to the beggining
    setSlider(0);
    setPaused(false);
    setIndex(0);
  }, [session]);

  useInterval(
    async () => {
      if (paused) return;
      //foreach race event
      let new_index_value = 0;
      if (isRewind) {
        if (index == 0) new_index_value = 0;
        else new_index_value = index - 1;
      } else {
        if (index + 1 >= data_race.length) {
          stop();
          new_index_value = index;
        } else new_index_value = index + 1;
      }
      setIndex(new_index_value);
      setSlider((index / data_race.length) * 100);
    },
    // Delay in milliseconds or null to stop it
    interval
  );

  const stop = () => {
    setPaused(true);
    setIndex(0);
    setSlider(0);
    setInterval(1000);
  };

  const forward = () => {
    if (!isRewind) {
      if (interval > 250) setInterval(interval / 2);
    } else {
      setInterval(1000);
    }
    setIsRewind(false);
  };

  const rewind = () => {
    if (isRewind) {
      if (interval > 250) setInterval(interval / 2);
    } else {
      setInterval(1000);
    }
    setIsRewind(true);
  };

  const getRaceName = (race: { type_race: number }) => {
    if (length === 2 && race.type_race === 1) return t("MainRace");
    else if (length === 2 && race.type_race === 2) return t("SprintRace");
  };
  return (
    <div className="livetiming-race-container">
      <h3>{getRaceName(gp)}</h3>
      {data_race == null ? (
        <>{t("NoData")}</>
      ) : (
        <>
          <Resizables
            gp={gp}
            circleDrivers={data_race[index].server_live}
            serverTiming={data_race[index].server_timing}
          />

          <div className="control-panel">
            <div>
              <FontAwesomeIcon icon={faBackward} onClick={() => rewind()} />
              {paused && (
                <FontAwesomeIcon
                  icon={faPlay}
                  onClick={() => {
                    setPaused(false);
                    setInterval(1000);
                    setIsRewind(false);
                  }}
                />
              )}
              {!paused && (
                <FontAwesomeIcon
                  icon={faPause}
                  onClick={() => setPaused(true)}
                />
              )}
              <FontAwesomeIcon icon={faStop} onClick={() => stop()} />
              <FontAwesomeIcon icon={faForward} onClick={() => forward()} />
            </div>
            <div className="timeline-container">
              <Slider
                axis="x"
                x={slider}
                onChange={({ x }) => {
                  setSlider(x);
                  let index_ = parseInt(data_race.length * (x / 100) + "");
                  if (index_ >= data_race.length) index_ = data_race.length - 1;
                  setIndex(index_);
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GrandPrixRecord;
