import React from "react";
import { useTranslation } from "react-i18next";

const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <div id="maintenance-container" className="default-color">
      <h3>{t("Oops")}</h3>
      <p>{t("MaintenanceMessage")}</p>
    </div>
  );
};

export default Maintenance;
