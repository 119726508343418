import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../../components/Admin/Title";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const SkipShift = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Skip Shift";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [driver, setDriver] = useState("");
  const [validated, setValidated] = useState(false);
  const [typeChampionship, setTypeChampionship] = useState("1");
  const [uploading, setUploading] = useState(false);
  const [drivers, setDrivers] = useState<
    { driver: { username: string }; driver_id: number }[]
  >([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main/getNewChampionshipData`,
        {
          params: {
            isSecondChamp: typeChampionship === "3" ? 1 : 0,
          },
        }
      )
      .then(({ data }) => {
        setDrivers(
          data.picker_list.filter((row: { state: number }) => row.state === 0)
        );
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [typeChampionship, uploading]);

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/skipShift`,
          {
            driver,
            user,
            token: JSON.parse(token).token,
            type_championship: typeChampionship,
          }
        )
        .then(() => {
          toast.success("The turn has been passed correctly");
          setUploading(false);
          setValidated(false);
          setDriver("");
        })
        .catch(({ response }) => {
          toast.error("Error");
          setUploading(false);
          setValidated(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Skip Shifts" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="3">
              <Form.Label>Championship (*)</Form.Label>
              <Form.Select
                required
                value={typeChampionship}
                onChange={(e: any) => {
                  setTypeChampionship(e.target.value);
                }}
              >
                <option value="1">Main</option>
                <option value="3">Secondary</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="9">
              <Form.Label>Driver (*)</Form.Label>
              <Form.Select
                required
                value={driver}
                onChange={(e: any) => {
                  setDriver(e.target.value);
                }}
              >
                <option value="">None</option>
                {drivers.map((driver) => {
                  return (
                    <option value={driver.driver_id} key={driver.driver_id}>
                      {driver.driver.username}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Skip Shift
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SkipShift;
