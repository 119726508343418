import { useEffect, useState } from "react";
import axios from "axios";
import { i18n } from "../../App";
import { useTranslation } from "react-i18next";
import "../../assets/style/historic.css";
import SeasonsChart from "../../components/Historic/SeasonsChart";

const Seasons = () => {
  const { t } = useTranslation();
  document.title = `LVF1 - ${t("Seasons")} & ${t("Events")}`;
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState<{ category: string }[]>([]);
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/historic/getSeasons`
      )
      .then(({ data }) => {
        translateData(data.data);
        translateCategories(data.categories);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [i18n.language, window.localStorage.getItem("theme")]);

  const translateData = (data: []) => {
    data.forEach((data_: { category: string }) => {
      data_.category = t(data_.category);
    });
    setData(data);
  };

  const translateCategories = (categories: { category: string }[]) => {
    categories.forEach((category) => {
      category.category = t(category.category);
    });
    setCategories(categories);
  };

  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="seasons-container" className="frame-turqoise">
        <h3 className="text-center frame-title">
          {t("Seasons").toUpperCase()} & {t("Events").toUpperCase()}
        </h3>
        <div id="seasons-chart-container">
          <SeasonsChart data={data} categories={categories} />
        </div>
      </div>
    </div>
  );
};

export default Seasons;
