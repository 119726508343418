import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Championship = ({
  typeChamp,
  isActive,
  name,
  notiEntries,
  notiTransfers,
  showTransfersMenu,
  collapsed,
  championshipPages,
  showTeams,
}: {
  typeChamp: number;
  isActive: boolean;
  name: string;
  notiEntries: boolean;
  notiTransfers: boolean;
  showTransfersMenu: boolean;
  collapsed: boolean;
  championshipPages: string[];
  showTeams?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [champClass, setChampClass] = useState("");

  useEffect(() => {
    setChampClass(
      checkIfStringStartsWith(window.location.pathname, championshipPages)
        ? "active-menu"
        : ""
    );
  }, [championshipPages, window.location.pathname]);

  const checkIfStringStartsWith = (str: string, list: string[]) => {
    if (str.includes(t("routes:News")))
      return list.some((substr) => str.startsWith(substr));
    return list.includes(str);
  };

  const handleClick = (e: any) => {
    const championship = "/" + encodeURI(name.replace(/ /g, ""));
    if (e.target.className !== "dropdown-item") {
      if (t("routes:Home") == "/") navigate(`${championship}`);
      else navigate(`${t("routes:Home")}${championship}`);
    }
  };

  const handleLink = () => {
    const championship = "/" + encodeURI(name.replace(/ /g, ""));
    if (t("routes:Home") == "/") return `${championship}`;
    else return `${t("routes:Home")}${championship}`;
  };

  const handleMenuLink = (page: string) => {
    if (typeChamp === 1) return page;
    else return page + "/" + encodeURIComponent(name.replace(/ /g, ""));
  };

  const handleMenuStandings = () => {
    if (typeChamp === 1) return t("routes:Standings");
    else if (typeChamp === 3)
      return (
        t("routes:Season") + "/" + encodeURIComponent(name.replace(/ /g, ""))
      );
    return "/";
  };

  return !isActive ? (
    <Nav.Link as={Link} to={handleLink()} href="#" className={champClass}>
      <>{name}</>
    </Nav.Link>
  ) : (
    <NavDropdown
      title={
        notiEntries || notiTransfers ? (
          <>
            <FontAwesomeIcon icon={faCircle} className="notification red" />{" "}
            {name}
          </>
        ) : (
          <>{name}</>
        )
      }
      className={champClass}
      show={collapsed ? undefined : showDropdown}
      onMouseEnter={() => {
        !collapsed && setShowDropdown(true);
      }}
      onMouseLeave={() => {
        !collapsed && setShowDropdown(false);
      }}
      onClick={(e) => {
        !collapsed && handleClick(e);
        !collapsed && setShowDropdown(false);
      }}
    >
      {collapsed && (
        <NavDropdown.Item
          as={Link}
          to={handleLink()}
          href="#"
          onClick={() => setShowDropdown(false)}
        >
          {t("Home")}
        </NavDropdown.Item>
      )}
      {showTransfersMenu && (
        <NavDropdown.Item
          as={Link}
          to={handleMenuLink(t("routes:Transfers"))}
          onClick={() => setShowDropdown(false)}
        >
          {notiTransfers ? (
            <FontAwesomeIcon icon={faCircle} className="notification red" />
          ) : (
            <></>
          )}
          {t("Transfers")}
        </NavDropdown.Item>
      )}
      {showTeams && (
        <NavDropdown.Item
          as={Link}
          to={handleMenuLink(t("routes:Drivers"))}
          href="#"
          onClick={() => setShowDropdown(false)}
        >
          {t("Drivers")}
        </NavDropdown.Item>
      )}
      {typeChamp !== 4 && (
        <NavDropdown.Item
          as={Link}
          to={handleMenuStandings()}
          href="#"
          onClick={() => setShowDropdown(false)}
        >
          {t("Standings")}
        </NavDropdown.Item>
      )}
      <NavDropdown.Item
        as={Link}
        to={handleMenuLink(t("routes:RaceEntries"))}
        href="#"
        onClick={() => setShowDropdown(false)}
      >
        {notiEntries ? (
          <FontAwesomeIcon icon={faCircle} className="notification red" />
        ) : (
          <></>
        )}
        {t("RaceEntries")}
      </NavDropdown.Item>

      <NavDropdown.Item
        as={Link}
        to={handleMenuLink(t("routes:Regulation"))}
        href="#"
        onClick={() => setShowDropdown(false)}
      >
        {t("Regulation")}
      </NavDropdown.Item>
      <NavDropdown.Item
        as={Link}
        to={handleMenuLink(t("routes:Livetiming"))}
        href="#"
        onClick={() => setShowDropdown(false)}
      >
        {t("Live Timing")}
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default Championship;
