import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const GrandPrixSessionTitle = (props: any) => {
  const session = props.session;
  const race_length = props.race_length;
  const suffix = props.suffix;

  const [sessionName, setSessionName] = useState(session);
  const { t } = useTranslation();
  useEffect(() => {
    if (race_length == 1 && session === "R1")
      setSessionName(t("Race").toUpperCase());
    else if (race_length < 3 && session === "R1")
      setSessionName(t("MainRace").toUpperCase());
    else if (race_length < 3 && session === "R2")
      setSessionName(t("SprintRace").toUpperCase());
  }, []);
  return (
    <label className="frame-title">
      {sessionName}
      {suffix != null && ` - ${suffix.toUpperCase()}`}
    </label>
  );
};

export default GrandPrixSessionTitle;
