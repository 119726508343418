import React from "react";
import { useTranslation } from "react-i18next";

const GrandPrixTyre = (props: any) => {
  const tyre = props.tyre;
  useTranslation();

  return (
    <img
      title={tyre.toUpperCase()}
      alt={tyre.toUpperCase()}
      className="tyre-image"
      src={`/assets/Tyres/${tyre}.png`}
    />
  );
};

export default GrandPrixTyre;
