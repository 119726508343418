import "../../assets/style/grandprix.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faArrowDown19,
  faFlagCheckered,
  faGaugeSimpleHigh,
  faChartGantt,
  faChartLine,
  faCarBurst,
  faMugHot,
  faMedal,
  faGavel,
  faShuffle,
} from "@fortawesome/free-solid-svg-icons";
const GrandPrixNavbar = ({
  name,
  active,
}: {
  name: string;
  active: number;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dataGP, setDataGP] = useState<any>();

  const handleIndex = () => {
    navigate(`${t("routes:GrandPrix")}/${name}`);
  };
  const handleQualifying = () => {
    navigate(`${t("routes:Qualifying")}/${name}`);
  };
  const handleRace = () => {
    navigate(`${t("routes:Race")}/${name}`);
  };
  const handleFastestLaps = () => {
    navigate(`${t("routes:FastestLaps")}/${name}`);
  };
  const handleLeaders = () => {
    navigate(`${t("routes:Leaders")}/${name}`);
  };
  const handlePositionChart = () => {
    navigate(`${t("routes:PositionChart")}/${name}`);
  };
  const handleGapChart = () => {
    navigate(`${t("routes:GapChart")}/${name}`);
  };
  const handleIncidents = () => {
    navigate(`${t("routes:Incidents")}/${name}`);
  };
  const handleClaims = () => {
    navigate(`${t("routes:Findings")}/${name}`);
  };
  const handlePostRace = () => {
    navigate(`${t("routes:PostRace")}/${name}`);
  };
  const handleRaceLivetiming = () => {
    navigate(`${t("routes:RaceLivetiming")}/${name}`);
  };
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getGrandPrixByName`,
        {
          params: {
            name,
          },
        }
      )
      .then(({ data }) => {
        document.title = "LVF1 - " + data.name;
        setDataGP(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [name]);
  if (dataGP == null) return <></>;
  return (
    <>
      <h3 id="gp-title" className="text-center">
        {dataGP.name}
      </h3>
      {dataGP.played >= 1 && (
        <div className="submenu flex-center">
          <button
            className={`submenu-button ${active == 1 && "submenu-selected"}`}
            onClick={handleIndex}
          >
            <FontAwesomeIcon icon={faHouse} /> <label>{t("Index")}</label>
          </button>
          {dataGP.type_races !== 1 && (
            <button
              className={`submenu-button ${active == 2 && "submenu-selected"}`}
              onClick={handleQualifying}
            >
              <FontAwesomeIcon icon={faArrowDown19} />
              <label>{t("Qualifying")}</label>
            </button>
          )}
          <button
            className={`submenu-button ${active == 3 && "submenu-selected"}`}
            onClick={handleRace}
          >
            <FontAwesomeIcon icon={faFlagCheckered} />
            <label>{t("Race")}</label>
          </button>
          {dataGP.hasRecord === true && (
            <button
              className={`submenu-button ${active == 11 && "submenu-selected"}`}
              onClick={handleRaceLivetiming}
            >
              <FontAwesomeIcon icon={faShuffle} />
              <label>{t("RaceLivetiming")}</label>
            </button>
          )}
          {dataGP.hasXML === true && (
            <>
              <button
                className={`submenu-button ${
                  active == 4 && "submenu-selected"
                }`}
                onClick={handleFastestLaps}
              >
                <FontAwesomeIcon icon={faGaugeSimpleHigh} />
                <label>{t("HotLaps")}</label>
              </button>
              {dataGP.type_races !== 1 && (
                <>
                  <button
                    className={`submenu-button ${
                      active == 5 && "submenu-selected"
                    }`}
                    onClick={handleLeaders}
                  >
                    <FontAwesomeIcon icon={faMedal} />
                    <label>{t("Leaders")}</label>
                  </button>
                  <button
                    className={`submenu-button ${
                      active == 6 && "submenu-selected"
                    }`}
                    onClick={handlePositionChart}
                  >
                    <FontAwesomeIcon icon={faChartGantt} />
                    <label>{t("GraphPositions")}</label>
                  </button>
                  <button
                    className={`submenu-button ${
                      active == 7 && "submenu-selected"
                    }`}
                    onClick={handleGapChart}
                  >
                    <FontAwesomeIcon icon={faChartLine} />
                    <label>{t("GraphicGaps")}</label>
                  </button>
                </>
              )}
              <button
                className={`submenu-button ${
                  active == 8 && "submenu-selected"
                }`}
                onClick={handleIncidents}
              >
                <FontAwesomeIcon icon={faCarBurst} />
                <label>{t("Incidents")}</label>
              </button>
              {dataGP.type_races !== 1 && dataGP.type_championship !== 2 && (
                <button
                  className={`submenu-button ${
                    active == 9 && "submenu-selected"
                  }`}
                  onClick={handleClaims}
                >
                  <FontAwesomeIcon icon={faGavel} />
                  <label>{t("Findings")}</label>
                </button>
              )}
            </>
          )}
          {dataGP.type_championship !== 2 && (
            <button
              className={`submenu-button ${active == 10 && "submenu-selected"}`}
              onClick={handlePostRace}
            >
              <FontAwesomeIcon icon={faMugHot} />
              <label>{t("PostRace")}</label>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default GrandPrixNavbar;
