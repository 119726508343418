import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Title from "../../../components/Admin/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

const CreateCustomScoringName = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Create Custom Scoring Name";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState("");
  const [championship, setChampionship] = useState("");
  const [scoring, setScoring] = useState("");
  const [championships, setChampionships] = useState<
    { id: number; name: string }[]
  >([]);
  const [scorings, setScorings] = useState<
    { id: number; description: string }[]
  >([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/general/getChampionshipsAndScorings`,
        {
          params: {
            championship,
          },
        }
      )
      .then(({ data }) => {
        setChampionship(data.championship);
        setScorings(data.scorings);
        setChampionships(data.championships);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [championship]);

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/createScoringVariantName`,
          {
            championship,
            scoring,
            name,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(({ data }) => {
          toast.success("Scoring variant created successfully");
          setName("");
          setValidated(false);
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          throw new Error(response);
        });
    } else setValidated(true);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Create Custom Scoring Name" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Championship (*)</Form.Label>
              <Form.Select
                value={championship}
                required
                onChange={(e: any) => {
                  setChampionship(e.target.value);
                }}
              >
                <option value="" disabled>
                  None
                </option>
                {championships != null &&
                  championships.map((championship) => {
                    return (
                      <option value={championship.id} key={championship.id}>
                        {championship.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Scoring (*)</Form.Label>
              <Form.Select
                value={scoring}
                required
                onChange={(e: any) => {
                  setScoring(e.target.value);
                }}
              >
                <option value="" disabled>
                  None
                </option>
                {scorings != null &&
                  scorings.map((scoring) => {
                    return (
                      <option value={scoring.id} key={scoring.id}>
                        {scoring.description}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="12">
              <Form.Label>Name (*)</Form.Label>
              <Form.Control
                value={name}
                required
                onChange={(e: any) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>
          </Row>

          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Create Scoring Variant Name
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateCustomScoringName;
