import "../../assets/style/grandprix_tables.css";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import { useUser } from "../../components/UserContext";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LoadingContent from "../../components/LoadingContent";
import DriverOfTheDay from "./postrace/DriverOfTheDay";
import Statements from "./postrace/Statements";

const PostRace = () => {
  useTranslation();
  const { user } = useUser() as any;
  const [loading, setLoading] = useState(true);
  let { grandprix } = useParams();
  const [data, setData] = useState({
    driver_of_the_day_votes: [],
    event_drivers: [],
    race_statements: [],
    user_voted: false,
    user_wrote: false,
  });

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getPostRace`,
        {
          params: {
            name: grandprix,
            user,
          },
        }
      )
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, [loading, user, window.localStorage.getItem("theme")]);

  if (loading) return <LoadingContent />;
  if (grandprix == null || data.event_drivers.length === 0)
    return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={10} />
      <div className="post-race-container flex">
        <DriverOfTheDay data={data} setLoading={setLoading} />
        <Statements data={data} setLoading={setLoading} />
      </div>
    </div>
  );
};

export default PostRace;
