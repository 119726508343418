import "../../assets/style/grandprix_tables.css";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import GrandPrixSessionTitle from "../../components/GrandPrix/GrandPrixSessionTitle";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import axios from "axios";
import LoadingContent from "../../components/LoadingContent";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const GrandPrixLineChart = (props: { type_chart: string }) => {
  const { t } = useTranslation();
  let { grandprix } = useParams();
  const type_chart = props.type_chart;
  const [loading, setLoading] = useState(true);
  const [loadingChart, setLoadingChart] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [charts, setCharts] = useState<any>([]);
  //const [update, setUpdate] = useState(0);
  useEffect(() => {
    //First, we remove the previous charts in case they exist
    charts.forEach((chart: any) => {
      chart.dispose();
    });
    const url_post = type_chart === "GAPS" ? "getGapChart" : "getPositionChart";
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/${url_post}`,
        {
          params: {
            name: grandprix,
          },
        }
      )
      .then(({ data }) => {
        setChartData(data);
        setLoading(false);
        Object.keys(data).forEach((key) => {
          setTimeout(() => {
            var div = document.getElementById(`chartRdiv${type_chart}${key}`);
            if (div) {
              var root = am5.Root.new(`chartRdiv${type_chart}${key}`);
              chart(root, data[key]);
              //We save the charts in case we have to remove it later
              setCharts((charts: any) => [...charts, root]);
            }
          }, 0);
        });
        setLoadingChart(false);
      })
      .catch(({ response }) => {
        setLoadingChart(false);
        setLoading(false);
        throw new Error(response);
      });
    //if (update === 0) setUpdate(1);
  }, [grandprix, props.type_chart, window.localStorage.getItem("theme")]);

  const chart = (root: am5.Root, data: any) => {
    //Colours deppending on the drak/light mode (labels and grid)
    const defaultColour =
      window.localStorage.getItem("theme") === "dark" ? "#FFFFFF" : "#000000";
    const myTheme = am5.Theme.new(root);
    myTheme.rule("Label").setAll({
      fill: am5.color(defaultColour),
    });
    myTheme.rule("Grid").setAll({
      stroke: am5.color(defaultColour),
    });

    root.setThemes([
      am5themes_Animated.new(root),
      myTheme,
      am5themes_Material.new(root),
    ]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    //We prevent the mouse wheel events
    chart.plotContainer.events.on("wheel", function (ev: any) {
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault();
        chart.set("wheelX", "panX");
        chart.set("wheelY", "zoomX");
      } else {
        chart.set("wheelX", "none");
        chart.set("wheelY", "none");
      }
    });

    // Create Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: type_chart === "GAPS" ? 0 : 1,
        max: data.ChartData[0].length - 1,
        strictMinMax: true,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: true,
        }),
      })
    );

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 1,
        max: data.ChartData.length,
        strictMinMax: true,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );
    // Create series
    function createSeries(
      name: string,
      field: string,
      main_color: string,
      secondary_color: string
    ) {
      // Changing the text color of the tooltips texts (and sizes)
      let tooltip: any = am5.Tooltip.new(root, {
        autoTextColor: false,
        paddingBottom: 3,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
      });

      //Default options
      let labelOptions: {} = {
        fontSize: 9,
      };
      let seriesOptions = {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        valueXField: "lap",
        calculateAggregates: true,
        tooltip: tooltip,
        legendLabelText: "[bold {stroke}]{name}:[/]",
        legendRangeLabelText: "[{stroke}]{name}:[/]",
        legendValueText: "[bold {stroke}]{valueY}[/]",
        legendRangeValueText: "[{stroke}]{valueYClose}[/]",
      };

      // If discomment, we take the main team colors for the lines
      /*if (secondary_color != null && main_color != null) {
        labelOptions = {
          fill: am5.color(secondary_color),
          fontSize: 9,
        };
        
         seriesOptions = {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "lap",
          calculateAggregates: true,
          tooltip: tooltip,
          legendLabelText: "[bold {stroke}]{name}:[/]",
          legendRangeLabelText: "[{stroke}]{name}:[/]",
          legendValueText: "[bold {stroke}]{valueY}[/]",
          legendRangeValueText: "[{stroke}]{valueYClose}[/]",
          fill: main_color,
          stroke: main_color,
        };
      }*/
      tooltip.label.setAll(labelOptions);

      var series = chart.series.push(am5xy.LineSeries.new(root, seriesOptions));
      series.strokes.template.set("strokeWidth", 3);

      if (series != null) {
        const tooltip = series.get("tooltip");
        if (tooltip != null)
          tooltip.label.set(
            "text",
            `[bold]{name}[/] (L{valueX}: {valueY}${
              type_chart === "POSITIONS" ? "º" : "s"
            })`
          );
        series.data.setAll(data.ChartData);
      }
    }
    data.Drivers.forEach((driver: any) => {
      createSeries(
        driver.name,
        `pos${driver.pos}`,
        driver.main_color,
        driver.secondary_color
      );
    });

    // Add cursor
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomXY",
        xAxis: xAxis,
      })
    );

    /*xAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );

    yAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );*/

    var legend = chart.children.push(
      am5.Legend.new(root, {
        height: 150,
        width: am5.percent(100),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.data.setAll(chart.series.values);
  };

  if (loading || loadingChart) return <LoadingContent />;
  if (grandprix == null || Object.keys(chartData).length === 0)
    return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar
        name={grandprix}
        active={type_chart === "GAPS" ? 7 : 6}
      />
      {!loading &&
        Object.keys(chartData).map((key) => {
          return (
            <div className="chart-container" key={key}>
              <GrandPrixSessionTitle
                session={key}
                race_length={Object.entries(chartData).length}
                suffix={
                  type_chart === "GAPS" ? t("GraphicGaps") : t("GraphPositions")
                }
              />
              <div
                key={key}
                id={`chartRdiv${type_chart}${key}`}
                style={{ width: "100%", height: "calc(100vh - 250px" }}
              ></div>
            </div>
          );
        })}
    </div>
  );
};

export default GrandPrixLineChart;
