import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Title from "../../../components/Admin/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import useIsAdmin from "../../../hooks/useIsAdmin";
import ErrorPage from "../../ErrorPage";
import { useUser } from "../../../components/UserContext";

interface Data {
  name: string;
}

const CreateScoringVariant = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Create Scorings";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState("");
  const [scores, setScores] = useState("");
  const [bonus, setBonus] = useState("");
  const [points, setPoints] = useState<string[]>([]);

  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/createScoringVariant`,
          {
            name,
            points,
            bonus,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(({ data }) => {
          toast.success("Scoring variant created successfully");
          setName("");
          setBonus("");
          setScores("");
          setPoints([]);
          setValidated(false);
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          throw new Error(response);
        });
    } else setValidated(true);
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Create Scoring Variant" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row>
            <Form.Group className="form-group" as={Col} md="10">
              <Form.Label>Name (*)</Form.Label>
              <Form.Control
                value={name}
                required
                onChange={(e: any) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="2">
              <Form.Label># Scores (*)</Form.Label>
              <Form.Control
                value={scores}
                required
                type="number"
                onChange={(e: any) => {
                  setScores(e.target.value);
                  setPoints((points: string[]) => {
                    const diff = parseInt(e.target.value) - points.length;
                    if (diff > 0) {
                      for (let i = 0; i < diff; i++) {
                        points.push("");
                      }
                    } else if (diff < 0) {
                      for (let i = diff; i < 0; i++) {
                        points.pop();
                      }
                    }
                    return points;
                  });
                }}
              />
            </Form.Group>
          </Row>
          <Row>
            {points.map((points_: string, index) => {
              return (
                <Form.Group className="form-group scoring-group" key={index}>
                  <Form.Label className="text-center full-width">
                    <b>{index + 1}º (*)</b>
                  </Form.Label>
                  <Form.Control
                    value={points_}
                    required
                    type="number"
                    onChange={(e: any) => {
                      const updatePoints = [...points];
                      updatePoints[index] = e.target.value;
                      setPoints(updatePoints);
                    }}
                  />
                </Form.Group>
              );
            })}
            {points.length > 0 && (
              <Form.Group className="form-group scoring-group">
                <Form.Label className="text-center full-width">
                  <b>BONUS</b>
                </Form.Label>
                <Form.Control
                  value={bonus}
                  type="number"
                  onChange={(e: any) => {
                    setBonus(e.target.value);
                  }}
                />
              </Form.Group>
            )}
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Create Scoring Variant
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateScoringVariant;
