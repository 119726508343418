import axios from "axios";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useUser } from "../../../components/UserContext";
import { toast } from "react-toastify";
import DateFormat from "../../../components/DateFormat";

const REntriesSecondary = ({
  raceData,
  setUpdateNotifications,
  setUpdate,
  update,
  dates,
  datesO,
  typeChamp,
}: {
  raceData: RaceEntries;
  setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
  setUpdate: Dispatch<SetStateAction<boolean>>;
  update: boolean;
  dates: string[];
  datesO: string[];
  typeChamp: number;
}) => {
  const { t } = useTranslation();
  const { user } = useUser() as any;

  const driverJSX = (driver: ReserveDriver | DriverEntry | DriversSec) => {
    return (
      <Link
        className={`driver ${
          driver.state === 1
            ? "green"
            : driver.state === 2
            ? "orange"
            : driver.state === 3
            ? "red"
            : ""
        }`}
        title={driver.username}
        to={`${t("routes:Driver")}/${encodeURIComponent(driver.username)}`}
      >
        {driver.username}
      </Link>
    );
  };

  const sendState = (state: number, session: number) => {
    let token = window.localStorage.getItem("userLogged");
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/setDriverState`,
        {
          user,
          token: JSON.parse(token).token,
          state,
          session,
          typeChamp,
        }
      )
      .then(({ data }) => {
        if (state === 1) toast.success(`${t("RaceEntrySigned")}`);
        else if (state === 2) toast.success(`${t("RaceEntryDoubt")}`);
        else if (state === 3) toast.success(`${t("RaceEntryAbsent")}`);
        setUpdate(!update);
        setUpdateNotifications((prev) => {
          return !prev;
        });
      })
      .catch(({ response }) => {
        toast.error(`${t("Error!")}`);
        throw new Error(response);
      });
  };

  return (
    <div id="race-entries-right">
      {dates.map((date, index) => {
        const filtered_drivers = raceData.drivers_sec.filter((driver) => {
          const session = driver.session;
          if (datesO[session - 1] === date) return true;
          return false;
        });
        const currentDriver = filtered_drivers.find(
          (driver) => driver.username == user
        );
        return (
          <React.Fragment key={index}>
            <div id="driver-entries-container">
              <p className="backgrounded-title text-center">
                {t("Session")} <DateFormat date={date} format={"full"} />
              </p>
              <div className="text-center hc-drivers">
                {user == null ? (
                  <p className="red bold">{t("LogInToSignUp")}</p>
                ) : (
                  <div id="buttons-container" className="flex-between">
                    {currentDriver == null ? (
                      <Button
                        className="button-green"
                        onClick={() => sendState(1, index)}
                      >
                        {t("Register")}
                      </Button>
                    ) : (
                      <Button
                        className="button-red"
                        onClick={() => sendState(3, index)}
                      >
                        {t("Absence")}
                      </Button>
                    )}
                  </div>
                )}
                {filtered_drivers.length === 0 ? (
                  t("NoSignedDrivers")
                ) : (
                  <>
                    <span id="registered-drivers">
                      {t("RegisteredDrivers")}
                    </span>
                    {filtered_drivers.map((driver, index) => {
                      return (
                        <b>
                          <span key={index}>{driverJSX(driver)}</span>
                        </b>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default REntriesSecondary;
