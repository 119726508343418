import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flag from "../../../../components/Flag";
import { Link } from "react-router-dom";
import getTextColor from "../../../../utils/DynamicContrast";
import { useTranslation } from "react-i18next";
import { teamLogo } from "../../../../utils/Standings";

const TeamTopStandings = ({
  lastGP,
  scrollStandings,
}: {
  lastGP: {
    driver_standings: any;
    team_standings: any;
    has_teams: false;
  };
  scrollStandings: (standings: number, direction: number, id: number) => void;
}) => {
  const { t } = useTranslation();

  const showStandingTable = () => {
    let jsx: any = [];
    let last_class: any = null;
    let total = 0;

    lastGP.team_standings.forEach((row: any, index: number) => {
      if (row.class !== last_class || index === 0) {
        const catalogs = new Set();

        for (const obj of lastGP.driver_standings) {
          catalogs.add(obj.catalog);
        }

        jsx.push(
          <div className="class-container" key={index}>
            {catalogs.size > 1 && (
              <div className="flex-center">
                <label
                  className="class_content"
                  style={{ background: row.class_color }}
                >
                  {row.class.toUpperCase()}
                </label>
              </div>
            )}
            <div
              className={`general-standings-container ${
                catalogs.size > 2 ? "has-many-classes" : ""
              }`}
            >
              <button
                className="scroll-button scroll-down"
                onClick={(e) => scrollStandings(1, 1, row.catalog)}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </button>
              <button
                className="scroll-button scroll-up hide"
                onClick={(e) => scrollStandings(1, 0, row.catalog)}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </button>
              <div
                className={`standings-container team-standings-${row.catalog} ${
                  lastGP.has_teams ? "" : "secondaryChamp"
                }`}
              >
                {lastGP.team_standings
                  .filter((row_: any) => row_.class === row.class)
                  .map(function (obj: any, index: number) {
                    return (
                      <div className="standings-team flex" key={index}>
                        <span className="standings-position flex-center">
                          {obj.position}
                        </span>
                        <div
                          className="teamdriver-container"
                          style={{
                            background:
                              obj.main_color == null
                                ? "#3bd8c9"
                                : obj.main_color,
                          }}
                        >
                          <div className="logo-container flex-center">
                            {teamLogo(obj.team_id, obj.logo, obj.team_name)}
                          </div>
                          <Link
                            to={`${t("routes:Team")}/${encodeURIComponent(
                              obj.team_name
                            )}`}
                            style={{ color: getTextColor(obj.main_color) }}
                          >
                            {obj.team_name}
                          </Link>
                        </div>
                        <span className="standings-points flex-center">
                          {obj.points}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
        last_class = row.class;
        total++;
      }
    });

    return jsx;
  };
  {
    return (
      <div className="standings-class-container">{showStandingTable()}</div>
    );
  }
};

export default TeamTopStandings;
