import { Form, Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FormEventHandler, useRef, useState } from "react";
import { useUser } from "../UserContext";
import axios from "axios";
import { toast } from "react-toastify";

const CircuitForm = ({
  dataForm,
  setDataForm,
  isUpdate,
}: {
  dataForm: {
    name: string;
    layout: string;
    turns: string;
    length: string;
    latitude: string;
    longitude: string;
    stage: boolean;
    id: string;
  };
  setDataForm: (data: any) => void;
  isUpdate?: boolean;
}) => {
  const token = window.localStorage.getItem("userLogged");
  const { user } = useUser() as any;
  const [picture, setPicture] = useState();
  const [uploading, setUploading] = useState(false);
  const [validated, setValidated] = useState(false);
  const refAIW = useRef<any>(null);

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const stageChange = ({ target }: { target: any }) => {
    const { checked } = target;
    setDataForm({
      ...dataForm,
      stage: checked,
    });
  };
  const submitForm = (event: any) => {
    if (token == null) return;
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formDataFiles = new FormData();
    formDataFiles.append("id", dataForm.id);
    formDataFiles.append("stage", dataForm.stage ? "1" : "0");
    formDataFiles.append("name", dataForm.name);
    formDataFiles.append("layout", dataForm.layout);
    formDataFiles.append("turns", dataForm.turns);
    formDataFiles.append("length", dataForm.length);
    formDataFiles.append("latitude", dataForm.latitude);
    formDataFiles.append("longitude", dataForm.longitude);
    formDataFiles.append("user", user);
    formDataFiles.append("token", JSON.parse(token).token);

    if (picture != null) formDataFiles.append("aiw", picture);

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/createOrUpdateCircuit`,
          formDataFiles
        )
        .then(() => {
          toast.success(
            `Circuit ${isUpdate ? "updated" : "created"} successfully`
          );
          setUploading(false);
          setValidated(false);
          if (!isUpdate)
            setDataForm({
              ...dataForm,
              name: "",
              layout: "",
              turns: "",
              length: "",
              latitude: "",
              longitude: "",
              id: "",
            });
        })
        .catch(({ response }) => {
          toast.error("Error: Circuit duplicated");
          setUploading(false);
          setValidated(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={submitForm}>
      <Row className="mb-3">
        <Form.Group className="form-group" as={Col} md="6">
          <Form.Label>Circuit Name (*)</Form.Label>
          <Form.Control
            name="name"
            type="text"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.name}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="6">
          <Form.Label>Layout</Form.Label>
          <Form.Control
            name="layout"
            type="text"
            onChange={(e) => inputChange(e)}
            value={dataForm.layout}
          />
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="3">
          <Form.Label>Turns (*)</Form.Label>
          <Form.Control
            name="turns"
            type="number"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.turns}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="3">
          <Form.Label>Length (*)</Form.Label>
          <Form.Control
            name="length"
            type="number"
            step="0.001"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.length}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="3">
          <Form.Label>Latitude (*)</Form.Label>
          <Form.Control
            name="latitude"
            type="number"
            step="0.0001"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.latitude}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="3">
          <Form.Label>Longitude (*)</Form.Label>
          <Form.Control
            name="longitude"
            type="number"
            step="0.0001"
            required
            onChange={(e) => inputChange(e)}
            value={dataForm.longitude}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="12">
          <Form.Label>AIW File {/*isUpdate ? "" : "(*)"*/}</Form.Label>
          <Form.Control
            type="file"
            accept=".aiw,.AIW"
            /*required={!isUpdate}*/
            ref={refAIW}
            onChange={() => setPicture(refAIW.current.files[0])}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group" as={Col} md="4">
          <Form.Check
            checked={dataForm.stage}
            name="stage"
            onChange={(e) => stageChange(e)}
            label="A->B Circuit"
          />
        </Form.Group>
      </Row>
      <div className="text-center">
        {uploading ? (
          <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
        ) : (
          <Button variant="primary" type="submit">
            {isUpdate ? "Update" : "Create"} Circuit
          </Button>
        )}
      </div>
    </Form>
  );
};

export default CircuitForm;
