import React, { useEffect, useMemo, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faInstagram,
  faTwitter,
  faYoutube,
  faTwitch,
  faPaypal,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div id="social-media" className="flex-center">
        <div className="space-around">
          <a
            href="https://www.youtube.com/channel/UCuJVltR33gR2tUZPSsbIzqA"
            target={"blank"}
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="https://www.twitch.tv/ligavirtualf1" target={"blank"}>
            <FontAwesomeIcon icon={faTwitch} />
          </a>
          <a href="https://discord.gg/RbgxetBjFe" target={"blank"}>
            <FontAwesomeIcon icon={faDiscord} />
          </a>
          <a href="https://twitter.com/LigaVirtualF1es" target={"blank"}>
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.instagram.com/ligavirtual_f1/" target={"blank"}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
      <div id="support" className="text-center">
        <a
          href={"https://www.paypal.com/donate/?hosted_button_id=B5PKHCV493GG4"}
          target={"_blank"}
        >
          <span>
            <FontAwesomeIcon icon={faPaypal} /> {t("SupportLVF1")}
          </span>
        </a>
      </div>
      <div id="copyright-message">
        <span>© 2022 LigaVirtualF1. All rights reserved.</span>
      </div>
      <script
        src="https://www.paypalobjects.com/donate/sdk/donate-sdk.js"
        charSet="UTF-8"
      ></script>
    </footer>
  );
};

export default Footer;
